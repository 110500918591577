<template>
  <div>
    <v-btn
      color="secondary"
      @click="showModal = true"
    >
      {{ $t('changeUsername') }}
    </v-btn>
    <ValidationObserver
      ref="observer"
      v-slot="{ invalid, handleSubmit }"
    >
      <v-dialog
        v-model="showModal"
        max-width="500px"
      >
        <v-card>
          <v-card-title>{{ $t('enterUsername') }}</v-card-title>
          <v-card-text>
            <v-form role="usernameForm">
              <v-text-field
                id="input-username"
                v-model="user.username"
                class="mb-3"
                name="username"
                :label="$t('username')"
                :rules="usernameValidation"
                :custom-validation="true"
                :error-messages="error"
                outlined
                @input="usernameInUse"
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              :disabled="invalid"
              color="secondary"
              class="ma-2"
              @click="handleSubmit(saveUsername)"
            >
              {{ $t('changeMyUsername') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </ValidationObserver>
  </div>
</template>

<script>
import makeHandleService from '@/services/api/handle';
import { handleDuplicateMixin } from '@/mixins/handleDuplicate';
import { AccountTypes } from '@/constants/userConstants';
export default {
  mixins: [handleDuplicateMixin],
  props: {
    /**
     * current account is an organization account or personal account
     * @type {Boolean}
     */

    isOrg: {
      type: Boolean,
      required: true,
    },
    /**
     * previous details including previous handle and handle id
     * {
     *  uid, handle
     * }
     * @type {Object}
     */
    previousDetail: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      user: {
        username: '',
      },
    };
  },
  computed: {
    usernameValidation() {
      return [
        v => !!v || this.$t('inputRequired'),
        v => /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/.test(v) || this.$t('invalidUsername'),
      ];
    },
  },
  methods: {
    /**
     * save new user name to the service
     *
     */
    async saveUsername() {
      try {
        const handleService = makeHandleService(this.$api);
        const handleUpdateData = {
          oldHandle: this.previousDetail.handle,
          name: this.user.username,
          owner_uid: this.previousDetail.owner_uid,
          owner_type: this.isOrg ? AccountTypes.ORGANIZATION : AccountTypes.USER,
          current: true,
        };
        await handleService.updateHandle(handleUpdateData);
        this.showModal = false;
        this.$emit('usernameUpdated', handleUpdateData);
        await this.$swal({
          title: this.$t('usernameUpdated'),
          icon: 'success',
          showConfirmButton: false,
          position: 'top-end',
          timer: 2000,
          toast: true,
        });
      } catch (error) {
        this.$swal({
          icon: 'error',
          title: this.$t('error'),
          text: error,
        });
      }
    },
  },
};
</script>

<style>
</style>