import { showConfirmationDialog, showErrorToast, showSuccessToast } from '@/utils/toast';

import i18n from '../../i18n';
import makeRunService from '@/services/api/run';

const state = {
  items: [],
  selectedRun: {},
  duplicatedRun: {},
  actionType: null,
  addNewRun: {}
};
const mutations = {
  SET_ITEMS: (state, items) => (state.items = items.filter((item) => !item.deleted_at)),
  ADD_ITEM: (state, item) => state.items.unshift(item),
  DELETE_ITEM: (state, itemUid) => (state.items = state.items.filter((item) => item.uid !== itemUid)),
  UPDATE_ITEM: (state, updatedItem) => {
    const index = state.items.findIndex((item) => item.uid === updatedItem.uid);
    if (index !== -1) {
      state.items.splice(index, 1, updatedItem);
    }
  },
  UPDATE_SELECTED: (state, item) => (state.selectedRun = item),
  SET_DUPLICATED_RUN: (state, run) => (state.duplicatedRun = run),
  CLEAR_DUPLICATED_RUN: (state) => (state.duplicatedRun = {}),
  SET_ACTION_TYPE: (state, actionType) => (state.actionType = actionType),
  CLEAR_ACTION_TYPE: (state) => (state.actionType = null),
  SET_ADD_NEW_RUN: (state, addNewRun) => (state.addNewRun = addNewRun),
  CLEAR_ADD_NEW_RUN: (state) => (state.addNewRun = {}),
};

const makeActions = (api) => {
  const runsService = makeRunService(api);
  return {
    fetchItems: async function ({ commit, rootState }, { handle, projectKey, perPage, currentPage }) {
      try {
        const dt = await runsService.getTestRuns(handle, projectKey, perPage, currentPage);
        commit('SET_ITEMS', dt.data.runs);
      } catch (error) {
        console.error('Error fetching items: ', error);
      }
    },
    deleteTestRun: async function ({ dispatch, rootState }, { swal, uid, handle, projectKey, perPage, currentPage }) {
      try {
        const dt = await runsService.deleteTestRun(handle, projectKey, uid);
        if (dt) {
          dispatch('fetchItems', { handle, projectKey, perPage, currentPage });
          if (swal) {
            showSuccessToast(swal, i18n.t('success.runDeleted'));
          }
        }
      } catch (error) {
        if (swal) {
          showErrorToast(swal, i18n.t('error.runDeleteFailed'));
        }
      }
    },
    deleteTestRuns: async function ({ dispatch, rootState }, { swal, handle, projectKey, payload }) {
      try {
        const dt = await runsService.deleteTestRuns(handle, projectKey, {
          ...payload,
        });
        if (dt) {
          dispatch('fetchItems', { handle, projectKey });
          showSuccessToast(swal, i18n.t('success.runDeleted'));
        }
      } catch (error) {
        showErrorToast(swal, i18n.t('error.runDeleteFailed'));
      }
    },
    updateTestRun: async function ({ commit, rootState }, { swal, handle, projectKey, uid, payload }) {
      try {
        const dt = await runsService.updateTestRun(handle, projectKey, uid, {
          ...payload,
        });
        if (dt) {
          commit('UPDATE_ITEM', dt.data);
          showSuccessToast(swal, i18n.t('success.testRunUpdated'));
        }
        return true;
      } catch (error) {
        console.error('Error updating test run: ', error);
        showErrorToast(swal, i18n.t('error.runUpdateFailed'));
        return false;
      }
    },
    updateTestRuns: async function ({ commit, rootState }, { swal, handle, projectKey, uid, payload }) {
      try {
        const dt = await runsService.updateTestRuns(handle, projectKey, {
          ...payload,
        });
        if (dt) {
          commit('UPDATE_ITEM', dt.data);
          showSuccessToast(swal, i18n.t('success.testRunUpdated'));
        }
        return true;
      } catch (error) {
        console.error('Error updating test run: ', error);
        showErrorToast(swal, i18n.t('error.runUpdateFailed'));
        return false;
      }
    },
    createTestRun: async function ({ commit, rootState }, { swal, handle, projectKey, payload }) {
      try {
        const dt = await runsService.createTestRun(handle, projectKey, {
          ...payload,
          // externalId: 'pinata',
          // source: 'pinata',
        });
        if (dt) {
          commit('ADD_ITEM', dt.data);
          showSuccessToast(swal, i18n.t('success.runCreated'));
        }
      } catch (error) {
        showErrorToast(swal, i18n.t('error.runCreateFailed'));
      }
    },
    searchTestRun: async function ({ commit, rootState }, { handle, projectKey, text, perPage, currentPage }) {
      try {
        const dt = await runsService.searchRuns(handle, projectKey, text, perPage, currentPage);
        commit('SET_ITEMS', dt.data.runs);
      } catch (error) {
        console.error('Error searching items: ', error);
      }
    },
    getProjectRuns: async function ({ commit, rootState }, { handle, projectKey, page, limit }) {
      try {
        const dt = await runsService.getProjectRuns(handle, projectKey, page, limit);
        commit('SET_ITEMS', dt.data.runs);
      } catch (error) {
        console.error('Error fetching items: ', error);
        throw error;
      }
    },
    getAllTestRuns: async function ({ commit, rootState }, { handle, projectKey, perPage, currentPage }) {
      try {
        const dt = await runsService.getTestRuns(handle, projectKey, perPage, currentPage);
        commit('SET_ITEMS', dt.data.runs);
      } catch (error) {
        console.error('Error fetching items: ', error);
        throw error;
      }
    },
    getTestRunById: async function ({ commit, rootState }, { handle, projectKey, runId }) {
      try {
        const dt = await runsService.getTestRunById(handle, projectKey, runId);
        if(dt.data.length)
          commit('SET_ITEMS', dt.data);
        
        return dt;
      } catch (error) {
        console.error('Error fetching items: ', error);
        throw error;
      }
    },
    duplicateTestRun: async function ({ commit, rootState },  run) {
      const duplicatedRun = { ...run }; 
      commit('SET_DUPLICATED_RUN', duplicatedRun);
    },
    setSelectedRun: async function ({ commit }, run) {
      commit('UPDATE_SELECTED', run);
    },
    clearDuplicatedRun: async function ({ commit }) {
      commit('CLEAR_DUPLICATED_RUN');
    },
  };
};

export default function makeRun(api) {
  return {
    namespaced: true,
    state,
    mutations,
    actions: makeActions(api),
  };
}
