<template>
  <v-card
    elevation
    flat
    class="px-4 pt-5 pb-8 w-full rounded-lg"
    :class="isCurrent ? 'grey lighten-5 black--text' : 'blue white--text'"
  >
    <v-card-title class="text-h6 font-weight-medium">
      {{ plan.name }}
    </v-card-title>

    <v-card-text
      :class="isCurrent ? 'black--text' : 'white--text'"
      style="height: 300px;"
    >
      <div class="d-flex align-end">
        <p class="text-h3 font-weight-bold text-uppercase mb-0">
          {{ plan.title }}
        </p>
        <p class="mb-0 pb-1 pl-1">
          {{ measurement }}
        </p>
      </div>

      <v-list
        class="mt-5"
        dense
        :class="isCurrent ? 'grey lighten-5' : 'blue'"
      >
        <v-list-item
          v-for="(item, i) in plan.benefits"
          :key="i"
          class="pl-0"
          :class="isCurrent ? 'black--text' : 'white--text'"
        >
          <v-list-item-icon class="mr-3">
            <v-icon :class="isCurrent ? 'blue--text' : 'white--text'">
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="body-2 text-left">
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>

    <v-card-actions>
      <v-btn
        v-if="isCurrent"
        class="text-capitalize"
        width="100%"
        disabled
      >
        {{ $t('billing.selectedPlan') }}
      </v-btn>
      <v-btn
        v-else
        color="white"
        class="text-capitalize blue--text font-weight-bold"
        width="100%"
        @click="$emit('select')"
      >
        {{ currentSubscription.rank > plan.rank ? $t('billing.downgradePlan') : $t('billing.upgradePlan') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    plan: {
      type: Object,
      required: true,
    },
    currentSubscription: {
      type: Object,
      required: true,
    },
  },

  computed: {
    measurement () {
      if (!this.plan.amount) {
        return ''
      }

      return this.plan.type === 'org' ? this.$t('billing.perUserMonth') : this.$t('billing.perMonth');
    },

    isCurrent () {
      return this.currentSubscription.stripe_price_id === this.plan.stripe_id;
    },
  },

  methods: {
    onSelect() {
      this.$emit('select')
    }
  }
}
</script>