<template>
  <v-data-table
    hide-default-footer
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="headers"
    :items="itemsPerView"
    :item-key="itemKey"
  >
    <template v-slot:[`header.name`]="{ header }">
      <div class="d-flex align-center">
        <div class="mb-2">
          <v-checkbox
            v-if="tableFilter === 'all' && itemsPerView.length"
            v-model="isAllUserChecked"
            :value="isAllUserChecked"
            color="blue"
            hide-details
            dense
            @change="toggleUsers"
          />
          <v-checkbox
            v-else-if="itemsPerView.length"
            v-model="isSelectedUserChecked"
            :value="isSelectedUserChecked"
            color="blue"
            hide-details
            dense
            @change="toggleUsers"
          />
        </div>
        <span class="header_text">{{ header.text }}</span>
        <v-icon
          size="18"
          color="gray-500"
        >
          mdi-chevron-down
        </v-icon>
      </div>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <td class="d-flex align-center">
        <div class="mb-2">
          <v-checkbox
            v-model="item.toBeSelected"
            :value="item.toBeSelected"
            color="blue"
            hide-details
            dense
            @change="toBeSelectedHandler"
          />
        </div>
        <v-avatar
          class="mr-2"
          size="40"
        >
          <img
            :src="item.image"
            width="100%"
            alt="logo"
          >
        </v-avatar>
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.name }}
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.email`]="{ item }">
      <td class="d-flex justify-space-between align-center">
        <span>{{ item.email }}</span>
        <v-btn icon>
          <v-icon
            color="gray-500"
            @click="$emit('updateUser', item)"
          >
            {{ tableFilter === 'all' ? 'mdi-plus-box-outline' : 'mdi-minus-box-outline' }}
          </v-icon>
        </v-btn>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    tableFilter: {
      type: String,
      default: 'all',
    },
    usersData: {
      type: Array,
    },
  },
  data() {
    return {
      users: this.usersData,
      itemKey: 'name',
      headers: [
        {
          text: this.$t('user'),
          value: 'name',
          sortable: false,
          class: 'rounded-l-lg gray-ish--text',
          align: 'start',
        },
        {
          text: this.$t('role'),
          value: 'role',
          sortable: true,
          class: 'gray-ish--text',
        },
        {
          text: this.$t('lastActivity'),
          value: 'lastActivity',
          sortable: true,
          class: 'gray-ish--text',
        },
        {
          text: this.$t('tag'),
          value: 'tag',
          sortable: true,
          class: 'gray-ish--text',
        },
        {
          text: this.$t('email'),
          value: 'email',
          sortable: true,
          class: 'rounded-r-lg gray-ish--text',
        },
      ],
      isAllUserChecked: false,
      isSelectedUserChecked: false,
    };
  },
  computed: {
    itemsPerView() {
      if (this.tableFilter === 'all') {
        return this.users.filter((item) => !item.selected);
      } else {
        return this.users.filter((item) => item.selected);
      }
    },
  },
  watch: {
    tableFilter(newValue) {
      this.toggleUsers();
    },
  },
  methods: {
    toggleUsers() {
      const isSelected = this.tableFilter === 'all' ? this.isAllUserChecked : this.isSelectedUserChecked;
      this.users.forEach((user) => {
        const condition = this.tableFilter === 'all' ? !user.selected : user.selected;
        if (condition) {
          this.$set(user, 'toBeSelected', isSelected);
        }
      });
    },
    toBeSelectedHandler() {
      const filteredUsers =
        this.tableFilter === 'all'
          ? this.users.filter((user) => !user.selected)
          : this.users.filter((user) => user.selected);
      const allTrue = filteredUsers.every((user) => user.toBeSelected);

      if (this.tableFilter === 'all') {
        this.isAllUserChecked = allTrue;
      } else {
        this.isSelectedUserChecked = allTrue;
      }
    },
  },
};
</script>
