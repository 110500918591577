export default function makeAccessTokenService(api) {
  return {
    newUserAccessToken: function (handle, data) {
      const accessTokenData = {
        newAccessTokenName: data.newAccessTokenName,
        expirationDate: data.expirationDate,
        orgUid: data.orgUid
      };
      return api.post(`/${handle}/accessTokens`, accessTokenData);
    },
    getUserAccessTokens: function (handle) {
      return api.get(`/${handle}/accessTokens`);
    },
    updateAccessToken: function (handle, data) {
      const accessTokenData = {
        newAccessTokenName: data.newAccessTokenName,
        expirationDate: data.expirationDate,
        orgUid: data.orgUid,
      };
      return api.patch(`/${handle}/accessTokens/${data.uid}`, accessTokenData);
    },
    deleteUserAccessToken: function (handle, accessTokenId) {
      return api.delete(`/${handle}/accessTokens/${accessTokenId}`);
    },
    deleteUserAllAccessTokens: function (handle) {
      return api.delete(`/${handle}/accessTokens`);
    },
  };
}
