<template>
  <v-container
    fluid
  >
    <Appbar />
    <router-view />
    <span id="versionSpan">
      {{ versionString }}
    </span>
  </v-container>
</template>

<script>
  import Appbar from "@/components/Layout/Appbar/Index.vue"
  export default {
    components: {
      Appbar
    },
    computed: {
      versionString() {
        if (
          process.env.VUE_APP_VERSION &&
          process.env.VUE_APP_STRIPE_PUBLIC_KEY.indexOf('live') < 0
        ) {
          return `FRONTEND VERSION: ${process.env.VUE_APP_VERSION}`;
        }
        return '';
      },
    },
  };
</script>

<style>
  .custom_bg {
    background-color: #f3f4f7;
    padding: 20px 20px 0;
    margin-bottom: 20px;
    min-height: 100%;
  }
  #versionSpan {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0 1em;
    color: white;
    background: black
  }
</style>
