export default {
  methods: {
    validateMimeTypes(files, allowedTypes) {
      const invalidFiles = files.filter(file => !allowedTypes.includes(file.type));
      if (invalidFiles.length > 0) {
        return { valid: false, invalidFiles };
      }
      return { valid: true, invalidFiles: [] };
    }
  }
}
