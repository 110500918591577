import DefaultLayout from '@/Layouts/DefaultLayout.vue';

const routes = [
  {
    path: '/:handle/workspace',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'workspace',
        component: () => import('@/views/Workspace/Index.vue'),
        props: true,
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    requiresAuth: true,
  };
  return { ...route, meta };
});
