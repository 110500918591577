<template>
  <BaseDialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card class="bg-white">
      <v-card-text class="py-8 px-sm-10">
        <v-flex class="d-flex align-start">
          <p class="ma-0 font-weight-bold text-h6 text-sm-h5 text-start black--text">
            {{ title }}
          </p>
          <v-icon
            class="mt-1 ml-4 pointer"
            @click="$emit('close')"
          >
            mdi-close
          </v-icon>
        </v-flex>
        <slot name="content">
          <v-flex class="mt-4">
            <p
              v-if="content_part2"
              class="text-start"
            >
              {{ content }}"{{ milestone_name }}"{{ content_part2 }}
            </p>
            <p
              v-else
              class="text-start"
            >
              {{ content }}
            </p>
          </v-flex>
        </slot>
        <slot name="footer">
          <v-row>
            <v-col cols="6">
              <v-btn
                dark
                large
                depressed
                width="100%"
                class="text-capitalize font-weight-bold black--text mt-2"
                color="gray-100"
                @click="$emit('close')"
              >
                {{ $t('projects.create_project.close_dialog.cancel_button') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                dark
                large
                width="100%"
                depressed
                class="text-capitalize font-weight-bold white--text mt-2"
                :color="color"
                @click="$emit('handleConfirmClick')"
              >
                {{ btn_label }}
              </v-btn>
            </v-col>
          </v-row>
        </slot>
      </v-card-text>
    </v-card>
  </BaseDialog>
</template>
  
  <script>
  import BaseDialog from '@/components/base/BaseDialog.vue';
  
  export default {
    components: {
      BaseDialog,
    },
    props: {
      value: Boolean,
      title: {
        type: String,
      },
      content: {
        type: String,
      },
      content_part2: {
        type: String,
      },
      color: {
        type: String,
      },
      btn_label: {
        type:String
      },
      milestone_name: {
        type: String,
      },
    },
    data() {
      return {
        dialog: this.value,
        showAgainConfirmBackDialog: false,
      };
    },
    watch: {
      value(newVal) {
        this.dialog = newVal;
      },
      dialog(newVal) {
        this.$emit('input', newVal);
      },
    },
  };
  </script>
  
  <style></style>
  