import axios from 'axios';

export default function makeUserService(api) {
  return {
    getOrgs: function (userId) {
      return api.get(`/users/${userId}/orgs`);
    },
    getProfile: function () {
      return api.get(`/profile`);
    },
    updateProfile: function (profile) {
      return api.put(`/profile`, profile);
    },
    updateUsers: function (handle, payload) {
      return api.patch(`/orgs/${handle}/users`, payload);
    },
    updatePreferences: function (payload) {
      return api.patch(`/preferences`, payload);
    },
    changePassword: function (payload) {
      return api.put(`/change-password`, payload);
    },
    searchUsers: function (query) {
      return api.get(`/users/search`, { params: { query } });
    },
    getSignedUrlUploadAvatar: function (payload) {
      return api.post(`/attachments`, payload);
    },
    getPreferences: function () {
      return api.get(`/preferences`);
    },
    uploadAvatar: function (url, payload) {
      return axios({
        method: 'PUT',
        url,
        headers: {
          'Content-Type': payload.type,
          'X-Upload-Content-Length': payload.size,
        },
        data: payload.file,
      });
    },
    getCurrentSubscription: function () {
      return api.get(`/subscriptions`, { params: { model: 'user' } });
    },
    getSubscriptionHistory: function () {
      return api.get(`/subscriptions/history`, { params: { model: 'user' } });
    },
    changeSubscription: function (payload) {
      return api.post('/checkouts', payload, {
        params: { model: 'user' },
      });
    },
    deleteUser: function (uid, password) {
      return api.delete(`/user/${uid}`, {
        data: {
          password,
        }
      })
    },
    createAuthentication: function (payload) {
      return api.post('/user/authentication/create', payload)
    },
    validateAuthentication: function (payload) {
      return api.post('/user/authentication/validation', payload)
    }
  };
}
