<template>
  <div class="d-flex flex-row align-center justify-space-between">
    <v-menu
      open-on-hover
      bottom
      offset-y
      max-width="150px"
      content-class="view-executions-menu"
      z-index="100"
    >
      <template v-slot:activator="{ on }">
        <div
          class="progress-linear"
          v-on="on"
        >
          <div
            class="progress-linear-bar"
            :style="{backgroundColor: percentage == 100 ? 'transparent' : '#EAECF0'}"
          >
            <div
              v-for="(status, index) in executions"
              :key="index"
              class="partial"
              :style="{width: `${status.count/caseCount * 100}%`,
                       'background-color': getStatusColor(status.id)
              }"
            />
          </div>
          <span class="font-weight-regular ml-3">{{ safePercentage(percentage) }}%</span>
        </div>
      </template>
      <v-list>
        <v-list-item
          v-for="(status, index) in executions"
          :key="index"
          dense
          height="35px"
        >
          <v-list-item-title class="text-left">
            <span
              class="text-capitalize"
              :style="{color: getStatusColor(status?.id)}"
            >{{ status.name }} {{ status.count }}</span>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
  import colorPreferencesMixin from '@/mixins/colorPreferences';
  export default{
    mixins: [colorPreferencesMixin],
    props:{
      executions:{
        type: [Object, Array],
        required: true
      },
      caseCount:{
        type: Number,
        default: 0
      },
      percentage:{
        default: 0
      }
    },
    created(){
      this.getStatuses("testRun");
    },
    methods:{
      safePercentage(value) {
        const percentage = value;
        return (typeof percentage === 'string' && percentage !== 'NaN') ? percentage : '0';
      },
    }
  }
</script>
<style scoped>
.progress-linear{
  width: 100%;
  display: flex;
  align-items: center;
}
.progress-linear .progress-linear-bar{
    min-width: 150px !important;
    height: 8px;
    border-radius: 10px;
    display: flex;
}
.progress-linear .progress-linear-bar .partial{
  border-radius: 10px;
  height: 100%;
}

.progress-linear .progress-linear-bar .passed{
  background-color: #66BB6A;
  z-index: 8;
}
.progress-linear .progress-linear-bar .incomplete{
  background-color: #FFA726;
  position: relative;
  z-index: 7;
}
.progress-linear .progress-linear-bar .failed{
  background-color: #EF5350;
  position: relative;
  z-index: 6;
}
</style>