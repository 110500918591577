import { 
  MilestoneTableHeader, 
  TestPlansTableHeader, 
  TestPlansRerunTableHeader, 
  TestRunTableHeader, 
  TestCaseTableHeader, 
  ExecutionTableHeader, 
  WorkspaceTableHeader, 
  CustomFieldsHeader, 
  CustomTemplatesHeader, 
  RoleMemberHeader, 
  SharedStepTableHeader, 
  TagsHeader, 
  UsersViewHeader, 
  ProjectEditHeader, 
  ProjectsViewHeader, 
  ProjectsDefectHeader, 
  MilestoneTestRunTableHeader, 
  TestPlansTestRunsTableHeaderAll, 
  CaseInRunTableHeader, 
  TestPlansTestRunsTableHeaderSelected,
  PersonalizationHeader } from '@/constants/grid';

const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

const state = {
  dynamicHeaders: {}, 
};

const getters = {
  dynamicHeaders: (state) => state.dynamicHeaders || {}, // Handles dynamic headers
};


const mutations = {
  setHeaders(state, { type, headers }) {
    // Handle all other types dynamically
    state.dynamicHeaders = {
      ...state.dynamicHeaders,
      [type]: headers,
    };
  },
  clearHeaders(state) {
    // Clear the dynamicHeaders object
    state.dynamicHeaders = {};
  },
};

const actions = {
  initializeHeaders({ commit, state }, { type, defaultHeaders }) {
    const headerMap = {
      milestone: { headers: MilestoneTableHeader, currentHeaders: state.milestone },
      milestoneEditRuns: { headers: MilestoneTestRunTableHeader, currentHeaders: state.milestoneEditRuns },
      milestoneTestRun: { headers: MilestoneTestRunTableHeader, currentHeaders: state.milestoneTestRun },
      milestoneTestPlan: { headers: TestPlansTableHeader, currentHeaders: state.milestoneTestPlan },
      milestoneEditPlans: { headers: TestPlansTableHeader, currentHeaders: state.milestoneEditPlans },
      plan: { headers: TestPlansTableHeader, currentHeaders: state.plan },
      addTestRun: { headers: TestPlansTestRunsTableHeaderAll, currentHeaders: state.addTestRun },
      addTestRunSelected: { headers: TestPlansTestRunsTableHeaderSelected, currentHeaders: state.addTestRunSelected },
      planDetailsView: { headers: TestPlansTestRunsTableHeaderAll, currentHeaders: state.planDetailsView },
      testPlanRerun: { headers: TestPlansRerunTableHeader, currentHeaders: state.testPlanRerun },
      plansDuplicate: { headers: TestPlansTableHeader, currentHeaders: state.plansDuplicate },
      duplicateAddRuns: { headers: TestPlansTestRunsTableHeaderAll, currentHeaders: state.duplicateAddRuns },
      run: { headers: TestRunTableHeader, currentHeaders: state.run },
      case: { headers: TestCaseTableHeader, currentHeaders: state.case },
      testExecution: { headers: ExecutionTableHeader, currentHeaders: state.testExecution },
      workspace: { headers: WorkspaceTableHeader, currentHeaders: state.workspace },
      customFields: { headers: CustomFieldsHeader, currentHeaders: state.customFields },
      personalizations: { headers: PersonalizationHeader, currentHeaders: state.personalizations },
      customTemplates: { headers: CustomTemplatesHeader, currentHeaders: state.customTemplates },
      roleMember: { headers: RoleMemberHeader, currentHeaders: state.roleMember },
      sharedSteps: { headers: SharedStepTableHeader, currentHeaders: state.sharedSteps },
      tags: { headers: TagsHeader, currentHeaders: state.tags },
      users: { headers: UsersViewHeader, currentHeaders: state.users },
      projectDetails: { headers: ProjectEditHeader, currentHeaders: state.projectDetails },
      projectsView: { headers: ProjectsViewHeader, currentHeaders: state.projectsView },
      projectsDefect: { headers: ProjectsDefectHeader, currentHeaders: state.projectsDefect },
      caseCustomTable: { headers: CaseInRunTableHeader, currentHeaders: state.caseCustomTable },
    };

    const selectedHeader = headerMap[type];

    // Initialize dynamic headers if not already set
    if (!state.dynamicHeaders[type]) {
      if (selectedHeader && !selectedHeader.currentHeaders) {
          commit('setHeaders', { type, headers: deepClone(selectedHeader.headers) });
      }
    }
  },
  resetHeaders({ commit }, { type, defaultHeaders }) {
    // Reset headers to initial values for both predefined and dynamic types
    commit('setHeaders', { type, headers: defaultHeaders });
  },
  updateHeaders({ commit }, { type, headers }) {
    // Update headers for both predefined and dynamic types
    commit('setHeaders', { type, headers });
  },
  clearAllHeaders({ commit }) {
    // Clear all dynamic headers
    commit('clearHeaders');
  },
};


export default function makeHeader() {
  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
  };
}
