import { toDate } from 'date-fns-tz';
import { format, addDays } from 'date-fns';
export const formatDate = (date, f = 'MMM do HH:mm, yyyy', d = '') => {
  if (typeof date === 'string' && date) {
    date = new Date(date);
  }

  let test = toDate(date);
  let test1 = format(test, f);

  return date ? test1 : d;
};

export const utcToLocalDate = (date) => {
  return toDate(date, { timeZone: 'Etc/GMT' });
};

export const getNumForDate = (num) => {
  if (num >= 10) {
    return num;
  } else {
    return '0' + num;
  }
};

export const getUTCDateString = (date) => {
  return (
    new Date(date).getFullYear() +
    '-' +
    getNumForDate(new Date(date).getMonth() + 1) +
    '-' +
    getNumForDate(new Date(date).getUTCDate()) +
    ' ' +
    getNumForDate(new Date(date).getUTCHours()) +
    ':' +
    getNumForDate(new Date(date).getUTCMinutes()) +
    ':' +
    getNumForDate(new Date(date).getUTCSeconds())
  );
};
/*
Compares 2 dates
if 0, date is same
if 1, first date is later than second date
if 2, The first date precedes the second date.
if 3, The first and second dates are exactly the same.
*/
export const compareDates = (firstDate, secondDate) => {
  let value = -1;
  if (
    firstDate.getFullYear() === secondDate.getFullYear() &&
    firstDate.getMonth() == secondDate.getMonth() &&
    firstDate.getDay() == secondDate.getDay()
  ) {
    value = 0;
  } else {
    if (firstDate > secondDate) {
      value = 1;
    } else if (firstDate < secondDate) {
      value = 2;
    } else {
      value = 3;
    }
  }
  return value;
};

export const getDayofWeek = (date) => {
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  return days[date.getDay()];
};

export const formattedDate = (input_date) => {
  const date = new Date(input_date);
  const month = date.getMonth() + 1;
  const day = date.getDate();
  const year = date.getFullYear();
  return `${month.toString().padStart(2, '0')}/${day.toString().padStart(2, '0')}/${year}`;
}


export const addDaysFrom = (days, startDate = new Date()) => {
  return addDays(startDate, days)
}

export const sleep = (ms) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function redirectToMappedRoute(route, router, defaultRouteName, defaultRouteParams = {}) {
  const routeMap = {
    activeAddMilestone: 'MilestoneTestActivities',
    activeAddTestPlan: 'TestPlanAddRuns',
    activeAddTestPlanDuplicate: 'TestPlanDuplicateAddRuns'
  };
  
  const targetRouteName = Object.keys(routeMap).find((param) => 
    route.query[param]
  )
    ? routeMap[Object.keys(routeMap).find((param) => route.query[param])]
    : defaultRouteName;
  const params = {
    handle: route.params.handle,
    key: route.params.key,
    ...defaultRouteParams,
  };

  router.push({
    name: targetRouteName,
    params,
  });
}

export function redirectWithQueryParams(route, router, defaultRouteName, params = {}, defaultRouteQuery = {}) {

  const queryKeys = ['activeAddMilestone', 'activeAddTestPlan', 'activeAddTestPlanDuplicate'];
  
  const query = queryKeys.reduce((acc, key) => {
    if (route.query[key]) {
      acc[key] = route.query[key];
    }
    return acc;
  }, { ...defaultRouteQuery }); 

  router.push({
    name: defaultRouteName,
    params: {
      ...params,
      handle: route.params.handle,
      key: route.params.key,
    },
    query,
  });
}