import makeOrgService from "@/services/api/org";

const state = {
  activeMembers: [],
  pendingInvites: [],
  orgs: {},
};

const getters = {
  getOrgPreferences: (state) => (handle) => {
    const org = state.orgs[handle] || {};
    return org || {};
  },
  getOrgStatusColorsByEntityType: (state) => (handle, entityType) => {
    const org = state.orgs[handle] || {};
    const preferencesData = org || {};
    return preferencesData?.statusColors?.filter((status) => status.entityType === entityType) || [];
  },
  getOrgPriorityColorsByEntityType: (state) => (handle, entityType) => {
    const org = state.orgs[handle] || {};
    const preferencesData = org || {};
    return preferencesData?.priorityColors?.filter((priority) => priority.entityType === entityType) || [];
  },
}

const mutations = {
  SET_ACTIVE_MEMBERS: (state, activeMembers) => (state.activeMembers = activeMembers),
  REMOVE_USER: (state, userId) => state.activeMembers = state.activeMembers.filter(user => user.uid !== userId),
  SET_PENDING_INVITES: (state, pendingInvites) => (state.pendingInvites = pendingInvites),
  ADD_INVITE: (state, invite) => state.pendingInvites.unshift(invite),
  DELETE_INVITE: (state, email) => state.pendingInvites = state.pendingInvites.filter(invite => invite.email !== email),
  setOrgPreferences(state, { handle, preferences }) {
    state.orgs = {
      ...state.orgs,
      [handle]: preferences,
    };
  }
};

const makeActions = (api) => {
  const orgService = makeOrgService(api);
  return {
    getPendingInvites: async function ({ commit }, handle) {
      const response = await orgService.listPendingInvites(handle);
      commit('SET_PENDING_INVITES', response.data);
    },
    sendInvite: async function ({ commit }, payload) {
      const response = await orgService.newInvite(payload);
      commit('ADD_INVITE', response.data);
    },
    deleteInvite: async function ({ commit }, { handle, email }) {
      await orgService.deleteInviteByEmail(handle, email);
      commit('DELETE_INVITE', email);
    },
    removeUser: async function ({ commit }, payload) {
      await orgService.removeUser(payload);
      commit('REMOVE_USER', payload.memberId);
    },
    leaveOrg: async function ({ commit }, { handle, orgHandle }) {
      await orgService.leaveOrg(handle, orgHandle);
    },
  };
}

export default function makeOrg(api) {
  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions: makeActions(api)
  };
}