<template>
  <v-dialog
    v-model="showDialog"
    class="test-cases-filter-drawer dialog-theme"
    transition="slide-x-transition"
    attach
    fullscreen
    width="485px"
  >
    <v-card class="d-flex flex-column justify-space-between overflow-hidden">
      <v-card-text class="black--text mt-8">
        <div class="d-flex align-center justify-space-between">
          <h2 class="black--text">
            {{ isEditMode ? $t('settingsPage.updateApiKey') : $t('settingsPage.createApiKey') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-10"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-start">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('name') }} <strong class="red--text">*</strong>
                </v-label>
              </div>
              <v-text-field
                v-model="token.name"
                type="text"
                class="rounded-lg field-theme"
                background-color="#F9F9FB"
                dense
                height="38px"
                :placeholder="$t('name')"
                :rules="tokenNameRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-start">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('expiration') }} <strong class="red--text">*</strong>
                </v-label>
              </div>
              <v-select
                v-model="token.expirationValue"
                :items="expirtationTypes"
                background-color="#F9F9FB"
                class="rounded-lg field-theme custom-append"
                dense
                item-text="name"
                item-value="value"
                height="38px"
                append-icon="mdi-chevron-down"
                :menu-props="{ offsetY: true }"
                :placeholder="$t('customFieldPage.chooseDataType')"
                :rules="requiredRules"
              />

              <template v-if="isCustomExpirationDate">
                <v-menu
                  v-model="showDateCalendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="300"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="customExpirationDate"
                      dense
                      background-color="#F9F9FB"
                      readonly
                      v-bind="attrs"
                      class="text-field field-theme rounded-lg custom-prepend"
                      clearable
                      height="38"
                      :placeholder="$t('settingsPage.customDate')"
                      :rules="requiredRules"
                      v-on="on"
                    >
                      <template v-slot:prepend-inner>
                        <calendarBlueIcon />
                      </template>
                    </v-text-field>
                  </template>
                  <v-date-picker
                    v-model="customExpirationDate"
                    :min="today"
                    @input="showDateCalendar = false"
                  />
                </v-menu>
              </template>

              <p v-else>
                {{ expirationDateText }}
              </p>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div class="actions-container d-flex justify-space-between">
      <v-btn
        width="204.5px"
        color="#F2F4F7"
        height="40"
        :depressed="true"
        class="text-capitalize btn-theme"
        elevation="0"
        @click="onCancel()"
      >
        {{ $t('cancel') }}
      </v-btn>
      <v-btn
        width="204.5px"
        class="btn-theme"
        height="40"
        color="primary"
        :depressed="true"
        elevation="0"
        @click="onCreate()"
      >
        {{ isEditMode ? $t('update') : $t('settingsPage.generateApiKey') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { getUTCDateString, formatDate, addDaysFrom } from '@/utils/util';
import calendarBlueIcon from '@/assets/svg/calendar-blue.svg';
import { requiredAndLengthRangeRules, validateTokenNameCharacters } from "@/utils/validation";

export default {
  name: 'CreateUpdateAPIKey',
  components: {
    calendarBlueIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({})
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField')
      ],
      tokenNameRules: [
        ...requiredAndLengthRangeRules(this, 2, 64),
        (value) => validateTokenNameCharacters(value, this),
      ],
      validForm: false,
      token: {
        uid: '',
        name: '',
        expirationValue: '',
      },
      expirtationTypes: [
        { id: 1, value: '7', name: this.$t('common.days', { days: 7 }) },
        { id: 2, value: '30', name: this.$t('common.days', { days: 30 }) },
        { id: 3, value: '60', name: this.$t('common.days', { days: 60 }) },
        { id: 4, value: '90', name: this.$t('common.days', { days: 90 }) },
        { id: 5, value: 'custom', name: this.$t('settingsPage.customDate') },
        { id: 6, value: 'no-expiration', name: this.$t('settingsPage.noExpiration') },
      ],
      customExpirationDate: null,
      showDateCalendar: false,
      showCalendar: false
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    isEditMode() {
      return !!this.token.uid
    },

    today() {
      return formatDate(new Date(), 'yyyy-MM-dd')
    },

    isCustomExpirationDate() {
      return this.token.expirationValue === 'custom'
    },

    expirationDateText() {
      if (this.token.expirationValue === 'no-expiration') {
        return this.$t('settingsPage.neverExpire')
      }

      if (this.token.expirationValue === 'custom') {
        return this.$t('settingsPage.willExpireOn', { date: formatDate(this.customExpirationDate, 'MMM do, yyyy') })
      }

      if (this.token.expirationValue === '') {
        return ''
      }

      const date = addDaysFrom(Number(this.token.expirationValue))

      return this.$t('settingsPage.willExpireOn', { date: formatDate(date, 'MMM do, yyyy') })
    },
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.token = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        expirationValue: this.data.expirationValue || this.data.uid ? 'custom' : '',
      }

      if (this.data.uid) {
        this.customExpirationDate = formatDate(this.data.expiresAt, 'yyyy-MM-dd')
      }
    },
  },

  methods: {
    onCreate() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);

      const expirationDate = this.token.expirationValue === 'no-expiration'
        ? null
        : this.token.expirationValue === 'custom'
          ? getUTCDateString(this.customExpirationDate)
          : getUTCDateString(addDaysFrom(Number(this.token.expirationValue), today))

      this.$emit(this.isEditMode ? 'update-api-key' : 'create-api-key', {
        uid: this.token.uid,
        newAccessTokenName: this.token.name,
        expirationDate,
      })
    },

    onCancel() {
      this.$emit('close-dialog')
    }
  }
}
</script>