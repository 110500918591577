<template>
  <v-sheet
    color="#F2F4F7"
    height="40px"
    rounded="lg"
    :outlined="true"
  >
    <div class="d-flex align-center justify-center">
      <v-btn
        icon
        depressed
        :ripple="false"
        plain
        @click="handleTable"
      >
        <ViewListSelectedIcon v-if="table" />
        <ViewListIcon v-else />
      </v-btn>
      <v-btn
        icon
        depressed
        :ripple="false"
        plain
        @click="handleTiles"
      >
        <ViewDashboardIcon v-if="table" />
        <ViewDashboardSelectedIcon v-else />
      </v-btn>
    </div>
  </v-sheet>
</template>

<script>
import ViewListIcon from '@/assets/svg/view-list.svg';
import ViewListSelectedIcon from '@/assets/svg/view-list-selected.svg';
import ViewDashboardIcon from '@/assets/svg/view-dashboard.svg';
import ViewDashboardSelectedIcon from '@/assets/svg/view-dashboard-selected.svg';

export default {
  components: {
    ViewListIcon,
    ViewListSelectedIcon,
    ViewDashboardIcon,
    ViewDashboardSelectedIcon
  },
  props: {
    table: Boolean
  },
  methods: {
    handleTable() {
      this.$emit('toggle-table', true);
    },
    handleTiles() {
      this.$emit('toggle-table', false);
    },
  }
};
</script>
