<template>
  <v-card
    class="white py-6 px-6 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2>{{ $t('defects') }}</h2>
    </div>
  </v-card>
</template>

<script>
export default {
  name: 'DefectHeader',
}
</script>
