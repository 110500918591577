import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import SettingsLayout from '@/Layouts/SettingsLayout.vue';
import UserSettingsLayout from '@/Layouts/UserSettingsLayout.vue';
import OrgSettingsLayout from '@/Layouts/OrgSettingsLayout.vue';

const routes = [
  {
    path: '/settings',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: SettingsLayout,
        children: [
          {
            path: '',
            redirect: 'account',
          },
          {
            path: 'account',
            name: 'Account',
            component: () => import('@/views/Settings/Personal/Account'),
          },
          {
            path: 'organizations',
            name: 'Organizations',
            component: () => import('@/views/Settings/Personal/Organizations'),
          },
          {
            path: 'organizations/:handle',
            component: UserSettingsLayout,
            children: [
              {
                path: '',
                name: 'UserOrganizations',
                component: () => import('@/views/Settings/User/Organizations'),
              },
              {
                path: ':handle/notifications',
                name: 'UserNotifications',
                component: () => import('@/views/Settings/User/Notifications'),
              },
            ],
          },
          {
            path: 'api-keys',
            name: 'APIKeys',
            component: () => import('@/views/Settings/Personal/ApiKeys'),
          },
          {
            path: 'notifications',
            name: 'Notifications',
            component: () => import('@/views/Settings/Personal/Notifications'),
          },
          {
            path: 'authentication',
            name: 'Authentication',
            component: () => import('@/views/Settings/Personal/Authentication'),
          },
          {
            path: 'billing',
            name: 'Billing',
            component: () => import('@/views/Settings/Personal/Billing'),
          },
          {
            path: 'personalization',
            name: 'Personalization',
            component: () => import('@/views/Settings/Personal/Personalization'),
          },
          {
            path: 'about',
            name: 'About',
            component: () => import('@/views/Settings/Personal/About'),
          },
        ],
      },
    ],
  },
  {
    path: '/organizations/:handle/settings',
    component: DefaultLayout,
    children: [
      {
        path: '',
        component: OrgSettingsLayout,
        children: [
          {
            path: '',
            redirect: 'profile',
          },
          {
            path: 'profile',
            name: 'OrgAccount',
            component: () => import('@/views/Settings/Organization/Organization'),
          },
          {
            path: 'api-keys',
            name: 'OrgAPIKeys',
            component: () => import('@/views/Settings/Organization/ApiKeys'),
          },
          {
            path: 'authentication',
            name: 'OrgAuthentication',
            component: () => import('@/views/Settings/Organization/Authentication'),
          },
          {
            path: 'billing',
            name: 'OrgBilling',
            component: () => import('@/views/Settings/Organization/Billing'),
          },
          {
            path: 'personalization',
            name: 'OrgPersonalization',
            component: () => import('@/views/Settings/Organization/Personalization'),
          },
          {
            path: 'about',
            name: 'OrgAbout',
            component: () => import('@/views/Settings/Organization/About'),
          },
        ],
      },
    ],
  },
];

export default routes.map(route => {
	const meta = {
		requiresAuth: true,
	}
	return { ...route, meta }
})
