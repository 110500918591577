<template>
  <v-app>
    <v-navigation-drawer
      app
      class="secondary"
      dark
    >
      <v-list
        dense
        class="text-left"
      >
        <v-list-item class="mt-6 mb-12 ml-2"> 
          <v-avatar
            tile
            size
          >
            <img
              style="max-width: 100%"
              :src="require('@/assets/png/logo.png')"
            >
          </v-avatar>
        </v-list-item>
        <v-list-item>
          <AccountSelector :current-account="currentAccount" />
        </v-list-item>
        <v-list-item>
          <ProjectSelector
            :account="currentAccount"
            @add-project="addProjectDialog = !addProjectDialog"
          />
        </v-list-item>
        <template v-for="nav in navItems">
          <v-list-item
            v-if="!nav.children"
            :key="nav.title"
            :to="nav.url"
          >
            <v-list-item-icon>
              <v-icon>{{ nav.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="font-weight-bold">
                {{ nav.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            v-else
            :key="nav.title + 'e'"
          >
            <v-list-item-content>
              <v-list-item-title class="subtitle-2 text-gray-500">
                {{ nav.title }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <template v-for="item in nav.children">
            <v-list-item
              v-if="item.url && item.routeName"
              :key="item.title"
              :to="item.url"
              :active="isActive(item.routeName)"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-else
              :key="item.title + 'e'"
            >
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold">
                  {{ item.title }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </template>
      </v-list>
    </v-navigation-drawer>
    <v-app-bar
      app
      color="white"
      flat
    >
      <v-row class="mt-4 ml-6">
        <v-col
          cols="12"
          sm="12"
          md="5"
        >
          <v-text-field
            dense
            :placeholder="$t('search')"
            outlined
          />
        </v-col>
      </v-row>
      <v-spacer />
      <!--
      <v-btn icon>
        <v-icon>mdi-bell</v-icon>
      </v-btn>
      -->
      <v-avatar :src="user.avatar_url" />
      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn
            icon
            v-on="on"
          >
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
        </template>
        <v-list
          class="text-left"
          dense
        >
          <v-list-item
            to="/orgs/new"
            :active="isActive('/orgs/new')"
          >
            <v-list-item-title>{{ $t('newOrganization') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/profile"
            :active="isActive('/profile')"
          >
            <v-list-item-title>{{ $t('profile') }}</v-list-item-title>
          </v-list-item>
          <v-list-item
            to="/settings/profile"
            :active="isActive('/settings/profile')"
          >
            <v-list-item-title>{{ $t('settings') }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="logout()">
            <v-list-item-title>{{ $t('logout') }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main class="default ma-0 pa-0">
      <router-view />
    </v-main>
    <v-dialog
      v-model="addProjectDialog"
      max-width="50%"
    >
      <ProjectForm
        :is-loading="savingInProgress"
        :existing-project="projectDetail"
        :account="currentAccount"
        @save="updateProject"
        @close="addProjectDialog = false"
      />
    </v-dialog>
  </v-app>
</template>

<script>

import { createNamespacedHelpers, mapActions as projectMapActions } from 'vuex';
import { sideNavItems } from '@/constants/nav';
import AccountSelector from '@/components/Layout/Selector/AccountSelector.vue';
import ProjectSelector from '@/components/Layout/Selector/ProjectSelector.vue';
import ProjectForm from '@/components/Project/ProjectForm.vue';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers('user');
import { DB_NAME } from '@/constants/dashboardConstants';

export default {
  components: {
    AccountSelector,
    ProjectSelector,
    ProjectForm
  },
  computed: {
    ...mapGetters(['userName', 'currentAccount', 'user']),
  },
  methods: {
    ...mapMutations(['emptyState']),
    ...projectMapActions("headers", ['clearAllHeaders']),
    isActive: function (name)
    {
      return this.$route.name === name;
    },
    logout() {
      this.emptyState()
      localStorage.clear();
      indexedDB.deleteDatabase(DB_NAME);
      this.clearAllHeaders();
      this.$router.push('/login');
    },
    updateProject(payload)
    {
      // TODO - update project
    },
  },
  data()
  {
    return {
      navItems: sideNavItems,
      addProjectDialog: false,
      savingInProgress: false,
      projectDetail: {},
    };
  },
};
</script>

<style>
.sidebar .nav-link.active {
  background-color: rgba(55, 65, 81, 1);
  padding: 13px;
}

.sidebar .nav-link:hover {
  background-color: rgba(55, 65, 81, 1);
  padding: 13px;
}
</style>
