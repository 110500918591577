<template>
  <v-list
    width="100%"
    class="pt-0 d-flex flex-column align-center"
  >
    <v-list-item
      v-for="(item, index) in items"
      :key="index"
      class="px-3 w-full"
    >
      <v-list-item-icon class="mr-3">
        <v-icon color="blue">
          {{ itemIcon }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-title>
        <v-text-field
          v-model="items[index]"
          type="text"
          dense
          filled
          :placeholder="$t('name')"
          :rules="requiredRules"
          hide-details
        />
      </v-list-item-title>
      <v-list-item-action
        @click="$emit('remove-item', index)"
      >
        <DeleteIcon />
      </v-list-item-action>
    </v-list-item>
  </v-list>
</template>

<script>
import DeleteIcon from '@/assets/svg/delete.svg';

export default {
  name: 'OptionItems',

  components: {
    DeleteIcon,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    }
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
    }
  },

  computed: {
    itemIcon() {
      if (this.type === 'multi') {
        return 'mdi-chevron-down'
      } else if (this.type === 'radio') {
        return 'mdi-radiobox-marked'
      } else if (this.type === 'checkbox') {
        return 'mdi-checkbox-marked'
      }

      return ''
    }
  },
}
</script>