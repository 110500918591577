<template>
  <v-card
    class="white mt-3 left-menu-card sticky-on-scroll pt-4"
    rounded="lg"
    elevation="0"
    :class="{ 'is-collapsed-menu': isMenuCollapsed }"
  >
    <v-list 
      :class="isMenuCollapsed ? 'px-3' : 'px-5'"
      nav
    >
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item
          v-for="(item, index) in menuItems"
          :key="item.title"
          :to="item.to"
          class="mh-36px"
          :class="{ 'px-3': !isMenuCollapsed, 'mb-3': isMenuCollapsed }"
          active-class="active-menu-item"
          @click="setActive(index)"
        >
          <v-list-item-icon 
            v-if="!isMenuCollapsed"
            class="my-3 mr-2"
            :class="item.className"
          >
            <component :is="item.icon" />
          </v-list-item-icon>
          <v-list-item-content
            class="pa-0"
            :class="item.className"
          >
            <component
              :is="item.icon"
              v-if="isMenuCollapsed"
            />
            <v-list-item-title
              v-if="!isMenuCollapsed"
              class="text-left"
            >
              <span class="fs-14px font-weight-regular text-theme-secondary">{{ item.title }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div
      class="collapse-btn"
      @click="toggleMenu"
    >
      <v-icon
        class="collapse-icon"
        color="#0C2FF3"
      >
        {{ isMenuCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-left-bottom' }}
      </v-icon>
      <span
        v-if="!isMenuCollapsed"
        class="collapse-text"
      >{{ $t('collapse') }}</span>
    </div>
  </v-card> 
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  name: 'LeftMenuCard',

  props: {
    menuItems: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      selectedItem: 0,
    };
  },

  computed: {
    ...mapGetters(['isMenuCollapsed']),
  },

  methods: {
    ...mapMutations(['toggleMenu']),
    setActive(index) {
      this.selectedItem = index;
    }
  }
};
</script>

<style scoped>
.left-menu-card {
  position: relative;
  height: calc(100vh - 100px);
  transition: width 0.3s;
}

.left-menu-card.sticky-on-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 12px;
  height: calc(100vh - 24px);
}


.text-left {
  text-align: left;
}

.left-menu-card.collapsed .collapse-btn .collapse-text {
  display: none;
}
.left-menu-card.collapsed .collapse-btn {
  justify-content: center;
}

</style>
