export const dataTypes = [
  { id: 1, name: 'Multi Select', type: 'multi', isOptionsRequired: true },
  { id: 2, name: 'Radio Buttons', type: 'radio', isOptionsRequired: true },
  { id: 3, name: 'Link', type: 'link', isOptionsRequired: false },
  { id: 4, name: 'Text', type: 'text', isOptionsRequired: false },
  { id: 5, name: 'Checkbox', type: 'checkbox', isOptionsRequired: true },
  { id: 6, name: 'Date', type: 'date', isOptionsRequired: false },
  { id: 7, name: 'Attachment', type: 'file', isOptionsRequired: false },
  { id: 8, name: 'Dropdown', type: 'dropdown', isOptionsRequired: true },
  
]