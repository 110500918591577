<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    :headers="headers"
    :items="colorItems"
    :item-key="itemKey"
    show-select
    class="data-table-style table-fixed mt-6"
  >
    <template v-slot:[`header.data-table-select`]="{ props, on }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          indeterminate-icon="icon-indeterminate"
          :input-value="props.value"
          :indeterminate="props.indeterminate"
          @change="on.input"
        />
      </div>
    </template>

    <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          :input-value="isSelected"
          @change="select"
          @click.stop
        />
      </div>
    </template>

    <template 
      v-slot:[`item.name`]="{ item }"
      class="text-end pt-5"
    >	
      <div class="font-weight-bold">
        {{ item.name }}
      </div>
    </template>
    <template v-slot:[`item.color`]="{ item }">
      <div class="no-pointer-events">
        <ColorItem
          :value="item.color"
          default-color="#000"
          :disabled="true"
        />
      </div>
    </template>
    <template 
      v-slot:[`item.id`]="{ item }" 
      class="text-end pt-5" 
    >
      <div class="d-flex justify-content-right">
        <v-btn
          :key="item?.id"
          icon
          color="primary"
          @click="onEdit('Edit', item.id, item.key)"
        >
          <PencilIcon />
        </v-btn>
      </div>
    </template>
  </v-data-table>
</template>

<script>
import ColorItem from './ColorItem.vue';
import PencilIcon from '@/assets/svg/pencil.svg?component';

export default {
  name: 'ColorsTable',

  components: {
    ColorItem,
    PencilIcon,
  },

  props: {
    headers: Array,
    itemKey: String,
    items: Array,
    type: String,
    onEdit: Function,
  },
  computed: {
    colorItems() {
      return this.items || [];
    },
  },
}
</script>

<style>
  .no-pointer-events {
    pointer-events: none;
  }

  .justify-content-right {
    justify-content: right;
  }

  .v-data-table__selected, .v-data-table-header {
    .v-icon {
      color: #0c2ff3 !important;
      caret-color: #0c2ff3 !important;
    }
  }
</style>
