<template>
  <div class="d-flex align-center">
    <div
      class="rounded-circle"
      style="width: 36px; height: 36px;"
      :style="{'background-color': value }"
      @click="showPickColorDialog=true"
    />

    <span
      class="text-theme-label ml-3 fs-14px font-weight-regular"
      @click="showPickColorDialog=true"
    >{{ value }}</span>

    <PickColorDialog
      v-model="showPickColorDialog"
      :original-color="value"
      @choose-color="$emit('input', $event)"
      @close-dialog="showPickColorDialog=false"
    />
  </div>
</template>

<script>
import PickColorDialog from './PickColorDialog.vue';

export default {
  name: 'ColorItem',

  components: {
    PickColorDialog,
  },

  props: {
    value: {
      type: String,
    },
  },

  data () {
    return {
      showPickColorDialog: false,
    }
  },
}
</script>