import Vue from 'vue';
import VueRouter from 'vue-router';
import AuthLayout from '@/Layouts/AuthLayout.vue';
import DashboardLayout from '@/Layouts/DashboardLayout.vue';
import ProjectLayout from '@/Layouts/ProjectLayout.vue';
import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import PersonalProfile from '@/views/PersonalProfile';
import UserProfileLayout from '@/Layouts/UserProfileLayout.vue';
import projectRoutes from '@/router/project';
import adminRoutes from '@/router/admin';
import settingsRoutes from '@/router/settings';
import runRoutes from '@/router/run';
import milestoneRoutes from '@/router/milestone';
import caseRoutes from '@/router/case';
import testPlansRoutes from '@/router/plan';
import projectSettingsRoutes from '@/router/projectSettings';
import workspaceRoutes from '@/router/workspace'
Vue.use(VueRouter);

export default function makeRouter(store) {
  const routes = [
    {
      path: '/:handle/dashboard',
      component: ProjectLayout,
      meta: {
        requiresAuth: true,
        isDashboard: true,
      },
      children: [
        {
          path: '/',
          name: 'DashboardMain',
          component: () => import('@/views/Dashboard'),
          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
    ...settingsRoutes,
    { // TODO combine with /organizations/ in settings
      path: '/orgs/:handle/invite/:token',
      component: AuthLayout,
      children: [
        {
          path: '',
          name: 'GetInvite',
          component: () => import('@/views/Org/GetInvite.vue'),
        },
      ],
        meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/',
      component: AuthLayout,
      beforeEnter: (to, from, next) =>
      {
        if (to.fullPath === '/') { 
          if (store.state?.user?.currentAccount?.handle) {
            next({
              name: 'ProjectsView',
              params: { handle: store.state.user.currentAccount.handle }
            });
          } else {
              next({ name: 'Login' });
          }
        } else {
          next();
        }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: () => import('@/views/Auth/LoginPage'),
        },
        {
          path: 'signup',
          name: 'signup',
          component: () => import('@/views/Auth/RegisterPage'),
        },
        {
          path: 'confirmEmail',
          name: 'confirmEmail',
          component: () => import('@/views/Auth/ConfirmEmailPage'),
        },
        {
          path: 'createPassword',
          name: 'createPassword',
          component: () => import('@/views/Auth/CreatePasswordPage'),
        },
        {
          path: 'forgotPassword',
          name: 'forgotPassword',
          component: () => import('@/views/Auth/ForgotPasswordPage'),
        },
        {
          path: 'resetPassword',
          name: 'resetPassword',
          component: () => import('@/views/Auth/ResetPasswordPage'),
        },
        {
          path: 'twoFactor',
          name: 'twoFactor',
          component: () => import('@/views/Auth/TwoFactorPage'),
        },
      ],
    },
    ...projectRoutes,
    ...adminRoutes,
    ...caseRoutes,
    ...runRoutes,
    ...milestoneRoutes,
    ...testPlansRoutes,
    ...projectSettingsRoutes,
    ...workspaceRoutes,
    {
      path:
        '/setup',
      name: 'Setup',
      component: () => import('@/views/Setup.vue')
    },
    {
      path: '/notFound',
      name: '404',
      component: () => import('@/views/404.vue')
    },
    {
      path: '*',
      name: 'NotFound',
      component: () => import('@/views/404.vue')
    },
  ];


  const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
  });

  router.beforeEach((to, from, next) => {
    // Check if the route requires authentication

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      // If user is authenticated, continue
      if (store.getters['user/isAuthenticated']) {
        // Set "currentAcount" on direct navigation
        if (
          to.params.handle &&
          to.params.handle !== store.state.user.currentAccount?.handle
        ) {
          store.state.user.currentAccount = store.state.user.orgs.find(
            (org) => org.handle === to.params.handle
          );
        }
        return next();
      } else {
        return next({ name: 'Login' });
      }
    } else if (to.fullPath === '/') {
      if (store.state?.user?.currentAccount?.handle) {
        // If they do, redirect to ProjectsView
        return next({
          name: 'ProjectsView',
          params: { handle: store.state.user.currentAccount.handle }
        });
      } else {
        // If not, allow navigation (which will be handled by the route's beforeEnter)
        return next();
      }
    } else {
      // For all other routes, allow navigation
      return next();
    }
  });

  return router;
}
