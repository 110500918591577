<template>
  <v-row align="start">
    <v-col
      cols="12"
      align="left"
    >
      <v-card-title>{{ $t("changeUsername") }}</v-card-title>
      <v-divider class="my-6" />
      <v-row>
        <v-col
          cols="3"
          col-sm="5"
        >
          {{ user?.handle }}
        </v-col>

        <UsernameUpdate
          :is-org="false"
          :previous-detail="{ handle: user.username, owner_uid: user.uid }"
          @usernameUpdated="updatedUsername"
        />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import UsernameUpdate from '@/components/Form/UsernameUpdate';
import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('user');
export default {
  components: { UsernameUpdate },
  computed: {
    ...mapState(['user']),
  },
  methods: {
    ...mapActions({ setUser: 'setUser' }),
    /**
     * update local state after the user name is succesfully updated
     * @param {Object} payload
     * */
    updatedUsername(payload) {
      let newUser = {
        ...this.user,
        username: payload.name,
      };
      this.setUser(newUser);
    },
  },
};
</script>