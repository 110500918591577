export default function makeAuthService(api) {
  return {
    login: function (credentials) {
      return api.post(`/signin`, credentials)
    },
    register: function (credentials) {
      return api.post(`/signup`, credentials)
    },
    forgotPassword: function (email) {
      return api.post(`/forgot-password`, email)
    },
    resetPassword: function (payload) {
      return api.post(`/reset-password`, payload)
    },
    verifyEmail: function (token) {
      return api.get(`/verify-reset-token?token=${token}`)
    },
  };
}
