<template>
  <v-row id="main-row">
    <v-col
      v-if="requestState.done"
      id="alert-col"
      cols="12"
      align="center"
    >
      <v-alert
        v-if="!requestState.hasError"
        id="success-alert"
        dismissible
      >
        {{ requestState.message }}
      </v-alert>
      <v-alert
        v-if="requestState.hasError"
        id="error-alert"
        variant="error"
        dismissible
      >
        {{
          requestState.message
        }}
      </v-alert>
    </v-col>
    <v-col
      id="invite-button-col"
      cols="12"
      align="right"
      class="my-0 pb-0"
    >
      <v-btn
        id="invite-member-dialog-activate"
        color="secondary"
        @click="inviteMember = !inviteMember"
      >
        {{
          $t('inviteMember')
        }}
      </v-btn>
    </v-col>
    <v-col
      v-if="removeMemberDialog"
      id="remove-member-dialog-col"
    >
      <v-dialog
        id="remove-member-dialog"
        v-model="removeMemberDialog"
        :title="$t('areYouSure')"
        :ok-title="$t('yes')"
        centered
        @ok="removeUser(selectedMember)"
      >
        <v-card
          v-if="selectedMember"
          id="remove-member-dialog-card"
        >
          <v-card-text
            id="remove-member-dialog-text"
            class="px-12 py-12"
          >
            {{
              $t('removeMemberConfirm', {
                username: selectedMember.username,
                orgname: current_org.name,
              })
            }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col
      v-if="inviteMember"
      id="members-invitation-modal-col"
    >
      <v-dialog
        id="members-invitation-modal"
        v-model="inviteMember"
        width="40%"
      >
        <v-card id="members-invitation-modal-card">
          <invite-member-modal
            v-if="inviteMember"
            id="invite-member-modal"
            :members="members"
            :current_org="current_org"
            :roles="roles"
            @completed="invitedUser"
          />
        </v-card>
      </v-dialog>
    </v-col>
    <v-col
      id="members-list-card-col"
      cols="12"
    >
      <v-card id="members-list-card">
        <v-card-title
          class="subtitle-1 text-center"
          color="default"
        >
          <v-row
            align="center"
            justify="space-between"
          >
            <v-col cols="auto">
              <v-checkbox
                id="select-all-members"
                v-model="selectAllMembers"
                :value="selectAllMembers"
                :label="$t('members')"
                hide-details
              />
            </v-col>
            <v-col
              v-if="totalMembers > 1"
              id="members-list-total-members"
              cols="auto"
            >
              {{ totalMembers }} {{
                $t('membersLeft') }}
            </v-col>
            <v-col
              v-else
              id="members-list-total-members-1"
              cols="auto"
            >
              {{ totalMembers }} {{ $t('memberLeft')
              }}
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text
          v-if="totalMembers"
          class="px-0 py-0"
        >
          <member-item
            v-for="member in members"
            id="members-list"
            :key="member.uid"
            :is-selected="selectAllMembers"
            :user-id="member.uid"
            :is-admin="isAdmin"
            :username="member.first_name + ' ' + member.last_name"
            :role="member.role"
            :permissions="roles"
            @change-permission="changeMemberPermission"
            @remove-member="enableMemberRemovalDialog"
            @member-selected="memberSelected"
          />
        </v-card-text>
        <v-card-text
          v-else
          class="text-center"
        >
          <v-row aligh-h="center">
            <v-col
              cols="12"
              align="center"
            >
              <v-icon font-size="2em">
                mdi-information-outline
              </v-icon>
            </v-col>
            <v-col class="my-10 py-10">
              <h6>{{ $t('noMembersInvited') }}</h6>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-pagination
            v-if="members.length > 30"
            v-model="currentPage"
            class="py-12 my-12"
            :total-visible="5"
            :total-items="totalRows"
            :items-per-page="perPage"
            align="center"
            aria-controls="members-list"
            prev-icon="mdi-chevron-left"
            next-icon="mdi-chevron-right"
            @input="$emit('loadNext', { currentPage: currentPage, perPage: perPage })"
          />
        </v-card-actions>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import MemberItem from '@/components/Members/MemberItem.vue';
import InviteMemberModal from '@/components/Members/InviteMemberModal.vue';
import makeOrgService from '@/services/api/org';

export default {
  components: {
    MemberItem,
    InviteMemberModal,
  },
  props: {
    /**
     * selected organization
     */
    current_org: {
      type: Object,
      required: true,
    },
    /**
     * current organization members
     */
    members: {
      type: Array,
      required: false,
      default() {
        return [];
      },
    },
    /**
     * is current
     */
    isAdmin: {
      type: Boolean,
      default: false,
    },
    /**
     * roles that are allowed by the service
     */
    roles: {
      type: Array,
      required: true,
    },
    /**
     * total number of available members
     */
    totalMembers: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      selectAllMembers: false,
      currentPage: 1,
      totalRows: this.totalMembers / 30,
      perPage: 30,
      inviteMember: false,
      removeMemberDialog: false,
      selectedMember: undefined,
      requestState: {
        isLoading: false,
        hasError: false,
        data: undefined,
        message: '',
        done: false,
      },
    };
  },

  methods: {
    /**
     * enable member removal confirmtion
     */
    enableMemberRemovalDialog(data) {
      this.selectedMember = data;
      this.removeMemberDialog = !this.removeMemberDialog;
    },
    /**
     * remove user from organization
     * @param {Object} data
     */
    async removeUser(data) {
      try {
        const orgService = makeOrgService(this.$api);
        this.requestState.isLoading = true;
        await orgService.removeUser({
          handle: this.current_org.handle,
          ...data,
        });
        this.removeMemberDialog = false;
        this.requestState.isLoading = false;
        this.requestState.message = this.$t('memberDeleted', {
          username: data.username,
          orgname: this.current_org.name,
        });
        this.$emit('removedUser', data);
        this.requestState.done = true;
      } catch (error) {
        this.requestState.message = this.$t('memberNotDeleted', {
          username: data.username,
          orgname: this.current_org.name,
        });
        this.removeMemberDialog = false;
        this.requestState.isLoading = false;
        this.requestState.hasError = true;
        this.requestState.done = true;
      }
    },
    /**
     * inviatation was sent successfully
     */
    invitedUser() {
      this.$swal({
        title: this.$t('invitationSent'),
        icon: 'success',
        showConfirmButton: false,
        position: 'top-center',
        timer: 2000,
        toast: true,
      });
      this.inviteMember = !this.inviteMember;
    },
    /**
     * changes role of user
     * @param {Object} data
     */
    async changeMemberPermission(data) {
      const orgService = makeOrgService(this.$api);
      this.requestState.isLoading = true;
      try {
        await orgService.updateRoleOfMember({
          org_id: this.current_org.handle,
          ...data,
        });
        this.requestState.data = data;
        this.requestState.message = this.$t('roleUpdateSuccess', { username: data.username });
        this.$emit('updateMember', data);
        this.requestState.isLoading = false;
        this.requestState.done = true;
      } catch (error) {
        this.requestState.data = data;
        this.requestState.message = this.$t('roleUpdateNotSuccessful', { username: data.username });
        this.requestState.isLoading = false;
        this.requestState.hasError = true;
        this.requestState.done = true;
      }
    },
    memberSelected(isSelected, memberId) {
      // TODO update list ids
      console.log(isSelected, memberId);
    },
  },
};
</script>
