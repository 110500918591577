<template>
  <div>
    <div
      v-if="items.length"
      class="tag-editor"
      @click.stop="showModal = true"
    >
      <span class="truncate"> {{ items[0].name }} </span>
      <span
        v-if="items.length > 1"
        class="ml-1"
      > +{{ items.length - 1 }} </span>
      <v-icon color="black">
        mdi-chevron-down
      </v-icon>
    </div>

    <div v-else>
      <span>-</span>
    </div>

    <v-dialog
      v-model="showModal"
      persistent
      max-width="400px"
    >
      <v-card>
        <v-card-title class="headline d-flex justify-space-between font-weight-bold">
          {{ $t('tags') }}
          <v-btn
            icon
            @click="showModal = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <div
            v-for="(item, index) in sortedTags"
            :key="index"
            :class="{'disabled-action': isProjectArchived}"
          >
            <v-checkbox
              v-model="selectedTags"
              class="field-theme"
              :ripple="false"
              :value="item"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
              :hide-details="true"
              :disabled="isProjectArchived"
            >
              <template v-slot:label>
                <span class="fs-14px text-theme-label">{{ item.name }}</span>
              </template>
            </v-checkbox>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="blue"
            class="text-capitalize mt-0 w-100 btn"
            depressed
            height="40"
            @click="closeModal"
          >
            {{ $t('close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'TagEditor',
  props: {
    items: {
      type: Array,
      required: true,
    },
    value: {
      type: Array,
      default: () => [],
    },
    isProjectArchived: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showModal: false,
      selectedTags: this.items ? [...this.items] : [],
    };
  },
  computed: {
    sortedTags() {
      return [...this.items].sort();
    },
    },
  watch: {
    showModal(newValue){
          if (!newValue) {
            this.$emit('updateTag', this.selectedTags);
        }
    }
  },

  methods: {
    closeModal() {
      this.showModal = false;
    },

  },
};
</script>

<style scoped lang="scss">
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.tag-editor {
  display: flex;
  flex-wrap: wrap;
  gap: 3px;
  font-weight: 400;
  align-items: center;
  cursor: pointer;
}
.btn {
  width: 100%;
  height: 40px;
  color: white !important;
  padding: 8px 24px 8px 24px;
  gap: 8px;
  border-radius: 6px;
}
</style>
