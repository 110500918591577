<template>
  <v-card
    class="white py-6 px-6 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-center justify-space-between">
      <h2 class="text-theme-base">
        {{ $t('templatesPage.templates') }}
      </h2>
      <v-btn
        color="primary"
        depressed
        :class="{
          'text-capitalize btn-theme rounded-lg': true,
          'disabled-action': isProjectArchived
        }"
        height="40px"
        @click="!isProjectArchived && $emit('create-custom-field')"
      >
        {{ $t('templatesPage.createTemplate') }}
        <v-icon
          class="ml-1"
          size="xs"
        >
          mdi-plus
        </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import projectStatus from '@/mixins/projectStatus';

export default {
  name: 'CustomFieldHeader',
  mixins: [projectStatus],
 
}
</script>
