<template>
  <v-container fluid>
    <Appbar />

    <div class="d-flex">
      <div
        class="pr-0 pb-0"
        :style="{ width: isProjectMenuCollapsed ? '4vw' : '12vw' }"
        :class="{ 'mw-leftmenu': !isProjectMenuCollapsed }"
      >
        <project-left-menu :menu-items="menuItems" />
      </div>

      <div
        :style="{ width: isProjectMenuCollapsed ? '96vw' : '88vw' }"
        class="pb-0"
      >
        <router-view />
      </div>
    </div>
    <span id="versionSpan">
      {{ versionString }}
    </span>
    <div v-if="isTextAssistOpen">
      <TextAssist />
    </div>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { createNamespacedHelpers } from 'vuex';
import MilestoneIcon from '@/assets/svg/left-menu/milestone.svg';
import TestPlansIcon from '@/assets/svg/left-menu/test-plans.svg';
import TestRunsIcon from '@/assets/svg/left-menu/test-runs.svg';
import TestCasesIcon from '@/assets/svg/left-menu/test-cases.svg';
import TemplatesIcon from '@/assets/svg/left-menu/templates.svg';
import CustomFieldsIcon from '@/assets/svg/left-menu/custom-fields.svg';
import SharedStepsIcon from '@/assets/svg/left-menu/shared-steps.svg';

import Appbar from "@/components/Layout/Appbar/Index.vue"
import ProjectLeftMenu from '@/components/Project/ProjectLeftMenu';
import TextAssist from '@/components/base/TextAssist.vue';

const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'ProjectLayout',

  components: {
    Appbar,
    ProjectLeftMenu,
    TextAssist,
  },
  computed: {
    ...mapGetters(['isProjectMenuCollapsed', 'isTextAssistOpen']),
    ...mapState(['currentAccount']),

    versionString() {
      if (
        process.env.VUE_APP_VERSION &&
        process.env.VUE_APP_STRIPE_PUBLIC_KEY.indexOf('live') < 0
      ) {
        return `FRONTEND VERSION: ${process.env.VUE_APP_VERSION}`;
      }
      return '';
    },

    menuItems() {
      const handle = this.$route.params.handle
      const projectKey = this.$route.params.key
      return [
        {
          group: 'General',
          list: [
            { id: 1, title: 'Milestones', icon: MilestoneIcon, className: 'stroke', to: { name: 'Milestones', params: { handle: handle, key: projectKey } }, isActive: false },
            { id: 2, title: 'Test plans', icon: TestPlansIcon, className: 'stroke', to: { name: 'TestPlans', params: { handle: handle, key: projectKey } }, isActive: false },
            { id: 3, title: 'Test runs', icon: TestRunsIcon, className: 'stroke', to: { name: 'Runs', params: { handle: handle, key: projectKey } }, isActive: false },
            { id: 4, title: 'Test cases', icon: TestCasesIcon, className: 'stroke', to: { name: 'Cases', params: { handle: handle, key: projectKey } }, isActive: false },
          ]
        },
        {
          group: 'Settings',
          list: [
            { id: 5, title: 'Templates', icon: TemplatesIcon, className: 'stroke', to: { name: 'Templates', params: { handle: handle, key: projectKey } }, isActive: false },
            { id: 6, title: 'Custom fields', icon: CustomFieldsIcon, className: 'stroke', to: { name: 'CustomFields', params: { handle: handle, key: projectKey } }, isActive: false },
            { id: 7, title: 'Shared steps', icon: SharedStepsIcon, className: 'fill', to: { name: 'SharedSteps', params: { handle: handle, key: projectKey } }, isActive: false },
          ]
        }
      ];
    }
  },
}
</script>

<style scoped>
#versionSpan {
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: 0 1em;
  color: white;
  background: black
}

.app-bar-project {
  margin-left: 12px;
  margin-right: 12px;
}

</style>
