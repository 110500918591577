<template>
  <div class="search-field d-flex align-center">
    <iconSearch class="icon-search" />
    <v-text-field
      v-model="internalValue"
      height="40"
      background-color="#F9F9FB"
      hide-details
      ripple="false"
      class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
      :placeholder="placeholder"
    >
      <template v-slot:prepend-inner>
        <SearchIcon />
      </template>
    </v-text-field>
  </div>
</template>

<script>
import iconSearch from '@/assets/svg/search-outline-16x16-gray.svg';
import SearchIcon from '@/assets/svg/search-icon.svg';

export default {
  name: 'SearchField',
  components: {
    iconSearch,
    SearchIcon,
  },
  props: ['value', 'placeholder'],
  
  computed: {
    internalValue: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.search-field {
  position: relative;
  background: #f9f9fb;
  border-radius: 8px;
}

.search-field .icon-search {
  flex: none;
  position: absolute;
  top: 50%;
  left: 12px;
  transform: translateY(-50%);
}

.search-field .v-text-field {
  margin-top: 0;
  flex: auto;
}
</style>