import ProjectLayout from '@/Layouts/ProjectLayout.vue';

const routes = [
  {
    path: '/:handle/:key/cases',
    component: ProjectLayout,
    children: [
      {
        path: '',
        name: 'Cases',
        component: () => import('@/views/Tests/Case/Index'),
      },
      {
        path: 'folders/:folderUid',
        name: 'CasesFolder',
        component: () => import('@/views/Tests/Case/Index'),
      },
      {
        path: 'create',
        name: 'CreateTestCases',
        component: () => import('@/views/Tests/Case/CaseExecutionService'),
      },
      {
        path: 'repository',
        name: 'CaseFromRepository',
        component: () => import('@/views/Tests/Case/CaseFromRepository'),
      },
      {
        path: ':uid/edit',
        name: 'EditTestCases',
        component: () => import('@/views/Tests/Case/CaseExecutionService'),
      },
      {
        path: 'import/csv',
        name: 'ImportCSV',
        component: () => import('@/views/Tests/Case/Import/ImportCsv'),
      },
    
      {
        path: 'export/spreadsheet',
        name: 'ExportSpreadsheet',
        component: () => import('@/views/Tests/Case/Export/ExportSpreadsheet'),
      },
    ],
  },
];

export default routes.map((route) => {
  const meta = {
    requiresAuth: true,
  };
  return { ...route, meta };
});
