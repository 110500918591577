<template>
  <div>
    <v-dialog
      v-model="isTextAssistOpen"
      persistent
      max-width="1200px"
    >
      <div
        class="custom-padding white fill-height v-align-start font-inter rounded-lg overflow-hidden"
      >
        <!-- Header -->
        <v-row
          align-content="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="12"
            class="headline d-flex justify-space-between font-weight-bold"
          >
            <span class="ml-5">   
              {{ $t('textAssist.title') }}
            </span>
            <v-btn 
              icon
              class="mr-2"
              @click="onCloseAssist" 
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>

        <!-- Main Content -->
        <v-row
          align-content="center"
          justify="center"
        >
          <!-- Left Column: Field Content -->
          <v-col
            cols="12"
            sm="6"
            class="text-field-contents"
          >
            <v-card class="no-shadow">
              <v-card-text>
                <CustomFieldFromTemplate
                  :key="textAssistField.id"
                  name="subTemplate"
                  :field="textAssistField"
                  :can-delete="false"
                  :can-assist="false"
                  @delete="removeCustomField(field)"
                />
              </v-card-text>
            </v-card>
          </v-col>

          <!-- Divider -->
          <v-divider
            vertical 
            class="divider-theme"
          />

          <!-- Right Column: Assistant Messages -->
          <v-col
            cols="auto"
            class="flex-grow-1 flex-shrink-0"
          >
            <v-responsive
              class="overflow-y-hidden fill-height"
              height="500"
            >
              <v-card
                flat
                class="d-flex flex-column fill-height"
              >
                <!-- Messages Section -->
                 
                <v-card-text  
                  ref="chatContainer"
                  class="chat-container flex-grow-1 align-center overflow-y-auto"
                >
                  <div
                    v-for="msg in dialog"
                    :key="msg.id"
                    class="message-container"
                    :class="{ 'align-right': msg.author === 'user' }"
                  >
                    <v-card
                      v-if="msg.author === 'user'"
                      :color="msg.author === 'user' ? 'primary' : 'grey lighten-3'"
                      class="pa-3 mb-2 message-card no-shadow"
                      dark
                    >
                      {{ msg.content }}
                    </v-card>
                    <v-card
                      v-else
                      class="pa-3 mb-2 message-card message-border d-flex justify-space-between"
                      outlined
                    >
                      <span>{{ msg.content }}</span>
                    </v-card>
                    <v-btn
                      v-if="msg.author !== 'user' && msg.id !== 1"
                      icon
                      class="ml-2 no-shadow"
                      aria-label="Revert Changes"
                      @click="revertChanges(msg.id)"
                    >
                      <v-icon>mdi-restore</v-icon>
                    </v-btn>
                  </div>
                  <!-- Loading Message -->
                  <div 
                    v-if="loading" 
                    class="message-container loader"
                  >
                    <v-card
                      class="pa-3 mb-2 message-card bg-theme-base d-flex justify-space-between"
                      outlined
                    >
                      <span>{{ $t('textAssist.analyzingRequirements') }}</span>
                    </v-card>
                  </div>
                </v-card-text>

                <!-- Input Section -->
                <v-card-text class="flex-shrink-1 d-flex align-center input-container">
                  <v-text-field
                    v-model="newMessage.content"
                    :placeholder="$t('textAssist.typeYourRequirements')"
                    type="text"
                    dense
                    height="38px"
                    hide-details
                    class="rounded-lg field-theme"
                    background-color="#F9F9FB"
                    @keyup.enter="addMessage"
                  />
                  <v-btn
                    color="primary"
                    class="ml-4 send-btn no-shadow"
                    fab
                    small
                    @click="addMessage"
                  >
                    <v-icon :size="20">
                      mdi-send
                    </v-icon>
                  </v-btn>
                </v-card-text>
              </v-card>
            </v-responsive>
          </v-col>
        </v-row>

        <!-- Footer with Action Buttons -->
        <v-row
          justify="end"
          class="action-btn-wrapper px-6 py-4"
        >
          <v-btn
            width="160"
            color="#F2F4F7"
            class="text-capitalize rounded-lg mr-3 btn-theme"
            depressed
            @click="rejectChanges"
          >
            {{ $t('textAssist.rejectChanges') }}
          </v-btn>
          <v-btn
            width="160"
            color="primary"
            class="text-capitalize rounded-lg btn-theme"
            depressed
            @click="acceptChanges"
          >
            {{ $t('textAssist.acceptChanges') }}
          </v-btn>
        </v-row>
      </div>
    </v-dialog>
  </div>
</template>


<script>
import { mapGetters, mapMutations } from 'vuex';
import CustomFieldFromTemplate from "@/views/Tests/Case/Components/CustomFieldFromTemplate.vue";
import makeAssistService from '@/services/api/assist';
import { showErrorToast } from '@/utils/toast';

export default {
  name: 'TextAssist',
  components: {
    CustomFieldFromTemplate,
  },
  data() {
    return {
      dialog: [],
      newMessage: { id: null, author: "user", content: "" },
      originalText: "",
      currentText: "", 
      assistService: null,
      handle: null,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['isTextAssistOpen', 'textAssistField', 'textAssistCallback']),
  },

  mounted() {
    this.handle = this.$route.params.handle;
    this.assistService = makeAssistService(this.$api);
    this.init(this.handle);
    this.originalText = this.textAssistField.value || "";
    this.currentText = this.originalText;
    this.dialog = [
      {
        id: 1,
        author: "system",
        content: this.$t('textAssist.InitialMessage'),
        newValue: ""
      },
    ];
  },

  async beforeRouteUpdate(to, from, next) {
    const handle = to.params.handle;
    if (handle && handle !== from.params.handle) {
      this.handle = handle;
      try {
        await this.init(handle);
        next();
      } catch (error) {
        next();
      }
    } else {
      next();
    }
  },

  methods: {
    ...mapMutations(['toggleTextAssist']),

    scrollToBottom() {
      const container = this.$refs.chatContainer;
      if (container) {
        container.scrollTop = container.scrollHeight;
      }
    },

    async getResponse() {
      const data = {
        messageContent: this.newMessage.content,
        textFieldValue: this.textAssistField.value,
        textFieldName: this.textAssistField.name,
      };

      this.loading = true;

      try {
        const response = await this.assistService.getAssistResponse(this.handle, data);

        setTimeout(() => {
          this.loading = false;
          this.dialog.push({
            id: this.dialog.length + 1,
            author: "system",
            content: response.data.response.changeSummary,
            newValue: response.data.response.newValue,
          });
          this.$nextTick(() => this.scrollToBottom());
          this.currentText = response.data.response.newValue || this.currentText;
          this.textAssistField.value = this.currentText;
        }, 1500);
      } catch (err) {
        this.loading = false;
        showErrorToast(this.$swal, err.response?.data?.message || this.$t('error.internalServerError'));
      }
    },

    addMessage() {
      if (this.newMessage.content.trim() !== "") {
        const newId = this.dialog.length + 1;
        this.dialog.push({ ...this.newMessage, id: newId });
        this.$nextTick(() => this.scrollToBottom());
        this.getResponse();
        this.newMessage.content = "";
      }
    },

    acceptChanges() {
      this.textAssistField.value = this.currentText;
      this.originalText = this.currentText;
      this.onCloseAssist();
    },

    rejectChanges() {
      this.textAssistField.value = this.originalText;
      this.onCloseAssist();
    },

    revertChanges(messageId) {
      const message = this.dialog.find((msg) => msg.id === messageId);
      
      if (message) {
        this.currentText = message.newValue;
        if (this.textAssistField) {
          this.textAssistField.value = message.newValue;
        }
      }
    },

    onCloseAssist() {
      this.textAssistField.value = this.originalText;
      this.toggleTextAssist();
      this.dialog = [];
      this.newMessage.content = "";
    },
  },
};
</script>



<style scoped lang="scss">
.text-field-contents {
  display: block;
  max-width: 438px;
  width: 438px;
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-container {
  display: flex;
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.message-card {
  text-align: start;
  max-width: 438px;
}

.message-input {
  flex-grow: 1;
  border-radius: 12px !important;
}

.v-text-field__control {
  border-radius: 12px !important; 
}

.send-btn {
  background-color: #2196f3;
  color: white;
  align-self: center;
}

.send-btn:hover {
  background-color: #1976d2;
}

.custom-padding {
  padding: 24px !important;
}

.no-shadow {
  box-shadow: none !important;
}

.message-border {
  border-color: #F2F4F7 !important;
}

.chat-container {
  scroll-behavior: smooth;
}

.loader {
  text-align: center;
  font-size: 16px;
  color: #888;
  animation: fade-in-out 1s ease-in-out infinite;
}

@keyframes fade-in-out {
  0%, 100% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
}


</style>
