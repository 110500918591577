<template>
  <v-row>
    <v-col cols="12">
      <v-select
        v-model="localSelectedItem"
        :items="items"
        :label="label"
        :prepend-icon="prependIcon"
        outlined
        dense
        return-object
        hide-details
      >
        <template v-slot:item="{ item }">
          <v-row
            align="center"
            class="text-left"
          >
            <v-col class="shrink">
              <v-icon :color="item.color">
                {{ item.icon }}
              </v-icon>
            </v-col>
            <v-col>
              {{ item.text }}
            </v-col>
          </v-row>
        </template>
        <template v-slot:selection="{ item }">
          <span>
            <v-icon
              :color="item.color"
              left
            >{{ item.icon }}</v-icon>
            {{ item.text }}
          </span>
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script>
/**
 * @name IconDropdownSelector
 * @description A dropdown selector with icons
 * @param {String} label - The label for the dropdown
 * @param {String} prependIcon - The icon to prepend to the dropdown
 * @param {Array} items - The items to display in the dropdown
 * @param {Object} selectedItem - The selected item
 */
export default {
  name: 'IconDropdownSelector',
  props: {
    label: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectedItem: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      localSelectedItem: this.selectedItem,
    };
  },
  watch: {
    selectedItem: {
      immediate: true,
      handler(newValue) {
        this.localSelectedItem = newValue;
      },
    },
   localSelectedItem(newValue) {
    this.$emit('update:selectedItem', newValue);
  },
  },
};
</script>
