<template>
  <v-row class="align-items-center ml-0 mr-0 justify-content-between">
    <v-row class="align-items-center ml-0 mr-0">
      <CheckIcon class="activeImg1" />
      <v-text-field
        v-model="accessToken"
        readonly
        dense
        class="font-weight-normal fs-14 font-inter text-left mb-0 px-5 border-0"
        @focus="$event.target.select()"
      />
      <v-tooltip
        v-if="copied"
        bottom
      >
        <template v-slot:activator="{ on }">
          <v-btn
            id="btn-copy"
            icon
            @click="copy"
            v-on="on"
          >
            <CopyIcon
              v-if="!copied"
              class="width-15 height-15 activeImg"
            />
            <CheckIcon
              v-if="copied"
              class="activeImg1"
            />
          </v-btn>
        </template>
        <span>Copied!</span>
      </v-tooltip>
    </v-row>
    <v-btn
      class="text-red-100 border-gray-300 font-weight-medium fs-12"
      @click="deleteAccessToken"
    >
      Delete
    </v-btn>
  </v-row>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg';
import CopyIcon from '@/assets/svg/copy.svg';
export default {
  components: { CheckIcon, CopyIcon },
  props: {
    accessToken: String,
  },
  data() {
    return {
      isCopied: false,
      intervalid1: '',
    };
  },
  computed: {
    copied() {
      return this.isCopied;
    },
  },
  beforeDestroy() {
    clearInterval(this.intervalid1);
  },
  mounted() {
    clearInterval(this.intervalid1);
  },
  methods: {
    copy() {
      if (this.isCopied) {
        return;
      }

      this.isCopied = true;
      this.$refs.myinput.focus();
      document.execCommand('copy');
      this.intervalid1 = setInterval(this.tooltipOff, 5050);
    },
    tooltipOff() {
      this.isCopied = false;
      clearInterval(this.intervalid1);
    },
    deleteAccessToken() {
      this.$emit('onDelete');
    },
  },
};
</script>

<style lang="scss" scoped>
.activeImg {
  path {
    fill: #3b82f6;
  }
}
.activeImg1 {
  path {
    fill: #3fb950;
  }
}
</style>