<template>
  <v-container
    class="white app-height-global align-start card pa-6"
    fluid
  >
    <v-row>
      <v-col
        cols="6"
        sm="6"
      >
        <div class="d-flex flex-row justify-start align-center">
          <v-responsive
            class="ma-0"
            max-width="344"
          >
            <v-text-field
              v-model="searchTerm"
              :placeholder="$t('placeHolder.searchByNameId')"
              background-color="#F9F9FB"
              class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0 flex-inherit"
              height="38"
              dense
              hide-details
            >
              <template v-slot:prepend-inner>
                <SearchIcon />
              </template>
            </v-text-field>
          </v-responsive>
          <WorkspaceFilter 
            :projects="projects"
            :tags="tags"
            @filters="applyFilters" 
          />
        </div>
      </v-col>
      <v-col
        cols="6"
        sm="6"
        class="d-flex justify-end"
      >
        <SettingsMenu 
          table-type="workspace"
        />
      </v-col>
    </v-row>  
    <v-row>
      <v-col
        cols="12"
        sm="12"
      >
        <v-data-table
          v-model="selectedRows"
          :headers="filteredHeaders"
          :items="isSelectedData ? selectedData : displayTableData"
          item-key="uid"
          show-select
          class="data-table-style table-fixed"
          :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
          :item-class="getItemClass"
          @input="updateSelectedRows"
          @click:row="handleClick"
        >
          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <div class="d-flex justify-center align-center">
              <v-checkbox
                id="remember-me-checkbox"
                class="field-theme"
                :ripple="false"
                off-icon="icon-checkbox-off"
                on-icon="icon-checkbox-on"
                indeterminate-icon="icon-indeterminate"
                :input-value="props.value"
                :indeterminate="props.indeterminate"
                @change="on.input"
              />
            </div>
          </template>

          <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
            <div class="d-flex justify-center align-center">
              <v-checkbox
                id="remember-me-checkbox"
                class="field-theme"
                :ripple="false"
                off-icon="icon-checkbox-off"
                on-icon="icon-checkbox-on"
                :input-value="isSelected"
                @change="select"
                @click.stop
              />
            </div>
          </template>

          <template v-slot:[`header.actions`]="{ header }">
            <div class="d-none">
              {{ header.text }}
            </div>
          </template>
          <template v-slot:[`item.id`]="{ item }">
            <div class="custom-attribute text-uppercase font-weight-regular text-truncate">
              {{ item.projectKey }} - {{ item.uid }}
            </div>
          </template>
          <template v-slot:[`item.assignDate`]="{ item }">
            <span class="custom-attribute text-center font-weight-regular">{{ item.lastAssignedAt ?? '-' }}</span>
          </template>
          <template v-slot:[`item.dueDate`]="{ item }">
            <span class="custom-attribute text-center font-weight-regular">{{ item.dueAt ?? '-' }}</span>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <span 
              class="fw-semibold"
              :style="{ color: getStatusColorByName(item.status) }"
            >{{ item.status }}</span>
          </template>
          <template v-slot:[`item.priority`]="{ item }">
            <span
              v-if="item.priority"
              class="fw-semibold"
              :style="{ color: getPriorityColorByName(item.priority) }"
            >{{ item.priority }}</span>
          </template>
          <template v-slot:[`item.name`]="{item}">
            <v-tooltip
              bottom
              left
              max-width="485px"
              :disabled="item.name.length < 61"
              content-class="tooltip-theme"
            >
              <template v-slot:activator="{ on, attrs }">
                <div 
                  class="custom-attribute text-truncate fw-semibold"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.name }}
                </div>
              </template>
              <span>{{ item.name }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.project`]="{ item }">
            <v-tooltip
              bottom
              left
              max-width="485px"
              :disabled="item.projectName.length < 61"
              content-class="tooltip-theme"
            >
              <template v-slot:activator="{ on, attrs }">
                <div 
                  class="custom-attribute text-truncate font-weight-regular"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ item.projectName }}
                </div>
              </template>
              <span>{{ item.projectName }}</span>
            </v-tooltip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex flex-row justify-center">
              <v-menu 
                left
                offset-y
                min-width="159px"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    icon
                    v-on="on"
                  >
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list
                  dense
                  class="text-left"
                >
                  <!-- Hide Pinata for now -->
                  <v-list-item v-if="false">
                    <v-list-item-icon>
                      <pinata-icon />
                    </v-list-item-icon>
                    <v-list-item-title>{{ $t('runWithPinata') }}</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="$emit('selectedExecutions', [item])">
                    <v-icon
                      color="#344054"
                      class="mr-3"
                    >
                      mdi-plus
                    </v-icon>
                    <v-list-item-content>{{ $t('addResult') }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </template>

          <template v-slot:[`item.tags`]="{item}">
            <v-tooltip
              bottom
              left
              max-width="485px"
              :disabled="!Array.isArray(item.tags) || item.tags.length < 2"
              content-class="tooltip-theme"
            >
              <template v-slot:activator="{ on, attrs }">
                <span
                  class="custom-attribute font-weight-regular text-theme-table-text"
                  v-bind="attrs"
                  v-on="on"
                >
                  <template>
                    <div class="text-truncate">
                      <template v-if="Array.isArray(item?.tags)">
                        <template v-if="item.tags.length > 0">
                          {{ item.tags.map(tag => `${tag.name}`).join(', ') }}
                        </template>
                        <template v-else>
                          <v-icon>mdi-minus</v-icon>
                        </template>
                      </template>
                      <template v-else-if="typeof item?.tags === 'string' && item.tags.trim() !== ''">
                        {{ item.tags.name }}
                      </template>
                      <template v-else>
                        <v-icon>mdi-minus</v-icon>
                      </template>
                    </div>

                  </template>
                </span>
              </template>
              <span>
                {{
                  Array.isArray(item?.tags)
                    ? item.tags.map(tag => `${tag.name}`).join(', ')
                    : ''
                }}
              </span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SettingsMenu from '@/components/Project/SettingsMenu.vue';
import WorkspaceFilter from '@/components/Workspace/Filter.vue';
import SearchIcon from '@/assets/svg/search-icon.svg';
import { createNamespacedHelpers } from 'vuex';
import pinataIcon from '@/assets/svg/pinata.svg'
import { dateMixin } from '@/mixins/date.js';
import { mapGetters, mapActions } from 'vuex';
const { mapState } = createNamespacedHelpers('user');
import colorPreferencesMixin from '@/mixins/colorPreferences';

export default {
  components: {
    WorkspaceFilter,
    SearchIcon,
    SettingsMenu,
    pinataIcon
  },
  mixins: [dateMixin, colorPreferencesMixin],
  props: {
    selectedExecution: {
      type: Object,
      default: () => {}
    },
    caseItems: Array,
    allowAction: {
      type: Boolean,
      default: true
    },
    execution: {
      type: Object
    },
    isDetailCollapsed:{
      type: Boolean,
      default: false
    },
    projects: {
        type: Array,
        default: () => [] 
    },
    tags: {
        type: Array,
        default: () => [] 
    },
  },
  data()
  {
    return {
      isSelectedData: false,
      selectedRows: [],
      headers: [],
      searchTerm: '',
      filters: null,
      selectedData: [],
    };
  },
  computed: {
    ...mapState(['currentAccount']),
    ...mapGetters({
      dynamicHeaders:'headers/dynamicHeaders'
    }),
    filteredHeaders() {
      const filtered = this.headers.filter((header) => header.checked);
      return filtered;
    },
    displayTableData() {
      return this.caseItems.filter((item) => {

        if(
          this.searchTerm.length 
          && item.name.toLowerCase().indexOf(this.searchTerm.toLocaleLowerCase())
          && item.externalId.toLowerCase().indexOf(this.searchTerm.toLocaleLowerCase())
          
          )
          return false

        if(this.filters && (this.filters.priorities.length || this.filters.tags.length)){
            if(this.filters.priorities && this.filters.priorities.length && !this.filters.priorities.includes(item.priority))
              return false

            if(this.filters.tags && this.filters.tags.length){
              let tagExists = false;
              for(const tag of item.caseFields.tags)
                if(this.filters.tags.includes(tag))
                  tagExists = true;

                return tagExists
              }
          }

         if(item.lastAssignedAt)
           item.lastAssignedAt = this.formatDate(item.lastAssignedAt, 'MM/DD/YY') 
          
          return item
        })
    },
    activeRow:{
      get() {
        return this.selectedExecution;
      }, set(value){
        this.$emit('update:selectedExecution', value);
      }
    },
  },
  created() {
    if(!this.dynamicHeaders.workspace) {
      this.initializeHeaders({ type: 'workspace' });
    }
    this.headers = this.dynamicHeaders.workspace;
    this.getPriorities("testCase");
    this.getStatuses("testCase");
  },
  methods: {
    ...mapActions("headers", ['initializeHeaders']),
    getColor(priority)
    {
      if (priority == 'High') {
        return 'text-red';
      } else if (priority == 'Medium') {
        return 'text-yellow';
      } else {
        return 'text-green';
      }
    },
    updateSelectedRows(){
      this.$emit('updateRows', this.selectedRows)
    },
    applyFilters(filters){
      this.filters = filters
    },
    handleClick(row)
    {
      this.$emit('expandDetail', row);
    },
    getItemClass(item) {
      return this.activeRow && item.uid === this.activeRow.uid ? 'active-row' : '';
    },
  },
};
</script>
<style scoped>
.text-red {
  color: #ef5350;
}

.text-green {
  color: #66bb6a;
}

.text-yellow {
  color: #ffa726;
}
</style>
