<template>
  <v-card>
    <v-card-title>{{ $t('addProject') }}</v-card-title>
    <v-tabs
      v-model="tab"
      class="primary"
      dark
      background-color="transparent"
    >
      <v-tab href="#project">
        {{ $t('project') }}
      </v-tab>
      <v-tab href="#users">
        {{ $t('users') }}
      </v-tab>
      <v-tab href="#groups">
        {{ $t('groups') }}
      </v-tab>
    </v-tabs>

    <v-card-text class="text-left">
      <v-tabs-items v-model="tab">
        <!-- Project Tab -->
        <v-tab-item value="project">
          <v-form
            ref="projectForm"
            class="pa-4"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="project.name"
                  outlined
                  dense
                  :label="$t('name')"
                  :rules="[rules.required]"
                />
              </v-col>
              <v-col cols="12">
                <v-tiptap
                  v-model="project.description"
                  :outlined="true"
                  :dense="false"
                  :label="$t('summary')"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="6"
              >
                <v-select
                  v-model="project.defaultAccess"
                  class="my-4"
                  dense
                  outlined
                  :label="$t('defaultAccess')"
                  :items="roles"
                  :menu-props="{ bottom: true, offsetY: true }"
                  required
                >
                  <template v-slot:item="{ item }">
                    <v-list-item-content
                      class="text-left"
                      dense
                    >
                      <v-list-item-title v-text="item" />
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="project.isCompleted"
                  :label="$t('projectCompleted')"
                />
              </v-col>
              <v-col cols="8">
                <v-alert
                  v-model="snackbar.show"
                  dark
                  outlined
                  :type="snackbar.type"
                >
                  {{ snackbar.message }}
                </v-alert>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>

        <!-- Users Tab -->
        <v-tab-item value="users">
          <v-form ref="usersForm">
            <!-- Users list goes here -->
          </v-form>
        </v-tab-item>

        <!-- Groups Tab -->
        <v-tab-item value="groups">
          <v-form ref="groupsForm">
            <!-- Groups list goes here -->
          </v-form>
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        depressed
        color="primary"
        @click="saveProject"
      >
        {{ $t('save') }}
      </v-btn>
      <v-btn
        depressed
        @click="cancel"
      >
        {{ $t('cancel') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    account: { type: Object, required: true }
  },
  data() {
    return {
      tab: null,
      roles: ['Admin', 'Editor', 'Viewer'],
      loading: false,
      snackbar: {
        show: false,
        type: 'success',
        message: '',
      },
      project: {
        name: '',
        summary: '',
        defaultAccess: '',
        isCompleted: false,
        source: 'testfiesta-web',
        externalId: 'testfiesta',
      },
      rules: {
        required: (value) => !!value || this.$t('fieldRequired'),
      },
    };
  },
  methods: {
    saveProject() {
      // Logic to save the project
      this.loading = true;

      if (this.$refs.projectForm.validate()) {
        // Project form is valid, proceed with saving
        let action = this.project.uid ? 'update' : 'add';
        let payload = { ...this.project, handle: this.account.handle };
        this.$store
          .dispatch(`project/${action}`, {
            swal: this.$swal,
            payload,
          })
          .then(() => {
            this.loading = false;
            this.$emit('close');
          })
          .catch((error) => {
            this.snackbar.message =
              action === 'add' ? this.$i18n.t('error.projectAddFailed') : this.$i18n.t('projectUpdateFailed');
            this.snackbar.type = 'error';
            this.snackbar.show = true;
            this.loading = false;
          });
      }
    },
    deleteProject() {
      this.loading = true;
      // Logic to delete the project
      this.$store
        .dispatch('project/delete', {
          swal: this.$swal,
          projectUid: this.project.uid,
        })
        .then(() => {
          this.snackbar.message = this.$i18n.t('success.projectDeleted');
          this.snackbar.type = 'success';
          this.snackbar.show = true;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar.message = this.$i18n.t('error.projectDeleteFailed');
          this.snackbar.type = 'error';
          this.snackbar.show = true;
          this.loading = false;
        });
    },
    cancel() {
      this.$emit('close');
    },
  },
};
</script>
