<template>
  <v-app>
    <div
      v-if="uploadedFiles && uploadedFiles.length"
      class="__attachment_progress"
    >
      <AttachmentUploader
        v-for="(attachment, index) in uploadedFiles"
        :key="index" 
        :attachment="attachment"
      />
    </div>
    <v-main class="bg-theme-base">
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";
import AttachmentUploader from '@/components/TestRuns/AttachmentUploader'

export default {
  components: {
    AttachmentUploader
  },
  computed: {
    ...mapGetters({
    isAuthenticated: 'user/isAuthenticated',
    uploadedFiles: 'attachment/uploadedFiles'
   }
    ),
  },
  
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.w-full {
  width: 100%;
}
.__attachment_progress{
  position: fixed;
  z-index: 100;
  right: 20px;
  bottom: 20px;
}
</style>
