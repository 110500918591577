<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    persistent
  >
    <v-card class="pb-5">
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            Pick Color
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <v-color-picker
          v-model="color"
          class="mt-3"
          hide-inputs
          dot-size="25"
          width="400"
        />

        <p class="text-body-1 text-left mb-1">
          {{ $t('dataColors.hex') }}
        </p>

        <p class="text-body-1 text-left mb-0 grey lighten-5 px-3 py-2 rounded-lg">
          {{ color }}
        </p>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn
              color="#F2F4F7"
              width="100%"
              height="40"
              :depressed="true"
              class="text-capitalize btn-theme"
              elevation="0"
              @click="$emit('close-dialog')"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              width="100%"
              class="btn-theme"
              height="40"
              color="primary"
              :depressed="true"
              elevation="0"
              @click="onSave()"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'PickColorDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    originalColor: {
      type: String,
      default: '#FFFFFF'
    },
  },

  data () {
    return {
      color: '#FFFFFF',
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.color = this.originalColor
    },
  },

  methods: {
    onSave() {
      this.showDialog = false

      this.$emit('choose-color', this.color)
    },
  }
}
</script>