<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-4">{{ $t("filters") }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t("filters") }}
            </h2>
            <v-btn
              icon
              @click="close()"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <v-expansion-panels
            v-model="tagsPanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t("tagPage.type") }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(tagType, index) in tagTypes"
                  :key="index"
                >
                  <v-checkbox
                    v-model="tagType.selected"
                    :value="tagType.selected"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ tagType.name }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>

      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{ $t("clearAll") }}
        </v-btn>

        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          elevation="0"
          @click="apply"
        >
          {{ $t("apply") }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { tagTypes } from "@/constants/tag.js";

export default {
  name: "TagFilterDialog",

  data() {
    return {
      tagsPanel: 0,
      showDialog: false,
      tagTypes: tagTypes,
      oldSelectedTagTypeIds: [],
    };
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return;
      }

      this.tagTypes.forEach((tagType) => {
        const alreadyChecked = this.oldSelectedTagTypeIds.includes(tagType.id);
        this.$set(tagType, "selected", alreadyChecked ? true : false);
      });
    },
  },

  mounted() {
    this.clearAll();
  },

  methods: {
    apply() {
      const selectedTagTypes = this.tagTypes.filter((tagType) => tagType.selected);
      this.oldSelectedTagTypeIds = selectedTagTypes.map((tagType) => tagType.id);

      this.$emit("update-filter-types", selectedTagTypes);
      this.showDialog = false;
    },

    clearAll() {
      this.tagTypes.forEach((tagType) => {
        this.$set(tagType, "selected", false);
      });
    },

    close() {
      this.showDialog = false;
    },
  },
};
</script>
