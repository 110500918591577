import Vue from 'vue';
import i18n from '@/i18n'; // Import the i18n instance
import ToastComponent from '@/components/Settings/Organization/ToastComponent.vue';
import '@/assets/scss/toast.scss';
import SuccessIcon from '../assets/svg/success-outline.svg';

const getMessage = (key, variables) => {
  const messagePath = `toast.${key}`;
  const messageExists = i18n.te(messagePath);
  return messageExists ? i18n.t(messagePath, variables) : key;
};

export const showSuccessToast = (globalToast, messageKey, variables = {}, confirm = false) => {
  const message = getMessage(messageKey, variables);
  globalToast({
    html: `
      <div class="swal-theme custom-content">
        <div class="custom-svg-icon">
          <img src="/icon/swal_success.svg" alt="Success Icon" width="42" height="42" />
        </div>
        <span class="custom-message">${message}</span>
      </div>
    `,
    customClass: {
      popup: 'custom-success-popup',
    },
    showConfirmButton: confirm,
    position: 'bottom-end',
    timer: 2500,
    toast: true,
    timerProgressBar: true,
  });
};

export const showErrorToast = (globalToast, messageKey, variables = {}) => {
  const message = getMessage(messageKey, variables);
  globalToast({
    html: `
      <div class="swal-theme custom-content">
        <div class="custom-svg-icon">
          <img src="/icon/swal_error.svg" alt="Error Icon" width="42" height="42" />
        </div>
        <span class="custom-message">${message}</span>
      </div>
    `,
    customClass: {
      popup: 'custom-error-popup',
    },
    showConfirmButton: false,
    position: 'bottom-end',
    timer: 2500,
    toast: true,
    timerProgressBar: true,
  });
};


export const showAlertToast = (globalToast, message) => {
  const swalWithBootstrapButtons = globalToast.mixin({
    toast: true,
    position: 'bottom-end',
    timer: 5000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', globalToast.stopTimer);
      toast.addEventListener('mouseleave', globalToast.resumeTimer);
    },
  });

  return swalWithBootstrapButtons
    .fire({
      html: `
          <div class="custom-content">
            <div class="custom-svg-icon">
              <svg width="48" height="50" viewBox="-7 -5 41 41" fill="none" xmlns="http://www.w3.org/2000/svg>
                <g transform="translate(18 17)">
                  <path d="M7.5 12.5L10.5 15.5L16.5 9.5M22 12.5C22 18.0228 17.5228 22.5 12 22.5C6.47715 22.5 2 18.0228 2 12.5C2 6.97715 6.47715 2.5 12 2.5C17.5228 2.5 22 6.97715 22 12.5Z" stroke="#079455" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <circle cx="12" cy="12.5" r="14.5" stroke="#C3E8D3" stroke-width="2" />
                  <circle cx="12" cy="12.5" r="18.5" stroke="#C3E8D3" stroke-width="2" opacity="0.5" />
                </g>
              </svg>
            </div>
            <span>${message}</span>
          </div>
        `,
      customClass: {
        container: 'custom-success-container',
        popup: 'colored-toast',
        timerProgressBar: 'custom-timer-bar',
        actions: 'custom-footer',
      },
      Text: message,
      showConfirmButton: true,
      showCloseButton: false,
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonText: 'Undo',
      cancelButtonAriaLabel: 'Undo',
      confirmButtonText: 'Close',
      confirmButtonAriaLabel: 'Close',
    })
    .then((result) => {
      if (result.isConfirmed) {
        return 'Close'; // The user clicked the "Close" button
      } else if (result.dismiss === swalWithBootstrapButtons.DismissReason.cancel) {
        return 'Undo'; // The user clicked the "Undo" button
      }
    });
};

export const showAuthErrorToast = (globalToast, message) => {
  globalToast({
      icon: 'error',
      text: message,
      showConfirmButton: false,
      position: 'bottom-end',
      timer: 4000,
      toast: true,
      timerProgressBar: true,
  });
};

export const showConfirmationDialog = (globalToast, title, text, icon, confirmButtonText, cancelButtonText) => {
    const swalWithBootstrapButtons = globalToast.mixin({
        customClass: {
            confirmButton: 'btn btn-success',
            cancelButton: 'btn btn-danger',
        },
        buttonsStyling: true,
    });
    return swalWithBootstrapButtons.fire({
        icon: icon,
        title: title,
        text: text,
        showCancelButton: true,
        showCloseButton: false,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        reverseButtons: true,
    });
};