<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table table-fixed data-table-style mt-6"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
  >
    <template v-slot:[`item.name`]="{ item }">
      <div
        v-if="item.name"
        class="text-truncate"
      >
        {{ item.name }}
      </div>
      <span v-else>-</span>
    </template>

    <template v-slot:[`item.description`]="{ item }">
      <div
        v-if="item.description"
        class="text-truncate"
      >
        {{ item.description }}
      </div>
      <span v-else>-</span>
    </template>

    <template v-slot:[`item.entityTypes`]="{ item }">
      <div
        v-if="item.entityTypes && item.entityTypes.length"
        class="text-truncate"
      >
        {{ item.entityTypes.join(",") }}
      </div>
      <span v-else>-</span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <td class="d-flex justify-space-between align-center">
        <v-menu
          v-if="!item.deletedAt"
          bottom
          left
          offset-y
          class="rounded-lg"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="grey darken-2">
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-tooltip
              bottom
              :disabled="isAbleToManage"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item
                    v-if="!item.archivedAt"
                    v-bind="attrs"
                    :disabled="!isAbleToManage"
                    v-on="on"
                    @click="$emit('edit-tag', item)"
                  >
                    <v-list-item-icon class="mr-4">
                      <EditIcon />
                    </v-list-item-icon>
                    <v-list-item-content class="text-left">
                      <v-list-item-title>
                        {{ $t("edit") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
              <span>{{
                $t("tagPage.noPermissionToDo", { action: $t("edit").toLowerCase() })
              }}</span>
            </v-tooltip>

            <v-tooltip
              bottom
              :disabled="isAbleToManage"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item
                    :disabled="!isAbleToManage"
                    @click="$emit('archive-tag', item)"
                  >
                    <v-list-item-icon class="mr-4">
                      <ArchiveIcon />
                    </v-list-item-icon>
                    <v-list-item-content class="text-left">
                      <v-list-item-title>
                        {{ item.archivedAt ? $t("unarchive") : $t("archive") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
              <span>{{
                $t("tagPage.noPermissionToDo", {
                  action: item.archivedAt
                    ? $t("unarchive").toLowerCase()
                    : $t("archive").toLowerCase(),
                })
              }}</span>
            </v-tooltip>

            <v-tooltip
              bottom
              :disabled="isAbleToManage"
            >
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-list-item
                    :disabled="!isAbleToManage"
                    @click="$emit('delete-tag', item)"
                  >
                    <v-list-item-icon class="mr-4">
                      <DeleteIcon />
                    </v-list-item-icon>
                    <v-list-item-content class="text-left">
                      <v-list-item-title class="red--text">
                        {{ $t("delete") }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
              <span>{{
                $t("tagPage.noPermissionToDo", { action: $t("delete").toLowerCase() })
              }}</span>
            </v-tooltip>
          </v-list>
        </v-menu>
      </td>
    </template>
  </v-data-table>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

import { tagTypes } from "@/constants/tag.js";
import EditIcon from "@/assets/svg/edit.svg";
import ArchiveIcon from "@/assets/svg/archived.svg";
import DeleteIcon from "@/assets/svg/delete.svg";

const { mapState } = createNamespacedHelpers("user");

export default {

  components: {
    EditIcon,
    ArchiveIcon,
    DeleteIcon,
  },
  props: {
    headers: Array,
    itemKey: String,
    items: Array,
  },

  data() {
    return {
      tags: this.items,
    };
  },

  computed: {
    ...mapState(["currentAccount"]),

    isAbleToManage() {
      return (
        this.currentAccount.roleName === "owner" ||
        this.currentAccount.roleName === "Account Admin"
      );
    },
  },
};
</script>
