export default function makeConfigurationService(api) {
    return {
        getConfigurations: async function (handle, projectKey, perPage, currentPage) {
            return api.get(`/${handle}/projects/${projectKey}/configurations?per_page=${perPage}&current_page=${currentPage}`);
        },
        createConfigurations: async function (handle, projectKey, data) {
            return api.post(`/${handle}/projects/${projectKey}/configurations`, data);
        },
        deleteConfiguration: async function (handle, projectKey, id) {
            return api.delete(`/${handle}/projects/${projectKey}/configurations/${ id }`);
        },
        updateConfiguration: async function (handle, projectKey, id, data) {
            return api.patch(`/${handle}/projects/${projectKey}/configurations/${ id }`, data);
        },
    };
}
