<template>
  <div>
    <v-dialog
      v-model="isOpenStatus"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
      @click:outside="clickOutside"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6 mb-4">
            <h2 class="black--text">
              Export test cases
            </h2>
            <v-btn
              icon
              @click="$emit('closeDialog')"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div>
            <div class="select-title mt-4">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t("exportTo") }}
              </v-label>
            </div>
            <v-radio-group v-model="selectedItem">
              <v-radio
                v-for="(item, index) in exportTypes"
                :key="index"
                :label="item.title"
                :value="item.value"
              />
            </v-radio-group>
            <div class="select-title mt-4">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('fields') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedFields"
              placeholder="Choose fields"
              :items="fields"
              item-text="title"
              item-value="value"
              multiple
              append-icon="mdi-chevron-down"
              class="mt-0 pt-1 rounded-lg field-theme custom-prepend mh-38px"
              background-color="#F9F9FB"
            >
              <template v-slot:prepend-item>
                <v-list-item
                  :ripple="false"
                  @mousedown.prevent
                  @click="toggle"
                >
                  <v-list-item-action>
                    <v-checkbox
                      hide-details
                      class="field-theme"
                      :ripple="false"
                      :value="selectAllFields"
                      :indeterminate="selectSomeFields"
                      indeterminate-icon="icon-indeterminate"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                    >
                      <template v-slot:label>
                        <span class="fs-14px text-theme-label">{{ $t("selectAll") }}</span>
                      </template>
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item
                  :ripple="false"
                  @mousedown.prevent
                  @click="item.isSelected = !item.isSelected"
                >
                  <v-list-item-action>
                    <v-checkbox
                      v-model="item.isSelected"
                      hide-details
                      class="field-theme mt-0 pt-0"
                      :ripple="false"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                      :value="item.isSelected"
                    >
                      <template v-slot:label>
                        <span class="fs-14px text-theme-label">{{ `${item.title}` }}</span>
                      </template>
                    </v-checkbox>
                  </v-list-item-action>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          depressed
          height="40"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="$emit('closeDialog')"
        >
          {{ $t("cancel") }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme text-capitalize"
          height="40"
          color="primary"
          depressed
          full-width
          elevation="0"
          :disabled="selectedFields.length === 0"
          @click="exportData"
        >
          {{ $t("export") }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { saveAs } from "file-saver";
import Papa from "papaparse/papaparse.js";
import * as XLSX from "xlsx";

export default {
  name: "ExportDialog",
  props: {
    isOpen: Boolean,
    selectedRows: Array,
  },
  data() {
    return {
      selectedFields: [],
      selectedItem: "csv",
      exportTypes: [
        { title: "CSV", value: "csv" },
        { title: "Excel", value: "excel" },
      ],
      isOpenStatus: this.isOpen,
      fields: [
        { title: "ID", value: "uid", isSelected: false },
        { title: "Name", value: "name", isSelected: false },
        { title: "Priority", value: "priority", isSelected: false },
        { title: "Description", value: "description", isSelected: false },
        { title: "Steps", value: "steps", isSelected: false },
        { title: "Expected result", value: "result", isSelected: false },
      ],
    };
  },
  computed: {
    selectAllFields() {
      return this.fields.every(field => field.isSelected);
    },
    selectSomeFields() {
      const selectedCount = this.fields.filter(field => field.isSelected).length;
      return selectedCount > 0 && selectedCount < this.fields.length;
    },
    icon() {
      if (this.selectAllFields) return "mdi-close-box";
      if (this.selectSomeFields) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    isOpen(newVal) {
      this.isOpenStatus = newVal;
    },
    fields: {
      handler() {
        this.updateSelectedFields();
      },
      deep: true
    }
  },
  methods: {
    clickOutside() {
      this.$emit("closeDialog");
    },
    toggle() {
      const allSelected = this.selectAllFields;
      this.fields.forEach(field => {
        field.isSelected = !allSelected;
      });
      this.updateSelectedFields();
    },
    updateSelectedFields() {
      this.selectedFields = this.fields
        .filter(field => field.isSelected)
        .map(field => field.value);
    },
    exportData() {
      const data = this.prepareData();
      if (this.selectedItem === "csv") {
        this.exportToCSV(data);
      } else if (this.selectedItem === "excel") {
        this.exportToExcel(data);
      }
    },
    prepareData() {
      return this.selectedRows;
    },
    exportToCSV(data) {
      const headers = this.getHeaders();
      const csvData = data.map((row) => {
        const newRow = {};
        headers.forEach((header) => {
          switch (header) {
            case "priority":
              newRow[header] = row.customFields.priority;
              break;
            case "steps":
              newRow[header] = row.steps?.length;
              break;
            default:
              newRow[header] = row[header] || "N/A";
          }
        });

        return newRow;
      });

      const csv = Papa.unparse(csvData);
      const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "test_cases.csv";
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    exportToExcel(data) {
      const headers = this.getHeaders();
      const worksheet = XLSX.utils.json_to_sheet(
        data.map((row) => {
          const newRow = {};
          headers.forEach((header) => {
            switch (header) {
              case "priority":
                newRow[header] = row.customFields.priority;
                break;
              case "steps":
                newRow[header] = row.steps?.length;
                break;
              default:
                newRow[header] = row[header] || "N/A";
            }
          });

          return newRow;
        })
      );
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Test Cases");
      const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      const blob = new Blob([excelBuffer], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(blob, "test_cases.xlsx");
    },
    getHeaders() {
      return this.selectedFields.map((item) => {
        if (typeof item === "string") {
          return item;
        }
        return item.value;
      });
    },
    getFieldValue(header) {
      const field = this.fields.find((f) => f.title === header);
      return field ? field.value : "";
    },
  },
};
</script>
<style>
.test-cases-filter-drawer .v-sheet {
  display: flex;
}

.test-cases-filter-drawer .v-card__text {
  display: flex;
  flex-direction: column;
}

.test-cases-filter-drawer .actions-container {
  margin-top: auto !important;
}

.select-title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
</style>

