import DefaultLayout from '@/Layouts/DefaultLayout.vue';
import ProjectLayout from '@/Layouts/ProjectLayout.vue';

const routes = [
  {
  path: '/:handle/:key/settings',
    component: ProjectLayout,
    children: [
      {
            path: 'templates',
            name: 'Templates',
            component: () => import('@/views/Admin/CustomTemplates/Index'),
          },
          {
            path: 'custom-fields',
            name: 'CustomFields',
            component: () => import('@/views/Admin/CustomFields/Index'),
          },
          {
            path: 'shared-steps',
            name: 'SharedSteps',
            component: () => import('@/views/Admin/SharedSteps/Index'),
          },
    ],
  },
];

export default routes.map(route => {
  const meta = {
    requiresAuth: true,
  }
  return { ...route, meta }
});
