<template>
  <v-row
    justify="center"
    class="pa-4"
  >
    <v-dialog
      v-model="show"
      persistent
      max-width="1024"
    >
      <v-card class="pa-4 mx-auto mb-4">
        <v-card-actions>
          <v-row
            justify="end"
            class="px-4"
          >
            <v-col cols="11">
              <v-card-title class="text-h4 font-weight-medium justify-center">
                {{ $t('addCardDetails') }}
              </v-card-title>
            </v-col>
            <v-spacer />
            <v-col cols="1">
              <v-btn
                class="mr-auto red--text"
                text
                @click="$emit('close')"
              >
                {{ $t('cancel') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>

        <v-divider />

        <v-row class="pa-4">
          <v-col>
            <div
              id="payment-element"
              ref="payment-element"
            />
          </v-col>
        </v-row>

        <v-divider />

        <v-card-actions>
          <v-btn
            ref="submitBtn"
            class="mt-2 pa-3 mx-auto btn"
            width="40%"
            outlined
            @click="submit"
          >
            {{ $t('payNow') }}
            <v-progress-circular
              v-if="isLoading"
              indeterminate
              model-value="10"
              :size="20"
            />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
export default {
  props: {
    clientSecret: String,
    show: Boolean,
  },
  emits: ['close'],
  data: () => ({
    elementsOptions: {},
    elements: null,
    confirmParams: {
      return_url: location.href,
    },
    isLoading: false,
  }),
  mounted() {
    this.elementsOptions.clientSecret = this.$props.clientSecret;
    const elements = this.$stripe.elements({ clientSecret: this.$props.clientSecret, locale: 'en' });

    const form = elements.create('payment', {
      fields: {
        billingDetails: {
          address: {
            country: 'auto',
          },
        },
      },
      hidePostalCode: false,
    });

    form.mount('#payment-element');
    this.elements = elements;
  },
  methods: {
    async submit() {
      this.isLoading = true;
      const value = await this.$stripe.confirmSetup({
        elements: this.elements,
        confirmParams: this.confirmParams,
      });
    },
  },
};
</script>