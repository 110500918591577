import { render, staticRenderFns } from "./FileSelection.vue?vue&type=template&id=10b08448&scoped=true"
import script from "./FileSelection.vue?vue&type=script&lang=js"
export * from "./FileSelection.vue?vue&type=script&lang=js"
import style0 from "./FileSelection.vue?vue&type=style&index=0&id=10b08448&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "10b08448",
  null
  
)

export default component.exports