<template>
  <v-container class="my-6">
    <v-row
      justify="end"
      align="start"
    >
      <v-col
        cols="12"
        sm="12"
        md="2"
      >
        <v-avatar size="17rem">
          <img
            v-if="currentUser.avatarUrl"
            :src="currentUser.avatarUrl"
          >
          <v-icon
            v-else
            size="17rem"
          >
            mdi-account-circle
          </v-icon>
        </v-avatar>
        <div v-if="!isUpdating">
          <v-row class="my-2">
            <h5
              class="bg-secondary text-left"
            >
              {{ currentUser.firstName + " " + currentUser.lastName }}
            </h5>
          </v-row>
         
          <v-row class="my-2">
            <v-btn
              small
              block
              color="secondary"
              @click="enableUpdate"
            >
              {{ $t("editProfile") }}
            </v-btn>
          </v-row>
          <v-row class="bg-secondary text-right my-2">
            <v-btn
              text
              color="secondary"
              class="px-0"
              small
            >
              <v-icon>mdi-account-group</v-icon>
              <span class="mx-2">12</span>
              followers
            </v-btn>
            .
            <v-btn
              text
              color="secondary"
              class="px-0"
              small
            >
              <span class="mx-2">9</span>
              following
            </v-btn>
          </v-row>
          <v-row>
            <p class="bg-secondary text-left">
              {{ currentUser.email }}
            </p>
          </v-row>
          <v-divider />
          <v-row class="my-2">
            <h5 class="bg-secondary text-left ">
              Organizations
            </h5>
          </v-row>
          <v-row class="bg-secondary text-left">
            <v-avatar size="lg">
              <v-icon>mdi-alarm</v-icon>
            </v-avatar>
            <v-avatar>
              <v-icon>mdi-apps</v-icon>
            </v-avatar>
            <v-avatar>
              <v-icon>mdi-wall</v-icon>
            </v-avatar>
          </v-row>
        </div>
        <v-col
          v-else
          class="text-left"
          cols="12"
          sm="12"
          md="12"
        >
          <v-form>
            <v-text-field
              id="first-name-input"
              v-model="profileForm.firstName"
              label="First Name"
              required
              outlined
            />
            <v-text-field
              id="last-name-input"
              v-model="profileForm.lastName"
              label="Last Name"
              required
              outlined
            />
            <v-text-field
              id="email-input"
              v-model="profileForm.email"
              label="Email address"
              type="email"
              required
              outlined
            />
            <v-btn
              class="mx-2"
              small
              outlined
              color="primary"
              @click="updateProfile"
            >
              {{ $t("save") }}
            </v-btn>
            <v-btn
              class="mx-2"
              type="reset"
              small
              color="secondary"
              @click="isUpdating = !isUpdating"
            >
              {{ $t("cancel") }}
            </v-btn>
          </v-form>
        </v-col>
      </v-col>

      <v-col
        cols="12"
        sm="12"
        md="8"
      >
        <v-tabs
          v-model="activeTab"
          class="secondary"
        >
          <v-tab
            v-for="(item) in navItems"
            :key="item.title"
            :href="item.url"
            class="text-decoration-none"
          >
            <v-icon>{{ item.icon }}</v-icon>
            <span class="mx-2">{{ item.title }}</span>
            <v-badge
              v-if="item.stat"
              class="mx-2"
              color="secondary"
              :content="item.stat"
            />
          </v-tab>
        </v-tabs>

        <v-row class="my-20">
          <router-view />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { createNamespacedHelpers } from 'vuex';
import makeUserService from '@/services/api/user';

const { mapState, mapMutations } = createNamespacedHelpers('user');

export default {
  computed: {
    ...mapState({ currentUser: 'user' }),
  },
  data() {
    return {
      isUpdating: false,
      activeTab: 0,
      profileForm: {
        email: '',
        firstName: '',
        lastName: '',
      },
      navItems: [
        {
          title: 'Overview',
          icon: 'mdi-book',
          url: '/profile',
          stat: 22,
        },
        {
          title: 'Repositories',
          icon: 'mdi-journal-bookmark',
          url: '/profile/repositories',
        },
        {
          title: 'Projects',
          icon: 'mdi-view-column',
          url: '#',
        },
        {
          title: 'Packages',
          icon: 'mdi-package-variant-closed',
          url: '#',
          stat: 15,
        },

        {
          title: 'Stars',
          icon: 'mdi-star',
          url: '#',
        },
      ],
    };
  },
  methods: {
    ...mapMutations(['setUser']),
    async updateProfile() {
      try {
        const userService = makeUserService(this.$api);
        const response = await userService.updateProfile(this.profileForm);
        if (response.status === 200) {
          this.$swal({
            title: this.$t('profileUpdated'),
            icon: 'success',
            showConfirmButton: false,
            position: 'top-center',
            timer: 2000,
            toast: true,
          });
          this.profileForm.uid = this.currentUser.uid;
          this.profileForm.admin = this.currentUser.admin;
          this.setUser(this.profileForm);
          localStorage.setItem('user', JSON.stringify(this.profileForm));
          this.isUpdating = false;
        }
      } catch (err) {
        this.$swal({
          title: err,
          icon: 'error',
          showConfirmButton: false,
          position: 'top-center',
          timer: 2000,
          toast: true,
        });
        this.isUpdating = false;
      }
    },
    enableUpdate() {
      this.profileForm.email = this.currentUser.email;
      this.profileForm.firstName = this.currentUser.firstName;
      this.profileForm.lastName = this.currentUser.lastName;
      this.isUpdating = true;
    },
  },
};
</script>

<style>
.v-tabs__content {
  padding-top: 20px;
}
.tabbed-nav {
  white-space: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
</style>