<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    persistent
    fullscreen
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('defect.editDefect') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-10"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('name') }}
              </p>
              <v-text-field
                v-model="defect.name"
                type="text"
                dense
                filled
                :placeholder="$t('name')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('integration') }}
              </p>
              <v-select
                v-model="defect.integration"
                :items="integrationList"
                dense
                filled
                item-text="label"
                item-value="value"
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('priority') }}
              </p>
              <v-select
                v-model="defect.priority"
                :items="priorityList"
                dense
                filled
                item-text="label"
                item-value="value"
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('status') }}
              </p>
              <v-select
                v-model="defect.status"
                :items="statusList"
                dense
                filled
                item-text="label"
                item-value="value"
                :rules="requiredRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn
              color="gray-100"
              width="100%"
              class="text-capitalize"
              elevation="0"
              @click="$emit('close-dialog')"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="blue"
              width="100%"
              elevation="0"
              class="white--text text-capitalize"
              :disabled="!validForm"
              @click="onEdit()"
            >
              {{ $t('defect.sendUpdate') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { priorityList, statusList, integrationList } from '@/constants/defect.js';

export default {
  name: 'UpdateDefect',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({})
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: false,
      priorityList: priorityList,
      statusList: statusList,
      integrationList: integrationList,
      defect: {
        uid: '',
        name: '',
        integration: '',
        priority: '',
        status: '',
      },
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.defect = {
        uid: this.data.uid || '',
        name: this.data.name || '',
        integration: this.data.integration || '',
        priority: this.data.priority || '',
        status: this.data.status || '',
      }
    },
  },

  methods: {
    onEdit() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit('edit', this.defect)
    },
  }
}
</script>