
export default function makeAttachmentService(api) {
    return {
        getSignedAttachmentUrl: function (handle,payload) {
            return api.post(`/${handle}/attachments`, payload)
        },
        uploadAttachment: function (payload) {
            return api.post(`attachments`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            })
        },
        getAttachmentUrl(handle,id){
            return api.get(`/${handle}/attachments/${id}/object`)
        },
        deleteAttachment: function (handle, id) {
            return api.delete(`/${handle}/attachments/${id}`)
        },
        cleanupAttachments: function (handle, id ,relatedTo){
            return api.delete(`/${handle}/attachments/${id}/${relatedTo}`)
        },
        uploadAttachments: function (payload) {
            return api.post(`attachments/multiple`, payload, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
        }
    };
}