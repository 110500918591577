import i18n from '../../i18n';
import makeMilestonesService from '@/services/api/milestone';
import { showSuccessToast } from '@/utils/toast';

const state = {
  items: [],
  selectedMilestone: {
    custom_fields: {
      test_runs: []
    }
  },
};

const mutations = {
  SET_MILESTONES: (state, milestones) => (state.items = milestones),
  SET_SELECTED_MILESTONE: (state, milestone) => (state.selectedMilestone = milestone),
  CLEAR_SELECTED_MILESTONE: (state, milestone) => (state.selectedMilestone = {
    custom_fields: {
      test_runs: []
    }
  }),
  ADD_MILESTONE: (state, milestone) => state.items.unshift(milestone),
  DELETE_MILESTONE: (state, milestoneUid) =>
    (state.items = state.items.filter((milestone) => milestone.uid !== milestoneUid)),
  UPDATE_MILESTONE: (state, updatedMilestone) => {
    const index = state.items.findIndex((milestone) => milestone.uid === updatedMilestone.uid);
    if (index !== -1) {
      state.items.splice(index, 1, { ...state.selectedMilestone, ...updatedMilestone });
    }
  },
  ADD_TO_MILESTONES: () => { },
};

const makeActions = (api) => {
  const milestonesService = makeMilestonesService(api);
  return {
    get: async function ({ commit, rootState }, { handle, projectKey, params }) {
      const response = await milestonesService.getMilestones(handle, projectKey, params);
      commit('SET_MILESTONES', response.data.milestones);
    },
    find: async function ({ commit, rootState }, { handle, projectKey, uid }) {
      const response = await milestonesService.findMilestone(handle, projectKey, uid);
      commit('SET_SELECTED_MILESTONE', response.data);
    },
    add: async function ({ commit, rootState }, { swal, handle, projectKey, payload }) {
      const response = await milestonesService.createMilestone(handle, projectKey, payload);
      commit('ADD_MILESTONE', response.data);
      showSuccessToast(swal, i18n.t('success.milestoneCreated'));
    },
    addToMilestone: async function ({ commit, rootState }, { swal, handle, projectKey, payload }) {
      const response = await milestonesService.addToMilestone(handle, projectKey, payload);
      commit('ADD_TO_MILESTONES', response.data);
      showSuccessToast(swal, i18n.t('success.milestoneCreated'));
    },
    delete: async function ({ commit, rootState }, { swal, handle, projectKey, milestoneUid }) {
      await milestonesService.deleteMilestone(handle, projectKey, milestoneUid);
      commit('DELETE_MILESTONE', milestoneUid);
      showSuccessToast(swal, i18n.t('success.milestoneDeleted'));
    },
    update: async function ({ commit, rootState }, { swal, handle, projectKey, milestoneId, payload }) {
      await milestonesService.updateMilestone(handle, projectKey, milestoneId, payload);
      commit('UPDATE_MILESTONE', payload);
      showSuccessToast(swal, i18n.t('success.milestoneUpdated'));
    },
  };
};

const getters = {
  get(state) {
    return state.milestones;
  },
};

export default function makeMilestone(api) {
  return {
    namespaced: true,
    state,
    mutations,
    actions: makeActions(api),
  };
}
