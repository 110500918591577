<template>
  <v-card
    class="white pt-4 px-6 pb-0 my-0"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <v-container fluid>
      <div class="custom-header d-flex flex-row justify-space-between align-start">
        <div class="d-flex flex-column justify-start custom-header ">
          <div class="mb-4">
            <div
              class="back-to-projects"
              @click="handleBackClick"
            >
              <v-icon color="blue">
                mdi-chevron-left
              </v-icon>
              <p class="d-flex-inline justify-center align-center ma-0 blue--text font-weight-bold">
                {{ $t('testruns.create_testrun.back_to_testrun') }}
              </p>
            </div>
          </div>
          <div>
            <div class="mb-1">
              <v-responsive>
                <v-text-field
                  v-model="item.name"
                  dense
                  class="d-flex ma-0 font-inter black--text font-weight-bold text-h4"
                  append-outer-icon="mdi-pencil"
                  hide-details
                  @change="updateFilter"
                />
              </v-responsive>
            </div>
            <div>
              <v-responsive>
                <v-text-field
                  v-model="item.customFields.description"
                  :value="item.customFields.description"
                  class="d-flex ma-0 font-inter pt-0 shrink"
                  hide-details
                  @change="updateFilter"
                />
              </v-responsive>
            </div>
          </div>
        </div>
        <div
          cols="auto"
          class="d-flex flex-row align-center pt-0"
          height="40"
        >
          <span class="mr-2">{{ $t('testruns.status') }}</span>
          <v-select
            v-model="item.status"
            type="text"
            dense
            single-line
            filled
            hide-details
            :items="statuses"
            item-text="name"
            item-value="name"
            class="rounded-lg ma-0"
            append-icon="mdi-chevron-down"
            clear-icon="body-2"
            width="90"
            :menu-props="{ offsetY: true }"
            @change="updateFilter"
          />
        </div>
      </div>

      <div class="d-flex justify-space-between">
        <div class="d-flex flex-column w-33">
          <div class="text-left">
            <v-label class="fs-14px text-theme-label font-weight-medium">
              {{ $t('testruns.create_testrun.milestone') }}
            </v-label>
          </div>
          <v-select
            v-model="selectedMilestones"
            background-color="#F9F9FB"
            type="text"
            dense
            :placeholder="$t('testruns.milestone_dialog.content')"
            hide-details
            class="rounded-lg field-theme custom-prepend mh-38px"
            append-icon="mdi-chevron-down"
            :items="milestones"
            item-text="name"
            item-value="uid"
            multiple
            clear-icon="body-2"
            :menu-props="{ offsetY: true }"
            @change="updateFilter"
          >
            <template v-slot:selection="{ item }">
              <div
                class="d-flex align-center custom-chip-theme mr-1 mb-1"
              >
                <div class="text-theme-label label text-truncate mr-1">
                  {{ item.name }}
                </div>
                <v-icon
                  size="16px"
                  @click="onRemoveSelectedMilestone(item.uid)"
                >
                  mdi-close
                </v-icon>
              </div>
            </template>

            <template v-slot:item="{ item, on }">
              <v-list-item
                :ripple="false"
                v-on="on"
              >
                <v-list-item-action>
                  <v-checkbox
                    hide-details
                    :input-value="milestoneSelection(item.uid)"
                    class="field-theme mt-0 pt-0"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ `${item.name}` }}</span>
                    </template>
                  </v-checkbox>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <div class="d-flex flex-column w-33 mx-4">
          <div class="text-left">
            <v-label class="fs-14px text-theme-label font-weight-medium">
              {{ $t('testruns.create_testrun.priority') }}
            </v-label>
          </div>
          <v-select
            v-model="item.priority"
            type="text"
            dense
            background-color="#F9F9FB"
            :placeholder="$t('testruns.selectPriority')"
            class="rounded-lg field-theme custom-prepend mh-38px"
            hide-details
            append-icon="mdi-chevron-down"
            :items="priorities"
            clear-icon="body-2"
            item-text="name"
            item-value="name"
            :menu-props="{ offsetY: true }"
            @change="updateFilter"
          />
        </div>
        <div class="d-flex flex-column w-33">
          <div class="text-left">
            <v-label class="fs-14px text-theme-label font-weight-medium">
              {{ $t('testruns.create_testrun.tags') }}
            </v-label>
          </div>
          <v-select
            v-model="selectedTags"
            background-color="#F9F9FB"
            dense
            :items="tags"
            item-key="uid"
            item-value="name"
            item-text="name"
            :placeholder="$t('testruns.selectTags')"
            class="rounded-lg field-theme custom-prepend mh-38px"
            append-icon="mdi-chevron-down"
            hide-details="true"
            :menu-props="{ offsetY: true }"
            multiple
            @change="updateFilter"
          >
            <template v-slot:selection="{ item }">
              <div
                class="d-flex align-center custom-chip-theme mr-1 mb-1"
              >
                <div class="text-theme-label label text-truncate mr-1">
                  {{ item.name }}
                </div>
                <v-icon
                  size="16px"
                  @click="onRemoveSelectedTags(item.name)"
                >
                  mdi-close
                </v-icon>
              </div>
            </template>

            <template v-slot:item="{ item, on }">
              <v-list-item
                :ripple="false"
                v-on="on"
              >
                <v-list-item-action>
                  <v-checkbox
                    hide-details
                    :input-value="tagsSelection(item.name)"
                    class="field-theme mt-0 pt-0"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ `${item.name}` }}</span>
                    </template>
                  </v-checkbox>
                </v-list-item-action>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
    </v-container>
    <slot name="additional-actions" />
    <RunDiscardDialog
      v-model="showConfirmBackDialog"
      :title="$t('testruns.edit_testrun.title')"
      :content="$t('testruns.edit_testrun.content')"
      :btn_label="$t('testruns.edit_testrun.btn_label')"
      color="danger"
      @close="handleCloseClick"
      @handleConfirmClick="handleConfirmClick"
    />
  </v-card>
</template>

<script>
import RunDiscardDialog from '@/components/TestRuns/RunDiscardDialog.vue';
import makeTagsService from '@/services/api/tag';
import { showErrorToast } from '@/utils/toast';
import colorPreferencesMixin from '@/mixins/colorPreferences';

let tagService;
export default {
  name: 'RunEditor',
  components: {
    RunDiscardDialog,
  },
  mixins: [colorPreferencesMixin],
  props: {
    item: Object,
    milestones: Array,
  },
  data() {
    return {
      tags: [],
      forms: {
        status: '',
        assign: '',
        milestone: '',
        priority: '',
        tags: ''
      },
      showConfirmBackDialog: false,
      selectedTags: [],
      selectedMilestones: [],
    };
  },
  computed: {
    milestoneIds() {
      return this.item.testMilestones.map((item) => item.uid);
    },
    getTags() {
      return this.item.customFields?.tags;
    },

  },
  created() {
    tagService = makeTagsService(this.$api);
    this.getPriorities("testRun");
    this.getStatuses("testRun");
  },
  async mounted() {
    this.selectedMilestones = this.milestoneIds;
    this.selectedTags = this.getTags;
    await this.getAllTags();
  },
  methods: {
    updateFilter() {
      this.$emit('update-filter', this.item);
      this.$emit('update-tags', this.selectedTags);
      this.$emit('update-milestones', this.selectedMilestones);
    },
    handleBackClick() {
      this.showConfirmBackDialog = true;
    },
    handleCloseClick() {
      this.showConfirmBackDialog = false;
    },
    handleConfirmClick() {
      this.showConfirmBackDialog = false;
      this.$router.replace({ name: 'Runs' });
    },
    onRemoveSelectedMilestone(uid) {
      const index = this.selectedMilestones.indexOf(uid);
      if (index !== -1) {
        this.selectedMilestones.splice(index, 1);
      }
    },
    milestoneSelection(uid) {
      return this.selectedMilestones ? this.selectedMilestones.some((id) => id === uid) : false;
    },
    onRemoveSelectedTags(uid) {
      const index = this.selectedTags.indexOf(uid);
      if (index !== -1) {
        this.selectedTags.splice(index, 1);
      }
    },
    tagsSelection(tagName) {
      if (typeof this.selectedTags === 'string') {
        this.selectedTags = [this.selectedTags];
      } else if (!Array.isArray(this.selectedTags)) {
        this.selectedTags = [];
      }

      return this.selectedTags ? this.selectedTags.some((name) => name === tagName) : false;
    },
    async getAllTags() {
      try {
        const response = await tagService.getTags(
          this.$route.params.handle,
          'runs'
        );
        if (response.status === 200) {
          
          this.tags = response.data;

        }
      } catch (err) {
        showErrorToast(this.$swal, this.$t('fetchError', { item: 'tags' }))
      } 
    },

  },
};
</script>

<style scoped>
h2{
  font-weight: 900;
}

.custom-header{
  height: 118px;
}

.back-to-projects {
  display: flex;
  cursor: pointer;
  width: max-content;
}

.responsive-text-field {
  display: inline-block;
  width: auto;
}

/* .horizontal-margin {
  margin: 0px 10px;
} */

/* .tray-btn-margin {
  min-width: 40px !important;
  width: 40px !important;
  padding: 10px 0px !important;
}

.tray-btn-outline {
  border-radius: 8px;
  box-shadow: none;
} */
</style>
