<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table data-table-style mt-6"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
    :value="selectedItems"
    height="100%"
    show-select
    @input="onRowClick"
    @click:row="handleRowClick"
  >
    <template v-slot:[`header.data-table-select`]="{ props, on }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          indeterminate-icon="icon-indeterminate"
          :input-value="props.value"
          :indeterminate="props.indeterminate"
          @change="on.input"
        />
      </div>
    </template>

    <template v-slot:[`item.data-table-select`]="{ isSelected, select }">
      <div class="d-flex justify-center align-center">
        <v-checkbox
          id="remember-me-checkbox"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          :input-value="isSelected"
          @change="select"
          @click.stop
        />
      </div>
    </template>

    <template v-slot:[`item.user`]="{ item }">
      <td class="d-flex align-center">
        <div class="custom-div mr-2" />
        <v-avatar
          class="mr-2"
          size="40"
        >
          <img
            :src="resolveAvatarUrl(item.avatar_url)"
            width="100%"
            alt="logo"
          >
        </v-avatar>
        <div class="text-start">
          <div class="text-subtitle-2 font-weight-bold">
            {{ item.first_name }} {{ item.last_name }}
          </div>
        </div>
      </td>
    </template>

    <template v-slot:[`item.role`]>
      <td class="text-start">
        <v-menu
          offset-y
          :position-y="10"
        >
          <template #activator="{ on }">
            <div
              role="button"
              v-on="on"
            >
              {{ role.name }}
            </div>
          </template>
        </v-menu>
      </td>
    </template>

    <template v-slot:[`item.tag`]="{ item }">
      <td class="text-start">
        <v-menu
          offset-y
          :position-y="10"
        >
          <template #activator="{ on }">
            <div
              role="button"
              v-on="on"
            >
              {{ item.tag && item.tag.length ? `${item.tag[0]} +${item.tag.length - 1}` : '' }}
              <v-icon class="ml-3">
                mdi-chevron-down
              </v-icon>
            </div>
          </template>
          <v-list class="pt-4">
            <v-list-item
              v-for="tag in item.tag"
              :key="tag"
              class="m-0"
            >
              <div class="d-flex align-start">
                <div class="d-flex align-center mt-2">
                  <span class="mr-2">{{ tag }}</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </td>
    </template>

    <template v-slot:[`item.email`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item.email }}
      </span>
    </template>
  </v-data-table>
</template>
  
  <script>
  import { createNamespacedHelpers  } from 'vuex';
  
  const { mapState } = createNamespacedHelpers('user');
  
  export default {
    props: {
      headers: Array,
      itemKey: String,
      items: Array,
      clearSelection: Boolean,
      role: Object,
    },
  
    data() {
      return {
        roleMembers: this.items,
        selectedItems: [],
      }
    },
  
    computed: {
      ...mapState(['currentAccount']),
  
      isAbleToManage() {
        return this.currentAccount.roleName === "owner" || this.currentAccount.roleName === "admin"
      }
    },
    watch: {
        clearSelection(newVal) {
        if (newVal) {
            this.selectedItems = [];
            this.$emit('select-item', this.selectedItems);
        }
        },
    },
    methods: {
        onRowClick(item) {
            this.selectedItems = item;
            this.$emit('select-item', this.selectedItems);
        },
        handleRowClick(item) {
            this.$emit('select-row', item);
        },
        resolveAvatarUrl(avatarUrl) {
          return avatarUrl || require('@/assets/png/avatar.png');
        }
    }
  };
  </script>
  