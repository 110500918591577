<template>
  <div
    class="upload__attachment d-flex align-center"
    :style="{borderColor: attachment.failed ? '#F2284E' : 'transparent'}"
  >
    <StopConfirmationDialog 
      :value="showDialog"
      @input="showDialog = false"
      @stop-uploading="stopUploading"
    />
    <div class="attachment_file-type">
      <span
        v-if="attachment.extension"
        :style="{backgroundColor: attachment.failed ? '#F2284E' : '#0D3EF2'}"
      >{{ attachment.extension }}</span>
      <v-icon
        color="#D0D5DD"
        x-large
      >
        mdi-file-outline
      </v-icon>
    </div>
    <div class="attachment_file-details">
      <div class="attachment_details_head d-flex align-center justify-between">
        <p
          id="fileName"
          class="mb-0"
        >
          {{ attachment.fileName }}
        </p>
        <div class="action-btns">
          <v-btn
            v-if="attachment.failed" 
            text 
            dense 
            icon 
            :loading="loading" 
            @click="retryUploadFile"
          >
            <v-icon small>
              mdi-reload
            </v-icon>
          </v-btn>
          <v-btn
            text
            dense
            icon
            @click="handleClose"
          >
            <v-icon small>
              mdi-close
            </v-icon>
          </v-btn>
        </div>
      </div>  
      <p class="mb-0">
        {{ attachment.size }} KB
      </p>
      <div class="attachment_progress d-flex align-center">
        <v-progress-linear
          :color="attachment.failed ? '#F2284E' : '#0D3EF2'"
          rounded
          height="6"
          :value="attachment.progress"
        />
        <p class="mb-0">
          {{ attachment.progress }}%
        </p>
      </div>
    </div>
  </div>
</template>
<script>
import StopConfirmationDialog from './StopConfirmationDialog'
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('attachment')
export default{
  props:{
    attachment:{
      type: Object,
      required: true
    }
  },
  data(){
    return {
      showDialog: false,
      loading: false
    }
  },
  methods:{
    ...mapActions(['retryUpload', 'removeUploadedFile']),
    stopUploading(){
      this.attachment.source.cancel();
      this.showDialog = false
    },
    async retryUploadFile(){
      const handle = this.$route.params.handle;
      const swal = this.$swal
      this.loading = true
      await new Promise(resolve => setTimeout(resolve, 1000)) 

      this.retryUpload({swal, handle, attachment: this.attachment});

      this.loading = false
      
    },
    async handleClose(){
      if(this.attachment.failed)
        this.removeUploadedFile(this.attachment)
      else
        this.showDialog = true
    }
  },
  components: {
    StopConfirmationDialog
  }
}
</script>
<style scoped>
.upload__attachment{
  background-color: #FFF;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #EAECF0;
  width: 320px;
  gap: 12px;
  border: 1px solid transparent;
}
.attachment_file-details{
  flex-grow: 1;
}
.attachment_file-type{
  position: relative;
  display: flex;
  align-items: center;
}
.attachment_file-type .v-icon{
  z-index: 5;
  font-size: 55px !important;
}
.attachment_file-type span{
  position: absolute;
  font-size: 10px;
  padding: 1px 5px;
  border-radius: 2px;
  color: #FFF;
  z-index: 6;
}
.attachment_file-details{
  display: flex;
  flex-direction: column;
  gap: 2px;
  overflow: hidden;
}
.attachment_file-details p{
  font-size: 14px;
  font-family: 'Inter',sans-serif;
  color: #344054;
}
.attachment_file-details #fileName{
  white-space: nowrap;
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attachment_file-details .attachment_progress{
  gap: 5px;
}
</style>