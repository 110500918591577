<template>
  <!-- header section -->
  <div
    class="card bg-white rounded-lg pa-6"
  >
    <DeleteResultDialog
      :value="showDeleteConfirmDialog"
      @input="showDeleteConfirmDialog = !showDeleteConfirmDialog"
      @respond="handleDeleteResponse"
    />
    <v-dialog
      v-model="testDialog"
      max-width="594"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex justify-between">
          <h4>{{ selectedResultUid ? $t('editResult') : $t('addResult') }}</h4>
          <v-btn 
            text 
            small 
            depressed 
            @click="closeTestDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form class="text-left">
            <v-label class="text-left fs-14px text-theme-label font-weight-medium">
              {{ $t('status') }}
            </v-label>
            <v-select 
              v-model="resultState" 
              placeholder="Select status" 
              :items="resultStatus" 
              :hide-details="true" 
              append-icon="mdi-chevron-down"
              class="mt-0 pt-1 rounded-lg field-theme custom-prepend mb-4"
              height="38px"
              background-color="#F9F9FB"  
              :menu-props="{'offset-y': true}"
            />
            <label class="text-weight-black result-form-label">{{ $t('addComment') }}</label>
            <tiptap-editor
              v-model="resultComment"
              class="mb-8"
              @files-added="updateFiles"
            />
            <label class="text-weight-black result-form-label">{{ $t('addAttachment') }}</label>
            <fileInput
              :files.sync="files"
              class="w-full mb-4"
            />
            <div class="test-result-actions d-flex justify-between">
              <v-btn
                depressed
                class="f-color-white btn-theme text-capitalize rounded-lg"
                color="gray-100"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                height="40px"
                @click="closeTestDialog"
              >
                {{ $t('cancel') }}
              </v-btn>
              <v-btn
                depressed
                class="f-color-white btn-theme text-capitalize rounded-lg"
                color="primary"
                :width="$vuetify.breakpoint.smAndDown ? '100%' : '150px'"
                height="40px"
                @click="selectedResultUid ? updateResult() : addResult() "
              >
                {{ selectedResultUid ? $t('editResult') : $t('addResult') }}
              </v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
    <div class="d-flex justify-start">
      <v-btn
        class="text-capitalize bg-white f-color-blue px-2 toggle-btn mb-5"
        depressed
        @click="testDialog = true"
      >
        <div class="d-flex align-center">
          <PlusBlueIcon />
          <span class="ml-2 fw-semibold fs-14px">{{ $t('addResult') }}</span>
        </div>
      </v-btn>
    </div>
    <h4>{{ $t('defect.overview') }}</h4>
    <div
      id="execution-detail"
      class="execution-detail my-4"
    >
      <div class="d-flex justify-space-between">
        <div class="block rounded-lg w-50 mr-2 mh-56px bg-gray-theme d-flex align-center">
          <v-select
            id="execution_status"
            v-model="executionStatus"
            :menu-props="{'offset-y': true}"
            :items="status"
            label="Status"
            item-text="text"
            item-value="value"
            dense
            filled
            background-color="#F9F9FB"
            append-icon="mdi-chevron-down"
            flat
            item-color="grey"
            :hide-details="true"
            @change="$emit('updateExecution', {property: 'status', value: executionStatus})"
          >
            <template v-slot:selection="{ item }">
              <span
                class="fs-14px fw-semibold"
                :style="{ color: item.color }"
              >
                {{ item.text }}
              </span>
            </template>
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item 
                class="mh-36px cursor-pointer"
                v-bind="attrs" 
                @click="on.click" 
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="fs-14px fw-semibold"
                    :style="{ color: item.color }"
                  >
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </div>
        <div class="block rounded-lg w-50 ml-2 mh-56px bg-gray-theme d-flex align-center">
          <v-select
            v-model="executionPriority"
            filled
            :menu-props="{'offset-y': true}"
            label="Priority"
            :items="priorities"
            background-color="#F9F9FB"
            append-icon="mdi-chevron-down"
            dense
            :hide-details="true"
            @change="$emit('updateExecution', {property: 'priority', value: executionPriority})"
          >
            <template v-slot:selection="{ item }">
              <span
                class="fs-14px fw-semibold"
                :style="{ color: item.color }"
              >
                {{ item.text }}
              </span>
            </template>
            <template v-slot:item="{ item, on, attrs }">
              <v-list-item 
                class="mh-36px cursor-pointer"
                v-bind="attrs" 
                @click="on.click" 
              >
                <v-list-item-content>
                  <v-list-item-title
                    class="fs-14px fw-semibold"
                    :style="{ color: item.color }"
                  >
                    {{ item.text }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-select>
        </div>
      </div>
      
      <v-expansion-panels
        v-model="overviewCollapsed"
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 no-expansion-header-icon">
            <h4
              class="f-color-blue toggle-btn"
              @click="overviewCollapsed = overviewCollapsed"
            >
              {{ overviewCollapsed == 0 ? $t('defect.showLess') : $t('defect.showMore') }}
            </h4>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div class="d-flex justify-space-between mb-2">
              <div class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px bg-gray-theme">
                <div class="">
                  <h5 class="align-left">
                    {{ $t('id') }}
                  </h5>
                  <div class="align-left contents fw-semibold fs-14px">
                    {{ execution.externalId }}
                  </div>
                </div>
              </div>
              <div class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px bg-gray-theme">
                <div class="">
                  <h5 class="align-left">
                    {{ $t('creator') }}
                  </h5>
                  <div class="align-left contents fw-semibold fs-14px">
                    {{ creator }}
                  </div>
                </div>
              </div>
            </div>

            <div class="d-flex justify-space-between mb-5">
              <div class="block rounded-lg px-3 py-2 mr-2 w-50 mh-56px bg-gray-theme">
                <div>
                  <h5 class="align-left">
                    {{ $t('assignDate') }}
                  </h5>
                  <div
                    class="align-left contents fw-semibold fs-14px"
                  >
                    {{ lastAssigned }}
                  </div>
                </div>
              </div>
              <div class="block rounded-lg px-3 ml-2 py-2 w-50 mh-56px bg-gray-theme">
                <div>
                  <h5 class="align-left">
                    {{ $t('milestone.create_milestone.dueDate') }}
                  </h5>
                  <div
                    class="align-left contents fw-semibold fs-14px"
                  >
                    {{ dueDate }}
                  </div>
                </div>
              </div>
            </div>
            <fileInputWithSlider
              :files.sync="attachments"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <div>
      <div class="d-flex justify-space-between align-center mb-3">
        <div class="panel-title">
          {{ $t('description') }}
        </div>
        <v-btn
          v-if="execution.steps.length"
          text
          class="btn-theme"
          @click="setAllStepsPassed"
        >
          <div class="d-flex align-center">
            <CheckmarkIcon />
            <div class="fs-14px fw-semibold text-status-passed ml-1">
              {{ $t('setAllStepsPassed') }}
            </div>
          </div>
        </v-btn>
      </div>
      <template
        v-if="execution.steps.length"
      >
        <div
          v-for="(step, index) in execution.steps"
          :key="step.id"
          class="d-flex mb-3 align-stretch"
        >
          <div class="bg-gray-theme minw-32px mr-3">
            {{ index + 1 }}
          </div>
          <div class="d-flex flex-column">
            <div class="text-start mb-3">
              <label class="fs-14px text-theme-label fw-semibold mb-2">{{ $t('description') }}</label>
              <p class="mb-0 fs-14px font-weight-regular">
                {{ step.description }}
              </p>
            </div>
            <div class="text-start mb-3">
              <label class="fs-14px text-theme-label fw-semibold mb-2">{{ $t('expectedResult') }}</label>
              <p class="mb-0 fs-14px font-weight-regular">
                {{ step.expectedResult }}
              </p>
            </div>
            <v-select 
              :items="status" 
              :placeholder="$t('chooseStatus') + '...'"
              item-text="text"
              item-value="value"
              append-icon="mdi-chevron-down"
              :menu-props="{ offsetY: true, left: true, maxWidth: '250px !important' }"
              height="20px"
              hide-details
              flat
              class="rounded-lg  pt-0 mt-0 select-assignee status font-weight-regular"
              @change="(item) => updateExecution('status', item, statusItem.status)"
            >
              <template v-slot:selection="{ item }">
                <span
                  class="fs-14px fw-semibold"
                  :style="{ color: item.color }"
                >
                  {{ item.text }}
                </span>
              </template>

              <template v-slot:item="{ item, on, attrs }">
                <v-list-item 
                  class="mh-36px cursor-pointer"
                  v-bind="attrs" 
                  @click="on.click" 
                >
                  <v-list-item-content>
                    <v-list-item-title
                      :style="{ color: item.color }"
                    >
                      <span class="fs-14px fw-semibold">{{ item.text }}</span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-select>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="font-weight-medium text-theme-secondary fs-14px text-center">
          {{ $t("noDescriptionAvailableYet") }}
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { resultStatus } from '@/constants/results.js'
import { formatDate } from '@/utils/util';
import makeAttachment from '@/services/api/attachment';
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import DeleteResultDialog from '@/components/Execution/DeleteResultDialog.vue';
import fileInputWithSlider from "@/components/base/FileInputWithSlider.vue";
import fileInput from "@/components/base/FileInput.vue";
import PlusBlueIcon from '@/assets/svg/plus-blue.svg';
import CheckmarkIcon from '@/assets/svg/checkmark.svg';
import TiptapEditor from '@/components/base/TipTapEditor.vue';


export default {
  name: 'ResultDetailView',
  components: {
    DeleteResultDialog,
    fileInputWithSlider,
    PlusBlueIcon,
    CheckmarkIcon,
    fileInput,
    TiptapEditor
  },
  props: {
    isSelectedExecutionFirstIndex: Boolean,
    isSelectedExecutionLastIndex: Boolean,
    execution: Object,
    testResults: Array,
    showActions: {
      type: Boolean,
      default: true,
    },
    showName: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ['link']
      ],
      attachments: [],
      files: [],
      resultStatus,
      resultState: null,
      resultComment: null,
      overviewCollapsed: [0],
      descriptionPanel: [0],
      runsPanel: null,
      runs: [],
      priorities: [
        { value: 'High', text: 'High', color: '#EF5350' },
        { value: 'Medium', text: 'Medium', color: '#FFA726' },
        { value: 'Low', text: 'Low', color: '#66BB6A' },
      ],
      status: [
        { value: 'New', text: 'New', color: '#42A5F5' },
        { value: 'Incomplete', text: 'Incomplete', color: '#FFA726' },
        { value: 'Passed', text: 'Passed', color: '#66BB6A' },
        { value: 'Failed', text: 'Failed', color: '#EF5350' },
      ],
      collapseStep: [],
      collapseResult: [],
      testDialog: false,
      selectedResultUid: null,
      selectedResultAttachments: [],
      showDeleteConfirmDialog: false,
      handleDeletedResultUid: null,
    }
  },
  computed: {
    lastAssigned() {
      return this.execution.lastAssignedAt
        ? formatDate(this.execution.lastAssignedAt, 'dd-MM-yy')
        : 'None';
    },
    dueDate() {
      return this.execution.dueAt
        ? formatDate(this.execution.dueAt, 'dd-MM-yy')
        : 'None';
    },
    creator() {
      return this.execution.creator ? `${this.execution.creator.firstName} ${this.execution.creator.lastName}` : 'None';
    },
    executionStatus:{
      get(){
        return this.execution.status
      },
      set(value){
        if(value == "Passed")
          this.execution.toWait = 1
        this.execution.status = value
      }
    },
    executionPriority:{
      get(){
        return this.execution.priority
      },
      set(value){
        this.execution.priority = value
      }
    },
  },
  methods: {
    setAllStepsPassed() {
      
    },
    updateFiles(files) {
      this.resultComment = files;
    },
    inited (viewer) {
      this.$viewer = viewer
    },
    show () {
      this.$viewer.show()
    },
    handleEdit() {
      this.$router.push({
        name: 'EditTestCases',
        params: { uid: this.caseItem.test_case_ref },
      });
    },
    deleteAttachment(index){
      this.files.splice(index,1);
    },
    showResultDialog(uid){
      this.showDeleteConfirmDialog = true;
      this.handleDeletedResultUid = uid;
    },
    handleDeleteResponse(value){
      if(value && this.handleDeletedResultUid)
        this.deleteResult(this.handleDeletedResultUid)

      this.showDeleteConfirmDialog = false;
      this.handleDeletedResultUid = null;
    },
    async deleteResultAttachment(uid){
      const attachmentService = makeAttachment(this.$api);
      const handle = this.$route.params.handle
      await attachmentService.deleteAttachment(handle,uid).then(() => {
        this.selectedResultAttachments = this.selectedResultAttachments.filter(element => element.uid != uid)
        const testResultIndex = this.testResults.findIndex(element => element.uid = this.selectedResultUid);
        this.testResults[testResultIndex].attachments = this.selectedResultAttachments
        showSuccessToast(this.$swal, this.$t('success.deleteAttachment'))
      }).catch(() => {
        showErrorToast(this.$swal, this.$t('error.failedToDeleteAttachment'))
      })

    },
    addResult(){
      const payload = {
        files: this.files,
        status: this.resultState.toLowerCase(),
        comment: this.resultComment
      }

      this.$emit('addResult', payload)
      this.closeTestDialog();
    },
    updateResult(){
      const payload = {
        files: this.files,
        status: this.resultState.toLowerCase(),
        comment: this.resultComment
      }
      this.$emit('updateResult', this.selectedResultUid, payload)
      this.closeTestDialog();
      
    },
    resultDialog(resultUid, status, comment){
      this.resultComment = comment;
      this.resultState = status[0].toUpperCase() + status.slice(1)
      this.selectedResultUid = resultUid;
      this.testDialog = true;
      const selectedResult = this.testResults.filter((element) => {
        if(element.resultUid == resultUid)
          return element.attachments
      });

      if(selectedResult.length)
        this.selectedResultAttachments = selectedResult[0].attachments
      
    },
    deleteResult(resultUid){
      this.$emit('deleteResult', resultUid)
    },
    closeTestDialog(){
      this.files = [];
      this.selectedResultAttachments = [];
      this.resultComment = null;
      this.resultState = null;
      this.selectedResultUid = null;
      this.testDialog = false;
    },
    viewPreviousExecution(){
      if(!this.isSelectedExecutionFirstIndex){
        this.$emit('moveItem', 'previous')
      }
    },
    viewNextExecution(){
      if(!this.isSelectedExecutionLastIndex){
        this.$emit('moveItem', 'next')
      }
    }
  },
}
</script>
<style scoped>
.result-form-label{
  font-size: 14px !important;
  color: #000 !important;
  font-weight: 500 !important;
  margin-bottom: 6px !important;
  display: block !important;
}
.tags{
  gap: 8px;
}
#input-file-browser{
  display: block;
  height: 120px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #D0D5DD;
  background-color: #F9FAFB;
  color: #0C2FF3;
  font-weight: bold;
  cursor: pointer;
}
#input-file-browser:hover{
  background-color: #eeeeee;
  transition: all 0.5s;
}

</style>


<style scoped>
/* Customize Editor */
.quillWrapper{
  background-color: #f0f0f0;
  border-radius: 16px;
}
.quillWrapper .ql-toolbar{
  border: 0px !important;
}
.quillWrapper #quill-container{
  border: 0px !important;
  height: 120px;
}
.quillWrapper .ql-toolbar .ql-formats svg{
  width: 16px !important;
}
.quillWrapper .ql-toolbar .ql-formats button{
  width: 20px;
}
.ql-editor{
  min-height: auto !important;
}
/* Result buttons */
.test-result-actions{
  gap: 16px;
}
.test-result-actions button{
  width: calc(50% - 16px) !important;
  border-radius: 6px !important;
}
.images-preview{
  margin: 15px 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.image-preview img{
  width: 60px;
  object-fit: cover;
  height: 60px;
  border: 1px dashed blue;
}
.image-preview button{
  position: absolute;
}
.execution-detail .v-text-field--filled.v-input--dense .v-label--active, .v-text-field--full-width.v-input--dense .v-label--active{
  color: #667085 !important
}

h2, h3, h4{
  text-align: left;
}
h5{
  color:#667085;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.collapse-btn{
  color:#0C2FF3;
}
p{
  text-align: left;
}
.flex{
  display: flex;
}
.justify-between{
  justify-content: space-between;
}
.justify-start{
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.bg-white{
  background-color: white !important;
}
.close-btn:hover{
  cursor: pointer;
}
.f-color-blue{
  color: #0C2FF3 !important;
}
.w-50{
  width: 50%;
}
.align-left{
  text-align: left;
}
.align-left .contents{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0C111D;
}
.toggle-btn{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}

.panel-title{
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
}

.step-item{
  gap: 8px
}
.step-item .step-item-status h5{
  font-size: 12px;
  margin-top: 10px;
}
.step-item > div{
  width: calc(50% - 4px);
  flex-shrink: 0;
}
.step-item > div h5{
  color: #667085;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 8px;
}
.step-item > div p{
  font-size: 14px;
  color: #0C111D;
}
.result-expansion .step-item > div{
  width: 100%;
}
.btn-custom:hover::before{
  display: none;
}
/* Test Results */
.result-expansion .v-timeline-item__dot{
  box-shadow: none !important;
}
.result-expansion .expansion-header h4.result-name{
  font-weight: 500 !important;
  color: #0C111D;
  font-size: 15px;
  font-family: 'Inter', sans-serif;
}
.result-expansion .expansion-header h4.result-date{
  color: #667085;
  font-weight: 400;
  font-size: 13px;
}
.result-expansion .step-item-attachments .viewer img{
  width: 150px;
  height: 100px;
  margin: 8px;
  border: 3px solid #888;
  cursor: pointer;
}
.result-expansion .step-item-attachments .images-attachment .item-attachment{
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}
.result-expansion .step-item-attachments .images-attachment .item-attachment a{
  border: 1px solid #D0D5DD;
  overflow: hidden;
  width: 120px;
  height: 80px;
  border-radius: 4px;
}
.result-expansion .step-item-attachments .images-attachment .item-attachment a img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.item-attachment span{
  max-width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;

}
.result-expansion .step-item-action{
  gap: 8px;
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
}

</style>

<style>
.swiper {
  width: 100%;
  height: 300px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f2f2f2;
}

/* Optional: Style navigation buttons */
.swiper-button-next,
.swiper-button-prev {
  color: #000;
}
</style>
