<template>
  <div>
    <v-sheet
      color="gray-100"
      class="d-flex align-center justify-center pointer"
      height="3.125rem"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-4">{{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      fullscreen
      max-width="485"
      persistent
      content-class="custom-field-filter-dialog"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="close()"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <v-expansion-panels
            v-model="priorityPanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('priority') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(item, index) in priorityList"
                  :key="index"
                >
                  <v-checkbox
                    v-model="selectedPriorities"
                    :value="item.value"
                    color="blue"
                    dense
                    :label="item.label"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-model="datePanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('date') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col cols="6">
                    <v-menu
                      v-model="showStartDateCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          dense
                          filled
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          clearable
                          :placeholder="$t('customFieldPage.startDate')"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :max="today"
                        @input="showStartDateCalendar = false"
                      />
                    </v-menu>
                  </v-col>

                  <v-col cols="6">
                    <v-menu
                      v-model="showEndDateCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          dense
                          filled
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          clearable
                          :placeholder="$t('customFieldPage.endDate')"
                          v-on="on"
                        />
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        :max="today"
                        @input="showEndDateCalendar = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-model="statusPanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('status') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(item, index) in statusList"
                  :key="index"
                >
                  <v-checkbox
                    v-model="selectedStatuses"
                    :value="item.value"
                    color="blue"
                    dense
                    :label="item.label"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>

        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                color="gray-100"
                width="100%"
                class="text-capitalize"
                elevation="0"
                @click="clearAll"
              >
                {{ $t('clearAll') }}
              </v-btn>
            </v-col>

            <v-col cols="6">
              <v-btn
                color="blue"
                width="100%"
                elevation="0"
                class="white--text text-capitalize"
                @click="apply"
              >
                {{ $t('apply') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/utils/util';
import { priorityList, statusList } from '@/constants/defect.js';

export default {
  name: 'DefectFilterDialog',

  props: {
    data: {
      type: Object,
      default: () => ({
        priorities: [],
        statuses: [],
        startDate: null,
        endDate: null,
      })
    },
  },

  data() {
    return {
      priorityList,
      statusList,
      showDialog: false,
      priorityPanel: 0,
      selectedPriorities: [],
      statusPanel: 0,
      selectedStatuses: [],
      datePanel: 0,
      showStartDateCalendar: false,
      startDate: null,
      showEndDateCalendar: false,
      endDate: null,
    };
  },

  computed: {
    today() {
      return formatDate(new Date(), 'yyyy-MM-dd')
    }
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.selectedPriorities = this.data.priorities || []
      this.selectedStatuses = this.data.statuses || []
      this.startDate = this.data.startDate
      this.endDate = this.data.endDate
    },
  },

  methods: {
    apply() {
      this.$emit('update-filter-condition', {
        priorities: this.selectedPriorities,
        statuses: this.selectedStatuses,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.showDialog = false;
    },

    clearAll() {
      this.selectedPriorities = []
      this.selectedStatuses = []
      this.startDate = null
      this.endDate = null
    },

    close() {
      this.showDialog = false;
    }
  }
}
</script>