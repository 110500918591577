export const userPlans = [
  {
    name: 'Basic',
    title: 'Free',
    amount: 0,
    stripe_id: process.env.VUE_APP_STRIPE_FREE_USER_PRICE_ID,
    rank: 0,
    benefits: [
      { title: 'Free for individuals', icon: 'mdi-check-circle-outline' },
    ],
    type: 'user',
  },
//{
//  name: 'Advanced',
//  title: '$4',
//  amount: 4,
//  stripe_id: process.env.VUE_APP_STRIPE_PAID_USER_PRICE_ID,
//  rank: 1,
//  benefits: [
//    { title: 'Unlimited users', icon: 'mdi-check-circle-outline' },
//    { title: 'Unlimited testcases', icon: 'mdi-check-circle-outline' },
//    { title: '500 GB storage', icon: 'mdi-check-circle-outline' },
//  ],
//  type: 'user',
//},
];

export const orgPlans = [
  {
    name: 'Beta',
    title: 'Free',
    price: '$0',
    stripe_id: process.env.VUE_APP_STRIPE_FREE_ORG_PRICE_ID,
    rank: 0,
    benefits: [
      { title: 'Free for our first users', icon: 'mdi-check-circle-outline' },
    ],
    type: 'org',
  },
  {
    name: 'Team',
    title: '$5',
    price: '$5',
    stripe_id: process.env.VUE_APP_STRIPE_PAID_ORG_PRICE_ID,
    rank: 1,
    benefits: [
      { title: 'For using TestFiesta with your team', icon: 'mdi-check-circle-outline' },
    ],
    type: 'org',
  },
];
