<template>
  <div>
    <v-dialog
      v-model="isOpenStatus"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
      @click:outside="clickOutside"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6 mb-4">
            <h2 class="black--text">
              {{ $t('edit') }}
            </h2>
            <v-btn
              icon
              @click="$emit('closeDialog')"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div>
            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('role') }}<span class="red--text">*</span> 
              </v-label>
            </div>
            <v-select
              v-model="selectedRole"
              :placeholder="$t('chooseRole')"
              append-icon="mdi-chevron-down"
              background-color="#F9F9FB"
              :items="roles"
              item-text="name"
              item-value="uid"
              class="rounded-lg field-theme custom-prepend"
              dense
              height="38"
            />
            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('project') }}
              </v-label>
            </div>
            <v-select
              v-model="selectedProject"
              :placeholder="$t('chooseProject')"
              append-icon="mdi-chevron-down"
              background-color="#F9F9FB"
              :items="projects"
              item-text="name"
              item-value="uid"
              class="rounded-lg field-theme custom-prepend"
              dense
              height="38"
            />
            <div class="select-title mt-4 mb-1">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ $t('tags') }}
              </v-label>
            </div>
            <TagSelector
              v-model="tagsData.selectedTags"
              :items="tags"
              class="pt-1 pa-0 mt-0 rounded-lg field-theme custom-prepend"
            />
            <section class="d-flex flex-column w-full">
              <div class="d-flex w-full justify-space-between align-center mt-4">
                <p class="font-weight-medium ma-0">
                  {{ $t('replaceExistingTags') }}
                </p>
                <v-switch
                  v-model="showReplaceTag"
                  inset
                  color="primary"
                />
              </div>

              <section
                v-if="showReplaceTag"
                class="d-flex w-full flex-column"
              >
                <div
                  class="d-flex flex-column"
                >
                  <div class="select-title mt-4 mb-1">
                    <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                      {{ $t('replaceTag') }}
                    </v-label>
                  </div>
                  <TagSelector
                    v-model="tagsData.replaceTag"
                    :items="tags"
                    class="pt-1 pa-0 mt-0 rounded-lg field-theme custom-prepend"
                  />
                </div>
                <div
                  class="d-flex flex-column"
                >
                  <div class="select-title mb-1">
                    <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                      {{ $t('with') }}
                    </v-label>
                  </div>
                  <TagSelector
                    v-model="tagsData.withTag"
                    :items="tags"
                    class="pt-1 pa-0 mt-0 rounded-lg field-theme custom-prepend"
                  />
                </div>
              </section>
            </section>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          depressed
          class="text-capitalize btn-theme"
          elevation="0"
          @click="$emit('closeDialog')"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme text-capitalize"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="$emit('clickSave', {selectedRole, selectedProject, tagsData})"
        >
          {{ $t('save') }} 
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import TagSelector from '@/components/base/TagSelector.vue';



export default {
  name: 'EditDialog',
  components: {
    TagSelector,
  },
  props: {
    isOpen: Boolean,
    roles: {
      type: Array,
      default: () => [],
    },
    projects: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
    selectedUser: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      tagsData: {
        selectedTags: [],
        replaceTag: [],
        withTag: [],
      },
      showReplaceTag: false,
      selectedRole: "",
      selectedProject: "",
      isOpenStatus: this.isOpen,
    };
  },

  watch: {
    isOpen(newVal) {
      this.isOpenStatus = newVal;
      if (!newVal) {
        this.resetForm();
      } else if (this.selectedUser) {
        this.initializeForm();
      }
    }
  },
  
  methods: {
    clickOutside() {
      this.$emit("closeDialog");
    },

    initializeForm() {
      if (this.selectedUser) {
        const selectedUserRole = this.roles.find(role => role.uid === this.selectedUser.role?.uid || role.uid === this.selectedUser.roleUid);
        this.selectedRole = selectedUserRole; 
        this.selectedProject = this.selectedUser.project;
        this.tagsData.selectedTags = this.selectedUser.tags || [];
      }
    },

    resetForm() {
      this.tagsData = {
        selectedTags: [],
        replaceTag: [],
        withTag: [],
      };
      this.showReplaceTag = false;
      this.selectedRole = "";
      this.selectedProject = "";
    },
  },
};
</script>

<style scoped>
.select-title{
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
input {
  height: 38px !important;
}
.custom-prepend{
  padding: 0px !important;
}
</style>
