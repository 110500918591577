<template>
  <div class="file-preview">
    <div
      v-if="internalFiles.length"
      class="file-previews mt-4 pa-3"
    >
      <div class="preview-item row justify-start">
        <div
          v-for="(file, index) in internalFiles"
          :key="index"
          class="preview-container mr-3 mb-3"
        >
          <template v-if="isImage(file)">
            <div class="h-full">
              <img
                :src="filePreview(file)"
                class="preview-image"
              >
              <v-btn
                icon
                small
                color="red"
                class="delete-btn"
                @click="removeFile(index)"
              >
                <removeIcon />
              </v-btn>
            </div>
          </template>
          <template v-else>
            <div class="h-full non-image-preview">
              <div class="file-placeholder">
                File: {{ file.name }}
              </div>
              <v-btn
                icon
                small
                color="red"
                class="delete-btn"
                @click="removeFile(index)"
              >
                <removeIcon />
              </v-btn>
            </div>
          </template>
        </div>
      </div>
    </div>

    <v-file-input
      v-model="internalFiles"
      class="file-input-bg field-theme-file round-8"
      dense
      placeholder="Browse files"
      hide-details
      multiple
      :accept="type"
      @change="syncFilesWithParent"
    />
  </div>
</template>

<script>
import removeIcon from '@/assets/svg/remove.svg';
export default {
    components: {
        removeIcon
    },
    props: {
        files: {
            type: Array,
            default: () => [],
        },
        type: {
            type: String,
            default: '',
        },
    },
    computed: {
      internalFiles: {
        get() {
          return this.files;
        },
        set(newFiles) {
          this.$emit('update:files', newFiles);
        },
      },
    },
    methods: {
      isImage(file) {
        return file.type.startsWith("image/");
      },
      filePreview(file) {
        return URL.createObjectURL(file);
      },
      removeFile(index) {
        const file = this.internalFiles[index];
        if (file.previewUrl) {
          URL.revokeObjectURL(file.previewUrl);
        }
        const updatedFiles = this.internalFiles.slice();
        updatedFiles.splice(index, 1);
        this.internalFiles = updatedFiles;
      },
      syncFilesWithParent(event) {
        this.internalFiles = [...event];
        this.$emit('onFileChange', event);
      },
    },
};
</script>
