<template>
  <v-card
    class="mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div
      v-if="!isSelf"
      class="text-left mb-1"
    >
      <v-btn
        color="blue"
        dark
        class="text-capitalize"
        text
        @click="$emit('go-back')"
      >
        <v-icon
          class="mr-1"
          size="xs"
        >
          mdi-chevron-left
        </v-icon>
        {{ $t('organization.backToOrganizations') }}
      </v-btn>
    </div>

    <v-form
      ref="form"
      v-model="validForm"
      lazy-validation
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          offset-sm="2"
          offset-md="3"
        >
          <v-row>
            <v-col
              v-if="isEditMode"
              cols="12"
              class="text-center"
            >
              <v-badge
                overlap
                color="white"
              >
                <template v-slot:badge>
                  <upload-avatar
                    is-org
                    @uploaded="updatedAvatar"
                  />
                </template>
                <v-avatar
                  class="mb-4"
                  size="9rem"
                  color="secondary"
                >
                  <img
                    v-if="org.avatarUrl"
                    :src="org.avatarUrl"
                    alt="avatar"
                  >
                  <v-icon
                    v-else
                    size="6rem"
                    color="white"
                  >
                    mdi-account
                  </v-icon>
                </v-avatar>
              </v-badge>
            </v-col>
            <v-col
              v-else
              cols="12"
              class="text-center"
            >
              <v-hover v-slot="{ hover }">
                <div
                  class="org-logo"
                  :style="imageStyle"
                  :class="{ hovering: hover }"
                  @click="openFileDialog"
                  @drop.prevent="handleDrop"
                  @dragover.prevent
                  @dragenter.prevent
                >
                  <input
                    ref="fileInput"
                    type="file"
                    :accept="`${orgImageTypes.join(', ')}`"
                    style="display: none"
                    @change="handleFileChange"
                  >
                  <template v-if="!imageSrc">
                    <p class="ma-0">
                      {{ $t('drag_and_drop.part1') }}
                    </p>
                    <p class="ma-0">
                      {{ $t('drag_and_drop.part2') }}
                    </p>
                    <p class="ma-0 font-weight-bold mt-2 blue--text">
                      {{ $t('drag_and_drop.part3') }}
                    </p>
                  </template>
                  <template v-if="hover && imageSrc">
                    <div class="img-actions">
                      <v-icon
                        class="edit-icon"
                        color="#FFF"
                        @click.stop="openFileDialog"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        color="#FFF"
                        class="delete-icon"
                        @click.stop="removeImage"
                      >
                        mdi-delete
                      </v-icon>
                    </div>
                  </template>
                </div>
              </v-hover>
            </v-col>
            <v-col
              cols="12"
              class="pb-0"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('orgName') }} <strong class="red--text text--lighten-1">*</strong>
              </p>
              <v-text-field
                v-model="org.name"
                type="text"
                dense
                filled
                :placeholder="$t('enterOrgname')"
                :rules="orgNameValidation"
              />
            </v-col>

            <v-col
              v-if="!isEditMode"
              cols="12"
              class="pb-0"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('orgAccountName') }}  <strong class="red--text text--lighten-1">*</strong>
              </p>

              <v-text-field
                v-model="org.handle"
                type="text"
                dense
                filled
                :placeholder="$t('enterOrgHandle')"
                :loading="handleRequestState.isLoading"
                :rules="accountNameValidation"
                :hint="orgHandleHint"
                persistent-hint
              />
            </v-col>

            <v-col cols="12">
              <v-btn
                color="blue"
                width="300px"
                elevation="0"
                class="white--text text-capitalize"
                :disabled="!validForm || loadingFile"
                :loading="loadingFile"
                @click="onUpdateOrganization()"
              >
                {{ isEditMode ? $t('organization.updateOrganization') : $t('organization.createOrganization') }}
              </v-btn>
            </v-col>

            <template v-if="isEditMode">
              <v-col
                cols="12"
                class="pb-4 mt-8 text-left"
              >
                <p class="font-weight-bold text-h6">
                  {{ $t('organization.deleteOrganization') }}
                </p>
                <p class="font-weight-medium body-2">
                  {{ $t('organization.deleteOrganizationNotice') }}
                </p>
                <v-btn
                  color="error"
                  width="200px"
                  elevation="0"
                  class="white--text text-capitalize mt-2"
                  @click="onDeleteOrganization()"
                >
                  {{ $t('organization.deleteOrganization') }}
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-form>

    <DeleteOrganizationConfirmDialog
      v-model="showDeleteConfirmDialog"
      @delete="deleteOrganization"
    />
  </v-card>
</template>

<script>
import { formatDate } from '@/utils/util';

import UploadAvatar from '../../Profile/UploadAvatar.vue';
import DeleteOrganizationConfirmDialog from './DeleteOrganizationConfirmDialog.vue';
import { handleDuplicateMixin } from '@/mixins/handleDuplicate';
import { createNamespacedHelpers, mapActions } from 'vuex'
const { mapMutations } = createNamespacedHelpers('user');
import fileValidator from '@/mixins/fileValidator.js'
import { showErrorToast } from '@/utils/toast';
import { orgImageTypes } from '@/constants/fileTypes.js'

export default {
  name: 'CreateUpdateOrganization',

  components: {
    UploadAvatar,
    DeleteOrganizationConfirmDialog,
  },
  mixins: [ handleDuplicateMixin, fileValidator ],

  props: {
    data: {
      type: Object,
      default: () => ({})
    },

    isSelf: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      orgImageTypes,
      orgNameValidation: [
        value => !!value || this.$t('error.requiredField'),
        value => (value.length >= 2 && value.length <= 50) || this.$t('min2max50Chars')
      ],
      validForm: false,
      imageSrc: null,
      org: {
        uid: '',
        avatarUrl: '',
        name: '',
        handle: '',
        createdBy: '',
        createdAt: null,
      },
      file: null,
      loadingFile: false,
      showDeleteConfirmDialog: false,
    }
  },

  computed: {
    isEditMode() {
      return !!this.org.uid
    },
    imageStyle() {
      return this.imageSrc ? { backgroundImage: `url(${this.imageSrc})` } : {};
    },
    orgHandleHint() {
      if (this.org.handle === '') {
        return this.$t('orgAccountNameLabel')
      }
      if (!this.handleError && this.handleAvailable) {
        return "handle is available"
      }
      return ''
    },
    accountNameValidation() {
      const defaultRules = [
        value => !!value || this.$t('error.requiredField'),
        value => /^(?=.{3,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/.test(value) || this.$t('invalidUsername'),

      ]
      if (this.handleError) {
        return [
          ...defaultRules,
          value => this.handleError,
        ]
      }
      return defaultRules
    }
  },
  watch: {
    'org.handle': {
      handler: 'usernameInUse',
      immediate: true,
    },
  },

  mounted() {
    this.org = {
      uid: this.data.uid || '',
      avatarUrl: this.data.avatarUrl || '',
      name: this.data.name || '',
      handle: this.data.handle || '',
      createdBy: this.data.created_by || '',
      createdAt: this.data.created_at ? formatDate(this.data.created_at, 'MM/dd/yy') : null,
    }
  },

  methods: {
    ...mapMutations(['updateOrg']),
    removeImage() {
      this.imageSrc = '';
    },
    openFileDialog() {
      this.$refs.fileInput.click();
    },
    handleDrop(event) {
      const file = event.dataTransfer.files[0];
      this.previewImage(file);
    },
    previewImage(file) {
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);

        this.file = file;
      }
    },
    handleFileChange(event) {
      const files = Array.from(event.target.files);

      const validationResult = this.validateMimeTypes(files, orgImageTypes);

      if (!validationResult.valid) {
        showErrorToast(this.$swal, this.$t('error.fileFormatNotSupported'));
      } else {
        const file = event.target.files[0];

        this.previewImage(file);
      }
    },
    updatedAvatar(avatarURL) {
      this.org.avatarUrl = avatarURL;
      this.updateOrg(this.org)
    },
    async onUpdateOrganization() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit(this.isEditMode ? 'update' : 'create', this.org, this.file)
    },

    onDeleteOrganization() {
      this.showDeleteConfirmDialog = true
    },

    deleteOrganization(password) {
      this.showDeleteConfirmDialog = false

      this.$emit('delete', password)
    }
  }
}
</script>
<style lang="scss" scoped>
.org-logo {
  border-radius: 50%;
  border: 2px dashed grey;
  width: 150px;
  height: 150px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  transition: border-color 0.3s;
  background-size: cover;
  background-position: center;
  position: relative;
}

.org-logo:hover {
  border-color: #2196f3;
}

.hovering .edit-icon,
.hovering .delete-icon {
  display: block;
}
.org-logo:hover .edit-icon,
.org-logo:hover .delete-icon {
  display: block;
}
</style>
<style>
.org-logo .edit-icon,
.org-logo .delete-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) translateX(-30px);
  display: none;
  color: white;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  padding: 10px;
  cursor: pointer;
}
.org-logo .img-actions {
  display: flex;
  gap: 12px;
}
.org-logo:hover .edit-icon,
.org-logo:hover .delete-icon {
  display: block;
}

</style>