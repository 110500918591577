<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table table-fixed data-table-style mt-6"
    :headers="filteredHeaders"
    :items="items"
    :item-key="itemKey"
  >
    <template v-slot:[`item.user`]="{ item }">
      <span class="header_text">
        {{ item.name }}
      </span>
    </template>
    <template v-slot:[`header.role`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.lastActivity`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.project`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.tag`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.email`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
      
      
    <template v-slot:[`item.role`]>
      <SelectWithSearch
        :items="roles"
        :placeholder="$t('selectRole')"
        :search-placeholder="$t('placeHolder.searchByName')"
        :no-data-text="$t('noMatchingRoles')"
        class="mw-108px"
        :search-active="false"
      />
    </template>
    <template v-slot:[`item.tag`]>
      <SelectWithSearch
        :items="tags"
        :placeholder="$t('selectTag')"
        :search-placeholder="$t('placeHolder.searchByName')"
        :no-data-text="$t('noMatchingTags')"
        class="mw-108px"
        :search-active="false"
      />
    </template>
    <template v-slot:[`item.email`]="{ item }">
      <span class="gray-ish--text fs-14px">
        {{ item.email }}
      </span>
    </template>
    <template v-slot:[`item.lastActivity`]="{ item }">
      <span class="gray-ish--text fs-14px">
        {{ item.lastActivity }}
      </span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <td class="d-flex align-center">
        <div class="custom-div mr-2" />
        <v-avatar
          class="mr-2"
          size="40"
        >
          <img
            :src="resolveAvatarUrl(item.avatar_url)"
            width="100%"
            alt="logo"
          >
        </v-avatar>
        <div class="text-start">
          <div class="text-subtitle-2 fs-14px">
            {{ item.first_name }} {{ item.last_name }}
          </div>
        </div>
      </td>
    </template>
    <template v-slot:[`item.project`]>
      <div class="d-flex justify-space-between align-center">
        <SelectWithSearch
          :items="projects"
          :placeholder="$t('selectProject')"
          :search-placeholder="$t('placeHolder.searchByName')"
          :no-data-text="$t('noMatchingProjects')"
          class="mw-108px"
          :search-active="false"
        />
      </div>
    </template>
  </v-data-table>
</template>
  
  <script>
  import { formattedDate } from '@/utils/util';
  import SelectWithSearch from '@/components/base/Forms/Select/SelectWithSearch.vue';
  
  export default {
    components: {
      SelectWithSearch
    },
    props: {
      filteredHeaders: Array,
      itemKey: String,
      items: Array,
      tags: Array,
      projects: Array,
      roles: Array,
    },
    methods: {
      formattedDate,
      resolveAvatarUrl(avatarUrl) {
        return avatarUrl || require('@/assets/png/avatar.png');
      }
    },
  };
  </script>
  