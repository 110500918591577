export function emailValidationRules(vueInstance) {
  /* eslint-disable no-control-regex */
  return [
    (v) => !!v || vueInstance.$t("emailRequired"),
    (v) =>
      /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/.test(v) ||
      vueInstance.$t("error.invalidEmail"),
  ];
  /* eslint-enable no-control-regex */
}

export function emailOrUsernameValidationRules(vueInstance) {
  /* eslint-disable no-control-regex */
  return [
    (v) => !!v || vueInstance.$t("emailOrUsernameRequired"),
    (v) => {
      const emailRegex = /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;
      const usernameRegex = /^[a-zA-Z0-9-_]{2,30}$/;
      return emailRegex.test(v) || usernameRegex.test(v) || vueInstance.$t("error.invalidEmailOrUsername");
    },
  ];
  /* eslint-enable no-control-regex */
}

export function passwordValidationRules(vueInstance) {
  return [
    (v) => !!v || vueInstance.$t("passwordRequired"),
    (v) => v.length >= 8 || vueInstance.$t("validPassword"),
    (v) => /[A-Z]/.test(v) || vueInstance.$t("passwordUppercase"),
    (v) => /[0-9]/.test(v) || vueInstance.$t("passwordNumber"),
    (v) => /[\W_]/.test(v) || vueInstance.$t("passwordSpecial"),
  ];
}

export function phoneNumberValidationRules(vueInstance) {
  return [
    (v) => !!v || vueInstance.$t('error.requiredField'),
    (v) => /^(\+1[-\s.]?)?(\(?\d{3}\)?[-\s.]?)?\d{3}[-\s.]?\d{4}$/.test(v) || vueInstance.$t("error.invalidPhoneNumber"),
  ]
}

export function requiredFieldValidationRules(vueInstance) {
  return [
    (v) => !!v || vueInstance.$t("error.requiredField"),
  ];
}

export function requiredAndMax255FieldValidationRules(vueInstance) {
  return [
    (v) => !!v || vueInstance.$t("error.requiredField"),
    (v) => v.length <= 255 || vueInstance.$t("error.max255Chars"),
  ];
}

export function firstNameValidation(vueInstance) {
  return [
    (v) => !!v || vueInstance.$t("firstNameRequired"),
    (v) => (v && v.length >= 2) || vueInstance.$t("min2Chars"),
  ];
}

export function lastNameValidation(vueInstance)
{
  return [
    (v) => !!v || vueInstance.$t("lastNameRequired"),
    (v) => (v && v.length >= 2) || vueInstance.$t("min2Chars"),
  ];
}

export function usernameValidation(vueInstance)
    {
      const defaultRules = [
        (v) => !!v || vueInstance.$t('usernameRequired'), // Check if the username is present

        // Check length between 2 and 30 characters
        (v) => (v && v.length >= 2 && v.length <= 30) || vueInstance.$t('usernameLength'),

        // Check if it starts with a valid character
        (v) => !/^[_.-]/.test(v) || vueInstance.$t('usernameInvalidStart'),

        // Check if it contains two consecutive underscores, periods, or hyphens
        (v) => !/.*[_.-]{2}/.test(v) || vueInstance.$t('usernameConsecutiveInvalid'),

        // Check if it ends with a valid character
        (v) => !/[_.-]$/.test(v) || vueInstance.$t('usernameInvalidEnd'),

        // Check for valid characters (letters, numbers, underscore, period, and hyphen)
        (v) => /^[a-zA-Z0-9_-]+$/.test(v) || vueInstance.$t('usernameInvalidCharacters')
      ];
      if (vueInstance.handleError) {
        return [
          ...defaultRules,
          value => vueInstance.handleError,
        ]
      }
      return defaultRules
    }

    export function requiredAndLengthRangeRules(vueInstance, minLength = 2, maxLength = 64) {
      return [
        (v) => !!v || vueInstance.$t("error.requiredField"),
        (v) =>
          (v && v.length >= minLength && v.length <= maxLength) ||
          vueInstance.$t("error.tokenNameLength", { min: minLength, max: maxLength }),
      ];
    }

    export function validateTokenNameCharacters(value, vueInstance) {
      return /^[a-zA-Z0-9-_~]+$/.test(value) || vueInstance.$t("error.tokenNameInvalidCharacters");
    }