<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-4">{{ $t('filters') }} {{ totalSelected > 0 ? `(${ totalSelected })` : '' }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="close()"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <v-expansion-panels
            v-model="projectPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('project') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <SearchComponent
                  class="mb-3"
                  :search="projectSearch"
                  :placeholder="$t('search')"
                  @update:search="projectSearch = $event"
                />
                <template v-if="isProjectFilteredExist">
                  <div
                    v-for="(item, index) in projectFiltered"
                    :key="index"
                  >
                    <v-checkbox
                      v-model="projectSelected"
                      :value="item"
                      class="field-theme"
                      :ripple="false"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                      :hide-details="true"
                    >
                      <template v-slot:label>
                        <span class="fs-14px text-theme-label">{{ item }}</span>
                      </template>
                    </v-checkbox>
                  </div>
                </template>
                <template v-else>
                  <div class="text-center">
                    {{ $t('noProjects') }}
                  </div>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-model="tagsPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('tags') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <SearchComponent
                  class="mb-3"
                  :search="tagSearch"
                  :placeholder="$t('search')"
                  @update:search="tagSearch = $event"
                />
                <template v-if="tagsFiltered?.length > 0">
                  <div
                    v-for="(item, index) in tagsFiltered"
                    :key="index"
                  >
                    <v-checkbox
                      v-model="tagSelected"
                      :value="item"
                      class="field-theme"
                      :ripple="false"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                      :hide-details="true"
                    >
                      <template v-slot:label>
                        <span class="fs-14px text-theme-label">{{ item }}</span>
                      </template>
                    </v-checkbox>
                  </div>
                </template>
                <template v-else>
                  <div class="text-center">
                    {{ $t('noTags') }}
                  </div>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">  
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{
            $t('clearAll') }}
        </v-btn>
  
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          elevation="0"
          @click="apply"
        >
          {{
            $t('apply') }}
        </v-btn>
      </div> 
    </v-dialog>
  </div>
</template>

<script>
import SearchComponent from '@/components/Project/SearchComponent.vue';

export default {
    name: 'RoleMemberFilterDialog',
    components: {
        SearchComponent,
    },
    props: {
        projects: {
            type: Array,
            default: () => [],
        },
        tags: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            projectPanel: 0,
            tagsPanel: 0,
            projectSearch: '',
            tagSearch: '',
            showDialog: false,
            tagSelected: [],
            projectSelected: [],
        };
    },
    computed: {
        projectFiltered() {
            return this.projects.filter((project) => project.toLowerCase().includes(this.projectSearch.toLowerCase()));
        },
        isProjectFilteredExist() {
            return this.projectFiltered.length > 0;
        },
        tagsFiltered() {
            return this.tags.filter((tag) => tag.toLowerCase().includes(this.tagSearch.toLowerCase()));
        },
        totalSelected() {
            return (this.projectSelected?.length ?? 0) + (this.tagSelected?.length ?? 0);
        },
    },

    watch: {
        showDialog(value) {
            if (!value) {
                return
            }

        },
    },

    mounted() {

    },

    methods: {
        apply() {
            this.$emit('apply', {
                projectSelected: this.projectSelected,
                tagSelected: this.tagSelected,
            });
            this.showDialog = false;
        },

        clearAll() {
            this.projectSelected = [];
            this.tagSelected = [];
            this.projectSearch = '';
            this.tagSearch = '';
        },

        close() {
            this.showDialog = false;
        },

    }
};
</script>