var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"px-2"},[_c('v-card-title',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.$t("edit profile"))+" ")]),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('v-form',{attrs:{"role":"updateProfileForm"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateProfile)}}},[_c('v-text-field',{staticClass:"mb-3",attrs:{"name":"first_name","label":_vm.$t('firstName'),"rules":[
            v => !!v || 'First name is required',
            v => (v && v.length <= 10 && v.length >=2) || 'First name must be geater than 2 and  less than 10 characters',
          ]},model:{value:(_vm.user.first_name),callback:function ($$v) {_vm.$set(_vm.user, "first_name", $$v)},expression:"user.first_name"}}),_c('v-text-field',{staticClass:"mb-3",attrs:{"name":"last_name","rules":[
            v => !!v || 'Last name is required',
            v => (v && v.length <= 10 && v.length >=2) || 'Last name must be geater than 2 and  less than 10 characters',
          ],"dense":"","outlined":""},model:{value:(_vm.user.last_name),callback:function ($$v) {_vm.$set(_vm.user, "last_name", $$v)},expression:"user.last_name"}}),_c('v-text-field',{staticClass:"mb-3",attrs:{"name":"email","type":"email","label":_vm.$t('email'),"rules":[
          v => !!v || 'E-mail is required',
          v => /.+@.+/.test(v) || 'E-mail must be valid'
        ],"dense":"","outlined":""},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('v-row',{staticClass:"justify-center mt-4"},[_c('profile-password-dialog'),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('updateProfile'))+" ")])],1)],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }