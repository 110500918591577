<template>
  <v-form
    ref="form"
    v-model="validForm"
    lazy-validation
  >
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <p class="font-weight-medium body-2 text-left mb-1">
          {{ $t('currentPassword') }}
        </p>
        <v-text-field
          v-model="currentPassword"
          dense
          filled
          :type="visiblePassword ? 'text' : 'password'"
          :placeholder="$t('currentPassword')"
          :rules="passwordRules"
          :append-icon="visiblePassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          @click:append="visiblePassword = !visiblePassword"
        />
      </v-col>

      <v-col
        cols="12"
        class="pb-0"
      >
        <p class="font-weight-medium body-2 text-left mb-1">
          {{ $t('newPassword') }}
        </p>
        <v-text-field
          v-model="newPassword"
          dense
          filled
          :type="visibleNewPassword ? 'text' : 'password'"
          :placeholder="$t('newPassword')"
          :rules="passwordRules"
          :append-icon="visibleNewPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          @click:append="visibleNewPassword = !visibleNewPassword"
        />
      </v-col>

      <v-col
        cols="12"
        class="pb-0"
      >
        <p class="font-weight-medium body-2 text-left mb-1">
          {{ $t('confirmPassword') }}
        </p>
        <v-text-field
          v-model="confirmPassword"
          dense
          filled
          :type="visibleConfirmPassword ? 'text' : 'password'"
          :placeholder="$t('confirmPassword')"
          :rules="confirmPasswordRules"
          :append-icon="visibleConfirmPassword ? 'mdi-eye-off-outline' : 'mdi-eye-outline'"
          @click:append="visibleConfirmPassword = !visibleConfirmPassword"
        />
      </v-col>

      <v-col cols="12">
        <v-btn
          color="blue"
          width="300px"
          elevation="0"
          class="white--text text-capitalize"
          :disabled="!validForm"
          @click="onChangePassword()"
        >
          {{ $t('updatePassword') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import makeUserService from '@/services/api/user';

import { showSuccessToast, showErrorToast } from '@/utils/toast';
import { passwordValidationRules } from "@/utils/validation";

export default {
  name: 'ChangePassword',

  data () {
    return {
      validForm: false,
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      visiblePassword: false,
      visibleNewPassword: false,
      visibleConfirmPassword: false,
    }
  },

  computed: {
    passwordRules() {
      return passwordValidationRules(this);
    },
    confirmPasswordRules() {
      return [
        ...passwordValidationRules(this),
        (v) => !v || v === this.newPassword || this.$t('confirmPasswordMustMatch')
      ]
    }
  },

  methods: {
    async onChangePassword() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      const userService = makeUserService(this.$api);

      try {
        await userService.changePassword({
          currentPassword: this.currentPassword,
          newPassword: this.newPassword,
        })

        this.currentPassword = ''
        this.newPassword = ''
        this.confirmPassword = ''

        showSuccessToast(this.$swal, 'Password has been updated')
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || err.response?.data?.error || err.response.data[0]?.msg)
      }
    }
  }
}
</script>