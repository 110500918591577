import { render, staticRenderFns } from "./ConfirmBulkDeleteDialog.vue?vue&type=template&id=4fc4360b"
import script from "./ConfirmBulkDeleteDialog.vue?vue&type=script&lang=js"
export * from "./ConfirmBulkDeleteDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports