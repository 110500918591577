<template>
  <div class="d-flex flex-row align-center justify-space-between">
    <v-menu
      open-on-hover
      bottom
      offset-y
      max-width="199px"
      content-class="view-executions-menu rounded-lg progress-bar-menu"
      z-index="100"
    >
      <template v-slot:activator="{ on }">
        <div 
          class="progress-linear" 
          v-on="on"
        >
          <div
            class="progress-linear-bar"
            :style="{backgroundColor: percentage == 100 ? 'transparent' : '#EAECF0'}"
          >
            <div
              v-for="(status, index) in executions" :key="index"
              class="partial"
              :style="{width: `${status.count/caseCount * 100}%`, 'background-color': getStatusColor(status.id)}"
            />
          </div>
          <span class="font-weight-regular ml-3">{{ safePercentage(percentage) }}%</span>
        </div>
      </template>
      <v-list>
        <div 
          v-if="titleVisible && typeName"
          class="px-3 mt-1 mb-2 fs-14px text-theme-label"
        >
          {{ $t(typeName) }} {{ $t('statusesn') }}
        </div>
        <v-list-item
          v-for="(status, index) in executions"
          :key="index"
          dense
          height="28px"
        >
          <v-list-item-title class="text-left">
            <div class="d-flex justify-space-between align-center">
              <span
                class="text-capitalize fs-14px"
                :style="{color: getStatusColor(status?.id)}"
              >{{ status.name }}</span>
              <span class="text-theme-label fs-14px">
                {{ status.count }}
              </span>
            </div>
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>
  <script>
    import colorPreferencesMixin from '@/mixins/colorPreferences';
    export default{
      mixins: [colorPreferencesMixin],
      props:{
        executions:{
          type: [Object, Array],
          required: true
        },
        caseCount:{
          type: Number,
          default: 0
        },
        percentage:{
          default: 0
        },
        typeName: {
          type: String,
          default: 'testCase'
        },
        titleVisible: {
          type: Boolean,
          default: false
        }
      },
      created(){
        this.getStatuses(this.typeName);
      },
      methods:{
        safePercentage(value) {
          const percentage = value;
          return (typeof percentage === 'string' && !isNaN(percentage) || 
            typeof percentage === 'number' && !isNaN(percentage)
          ) ? percentage : '0';
        },
      }
    }
  </script>
  <style scoped>
  .progress-linear{
    width: 100%;
    display: flex;
    align-items: center;
  }
  .progress-linear .progress-linear-bar{
      min-width: 150px !important;
      height: 8px;
      border-radius: 10px;
      display: flex;
  }
  .progress-linear-bar {
    position: relative; 
    background-color: rgb(234, 236, 240);
    border-radius: 10px; 
    overflow: hidden; 
  }

  .progress-linear-bar .partial {
    height: 100%; 
    float: left;
  }

  .progress-linear-bar .partial:first-child {
    border-radius: 10px 0 0 10px; 
  }

  .progress-linear-bar .partial:last-child {
    border-radius: 0 10px 10px 0; 
  }
  </style>