import ProjectLayout from '@/Layouts/ProjectLayout.vue';
import DefaultLayout from '@/Layouts/DefaultLayout.vue';

const routes = [
  {
    path: '/:handle/:key/runs',
    component: ProjectLayout,
    children: [
      {
        path: '',
        name: 'Runs',
        component: () => import('@/views/Tests/Runs/RunView'),
        props: true,
      },
    ],
  },
  {
    path: '/:handle/:key/runs/create',
    component: DefaultLayout,
    children: [
      {
        path: '',
        name: 'TestRunCreate',
        component: () => import('@/views/Tests/Runs/Create/TestRunCreateView'),
      },
      {
        path: 'cases',
        name: 'RunAddCase',
        component: () => import('@/views/Tests/Runs/Create/RunAddCaseView'),
        props: true,
      },
      {
        path: 'duplicate',
        name: 'TestRunDuplicate',  
      component: () => import('@/views/Tests/Runs/Duplicate/Index'),
      }
    ]
  },
  {
    path: '/:handle/:key/runs/:id',
    component: ProjectLayout,
    children: [
      {
        path: '',
        name: 'TestRunCaseEdit',
        component: () => import('@/views/Tests/Runs/RunCaseEditView'),
        props: true,
      },
      {
        path: 'edit',
        name: 'TestRunEdit',
        component: () => import('@/views/Tests/Runs/RunEditView'),
        props: true,
      },
    ]
  },
];

export default routes.map((route) => {
  const meta = {
    requiresAuth: true,
  };
  return { ...route, meta };
});
