export const tagTypes = [
  { id: 1, name: 'Users', value: 'users' },
  { id: 2, name: 'Roles', value: 'roles' },
  { id: 3, name: 'Milestones', value: 'milestones' },
  { id: 4, name: 'Cases', value: 'cases' },
  { id: 5, name: 'Runs', value: 'runs' },
  { id: 6, name: 'Executions', value: 'executions' },
  { id: 7, name: 'Results', value: 'results' },
  { id: 8, name: 'Plans', value: 'plans' },
  // { id: 9, name: 'defects', value: 'defects' }
]