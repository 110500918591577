<template>
  <v-slide-group
    :show-arrows="false"
    class="custom-carousel"
  >
    <v-slide-item
      v-for="(file, index) in files"
      :key="index"
      class="preview-container"
    >
      <v-card outlined>
        <template v-if="isImage(file)">
          <div class="position-relative">
            <img
              :src="filePreview(file)"
              class="preview-image"
            >
            <v-btn
              v-if="!isPreview"
              icon
              small
              color="red"
              class="delete-btn"
              @click="$emit('remove-file', index)"
            >
              <RemoveIcon />
            </v-btn>
          </div>
        </template>
        <template v-else>
          <div class="h-full non-image-preview">
            <div class="file-placeholder">
              {{ $t("file") }}: {{ file.name }}
            </div>
            <v-btn
              v-if="!isPreview"
              icon
              small
              color="red"
              class="delete-btn"
              @click="$emit('remove-file', index)"
            >
              <RemoveIcon />
            </v-btn>
          </div>
        </template>
      </v-card>
    </v-slide-item>
  </v-slide-group>
</template>

<script>
import RemoveIcon from "@/assets/svg/remove.svg";

export default {
  components: {
    RemoveIcon,
  },
  props: {
    files: {
      type: Array,
      required: true,
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  beforeDestroy() {
    // Revoke object URLs to avoid memory leaks
    this.files.forEach((file) => {
      if (file.previewUrl) {
        URL.revokeObjectURL(file.previewUrl);
      }
    });
  },
  methods: {
    isImage(file) {
      return file.type.startsWith("image/");
    },
    filePreview(file) {
      if (!file.previewUrl && !this.isPreview) {
        file.previewUrl = URL.createObjectURL(file);
      }
      return file.previewUrl;
    },
  },
};
</script>

