<template>
  <v-card
    class="py-6 px-6 ml-1 mt-3 d-flex flex-column"
    rounded="lg"
    elevation="0"
  >
    <div class="d-flex flex-row justify-space-between">
      <v-btn
        :class="{'text-capitalize bg-white f-color-blue px-0 toggle-btn':true, 'disabled-action': isProjectArchived }"
        depressed
        @click="handleEdit"
      >
        <div class="d-flex align-center">
          <EditBlueIcon />
          <span class="ml-2">{{ $t('edit') }}</span>
        </div>
      </v-btn>
      <div class="d-flex align-center">
        <div class="d-flex align-center mr-5">
          <v-btn
            depressed
            icon
            :disabled="!hasPrevious"
            :class="{ 'disabled-action': !hasPrevious }"
            @click="handlePrevious"
          >
            <span class="cursor-pointer d-flex align-center">
              <ArrowLeftIcon />
            </span>
          </v-btn>
          <v-btn
            depressed
            icon
            :disabled="!hasNext"
            :class="{ 'disabled-action': !hasNext }"
            @click="handleNext"
          >
            <span
              class="mx-2 cursor-pointer d-flex align-center"    
            >
              <ArrowRightIcon />
            </span>
          </v-btn>
        </div>
        <span
          class="close-btn"
          @click="closeDetail"
        ><v-icon>mdi-close</v-icon></span>
      </div>
    </div>

    <div class="mb-6">
      <h2>{{ detailItem.name }}</h2>
    </div>
    <h4 class="fw-semibold fs-16px text-theme-label mb-2">
      {{ $t('defect.overview') }}
    </h4>
    <div class="d-flex justify-space-between">
      <div
        class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px"
        style="background-color: #f9fafb"
      >
        <h5 class="align-left">
          {{ $t('templatesPage.creator') }}
        </h5>
        <div class="align-left contents fs-14px fw-semibold">
          {{ detailItem.creator.firstName }} {{ detailItem.creator.lastName }}
        </div>
      </div>
      <div
        class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px"
        style="background-color: #f9fafb"
      >
        <h5 class="align-left">
          {{ $t('dateCreation') }}
        </h5>
        <div class="align-left contents fs-14px fw-semibold">
          {{ formatCreatedAt(detailItem.createdAt) }}
        </div>
      </div>
    </div>

    <v-expansion-panels
      v-if="detailItem.steps?.length > 0"
      v-model="stepsPanel"
      flat
    >
      <v-expansion-panel>
        <v-expansion-panel-header class="mx-0 px-0 panel-title">
          {{ $t('Steps') }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-timeline
            dense
            class="timeline-theme"
          >
            <v-timeline-item
              v-for="step in detailItem.steps"
              :key="step.key"
              right
            >
              <step-item :step-item="step" />
            </v-timeline-item>
          </v-timeline>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
import { formatDate } from '@/utils/util';
import EditBlueIcon from '@/assets/svg/edit-blue.svg';
import ArrowRightIcon from '@/assets/svg/arrow-right.svg';
import ArrowLeftIcon from '@/assets/svg/arrow-left.svg';
import StepItem from '@/views/Tests/Case/Components/StepItem.vue';
import projectStatus from '@/mixins/projectStatus';


export default {
  name: 'SharedStepDetail',
  components: {
    EditBlueIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    StepItem
  },
  mixins: [projectStatus],
  props: {
    detailItem: {
      type: Object,
      required: true,
    },
    hasPrevious: {
      type: Boolean,
      default: false,
    },
    hasNext: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stepsPanel: 0,
    };
  },
 
  methods: {
    formatCreatedAt(date) {
      return formatDate(date, 'MM/dd/yy');
    },
    handlePrevious() {
      this.$emit('previous', this.detailItem);
    },
    handleNext() {
      this.$emit('next', this.detailItem);
    },
    handleEdit() {
      if(!this.isProjectArchived){
      this.$emit('edit', this.detailItem);
      }
    },
    closeDetail() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.nav-btn {
  min-width: 36px;
  width: 36px;
  height: 36px;
  padding: 0;
  margin: 0 4px;
  border-radius: 4px;
  background-color: #f9fafb;
}
.nav-btn:hover {
  background-color: #e5e7eb;
}
.nav-btn:disabled {
  opacity: 0.5;
  background-color: #f9fafb;
}
.close-btn {
  cursor: pointer;
  margin-left: 4px;
}
</style>
