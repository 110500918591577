<template>
  <div class="card flex justify-between h-40 bg-gray align-center px-3 py-1 my-1 rounded-lg">
    <div class="flex justify-start">
      <div class="run-id">
        <v-icon
          size="20px"
          class="mr-2"
        >
          {{ iconName }}
        </v-icon>
        <span>{{ runId }}</span>
      </div>
      <div class="run-title">
        {{ runTitle }}
      </div>
    </div>
    <div class="run-status d-flex align-center">
      <span class="fs-14px fw-semibold color-status-new mr-3">New</span>
      <icon-link />
    </div>
  </div>
</template>
<script>
import iconLink from '@/assets/svg/link-outline-20x20.svg'

export default {
  name: 'DefectItem',
  components: {
    iconLink,
  },
  props: {
    runId: String,
    runTitle: String,
    runStatus: String,
    iconName: String,
  },
}
</script>
<style scoped>
.h-40 {
  height: 40px;
}

.bg-gray {
  background-color: #F9FAFB;
}

.align-center {
  align-items: center;
}

.run-id {
  text-align: left;
  color: #667085;
  font-size: 14px;
  font-weight: 400;
}

.run-title {
  color: #0C111D;
  font-size: 14px;
  font-weight: 400;
}
.color-status-new {
  color: #66BB6A;
}
</style>
