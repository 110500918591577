import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
*/

export default function makeAssistService(api) {
  return {
    getAssistResponse: async function (handle, data) {
      return api.post(`/${handle}/assist`, data);
    },
  };
}
