export default function makeProjectsService(api) {
  return {
    getProjects: async function (handle, params) {
      return api.get(`/${handle}/projects?${params}`);
    },
    createProject: async function (handle, { ...data }) {
      return api.post(`/${handle}/projects`, data);
    },
    deleteProject: async function (handle, item) {
      return api.delete(`/${handle}/projects/${item}`);
    },
    updateProject: async function (handle, item, payload) {
      return api.patch(`/${handle}/projects/${item}`, payload);
    },
    validateProjectKey: async function (handle, projectKey) {
      return api.get(`/${handle}/projects/keys/${projectKey}`);
    }
  };
}
