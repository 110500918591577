<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    persistent
    fullscreen
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ isEditMode ? $t('tagPage.editTag') : $t('tagPage.createNewTag') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
  
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-10"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('tag') }}*
              </p>
              <v-text-field
                v-model="tag.name"
                type="text"
                dense
                filled
                :placeholder="$t('tagPage.placeholderTag')"
                :rules="requiredRules"
              />
            </v-col>
  
            <v-col
              cols="12"
              class="pb-0"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('description') }}
              </p>
              <v-text-field
                v-model="tag.description"
                type="text"
                dense
                filled
                :placeholder="$t('description')"
              />
            </v-col>
  
            <v-col cols="12">
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('tagPage.type') }}
              </p>
              <v-select
                v-model="tag.entitiy_types"
                :items="tagTypes"
                dense
                filled
                multiple
                item-text="name"
                item-value="name"
                :placeholder="$t('tagPage.chooseType')"
                persistent-placeholder
                :rules="requiredMultiSelectRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
  
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn
              color="gray-100"
              width="100%"
              class="text-capitalize"
              elevation="0"
              @click="onCancel()"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>
  
          <v-col cols="6">
            <v-btn
              color="blue"
              width="100%"
              elevation="0"
              class="white--text text-capitalize"
              :disabled="!validForm"
              @click="onCreate()"
            >
              {{ isEditMode ? $t('save') : $t('create') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
  
  <script>
  import { tagTypes } from '@/constants/tag.js';
  
  export default {
    name: 'CreateUpdateTagDialog',
  
    props: {
      value: {
        type: Boolean,
        default: false,
      },
  
      data: {
        type: Object,
        default: () => ({})
      },
    },
  
    data () {
      return {
        requiredRules: [
          value => !!value || this.$t('error.requiredField'),
        ],
        requiredMultiSelectRules: [
          value => !!value || value.length > 0 || this.$t('error.requiredField'),
        ],
        validForm: false,
        tagTypes: tagTypes,
        tag: {
          uid: '',
          name: '',
          description: '',
          entitiy_types: [],
        },
      }
    },
  
    computed: {
      showDialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
  
      isEditMode() {
        return !!this.tag.uid
      }
    },
  
    watch: {
      showDialog(value) {
        if (!value) {
          return
        }
  
        this.tag = {
          uid: this.data.uid || '',
          name: this.data.name || '',
          description: this.data.description || '',
          entitiy_types: this.data.entitiy_types || [],
        }
      },
    },
  
    methods: {
      onCreate() {
        const isValidForm = this.$refs.form.validate()
  
        if (!isValidForm) {
          return
        }
  
        this.$emit(this.isEditMode ? 'update-tag' : 'create-new-tag', this.tag)
      },
  
      onCancel() {
        this.$emit('close-dialog')
      }
    }
  }
  </script>