var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-btn',{staticClass:"mx-1",on:{"click":function($event){_vm.showModal = true}}},[_vm._v(" "+_vm._s(_vm.$t('changePassword'))+" ")]),_c('v-dialog',{attrs:{"title":_vm.$t('changePassword')},model:{value:(_vm.showModal),callback:function ($$v) {_vm.showModal=$$v},expression:"showModal"}},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('v-form',{attrs:{"role":"changePasswordForm"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.changePassword)}}},[_c('ValidationProvider',{attrs:{"rules":"required|min:6","name":"currentPassword"}},[_c('v-text-field',{attrs:{"type":"password","rules":[
              (value) => value === '' || value === null || value.length >= 6 || 'Password must be at least 6 characters'
            ],"label":_vm.$t('currentPassword'),"outlined":"","dense":""},model:{value:(_vm.passwordForm.currentPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "currentPassword", $$v)},expression:"passwordForm.currentPassword"}})],1),_c('ValidationProvider',{attrs:{"rules":"required|min:6","name":"Password","vid":"password"}},[_c('v-text-field',{attrs:{"type":"password","rules":[
              (value) => value === '' || value === null || value.length >= 6 || 'Password must be at least 6 characters'
            ],"label":_vm.$t('newPassword')},model:{value:(_vm.passwordForm.newPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "newPassword", $$v)},expression:"passwordForm.newPassword"}})],1),_c('ValidationProvider',{attrs:{"rules":"required|confirmed:password","name":"Password confirmation"}},[_c('v-text-field',{attrs:{"type":"password","rules":[
              (value) => value === '' || value === null || value.length >= 6 || 'Password must be at least 6 characters'
            ],"label":_vm.$t('confirmPassword')},model:{value:(_vm.passwordForm.repeatPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "repeatPassword", $$v)},expression:"passwordForm.repeatPassword"}})],1),_c('v-row',{staticClass:"justify-center border-top pt-3"},[_c('v-btn',{staticClass:"mx-1",on:{"click":_vm.hideModal}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-btn',{staticClass:"mx-1",attrs:{"color":"primary","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('change'))+" ")])],1)],1)]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }