<template>
  <v-form
    ref="form"
    v-model="valid"
    class="elevation-1"
    @submit.prevent="handleSubmit"
  >
    <v-container class="pa-10">
      <v-row />
      <v-row>
        <v-col
          v-for="(value, key) in fields"
          :key="key"
          cols="12"
        >
          <v-text-field
            v-model="fields[key]"
            :label="key.replaceAll('_', ' ')"
          />
        </v-col>
        <v-col
          v-if="errors.length > 0"
          xs="12"
          md="6"
        >
          <v-alert
            dense
            type="error"
            class="text-left lighten-1"
          >
            <li
              v-for="(v, i) in errors"
              :key="i"
            >
              {{ v }}
            </li>
          </v-alert>
        </v-col>
      </v-row>
      <v-row class="ml-1 mb-4">
        <v-btn
          class="primary white--text"
          type="submit"
          large
        >
          {{ $t('submit') }}
          <v-progress-circular
            v-if="loader"
            class="ml-2"
            indeterminate
            dark
            size="16"
          />
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import { Validator } from 'jsonschema';
const validator = new Validator();
export default {
  name: 'CreateUserForm',
  props: ['showLoader', 'entry', 'specs'],
  emits: ['submit'],
  data: () => ({
    fields: {},
    valid: true,
    errors: [],
  }),
  computed: {
    isDisabled() {
      return !this.valid || this.loader;
    },
    loader() {
      return this.showLoader;
    },
  },
  created() {
    const { schema } = this.specs;

    const excludedFields = [/created/, /updated/, /deleted/, /password/, /token/, /^uid$/, /sign_in_ip/];
    const includedTypes = ['number', 'string', 'integer', 'boolean'];

    for (const prop in schema.properties) {
      const { type } = schema.properties[prop];
      const excluded = excludedFields.some((r) => r.test(prop)) || !includedTypes.includes(type);

      if (excluded) continue;

      this.fields[prop] = this.entry[prop] ?? '';
    }
  },
  methods: {
    handleSubmit() {
      if (this.showLoader) return;
      const update = { ...this.entry, ...this.fields };

      const { errors } = validator.validate(update, this.specs.schema);
      if (errors.length > 0) {
        this.errors = errors.map((e) => e.stack.replace('instance.', ''));
        return;
      }

      return this.$emit(
        'submit',
        Object.keys(this.fields).reduce((acc, k) => {
          acc[k] = update[k] == '' ? null : update[k];
          return acc;
        }, {})
      );
    },
  },
};
</script>