<template>
  <div>
    <div class="d-flex justify-space-between align-center mb-3">
      <span class="font-weight-regular fs-14px text-theme-secondary">
        {{ $t("attachments") }}
      </span>
      <v-btn
        class="text-capitalize bg-white f-color-blue px-2 toggle-btn"
        depressed
        @click="openDialog"
      >
        <div class="d-flex align-center">
          <PlusBlueIcon />
          <span class="ml-2 fw-semibold fs-14px">{{ $t("add") }}</span>
        </div>
      </v-btn>
    </div>

    <template v-if="sliderItems.length > 0">
      <slider-group
        :files="sliderItems"
        :is-preview="false"
        @remove-file="removeFile"
      />
    </template>
    <template v-else>
      <div class="font-weight-medium text-theme-secondary fs-14px text-center">
        {{ $t("uploadFilesToProvide") }}
      </div>
    </template>

    <v-dialog
      v-model="dialogVisible"
      max-width="480"
      persistent
    >
      <v-card>
        <v-card-title class="d-flex justify-between">
          <h4>{{ $t("addAttachment") }}</h4>
          <v-btn
            text
            small
            depressed
            @click="closeDialog"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form class="text-left">
            <fileInput
              :files.sync="newFiles"
              class="w-full"
            />
          </v-form>
        </v-card-text>
        <v-card-actions class="justify-space-between px-6 pb-6">
          <v-btn
            depressed
            class="text-theme-label btn-theme text-capitalize rounded-lg"
            color="gray-100"
            width="204.5px"
            height="40px"
            @click="cancelDialog"
          >
            {{ $t("cancel") }}
          </v-btn>
          <v-btn
            depressed
            class="f-color-white btn-theme text-capitalize rounded-lg"
            color="primary"
            width="204.5px"
            height="40px"
            @click="addAttachments"
          >
            {{ $t("addAttachment") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import fileInput from "@/components/base/FileInput.vue";
import PlusBlueIcon from '@/assets/svg/plus-blue.svg';
import SliderGroup from "@/components/base/SliderGroup.vue";

export default {
  components: {
    fileInput,
    PlusBlueIcon,
    SliderGroup
  },
  props: {
    files: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      dialogVisible: false,
      newFiles: [],
    };
  },
  computed: {
    sliderItems: {
      get() {
        return this.files;
      },
      set(newFiles) {
        this.$emit("update:files", newFiles);
      },
    },
  },
  methods: {
    removeFile(index) {
      const file = this.sliderItems[index];
      if (file.previewUrl) {
        URL.revokeObjectURL(file.previewUrl); 
      }
      this.sliderItems.splice(index, 1);
      this.$emit("update:files", this.sliderItems);
    },
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.newFiles.forEach((file) => {
        if (file.previewUrl) {
          URL.revokeObjectURL(file.previewUrl);
        }
      });
      this.newFiles = []; 
      this.dialogVisible = false;
    },
    cancelDialog() {
      this.closeDialog();
    },
    addAttachments() {
      if (this.newFiles.length > 0) {
        this.sliderItems = [...this.sliderItems, ...this.newFiles];
      }
      this.closeDialog();
    },
  },
};
</script>

