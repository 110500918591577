<template>
  <div class="step-item mb-6">
    <div class="d-flex align-center justify-space-between ">
      <div class="d-flex align-center ">
        <icon-dots />
        <h4 class="ml-1">
          {{ $t('sharedStepPage.step', { count: index + 1 }) }}
        </h4>
      </div>
      
      <!-- Add kebab menu -->
      <div class="d-flex align-center ">
        <v-menu
          offset-y
          bottom
          class="rounded-lg"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              rounded
              depressed
              class="property"
              v-bind="attrs"
              :plain="true"
              icon
              v-on="on"
            >
              <v-icon size="24px">
                mdi-dots-vertical
              </v-icon>
            </v-btn>
          </template>
          <v-card
            rounded="8"
            elevation="0"
          >
            <v-list>
              <v-list-item
                :key="1"
                @click="addChildStep"
              >
                <div class="d-flex align-center">
                  <addChildStepIcon />
                </div>
                <v-list-item-title class="pl-3">
                  {{ $t('addChildStep') }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                :key="2"
                @click="$emit('delete-step', index)"
              >
                <div class="d-flex align-center">
                  <removeIcon />
                </div>
                <v-list-item-title class="color-red pl-3">
                  {{ $t('remove') }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </div>
    </div>

    <div class="ml-6 ">
      <div class="text-left">
        <v-label class="fs-14px text-theme-label font-weight-medium">
          {{ $t("name") }} <strong class="red--text text--lighten-1">*</strong>
        </v-label>
      </div>
    
      <v-text-field
        v-model="stepData.title"
        type="text"
        background-color="#F9F9FB"
        class="field-theme rounded-lg"
        :placeholder="$t('name')"
        height="38"
        :rules="titleValidation"
        @input="editStepItem"
      />
      <div class="text-left">
        <v-label class="fs-14px text-theme-label font-weight-medium">
          {{ $t('stepDescription') }}
        </v-label>
      </div>
      <v-textarea
        v-model="stepData.description"
        auto-grow
        :placeholder="$t('stepDescription')"
        background-color="#F9F9FB"
        class="field-theme rounded-lg"
        :rules="descriptionValidation"
        @input="emitStepData"
      />
      <div class="text-left">
        <v-label class="fs-14px text-theme-label font-weight-medium">
          {{ $t('expectedResult') }}
        </v-label>
      </div>
      <v-textarea
        v-model="stepData.expectedResult"
        auto-grow
        :placeholder="$t('expectedResult')"
        background-color="#F9F9FB"
        class="field-theme rounded-lg"
        :rules="resultValidation"
        @input="emitStepData"
      />

      <!-- Add child steps section -->
      <div v-if="stepData.children && stepData.children.length > 0">
        <child-step
          v-for="(child, childIndex) in stepData.children"
          :key="childIndex"
          :parent-title="`Step ${index + 1}`"
          :step-index="childIndex"
          :child-step="child"
          :can-duplicate="false"
          @description-change-child="updateChildDescription"
          @result-change-child="updateChildResult"
          @delete-child="deleteChildStep"
        />
      </div>
    </div>
  </div>
</template>

<script>
import iconDots from '@/assets/svg/dots-20x20-gray.svg';
import removeIcon from '@/assets/svg/remove.svg';
import addChildStepIcon from '@/assets/svg/add-child-step.svg';
import ChildStep from '@/views/Tests/Case/Components/ChildStep.vue';
import { requiredFieldValidationRules } from "@/utils/validation";

export default {
  name: 'StepItem',
  
  components: {
    iconDots,
    removeIcon,
    addChildStepIcon,
    ChildStep
  },

  props: {
    data: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },

  data() {
    return {
      stepData: {
        description: '',
        expectedResult: '',
        children: []
      },
      titleValidation: requiredFieldValidationRules(this),
      descriptionValidation: requiredFieldValidationRules(this),
      resultValidation: requiredFieldValidationRules(this)
    };
  },

  created() {
    this.stepData = {
      ...this.data,
      children: this.data.children || []
    };
  },

  methods: {
    emitStepData() {
      this.$emit('input-step', this.index, this.stepData);
    },

    editStepItem()
    {
          this.$emit('input-step', this.index, this.step);
      },


    addChildStep() {
      if (!this.stepData.children) {
        this.stepData.children = [];
      }

      const newChild = {
        description: '',
        expectedResult: '',
        title: `Step ${this.index + 1}.${this.stepData.children.length + 1}`
      };

      this.stepData.children.push(newChild);
      this.emitStepData();
    },

    updateChildDescription(description, index) {
      this.stepData.children[index].description = description;
      this.emitStepData();
    },

    updateChildResult(result, index) {
      this.stepData.children[index].expectedResult = result;
      this.emitStepData();
    },

    deleteChildStep(index) {
      this.stepData.children.splice(index, 1);
      this.emitStepData();
    }
  }
};
</script>

<style >
textarea{
  border-radius: 12px;
}
.step-item{
  max-width: 436px;
}
.property {
  min-width: 35px !important;
  background-color: white !important;
}

.color-red {
  color: #f2284e;
}

.case-contents {
  display: block;
  max-width: 438px;
  width: 438px;
}

.bg-color-grey {
  background-color: #f9f9fb;
}

.round-8 {
  border-radius: 8px;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.v-text-field__slot textarea {
  padding: 12px;
}

.font-inter {
  font-family: Inter;
}

.v-list-item:hover {
  background-color: #f9fafb;
  cursor: pointer;
}

.v-menu__content {
  text-align: left !important;
}


</style>
