<template>
  <v-container
    fluid
    style="padding: 0"
  >
    <v-overlay :value="isLoading">
      <v-progress-circular
        indeterminate
        color="primary"
        :size="50"
      />
      <p class="text-body-1 mt-3">
        {{ loadingText }}...
      </p>
    </v-overlay>

    <div class="d-flex">
      <div
        :style="{ width: isMenuCollapsed ? '4vw' : '12vw' }"
        class="pr-0 pb-0"
        :class="{ 'mw-leftmenu': !isMenuCollapsed }"
      >
        <left-menu :menu-items="menuItems" />
      </div>

      <div
        :style="{ width: isMenuCollapsed ? '96vw' : '88vw' }"
        class="pb-0"
      >
        <router-view />
      </div>
    </div>


    <span id="versionSpan">
      {{ versionString }}
    </span>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';

import LeftMenu from '@/components/Admin/LeftMenu';
import OrganizationsIcon from '@/assets/svg/settings-left-menu/org.svg';
import AccountIcon from '@/assets/svg/settings-left-menu/account.svg';
import KeyIcon from '@/assets/svg/settings-left-menu/key.svg';
import DropperIcon from '@/assets/svg/settings-left-menu/dropper.svg';
import InfoIcon from '@/assets/svg/settings-left-menu/about.svg';

export default {
  name: 'SettingsLayout',

  components: {
    LeftMenu,
  },

  computed: {
    ...mapGetters(['isMenuCollapsed', 'isLoading', 'loadingText']),

    versionString() {
      if (
        process.env.VUE_APP_VERSION &&
        process.env.VUE_APP_STRIPE_PUBLIC_KEY.indexOf('live') < 0
      ) {
        return `FRONTEND VERSION: ${process.env.VUE_APP_VERSION}`;
      }
      return '';
    },

    menuItems() {
      return [
        { title: 'Account', icon: AccountIcon, className: 'stroke', to: { name: 'Account' }, isActive: true },
        { title: 'Organizations', icon: OrganizationsIcon, className: 'stroke', to: { name: 'Organizations' }, isActive: false },
        { title: 'API Keys', icon: KeyIcon, className: 'stroke', to: { name: 'APIKeys' }, isActive: false },
        // TODO - save thse removed menu items for later
        //{ title: 'Notifications', icon: 'mdi-bell-outline', to: { name: 'Notifications' }, isActive: false },
        //{ title: 'Authentication', icon: 'mdi-shield-outline', to: { name: 'Authentication' }, isActive: false },
        //{ title: 'Billing', icon: 'mdi-note-outline', to: { name: 'Billing' }, isActive: false },
        { title: 'Personalization', icon: DropperIcon, className: 'stroke', to: { name: 'Personalization' }, isActive: false },
        { title: 'About', icon: InfoIcon, className: 'stroke', to: { name: 'About' }, isActive: false },
      ]
    }
  },
}
</script>
<style>
  #versionSpan {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0 1em;
    color: white;
    background: black
  }
</style>
