<template>
  <v-container
    fluid
    style="padding: 0"
  >
    <div class="d-flex">
      <div
        :style="{ width: isMenuCollapsed ? '4vw' : '12vw' }"
        class="pr-0 pb-0"
        :class="{ 'mw-leftmenu': !isMenuCollapsed }"
      >
        <left-menu :menu-items="menuItems" />
      </div>

      <div
        :style="{ width: isMenuCollapsed ? '96vw' : '88vw' }"
        class="pb-0"
      >
        <router-view />
      </div>
    </div>
    <span id="versionSpan">
      {{ versionString }}
    </span>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { createNamespacedHelpers } from 'vuex';

import LeftMenu from '@/components/Admin/LeftMenu';
import UsersIcon from '@/assets/svg/admin-left-menu/users.svg';
import TagsIcon from '@/assets/svg/admin-left-menu/tags.svg';
import RolesIcon from '@/assets/svg/admin-left-menu/roles.svg';

const { mapState } = createNamespacedHelpers('user');

export default {
  name: 'AdminLayout',

  components: {
    LeftMenu,
  },

  computed: {
    ...mapGetters(['isMenuCollapsed']),

    ...mapState(['currentAccount', 'user']),

    versionString() {
      if (
        process.env.VUE_APP_VERSION &&
        process.env.VUE_APP_STRIPE_PUBLIC_KEY.indexOf('live') < 0
      ) {
        return `FRONTEND VERSION: ${process.env.VUE_APP_VERSION}`;
      }
      return '';
    },
    menuItems() {
      const handle = this.currentAccount.handle
      const username = this.user.handle

      const orgMenuItems = [
        { title: 'Users', icon: UsersIcon, className: 'stroke', to: { name: 'UsersView', params: { handle } }, isActive: true },
        { title: 'Roles', icon: RolesIcon, className: 'stroke',  to: { name: 'Roles', params: { handle } }, isActive: false },
      ]

    

      return [
        ...(this.$route.params.handle !== username ? orgMenuItems : []),
        { title: 'Tags', icon: TagsIcon, className: 'stroke', to: { name: 'Tags', params: { handle } }, isActive: false },
      ]
    }
  },
}
</script>

<style>
  #versionSpan {
    position: absolute;
    bottom: 0px;
    left: 0px;
    padding: 0 1em;
    color: white;
    background: black
  }
</style>
