<template>
  <BaseDialog
    v-model="dialog"
    max-width="500px"
  >
    <v-card class="bg-white">
      <v-card-text class="py-8 px-sm-10">
        <v-container class="pa-0">
          <v-flex class="d-flex justify-space-between align-center mb-5">
            <p class="ma-0 font-weight-bold text-h6 text-sm-h5 text-start black--text">
              {{ title }}
            </p>
            <v-icon
              class="pointer"
              @click="$emit('close')"
            >
              mdi-close
            </v-icon>
          </v-flex>
          <v-row class="">
            <v-col
              v-for="(tag, index) in data"
              :key="index"
              cols="12"
              class="py-0"
            >
              <v-checkbox
                v-model="selected"
                :label="tag"
                :value="tag"
                color="blue"
                class="my-1"
              />
            </v-col>
          </v-row>
        </v-container>
        <slot name="footer">
          <v-row>
            <v-col cols="12">
              <v-btn
                dark
                large
                width="100%"
                class="text-capitalize font-weight-bold black--text mt-2"
                color="gray-100"
                @click="close()"
              >
                {{ $t('close') }}
              </v-btn>
            </v-col>
          </v-row>
        </slot>
      </v-card-text>
    </v-card>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/base/BaseDialog.vue';

export default {
  components: {
    BaseDialog,
  },
  props: {
    value: Boolean,
    title: {
      type: String,
    },
    data: {
      type: Array  
    },
    selectedTags:{
      type: Array,
      default() {
        return []
      }  
    },
  },
  data() {
    return {
      dialog: this.value,
      selected: []
    };
  },
  watch: {
    value(newVal) {
      this.dialog = newVal;
    },
    dialog(newVal) {
      this.$emit('input', newVal);
    },
  },
  mounted() {
    this.setValue()  
  },
  methods: {
    setValue() {
      this.selected = this.selectedTags
    },
    close() {
      this.$emit('close', this.selected)
    }
  }
};
</script>

<style></style>
