<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-3 py-2 d-flex flex-row">{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-expansion-panels
            v-model="rolesPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">  
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('role') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(role, index) in availableRoles"
                  :key="index"
                >
                  <v-checkbox
                    v-model="role.selected"
                    :value="role.selected"
                    :label="role.name"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels
            v-model="projectsPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('project') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(project, index) in availableProjects"
                  :key="index"
                >
                  <v-checkbox
                    v-model="project.selected"
                    :value="project.selected"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                    :label="project.name"
                  />
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <div class="text-start">
            <v-expansion-panels
              v-model="tagsPanel"
              flat
              class="mb-5"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  <div class="text-start">
                    <v-label class="text-theme-label font-weight-medium">
                      {{ $t('tags') }}
                    </v-label>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div
                    v-for="(tag, index) in availableTags"
                    :key="index"
                  >
                    <v-checkbox
                      v-model="tag.selected"
                      :value="tag.selected"
                      class="field-theme"
                      :ripple="false"
                      off-icon="icon-checkbox-off"
                      on-icon="icon-checkbox-on"
                      :hide-details="true"
                      :label="tag.name"
                    />
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{ $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="apply"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ProjectUserFilter',
  props: {
    availableProjects: {
      type: Array,
      required: true
    },
    availableRoles: {
      type: Array,
      required: true
    },
    availableTags: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      rolesPanel: 0,
      projectsPanel: 0,
      tagsPanel: 0,
      rolesMenu: false,
      projectsMenu: false,
      tagsMenu: false,
      showDialog: false,
      roles: [],
      projects: [],
      tags: [],
      selectedProjects: [],
      selectedRoles: [],
      selectedTags: []
    };
  },
  mounted() {
    this.initializeData();
  },
  methods: {
    initializeData() {

      this.roles = this.availableRoles.map(role => ({ ...role, selected: false }));
      this.projects = this.availableProjects.map(project => ({ ...project, selected: false }));
      this.tags = this.availableTags.map(tag => ({ ...tag, selected: false }));

    },
    apply() {
      const selectedRoles = this.availableRoles.filter(role => role.selected);
      const selectedProjects = this.availableProjects.filter(project => project.selected);
      const selectedTags = this.availableTags.filter(tag => tag.selected);
      const filters = {
        roles: selectedRoles,
        projects: selectedProjects,
        tags: selectedTags
      };
      this.$emit('filters', filters);
      this.showDialog = false;
    },
    clearAll() {
      // Reset selected arrays
      this.selectedProjects = [];
      this.selectedRoles = [];
      this.selectedTags = [];
      
      // Untick all checkboxes
      this.availableProjects.forEach(project => project.selected = false);
      this.availableRoles.forEach(role => role.selected = false);
      this.availableTags.forEach(tag => tag.selected = false);
      
      // Emit the updated filters
      this.$emit('filters', {
        projects: this.selectedProjects,
        roles: this.selectedRoles,
        tags: this.selectedTags
      });

      // Close the dialog
      this.showDialog = false;
    }
  }
};
</script>
