<template>
  <div>
    <v-dialog
      v-model="isShowDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
      @click:outside="cancel"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <v-tooltip
              bottom
              max-width="400px"
              :disabled="testPlan.name.length < 61"
              content-class="tooltip-theme"
            >
              <template v-slot:activator="{ on, attrs }">
                <h2 
                  class="black--text d-flex align-center"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('plans.milestone.addTestPlan') }}
                  <span class="plan__title">
                    &nbsp;{{ testPlan.name }}
                  </span>
                  {{ $t('plans.milestone.toMilestones') }}
                </h2>
              </template>
              <span>
                {{ testPlan.name }}
              </span>
            </v-tooltip>
            <v-btn
              icon
              @click="cancel"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div class="text-start pt-6">
            <div class="mb-1 text-start mt-4">
              {{ $t('plans.milestone.selectMilestones') }}
            </div>
            <div>
              <SearchComponent
                :search="searchQuery"
                :placeholder="$t('placeHolder.searchByName')"
                class="mr-3"
              />
              <v-checkbox 
                v-for="(item) in filteredItems" 
                :key="item.uid"
                v-model="selectedMilestoneUids" 
                class="field-theme"
                :ripple="false"
                off-icon="icon-checkbox-off"
                on-icon="icon-checkbox-on"
                :hide-details="true"
                :value="item.uid" 
                dense 
              >
                <template v-slot:label>
                  <span class="fs-14px text-theme-label">{{ item.name }}</span>
                </template>
              </v-checkbox>
            </div> 
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="cancel"
        >
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          elevation="0"
          @click="add"
        >
          {{ $t('add') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, createNamespacedHelpers, mapMutations } from 'vuex';
import makeMilestonesService from '@/services/api/milestone';
import SearchComponent from '@/components/Project/SearchComponent.vue';

const { mapActions } = createNamespacedHelpers('milestone');

let milestoneService;
export default {
  name: "PlanAddToMilestone",
  components: {
    SearchComponent
  },
  props: {
    showDialog: Boolean,
    testPlan: { type: Object, required: true },
    selectedPlans: { type: Array, required: true }
  },
  data() {
    return {
      milestones: [],
      selectedMilestoneUids: [],
      isShowDialog: true,
      searchQuery: '', 
    };
  },
  computed:{
    activeMilestones() {
      return this.milestones.filter((milestone) => milestone.archivedAt === null && milestone.deletedAt === null);
    },
    filteredItems() {
      return this.activeMilestones.filter(milestone => 
        milestone.name.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  watch: {
    isShowDialog(newValue) {
      this.$emit('change-milestone-drawer-state', newValue)
    }
  },
  created() {
    const handle = this.$route.params.handle
    this.get({ handle: handle });
    milestoneService = makeMilestonesService(this.$api);
  },

  mounted() {
    this.getAllMileStones();
  },

  methods: {
    ...mapActions(['get', 'addToMilestone']),
    ...mapMutations('milestone', ['SET_MILESTONES']),
    add() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      let plansData = []

      if (this.testPlan){
        plansData = [{
          uid: this.testPlan.uid,
          name: this.testPlan.name,
          projectUid: this.testPlan.projectUid
        }]
      }

      if (this.selectedPlans.length > 0) {
        plansData = this.selectedPlans.map(({uid, name, projectUid})=> ({uid, name, projectUid}))
      }

      this.addToMilestone({swal: this.$swal, handle, projectKey, payload: {
        planUids: plansData.map(({uid})=> uid),
        milestoneUids: this.selectedMilestoneUids
        }})

      this.$emit('change-milestone-drawer-state', false)

    },
    cancel() {
      this.$emit('change-milestone-drawer-state', false)
    },
    async getAllMileStones(){
      try{
        const response = await milestoneService.getMilestones(
          this.$route.params.handle,
          this.$route.params.key
        );
        if(response.status === 200) {
          this.milestones = response.data.milestones;
          this.SET_MILESTONES(response.data.milestones);
        }
      } catch(err){
        console.log(err)
      }
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
