<template>
  <!-- header section -->
  <div
    class="card bg-white rounded-lg ml-1 pa-6 app-height-global"
  >
    <div
      class="d-flex justify-space-between align-center mb-5"
    >
      <div>
        <v-btn
          :class="{
            'text-capitalize bg-white f-color-blue px-0 toggle-btn': true,
            'disabled-action': isProjectArchived
          }"
          depressed
          @click="!isProjectArchived && handleEdit()"
        >
          <div class="d-flex align-center">
            <EditBlueIcon />
            <span class="ml-2">{{ $t('edit') }}</span>
          </div>
        </v-btn>
      </div>
      <div class="d-flex align-center">
        <div class="d-flex align-center mr-5">
          <v-btn
            depressed
            icon
            :disabled="isSelectedCaseFirstIndex"
            :class="{ 'disabled-action': isSelectedCaseFirstIndex }"
            @click="viewPreviousCase"
          >
            <span class="cursor-pointer d-flex align-center">
              <ArrowLeftIcon />
            </span>
          </v-btn>
        
          <v-btn
            depressed
            icon
            :disabled="isSelectedCaseLastIndex"
            :class="{ 'disabled-action': isSelectedCaseLastIndex }"
            @click="viewNextCase"
          >
            <span
              class="mx-2 cursor-pointer d-flex align-center"    
            >
              <ArrowRightIcon />
            </span>
          </v-btn>
        </div>
        <span
          class="close-btn"
          @click="$emit('closeDetail')"
        ><v-icon>mdi-close</v-icon></span>
      </div>
    </div>

    <div class="mb-6">
      <h2>
        {{ caseItem.name }}
      </h2>

      <div class="d-flex flex-flow-wrap ">
        <div
          v-for="(item, index) in caseItem.tags"
          :key="index"
          class="mr-2 mb-2"
        >
          <v-chip
            :ripple="false"
            class="chip-theme"
          >
            {{ item.name }}
          </v-chip>
        </div>
      </div>
    </div>

    <div>
      <h4 class="fw-semibold fs-16px text-theme-label">
        {{ $t('defect.overview') }}
      </h4>
      <v-list
        class="list-theme"
      >
        <v-list-item class="px-0">
          <template>
            <div class="flex justify-between">
              <div
                class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px"
                style="background-color: #F9FAFB"
              >
                <h5 class="align-left">
                  {{ $t('template') }}
                </h5>
                <div class="align-left contents fw-semibold fs-14px">
                  {{ getTemplates }}
                </div>
              </div>
              <div
                class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px"
                style="background-color: #f9fafb"
              >
                <h5 class="align-left">
                  {{ $t('priority') }}
                </h5>
                <div
                  class="align-left contents fs-14px fw-semibold"
                  :style="'color: ' + getPriorityColor(caseItem.customFields.priorityColorId)"
                >
                  {{ caseItem.customFields.priority }}
                </div>
              </div>
            </div>
          </template>
        </v-list-item>
        <v-list-item
          v-if="!overviewCollapsed"
          class="px-0 mt-2"
        >
          <template>
            <div class="flex justify-between">
              <div
                class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px"
                style="background-color: #F9FAFB"
              >
                <h5 class="align-left">
                  {{ $t('creator') }}
                </h5>
                <div class="align-left contents fw-semibold fs-14px">
                  {{ getCreator }}
                </div>
              </div>
              <div
                class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px"
                style="background-color: #F9FAFB"
              >
                <h5 class="align-left">
                  {{ $t('id') }}
                </h5>
                <div class="align-left contents fw-semibold fs-14px">
                  {{ $route.params.key }} - {{ caseItem.uid }}
                </div>
              </div>
            </div>
          </template>
        </v-list-item>
        <v-list-item
          v-if="!overviewCollapsed"
          class="px-0 mt-2"
        >
          <template>
            <div class="flex justify-between">
              <div
                class="block rounded-lg px-3 py-2 w-50 mr-2 mh-56px"
                style="background-color: #f9fafb"
              >
                <h5 class="align-left">
                  {{ $t('lastUpdate') }}
                </h5>
                <div class="align-left contents fw-semibold fs-14px">
                  {{ lastUpdated }}
                </div>
              </div>
              <div
                class="block rounded-lg px-3 py-2 w-50 ml-2 mh-56px"
                style="background-color: #F9FAFB"
              >
                <h5 class="align-left">
                  {{ $t('folder') }}
                </h5>
                <div class="align-left contents fw-semibold fs-14px break-all">
                  {{ selectedFolderName }}
                </div>
              </div>
            </div>
          </template>
        </v-list-item>
        <template
          v-if="!overviewCollapsed"
        >
          <div class="text-left font-weight-regular fs-14px text-theme-secondary my-3">
            {{ $t('customFields') }}
          </div>
          <div class="flex justify-between">
            <div
              class="block rounded-lg px-3 py-2 w-full mh-56px"
              style="background-color: #f9fafb"
            >
              <h5 class="align-left text-capitalize">
                {{ $t('severity') }}
              </h5>
              <div class="align-left contents fw-semibold fs-14px">
                {{ getSeverity }}
              </div>
            </div>
          </div>
        </template>
      </v-list>



      <h4
        v-if="overviewCollapsed"
        class="f-color-blue toggle-btn"
        @click="overviewCollapsed = !overviewCollapsed"
      >
        {{ $t('defect.showMore') }}
      </h4>

      <h4
        v-else
        class="f-color-blue toggle-btn"
        @click="overviewCollapsed = !overviewCollapsed"
      >
        {{ $t('defect.showLess') }}
      </h4>

      <v-expansion-panels
        v-if="caseItem.steps?.length > 0"
        v-model="descriptionPanel"
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 panel-title">
            {{ $t('description') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-timeline
              dense
              class="timeline-theme"
            >
              <v-timeline-item
                v-for="step in caseItem.steps"
                :key="step.key"
                right
              >
                <step-item :step-item="step" />
              </v-timeline-item>
            </v-timeline>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>



      <v-expansion-panels
        v-model="runsPanel"
        flat
      >
        <v-expansion-panel>
          <v-expansion-panel-header class="mx-0 px-0 panel-title">
            {{ $t('testRuns') }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <TestRunItem
              v-for="(run, index) in caseItem.runs"
              :key="index"
              :external-id="run.externalId"
              :run-id="run.runUid"
              :run-status="run.status"
              :run-title="run.name"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
  </div>
</template>
<script>
import StepItem from '@/views/Tests/Case/Components/StepItem.vue';
import TestRunItem from '@/views/Tests/Case/Components/TestRunItem.vue';
import { formatDate } from '@/utils/util';
import EditBlueIcon from '@/assets/svg/edit-blue.svg';
import ArrowRightIcon from '@/assets/svg/arrow-right.svg';
import ArrowLeftIcon from '@/assets/svg/arrow-left.svg';
import projectStatus from '@/mixins/projectStatus';
import makeTemplateService from "@/services/api/template";
import colorPreferencesMixin from '@/mixins/colorPreferences';

let templateService;

export default {
  name: 'DetailView',
  components: {
    StepItem,
    EditBlueIcon,
    ArrowRightIcon,
    ArrowLeftIcon,
    TestRunItem,
  },
  mixins: [projectStatus, colorPreferencesMixin],
  props: {
    caseItem: Object,
    isSelectedCaseFirstIndex: Boolean,
    isSelectedCaseLastIndex: Boolean,
    selectedFolder: Array,
    fromRun: Boolean,
  },
  data() {
    return {
      overviewCollapsed: true,
      descriptionPanel: 0,
      runsPanel: 0,
      defectsPanel: 0,
      commentsPanel: 0,
      runs: [],
      templates: [],
    };
  },

  computed: {
    lastUpdated() {
      return formatDate(this.caseItem.updatedAt, 'MM/dd/yy');
    },
    getCreator() {
      return this.caseItem.creator ? `${this.caseItem.creator?.firstName} ${this.caseItem.creator?.lastName}` : 'None';
    },
    getSeverity(){
      return this.caseItem.customFields.severity || 'None';
    },
    getTemplates()
    {
      const template = this.templates.find(t => t.uid === this.caseItem.testTemplateUid);
      return template ? template.name : 'None';
    },
    selectedFolderName() {
      return this.selectedFolder.map(folder => folder.text).join(' - ');
    },
  },
  created() {
    this.getPriorities("testCase");
  },
  async mounted() {
    templateService = makeTemplateService(this.$api);
    this.fetchTemplates();
  },
  methods: {
    handleEdit() {

      const editObject = {
        name: 'EditTestCases',
        params: { uid: this.caseItem.testCaseRef },
      }
      if (this.fromRun) {
        editObject.query = { redirectTo: 'TestRunEdit' };
      }
      this.$router.push(editObject);
    },
    viewPreviousCase() {
      if (!this.isSelectedCaseFirstIndex) {
        this.$emit('viewPreviousCase');
      }
    },
    viewNextCase() {
      if (!this.isSelectedCaseLastIndex) {
        this.$emit('viewNextCase');
      }
    },
    async fetchTemplates() {
      try {
        const response = await templateService.getTemplates(
          this.$route.params.handle,
          this.$route.params.key,
          "per_page=9999&current_page=1"
        );
        this.templates = response.data.templates;
      } catch (error) {
        console.error('Error fetching templates:', error);
      }
    },
  },
};
</script>
<style scoped>
h2,
h3,
h4 {
  text-align: left;
}
h5 {
  color: #667085;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
}
.collapse-btn {
  color: #0c2ff3;
}
p {
  text-align: left;
}
.flex {
  display: flex;
}
.justify-between {
  justify-content: space-between;
}
.justify-start {
  justify-content: flex-start;
}
.align-center {
  align-items: center;
}
.bg-white {
  background-color: white !important;
}
.close-btn:hover {
  cursor: pointer;
}
.f-color-blue {
  color: #0c2ff3 !important;
}
.w-50 {
  width: 50%;
}
.align-left {
  text-align: left;
}
.align-left .contents {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #0c111d;
}
.toggle-btn {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  cursor: pointer;
}

.panel-title {
  font-family: Inter;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  color: #0C111D;
}
</style>