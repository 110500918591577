<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-3 py-2 d-flex flex-row">{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-expansion-panels
            v-model="priorityPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('testruns.create_testrun.priority') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(item,index) in priorities"
                  :key="index"
                  v-model="panelPriority"
                  :value="item.name"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <span class="fs-14px text-theme-label">{{ item.name }}</span>
                  </template>
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels
            v-model="statusPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('testruns.create_testrun.status') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(item,index) in statuses"
                  :key="index"
                  v-model="panelStatus"
                  :value="item.name"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <span class="fs-14px text-theme-label">{{ item.name }}</span>
                  </template>
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <div 
            v-for="(config, index) in configurations" 
            :key="index"
            class="mb-5"
          >
            <Selection             
              v-model="selectedConfig"
              :title="config.name"    
              :items="config.options"
            />
          </div>

          <div>
            <div class="text-start">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t("numberOfTestCases") }}
              </v-label>
            </div>
            <v-range-slider
              v-model="testCases"
              class="slider-theme"
              color="blue"
              track-color="#F2F4F7"
              thumb-color="#FFFFFF"
              hide-details
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="testCases[0]"
                :value="testCases[0]"
                background-color="#F9F9FB"
                class="field-theme"
                height="38"
                hide-details
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                v-model="testCases[1]"
                :value="testCases[1]"
                background-color="#F9F9FB"
                class="field-theme"
                height="38"
                hide-details
              />
            </div>
          </div>

          <v-expansion-panels
            v-model="milestonePanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('testruns.create_testrun.milestone') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  :placeholder="$t('search')"
                  background-color="#F9F9FB"
                  class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                  height="38"
                  dense
                  hide-details
                >
                  <template v-slot:prepend-inner>
                    <SearchIcon />
                  </template>
                </v-text-field>
                <v-checkbox
                  v-for="(item,index) in milestones"
                  :key="index"
                  v-model="panelMilestone"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  :hide-details="true"
                  :value="item.uid"
                >
                  <template v-slot:label>
                    <span class="fs-14px text-theme-label">{{ item.name }}</span>
                  </template>
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          
          <div class="text-start pt-6">
            <div class="text-start mb-2">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('creationDate') }}
              </v-label>
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    :value="dateStart"
                    background-color="#F9F9FB"
                    readonly
                    height="38"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dateStart"
                  @change="menu1 = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                {{ $t('-') }}
              </div>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#F9F9FB"
                    class="text-field mt-0 field-theme pa-0 rounded-lg custom-prepend"
                    :value="dateEnd"
                    readonly
                    height="40"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dateEnd"
                  @change="menu2 = false"
                />
              </v-menu>
            </div>
            <v-expansion-panels
              v-model="tagPanel"
              flat
              class="mb-8"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  <div class="text-start">
                    <v-label class="text-theme-label font-weight-medium">
                      {{ $t('testruns.create_testrun.tag') }}
                    </v-label>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="searchTag"
                    :placeholder="$t('search')"
                    background-color="#F9F9FB"
                    class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                    height="38"
                    dense
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <SearchIcon />
                    </template>
                  </v-text-field>
                  <v-checkbox
                    v-for="(item,index) in filterTags"
                    :key="index"
                    v-model="panelTag"
                    :value="item.name"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ item.name }}</span>
                    </template>
                  </v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <div>
              <div class="mb-1 text-start mt-4">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('testruns.progress') }}
                  </v-label>
                </div>
              </div>
              <v-range-slider
                v-model="progress"
                class="slider-theme"
                color="blue"
                track-color="#F2F4F7"
                thumb-color="#FFFFFF"
                hide-details
              />
              <div class="d-flex align-center">
                <v-text-field
                  v-model="progress[0]"
                  dense
                  :value="progress[0]"
                  background-color="#F9F9FB"
                  class="field-theme"
                  height="38"
                />
                <div class="mx-4 font-weight-bold text-h6">
                  -
                </div>
                <v-text-field
                  v-model="progress[1]"
                  dense
                  :value="progress[1]"
                  background-color="#F9F9FB"
                  class="field-theme"
                  height="38"
                />
              </div>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{ $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          elevation="0"
          @click="apply"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import SearchIcon from '@/assets/svg/search-icon.svg';
import calendarBlueIcon from '@/assets/svg/calendar-blue.svg';
import Selection from './Selection.vue';
import colorPreferencesMixin from '@/mixins/colorPreferences';

export default {
  name: "ProjectFilter",
  components: {
    SearchIcon,
    calendarBlueIcon,
    Selection,
  },
  mixins: [colorPreferencesMixin],
  props: {
    configurations: {
        type: Array,
        default: () => [] 
    },
    milestones: {
        type: Array,
        default: () => [] 
    },
    tags: {
        type: Array,
        default: () => [] 
    },
  },
  data() {
    return {
      searchTag: null,
      panelPriority: [],
      panelStatus: [],
      panelMilestone: [],
      selectedConfig: [],
      panelTag: [],
      testRun: [0, 100],
      testCases: [0, 100],
      progress: [0, 100],
      dateStart: null,
      dateEnd: null,
      menu1: false,
      menu2: false,
      showDialog: false,
      priorityPanel: 0,
      statusPanel: 0,
      milestonePanel: 0,
      tagPanel: 0,
      configPanel: 0,
    };
  },
  computed:{
    filterTags(){
      if(!this.searchTag)
        return this.tags
      
      return this.tags.filter(tag => tag.name?.startsWith(this.searchTag))
    }
  },
  created() {
    this.getPriorities("testRun");
    this.getStatuses("testRun");
  },
  methods: {
    apply() {
      this.$emit("applyFilters", {
        ...(this.panelPriority.length ? {panelPriority: this.panelPriority} : null),
        ...(this.panelStatus.length ? { panelStatus: this.panelStatus } : null),
        ...(this.panelMilestone.length ? {panelMilestone: this.panelMilestone} : null),
        ...(this.panelTag.length ? {panelTag: this.panelTag} : null),
        ...(this.dateStart && this.dateEnd ? {
          dateRange: {
            start: this.dateStart,
            end: this.dateEnd,
          }
        } : null),
        testCasesRange: this.testCases,
        progressRange: this.progress
      });
      this.showDialog = false;
    },
    clearAll() {
      this.panelPriority = [];
      this.panelStatus = [];
      this.panelMilestone = [];
      this.panelTag = [];
      this.testRun = [0, 100];
      this.testCases = [0, 100];
      this.progress = [0, 100];
      this.dateStart = null;
      this.dateEnd = null;

      this.$emit("applyFilters", {
        ...(this.panelPriority.length ? {panelPriority: this.panelPriority} : null),
        ...(this.panelStatus.length ? { panelStatus: this.panelStatus } : null),
        ...(this.panelMilestone.length ? {panelMilestone: this.panelMilestone} : null),
        ...(this.panelTag.length ? {panelTag: this.panelTag} : null),
        ...(this.dateStart && this.dateEnd ? {
          dateRange: {
            start: this.dateStart,
            end: this.dateEnd,
          }
        } : null),
        testCasesRange: this.testCases,
        progressRange: this.progress
      });
      
      this.showDialog = false;

    },
  }
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>