<template>
  <div class="white card d-flex flex-column justify-space-between pa-3 rounded-lg mr-3 sticky-on-scroll">
    <v-list v-if="isCollapsed" nav class="pa-0">
      <v-list-item-group color="primary">
        <v-list-item class="d-flex justify-center" :ripple="false" @click="searchCollapsedMenu">
          <v-list-item-icon class="justify-center mx-0">
            <SearchIcon />
          </v-list-item-icon>
        </v-list-item>
        <v-list-item class="d-flex justify-center" :ripple="false" @click="unlinkedCollapsedMenu">
          <v-list-item-icon class="justify-center mx-0">
            <UnlinkedIcon24 />
          </v-list-item-icon>
        </v-list-item>
        <v-list-item class="d-flex justify-center" :ripple="false" @click="linkedCollapsedMenu">
          <v-list-item-icon class="justify-center mx-0">
            <LinkedIcon24 />
          </v-list-item-icon>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <div v-if="!isCollapsed" class="d-flex flex-column">
      <v-text-field ref="searchField" :placeholder="$t('search')" background-color="#F9F9FB"
        class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0 mb-3 flex-inherit" height="38" dense
        hide-details>
        <template v-slot:prepend-inner>
          <SearchIcon />
        </template>
      </v-text-field>

      <v-btn text :color="displayedRuns == 'all' ? '#0C2FF3' : '#667085'"
        :class="displayedRuns == 'all' ? 'bg-theme-primary-light' : 'bg-gray-theme'"
        class="text-capitalize rounded-lg justify-start mb-3" width="100%" @click="onClickPlanActive('all')">
        {{ $t('testruns.all') }}
      </v-btn>

      <v-btn text :color="displayedRuns == 'unlinked' ? '#0C2FF3' : '#667085'"
        :class="displayedRuns == 'unlinked' ? 'bg-theme-primary-light' : 'bg-gray-theme'"
        class="text-capitalize rounded-lg bg-gray-theme justify-start mb-3" width="100%"
        @click="onClickPlanActive('unlinked')">
        <div class="d-flex align-center">
          <div class="mr-2">
            <UnlinkedIcon />
          </div>
          <span>{{ $t('testruns.unlinked') }} 0</span>
        </div>
      </v-btn>
      <v-btn text class="text-capitalize rounded-lg bg-gray-theme justify-start mb-2" width="100%"
        :color="Number.isInteger(displayedRuns) ? '#0C2FF3' : '#667085'"
        :class="Number.isInteger(displayedRuns) ? 'bg-theme-primary-light' : 'bg-gray-theme'" @click="onToPlanExpanded">
        <div class="d-flex justify-space-between w-full">
          <div class="d-flex align-center">
            <div class="mt-1 mr-2">
              <LinkedIcon />
            </div>
            <span>{{ $t('testruns.toPlans') }} {{ getActivePlansCount }}</span>
          </div>
          <div>
            <v-icon>
              {{ isToPlanExpanded ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
            </v-icon>
          </div>
        </div>
      </v-btn>

      <div v-if="isToPlanExpanded" class="plan-list-wrapper">
        <div v-for="(plan, index) in getActivePlans" :key="index">
          <v-tooltip bottom left max-width="200px" :disabled="plan.name.length < 15" content-class="tooltip-theme">
            <template v-slot:activator="{ on, attrs }">
              <v-btn text :color="Number.isInteger(displayedRuns) && displayedRuns == plan.uid ? '#0C2FF3' : '#667085'"
                :class="Number.isInteger(displayedRuns) && displayedRuns == plan.uid ? 'bg-theme-primary-light' : 'bg-gray-theme'"
                class="text-capitalize btn-full font-weight-regular rounded-lg justify-start mb-2" width="100%"
                v-bind="attrs" @click="onClickPlanActive(plan)" v-on="on">
                <div class="d-flex justify-between align-center w-full">
                  <div class="text-truncate">
                    {{ plan.name }}
                  </div>
                  <div>({{ plan.runs.length }})</div>
                </div>
              </v-btn>
            </template>
            <span>{{ plan.name }}</span>
          </v-tooltip>
        </div>
      </div>
      <div v-else class="plan-list-wrapper" />
    </div>
    <div v-if="!isCollapsed" class="sticky-scroll">
      <div class="text-left">
        <v-label class="text-left fs-14px text-theme-label font-weight-medium">
          {{ $t("createQuickPlan") }}
        </v-label>
        <v-text-field v-model="planName" :placeholder="$t('enterName')" height="38" background-color="#F9F9FB" :class="{
          'field-theme mt-0 pt-1': true,
          'disabled-action': isProjectArchived
        }" />
      </div>
      <v-btn type="submit" block color="primary" :depressed="true" :class="{
        'btn-theme': true,
        'disabled-action': isProjectArchived || createButtonLoading
      }" width="100%" height="40" :loading="createButtonLoading" @click="createTestPlan">
        {{ $t("create") }}
      </v-btn>
    </div>

    <div class="collapse-btn btn-runs-sticky" @click="toggleProjectMenu">
      <v-icon class="collapse-icon" color="#0C2FF3">
        {{ isCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-left-bottom'
        }}
      </v-icon>
      <span v-if="!isCollapsed" class="collapse-text">
        {{ $t('collapse') }}
      </span>
    </div>
  </div>
</template>

<script>
import makePlanService from '@/services/api/plan';
import SearchIcon from '@/assets/svg/search-icon.svg';
import UnlinkedIcon from '@/assets/svg/unlinked.svg';
import UnlinkedIcon24 from '@/assets/svg/unlinked24px.svg';
import LinkedIcon from '@/assets/svg/linked.svg';
import LinkedIcon24 from '@/assets/svg/linked24px.svg';
import projectStatus from '@/mixins/projectStatus';
import { showSuccessToast, showErrorToast } from '@/utils/toast';

let planService;

export default {
  components: {
    SearchIcon,
    UnlinkedIcon,
    UnlinkedIcon24,
    LinkedIcon,
    LinkedIcon24,
  },
  mixins: [projectStatus],
  data() {
    return {
      displayedRuns: 'all',
      isToPlanExpanded: false,
      plans: [],
      planName: '',
      createButtonLoading: false,
    };
  },
  computed: {
    getActivePlansCount() {
      return this.plans.filter(plan => plan.archivedAt == null).length;
    },
    getActivePlans() {
      return this.plans.filter(plan => plan.archivedAt == null);
    },
    localCollapsed: {
      get() {
        return this.isCollapsed; 
      },
      set(value) {
        this.$emit('update:isCollapsed', value);
      }
    }
  },
  created() {
    planService = makePlanService(this.$api);
  },
  mounted() {
    this.getTestPlans();
  },
  props: {
    isCollapsed: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    searchCollapsedMenu() {
      this.localCollapsed = !this.localCollapsed;
      this.$nextTick(() => {
        if (this.$refs.searchField) {
          this.$refs.searchField.focus();
        }
      });
    },
    unlinkedCollapsedMenu() {
      this.localCollapsed = !this.localCollapsed;
      this.displayedRuns = 'unlinked';
    },
    linkedCollapsedMenu() {
      this.localCollapsed = !this.localCollapsed;
      this.isToPlanExpanded = true;
    },
    toggleProjectMenu() {
      this.localCollapsed = !this.localCollapsed;
    },
    onToPlanExpanded(){
      this.isToPlanExpanded = !this.isToPlanExpanded
    },
    async getTestPlans() {
      const handle = this.$route.params.handle;
      const projectKey = this.$route.params.key;

      const testPlanService = makePlanService(this.$api);
      try {
        const response = await testPlanService.getAllTestPlans(handle, projectKey, 1000, 0);
        this.plans = response.data?.plans;
        return response.data?.plans;
      } catch (err) {
        showErrorToast(this.$swal, 'fetchError', { item: 'test plans' });
        return [];
      }
    },
    async createTestPlan() { 
      if(!this.isProjectArchived) {
      const payload = {
        name: this.planName,
        source: "testfiesta",
        status: '',
        milestoneUids: [],
        priority: '',
        runs: [],
        customFields: {
          archived: false
        }
     }
     try {
       this.createButtonLoading = true;
        const response = await planService.createTestPlan(
        this.$route.params.handle,
        this.$route.params.key,
        payload
       );
       if (response.status === 200) {
        const newPlan = response.data;
        if (newPlan) {
          this.plans.push({...newPlan, runs: []});
        }
        this.planName = "";
       }
      } catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message || 'Internal server error')
      } finally {
        this.createButtonLoading = false;
     }
    }
    },
    onClickPlanActive(plan) {
      const planId = plan?.uid || plan;
      this.$emit('onPlanActiveId', planId);
    },
  },
}
</script>

<style scoped>
.sticky-on-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 12px;
  height: calc(100vh - 6px);
}
.sticky-scroll {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  padding-bottom: 5rem;
  background: #fff;
    z-index: 1;
    padding-top: 1rem;
}
.plan-list-wrapper {
  height: calc(100vh - 28rem);
  overflow: auto;
  scrollbar-width: thin;
}
.btn-runs-sticky {
  position: -webkit-sticky;
  position: sticky;
  background: #fff;
  justify-content: flex-start;
  z-index: 9;
}
</style>