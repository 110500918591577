<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
        md="auto"
        class="d-flex align-center"
      >
        <SearchComponent
          :search="searchByName"
          :placeholder="$t('placeHolder.searchByName')"
          @update:search="searchByName = $event"
        />
      </v-col>
    </v-row>

    <v-data-table
      :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
      class="custom-table mt-6"
      :headers="headers"
      :items="filteredHistories"
      item-key="id"
    >
      <template v-slot:[`item.amount`]="{ item }">
        <span>{{ `$${item.amount_paid}` }}</span>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        <span>{{ formatCreatedAt(item.created_at) }}</span>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <span
          class="font-weight-bold text-capitalize"
          :class="item.status === 'paid' ? 'success--text' : 'error--text'"
        >
          {{ item.status }}
        </span>
      </template>

      <template v-slot:[`item.id`]="{ item }">
        <div class="d-flex justify-space-between">
          <v-btn
            icon
            color="grey"
            @click="onDownloadHistory(item)"
          >
            <v-icon>mdi-tray-arrow-down</v-icon>
          </v-btn>
        </div>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import * as _ from 'lodash'

import makeUserService from '@/services/api/user';
import makeOrgService from '@/services/api/org';

import { formatDate } from '@/utils/util';
import { showErrorToast } from '@/utils/toast';

import SearchComponent from '@/components/Project/SearchComponent.vue';

export default {
  components: {
    SearchComponent,
  },

  props: {
    isOrg: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      searchByName: '',
      headers: [
        {
          text: this.$t('name'),
          align: 'start',
          sortable: true,
          value: 'planName',
          class: 'elevation-0 rounded-l-lg',
        },
        {
          text: this.$t('common.createdAt'),
          value: 'created_at',
          sortable: true,
        },
        {
          text: this.$t('billing.amount'),
          value: 'amount',
          sortable: true,
        },
        {
          text: this.$t('status'),
          value: 'status',
          sortable: true,
        },
        {
          text: '',
          value: 'id',
          sortable: false,
          class: 'rounded-r-lg',
          width: 130,
        },
      ],
      originalHistories: [],
      filteredHistories: [],
    }
  },

  computed: {
    ...mapGetters({
      currentAccount: 'user/currentAccount',
    }),
  },

  watch: {
    searchByName: {
      handler: _.debounce(function () {
        this.filterHistories()
      }, 500),
    },
  },

  mounted() {
    this.loadHistories()
  },

  methods: {
    ...mapActions({
      setLoading: 'setLoading',
    }),

    formatCreatedAt(createdAt) {
      return formatDate(createdAt, 'MM/dd/yy')
    },

    async loadHistories() {
      this.setLoading({
        loading: true,
        loadingText: this.$t('billing.loadingSubscriptionHistory')
      })

      try {
        const response = this.isOrg
          ? await makeOrgService(this.$api).getSubscriptionHistory(this.currentAccount.uid)
          : await makeUserService(this.$api).getSubscriptionHistory()
        this.originalHistories = response.data.items.map((item) => ({
          ...item,
          planName: item.price_details?.metadata?.title || this.$t('billing.unknownPlan'),
          created_at: new Date(item.date_opened * 1000),
        }))
      } catch (err) {
        console.log(err)
        showErrorToast(this.$swal, err.response?.data?.message || this.$t('error.internalServerError'))
      } finally {
        this.setLoading({
          loading: false,
        })
      }

      this.filterHistories()
    },

    filterHistories() {
      let filteredHistories = _.cloneDeep(this.originalHistories)

      if (this.searchByName) {
        filteredHistories = filteredHistories.filter(item => item.planName.toLowerCase().includes(this.searchByName.toLowerCase()))
      }

      this.filteredHistories = _.cloneDeep(filteredHistories)
    },

    onDownloadHistory(history) {
      if (!history.pdf_url) {
        return
      }

      window.open(history.pdf_url, '_blank')
    }
  }
}
</script>
