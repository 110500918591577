export default function makeMilestonesService(api) {
  return {
    getMilestones: async function (handle, projectKey, params) {
      return api.get(`/${handle}/projects/${projectKey}/milestones?${params}&per_page=${10000}&current_page=${0}`);
    },
    findMilestone: async function (handle, projectKey, uid) {
      return api.get(`/${handle}/projects/${projectKey}/milestones/${uid}`);
    },
    createMilestone: async function (handle, projectKey, { ...data }) {
      return api.post(`/${handle}/projects/${projectKey}/milestones`, data);
    },
    deleteMilestone: async function (handle, projectKey, item) {
      return api.delete(`/${handle}/projects/${projectKey}/milestones/${item}`);
    },
    updateMilestone: async function (handle, projectKey, item, payload) {
      return api.patch(`/${handle}/projects/${projectKey}/milestones/${item}`, payload);
    },
    addToMilestone: async function (handle, projectKey, payload) {
      return api.post(`/${handle}/projects/${projectKey}/milestones/plans`, payload);
    },
    addRunsToMilestone: async function (handle, projectKey, uid, payload) {
      return api.patch(`/${handle}/projects/${projectKey}/milestones/${uid}`, payload)
    }
  };
}
