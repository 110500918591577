import makeAccessTokenService from '@/services/api/accessToken';

const state = {
  accessTokens: [],
};

const getters = {
  accessTokens: (state) => {
    return state.accessTokens;
  },
};

const mutations = {
  setAccessTokens(state, items) {
    state.accessTokens = items;
  },
  removeAccessToken(state, accessTokenId) {
    state.accessTokens = state.accessTokens.filter((e) => {
      return e.uid != accessTokenId;
    });
  },
  removeAllAccessTokens(state) {
    state.accessTokens = [];
  },
};

function makeActions(api) {
  const accessTokenService = makeAccessTokenService(api);

  return {
    getAccessTokens: function ({ commit }, { handle }) {
      accessTokenService
        .getUserAccessTokens(handle)
        .then((res) => {
          commit('setAccessTokens', res.data.accessTokens);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    deleteAccessToken: function ({ commit }, { handle, accessTokenId }) {
      accessTokenService
        .deleteUserAccessToken(handle, accessTokenId)
        .then(() => {
          commit('removeAccessToken', accessTokenId);
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
    deleteAllAccessTokens: function ({ commit }, { handle }) {
      accessTokenService
        .deleteUserAllAccessTokens(handle)
        .then(() => {
          commit('removeAllAccessTokens');
        })
        .catch((error) => {
          console.log('error', error);
        });
    },
  };
}

export default function makeAccessToken(api) {
  return {
    namespaced: true,
    state,
    getters,
    mutations,
    actions: makeActions(api),
  };
}
