<template>
  <div>
    <v-sheet>
      <v-row
        class="pt-4 mb-4"
        align="center"
      >
        <v-col
          cols="9"
          align="left"
        >
          <h1 class="display-3 hidden-xs-only text-capitalize">
            {{ formattedTitle }}
          </h1>
          <h1 class="display-1 hidden-sm-and-up text-capitalize">
            {{ formattedTitle }}
          </h1>
        </v-col>
        <v-col cols="3">
          <slot name="action" />
        </v-col>
      </v-row>
    </v-sheet>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
  },
  computed: {
    sheetHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return '200px';
        case 'sm':
          return '300px';
        case 'md':
          return '300px';
        case 'lg':
          return '300px';
        case 'xl':
          return '350px';
      }
      return '300px';
    },
    formattedTitle() {
      return this.title.replaceAll('-', ' ');
    },
  },
};
</script>

<style lang="scss" scoped></style>