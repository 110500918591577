<template>
  <v-main>
    <v-dialog
      v-model="chartDialog"
      max-width="50%"
    >
      <new-chart-form
        :chart-data="chartData"
        @close="chartDialog = !chartDialog"
        @add-chart="addChart($event)"
      />
    </v-dialog>
    <v-row justify="center">
      <v-col cols="5">
        <v-menu
          v-if="dashboards.length > 1"
          id="orgs"
          class="my-0 org-menu"
        >
          <template #activator="{ on }">
            <div
              v-if="dashboards.length > 0"
              v-on="on"
            >
              <v-icon>mdi-building</v-icon>
              {{ currentDashboardName }}
            </div>
            <div
              v-else
              v-on="on"
            >
              <v-icon>mdi-building</v-icon>
              <router-link to="/orgs/new">
                {{ $t('createOrg') }}
              </router-link>
            </div>
          </template>
          <v-list>
            <v-list-item
              v-for="item in dashboards"
              :key="item.uid"
              @click="setDashboard(item)"
            >
              <v-icon>mdi-building</v-icon>
              {{ item.name }}
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col cols="2">
        <v-btn
          v-if="dashboards.length > 1"
          class="make-default"
          @click="makeDashboardDefault()"
        >
          Make default
        </v-btn>
      </v-col>
      <v-col
        cols="3"
        align-self="start"
      >
        <v-text-field
          v-model="currentDashboardName"
          :label="$t('DashboardName')"
          outlined
          dense
        />
      </v-col>
      <v-col
        cols="2"
        align-self="start"
      >
        <v-btn
          color="secondary"
          outlined
          class="mx-2"
          @click="chartDialog = !chartDialog"
        >
          +
        </v-btn>
        <v-btn
          color="secondary"
          outlined
          @click="saveDashboard()"
        >
          Save
        </v-btn>
      </v-col>
    </v-row>
    <grid-layout
      :layout.sync="gridInfos"
      :col-num="colNum"
      :row-height="rowHeight"
      :is-draggable="layoutEditable"
      :is-resizable="layoutEditable"
      :responsive="responsive"
      :breakpoints="breakpoints"
      :cols="cols"
      @layout-ready="layoutReady = true"
      @layout-updated="layoutUpdated"
    >
      <grid-item
        v-for="(item, index) in gridInfos"
        :key="item.id"
        :ref="'grid-item-' + item.id"
        :x="item.x"
        :y="item.y"
        :w="item.w"
        :h="item.h"
        :i="item.i"
        :static="item.static"
      >
        <v-btn
          color="secondary"
          class="icon-btn"
          icon
          style="position: absolute;top: 2px;right: 5px;z-index: 100;border: 1px rgb(180,180, 180) solid;"
          @click="editGraph(item, chartInfos[index])"
        >
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
        <vue-apex-charts
          v-if="layoutReady && newChartReady"
          :key="chartInfos[index].id"
          :type="chartInfos[index].options.type"
          :series="chartInfos[index].series"
          :options="chartInfos[index].options"
          width="100%"
          height="100%"
        />
      </grid-item>
    </grid-layout>
  </v-main>
</template>

<script>
import palette from '@/assets/scss/dashboard-grid/palette.json';
import DashboardService from '@/services/api/dashboard';
import { uuid } from 'vue-uuid';
import VueApexCharts from 'vue-apexcharts';
import { GridLayout, GridItem } from 'vue-grid-layout';
import NewChartForm from '@/components/Dashboard/NewChartForm';
import { mapGetters, createNamespacedHelpers } from 'vuex';
import { DASHBOARD_REFRESH_SECONDS } from '@/constants/dashboardConstants';
const { mapMutations, mapState } = createNamespacedHelpers('user');
export default {
  components: {
    GridLayout,
    GridItem,
    VueApexCharts,
    NewChartForm,
  },
  props: {
    theme: {
      type: [String, Array],
      required: false,
      default() {
        return undefined;
      },
    },
    monochrome: {
      type: Object,
      required: false,
      default() {
        return {
          enabled: false,
          color: '#255aee',
          shadeIntensity: 0.65,
        };
      },
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
    colNum: {
      type: Number,
      required: true,
      default: 12,
    },
    rowHeight: {
      type: Number,
      required: true,
      default: 30,
    },
    layoutEditable: {
      type: Boolean,
      required: true,
      default: true,
    },
    responsive: {
      type: Boolean,
      required: false,
      default: false,
    },
    breakpoints: {
      type: Object,
      required: false,
      default() {
        return {
          lg: 1200,
          md: 996,
          sm: 768,
          xs: 480,
          xxs: 0,
        };
      },
    },
    cols: {
      type: Object,
      required: false,
      default() {
        return {
          lg: 12,
          md: 10,
          sm: 6,
          xs: 4,
          xxs: 2,
        };
      },
    },
    datasets: {
      type: Array,
      required: true,
      default: function() {
        return [];
      },
    },
    uid: {
      type: String,
      required: false,
      default() {
        return '';
      },
    },
  },
  data() {
    return {
      chartDialog: false,
      db: null,
      dashboardService: null,
      newChartReady: true,
      gridInfos: [],
      palette,
      layoutReady: false,
      isFirstMount: true,
      darkModeColorOptions: {
        background: 'transparent',
        foreColor: '#fff',
      },
      lightModeColorOptions: {
        background: '#fff',
        foreColor: '#232323',
      },
      previousThemeColors: [],
      timerCount: DASHBOARD_REFRESH_SECONDS,
      chartData: {},
      selectedDashboard: {},
      dashboards: [],
      currentDashboardName: '',
    };
  },
  computed: {
    ...mapGetters({
      orgs: 'user/orgs',
      //currentAccount: 'user/currentAccount',
      //lastUpdate: 'dashboard/lastUpdate', CTODO - move to state
    }),
    ...mapState(['user', 'currentAccount']),
    chartInfos: function() {
      if (!this.layoutReady) return [];

      document.documentElement.dataset.theme = this.isDarkMode();

      const chartInfos = this.datasets.map(item => item.chartInfo);

      return chartInfos.map(chartInfo => {
        const _chartInfo = {
          ...chartInfo,
          id: uuid.v4(),
          options: {
            ...chartInfo.options,
            theme: {
              mode: this.isDarkMode(),
              monochrome: {
                ...(chartInfo.options.theme?.monochrome || this.monochrome),
              },
            },
            chart: this.darkMode
              ? { ...chartInfo.options.chart, ...this.darkModeColorOptions }
              : {
                  ...this.lightModeColorOptions,
                  ...chartInfo.options.chart,
                },
          },
        };

        //if (this.themeToUse) {
        //  _chartInfo.options.colors = this.themeToUse;
        //} // CTODO - we set these by charts now, should we not?

        _chartInfo.options.chart = {
          ..._chartInfo.options.chart,
          events: {
            mounted: (chartContext, config) => {
              this.$emit('mounted', chartContext, config);
            },
            updated: (chartContext, config) => {
              this.$emit('updated', chartContext, config);
            },
          },
        };

        return _chartInfo;
      });
    },
    themeToUse() {
      if (this.theme === null) return undefined;

      if (Array.isArray(this.theme)) {
        return this.theme;
      }

      if (this.theme === undefined) {
        return palette.find(_theme => _theme.name === 'classic')?.colors;
      }

      return palette.filter(theme => this.theme === theme.name)?.[0]?.colors;
    },
  },
  watch: {
    //datasets(newValue, oldValue) {
    //const oldColors = oldValue[0].chartInfo.options.colors;
    //this.savePreviousThemeColors(oldColors);
    //},

    /**
     * listen to current account changes
     */
    currentAccount() {
      this.pullDashboardList(this.currentAccount.uid);
    },
    datasets() {},
    darkMode() {
      this.darkMode ? this.setDarkMode(this.previousThemeColors) : this.setLightMode(this.previousThemeColors);
    },
  },
  created() {
    this.dashboardService = DashboardService(this.$api);
    const lastFetchTime = localStorage.getItem('lastFetchTime');
    if (lastFetchTime > 0 && new Date().getTime() / 1000 - lastFetchTime < 900) {
      this.timerCount = new Date().getTime() / 1000 - lastFetchTime;
    }
    this.countDownTimer();
    this.validateProps();
    this.bindGridInfos();
  },
  mounted() {
    //this.pullDashboardList(this.currentAccount.uid)
    // CTODO - What is this doing?  Does it work with current dashboards? vvv
    this.addUniqueId();
  },
  methods: {
    ...mapMutations(['setUser']),
    async makeDashboardDefault() {
      if (!this.currentAccount) {
        // CTODO - link to create?
        this.$swal({
          icon: 'info',
          text: 'Please create an organization',
        });
      }
      const createdUid = await this.dashboardService
        .makeDefault({ org: this.currentAccount.uid, uid: this.uid })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: this.$t('error'),
            text: error,
          });
        });
      this.uid = createdUid.data.uid;
      this.user.preferences = {
        [this.currentAccount.uid]: {
          default_dashboard: this.uid,
        },
      };
      this.setUser(this.user);
      this.$swal({
        title: this.$t('success'),
        icon: 'success',
        showConfirmButton: false,
        position: 'top-end',
        timer: 2000,
        toast: true,
      });
    },
    countDownTimer() {
      // this.pullDashboard(this.currentAccount.uid, this.uid);
      setInterval(() => {
        // Just pull the dashboard for a refresh.
        this.pullDashboard(this.currentAccount.uid, this.uid);
      }, 15 * 60 * 1000);
    },
    async pullDashboardList(orgUID) {
      if (!orgUID) {
        orgUID = this.currentAccount.uid;
      }
      if (!orgUID) {
        return;
      }
      let response = await this.dashboardService.getDashboardList(orgUID).catch(error => {
        this.$swal({
          icon: 'error',
          title: this.$t('error'),
          text: error,
        });
      });
      if (response?.data) {
        if (response?.data?.dashboards) {
          this.dashboards = response.data.dashboards;
        }
        if (response?.data?.defaultDashboard) {
          this.updateUI(response.data.defaultDashboard);
        }
      }
    },
    async pullDashboard(orgUID, uid) {
      if (!orgUID) {
        orgUID = this.currentAccount.uid;
      }
      if (!orgUID) {
        return;
      }
      this.lastUpdate = localStorage.getItem('lastFetchTime');
      let response = await this.dashboardService.getDashboard(orgUID, uid, this.lastUpdate).catch(error => {
        this.$swal({
          icon: 'error',
          title: this.$t('error'),
          text: error,
        });
      });
      if (response?.data?.dashboard) {
        this.updateUI(response.data.dashboard);
      }
    },
    async updateUI(dashboard) {
      this.currentDashboardName = dashboard.name;
      this.uid = dashboard.uid;
      if (dashboard.body.properties) {
        for (const [key, value] of Object.entries(dashboard.body.properties)) {
          this[key] = value;
        }
      }
      if (Object.keys(dashboard.body).length > 0) {
        if (dashboard.body.items) {
          this.datasets = dashboard.body.items;
        } else {
          this.datasets = [];
        }
      } else {
        this.$swal({
          icon: 'error',
          title: 'Invalid',
          text: 'Invalid dashboard body.',
        });
      }
      this.newChartReady = false;
      this.validateProps();
      this.bindGridInfos();
      this.$nextTick(() => {
        // Ugly hack to get the chart to fit in the grid on initial load.
        this.newChartReady = true;
      });

      localStorage.setItem('lastFetchTime', new Date().getTime() / 1000);
      this.db = await this.dashboardService.updateIDB(dashboard).catch(err => {
        console.log('errr ', err);
      });

      let newDataSet = [];
      if (dashboard.body.items) {
        for (const chart of dashboard.body.items) {
          newDataSet.push(this.dashboardService.updateChart(this.db, chart));
        } // CTODO - What do we do with newDataSet?
      }
    },
    layoutUpdated(event) {
      if (this.currentDashboardName && this.currentAccount.uid && this.uid) {
        this.saveDashboard();
      }
    },
    addChart(chartData) {
      // CTODO - add the new data/types too?
      this.newChartReady = false;
      let gridUUID = uuid.v4();
      this.datasets.push({
        id: uuid.v4(),
        chartInfo: {
          id: uuid.v4(),
          series: chartData.series,
          options: chartData.options,
        },
        gridInfo: {
          id: gridUUID,
          x: 0,
          y: 0,
          w: 6,
          h: 12,
          i: gridUUID,
          static: false,
        },
      });
      this.bindGridInfos();

      this.$nextTick(() => {
        // Ugly hack to get the chart to fit in the grid on initial load.
        this.newChartReady = true;
      });
    },
    saveDashboard() {
      let dashboard = {
        properties: {
          colNum: this.colNum,
          rowHeight: this.rowHeight,
          layoutEditable: this.layoutEditable,
          responsive: this.responsive,
          breakpoints: this.breakpoints,
          cols: this.cols,
        },
        items: [],
        dataTypes: [],
      };
      let dataTypes = new Set();

      for (const dataset of this.datasets) {
        dashboard.items.push(dataset);
        let dimensions = dataset.chartInfo.series.flatMap(series =>
          series.ref
            ? series.ref.axises
              ? [
                  ...Object.values(series.ref.axises).map(axis => axis.dimension),
                  ...series.ref.filters.map(filter => filter.dimension),
                ]
              : []
            : []
        );
        for (const dimension of dimensions) {
          dataTypes.add(dimension.split('.')[0]);
        }
      }
      dashboard.dataTypes = [...dataTypes];
      this.dashboardService
        .saveDashboard(this.currentDashboardName, dashboard, this.currentAccount.uid, this.uid)
        .then(response => {
          this.uid = response.data.uid;
        })
        .catch(error => {
          this.$swal({
            icon: 'error',
            title: this.$t('error'),
            text: error.data.error,
          });
        });
    },
    isType(element) {
      return Object.prototype.toString
        .call(element)
        .slice(8, -1)
        .toLowerCase();
    },
    validateProps() {
      for (const data of this.datasets) {
        const { chartInfo, gridInfo } = data || {};
        const { series, options } = chartInfo || {};
        const { chart } = options || {};

        if (!(this.isType(data) === 'object')) {
          return console.error(
            '[dashboard-grid warn]: Invalid datasets prop: Please check the type or structure of datasets prop. The type of element in datasets must be an object.'
          );
        }

        if (
          !(this.isType(chartInfo) === 'object') ||
          !(this.isType(options) === 'object') ||
          !(this.isType(chart) === 'object') ||
          !(this.isType(gridInfo) === 'object')
        ) {
          return console.error(
            '[dashboard-grid warn]: Invalid datasets prop: Please check the type or structure of datasets prop. The type of prop, such as chartInfo, chartInfo.options, chartInfo.options.chart, gridInfo, must be an object.'
          );
        }

        if (!(this.isType(series) === 'array')) {
          return console.error(
            '[dashboard-grid warn] Invalid prop: Please check the type or structure of chartInfo.series prop. The type of prop must be an array.'
          );
        }

        if (!(!chart.type || this.isType(chart.type) === 'string') || !(this.isType(gridInfo.i) === 'string')) {
          return console.error(
            '[dashboard-grid warn] Invalid prop: Please check the type or structure of chartInfo.options.chart.type and gridInfo.i prop. The type of prop must be a string.'
          );
        }

        if (
          !(this.isType(gridInfo.x) === 'number') ||
          !(this.isType(gridInfo.y) === 'number') ||
          !(this.isType(gridInfo.w) === 'number') ||
          !(this.isType(gridInfo.h) === 'number')
        ) {
          return console.error(
            '[dashboard-grid warn] Invalid prop: Please check the type or structure of gridInfo.x, gridInfo.y, gridInfo.w, gridInfo.h prop. The type of prop must be a number.'
          );
        }

        if (!(this.isType(gridInfo.static) === 'boolean')) {
          return console.error(
            '[dashboard-grid warn] Invalid prop: Please check the type or structure of gridInfo.static prop. The type of prop must be a boolean.'
          );
        }
      }
    },
    bindGridInfos() {
      this.gridInfos = this.datasets.map(item => item.gridInfo);
    },
    addUniqueId() {
      return this.datasets.forEach(item => {
        item.id = item.id ?? uuid.v4();
        item.chartInfo.id = item.chartInfo.id ?? uuid.v4();
        item.gridInfo.id = item.gridInfo.id ?? uuid.v4();
        item.gridInfo.i = item.gridInfo.i ?? uuid.v4();

        return item;
      });
    },
    editGraph(gridItem, chartInfo) {
      this.chartData = { gridItem, chartInfo };
      this.chartDialog = true;
    },
    isDarkMode() {
      return this.darkMode ? 'dark' : 'light';
    },
    savePreviousThemeColors(oldColors) {
      return (this.previousThemeColors = oldColors);
    },
    setDarkMode(oldColors) {
      const currentThemeOptions = {
        mode: this.isDarkMode(),
        monochrome: { ...this.monochrome },
      };

      this.datasets.forEach(item => {
        item.chartInfo.options.colors = oldColors;
        item.chartInfo.options.theme = currentThemeOptions;
        item.chartInfo.options.chart = {
          ...item.chartInfo.options.chart,
          ...this.darkModeColorOptions,
        };

        return item;
      });

      return this.addUniqueId();
    },
    setLightMode(oldColors) {
      const currentThemeOptions = {
        mode: this.isDarkMode(),
        monochrome: { ...this.monochrome },
      };

      this.datasets.forEach(item => {
        item.chartInfo.options.colors = oldColors;
        item.chartInfo.options.theme = currentThemeOptions;
        item.chartInfo.options.chart = {
          ...item.chartInfo.options.chart,
          ...this.lightModeColorOptions,
        };

        return item;
      });

      return this.addUniqueId();
    },
    setDashboard(e) {
      this.uid = e.uid;
      this.pullDashboard(this.currentAccount.uid, this.uid);
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/dashboard-grid/theme-colors';

.vue-grid-item {
  padding: 20px;
  touch-action: none;
  background-color: $color-grid-item-background;

  &:not(.vue-grid-placeholder) {
    border-radius: 13px;
    box-shadow: rgba(0, 0, 0, 0.08) 0 4px 10px;
  }

  @media screen and (max-width: 768px) {
    padding: 5px;
  }
}

.apexcharts-svg {
  border-radius: 13px;
}

.make-default {
  color: #8841eb !important;
  z-index: 100;
  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%) !important;
}

#orgs button {
  display: flex;
  align-items: center;
}
</style>
