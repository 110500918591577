<template>
  <v-card
    class="white py-4 px-4 mt-3"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <v-row>
      <v-col
        cols="12"
        sm="12"
        class="back-btn-container card"
      >
        <div class="back-btn">
          <router-link :to="{ name: 'Cases', params: { handle: $route.params.handle, key: $route.params.key } }">
            <v-icon>mdi-chevron-left</v-icon> {{ $t('backToTestCases') }}
          </router-link>
        </div>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        class="back-btn-container card ml-2"
      >
        <h2>{{ $t('repository') }}</h2>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        class="back-btn-container ml-2"
      >
        <v-chip
          class="mr-2 rounded"
          color="#E6ECFF"
        >
          {{ $t('all') }} 19
        </v-chip>
        <v-chip
          class="rounded"
          color="#F9FAFB"
        >
          {{ $t('selected') }} 0
        </v-chip>
      </v-col>
    </v-row>
    <slot name="additional-actions" />
  </v-card>
</template>
<script>
export default {
  name: 'RepositoryHeader',
  props: {
    title: String,
    actionText: String,
    hasAction: {
      type: Boolean,
      default: true,
    },
  },

};
</script>
<style>
.back-btn-container{
  display: flex;
  align-items: flex-start;
}
.back-btn a{
  text-decoration: none;
  color: #0C2FF3 !important;
  font-weight: 600;
}
.back-btn a i{
  color: #0C2FF3 !important;
}
</style>