<template>
  <v-data-table
    hide-default-footer
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table mt-6"
    :headers="headers"
    :items="items"
    :item-key="itemKey"
  >
    <template v-slot:[`header.ID`]="{ header }">
      <span class="header_text">{{ header.text }}</span>
    </template>
    <template v-slot:[`header.Name`]="{ header }">
      <span class="header_text">{{ header.text }}</span>
    </template>
    <template v-slot:[`header.Priority`]="{ header }">
      <span class="header_text">{{ header.text }}</span>
    </template>
    <template v-slot:[`header.Tag`]="{ header }">
      <span class="header_text">{{ header.text }}</span>
    </template>

    <template v-slot:[`item.uid`]="{ item }">
      <td class="d-flex align-center">
        <span>{{ item.uid }}</span>
      </td>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <td class="d-flex align-center">
        <span>{{ item.name }}</span>
      </td>
    </template>
    <template v-slot:[`item.priority`]="{ item }">
      <td class="text-start">
        <span>{{ item.priority }}</span>
      </td>
    </template>
    <template v-slot:[`item.tag`]="{ item }">
      <td class="text-start">
        <span>{{ item.tag }}</span>
      </td>
    </template>
  </v-data-table>
</template>

<script>
export default {
  props: {
    headers: Array,
    itemKey: String,
    items: Array,
  },
  methods: {
    
  }
};
</script>

<style scoped>
.header_text {
  font-weight: bold;
}

.custom-table {
  
}
</style>
