import axios from 'axios'
import Vue from 'vue'

Vue.mixin({
  data(){
    return{
      loaderState: false
    }
  },
  beforeCreate() {
    const options = this.$options;
    if (options.api)
      this.$api = options.api;
    else if (options.parent && options.parent.$api)
      this.$api = options.parent.$api;
  },
  methods:{
    async init(args){
      const delayLoader = setTimeout(() => {
        this.loaderState = true;
      },1000)
      await Promise.all([
        ...args,
      ])
      clearTimeout(delayLoader);
      await new Promise((resolve) => setTimeout(() => {
        this.loaderState = false;
        return resolve
      }, 1000))
      
    }
  }
});

export default function makeAPI(baseURL) {
  return axios.create({
    baseURL: baseURL,
    withCredentials: true,
  });
}
