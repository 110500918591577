<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    persistent
    fullscreen
    content-class="payment-method"
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('billing.addPaymentMethod') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-5"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('billing.cardNumber') }}
              </p>
              <div id="card-number" />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 mt-3"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('firstName') }}
              </p>
              <v-text-field
                v-model="paymentInfo.firstName"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('lastName') }}
              </p>
              <v-text-field
                v-model="paymentInfo.lastName"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="6"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('billing.expirationDate') }}
              </p>
              <div id="card-expiry" />
            </v-col>

            <v-col
              cols="6"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('billing.securityCode') }}
              </p>
              <div id="card-cvc" />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 mt-5"
            >
              <p class="font-weight-bold body-2 text-left mb-1">
                {{ $t('billing.billingAddress') }}
              </p>
            </v-col>

            <v-col
              cols="12"
              class="pb-0 pt-1 mt-2"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.country') }}
              </p>
              <v-select
                v-model="paymentInfo.country"
                :items="countryList"
                dense
                filled
                item-text="name"
                item-value="code"
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.addressLine', { index: 1 }) }}
              </p>
              <v-text-field
                v-model="paymentInfo.streetAddress"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.addressLine', { index: 2 }) }}
              </p>
              <v-text-field
                v-model="paymentInfo.streetAddress2"
                type="text"
                dense
                filled
              />
            </v-col>

            <v-col
              cols="6"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.city') }}
              </p>
              <v-text-field
                v-model="paymentInfo.city"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="6"
              class="pb-0 pt-1"
            >
              <p class="font-weight-medium body-2 text-left mb-1">
                {{ $t('address.postalCode') }}
              </p>
              <v-text-field
                v-model="paymentInfo.postalCode"
                type="text"
                dense
                filled
                :rules="requiredRules"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-row class="mt-3">
          <v-col cols="6">
            <v-btn
              color="gray-100"
              width="100%"
              class="text-capitalize"
              elevation="0"
              @click="$emit('close-dialog')"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-btn
              color="blue"
              width="100%"
              elevation="0"
              class="white--text text-capitalize"
              :disabled="!validForm"
              @click="$emit('add-payment-method', paymentInfo)"
            >
              {{ $t('save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { countryList } from '@/constants/address';

export default {
  name: 'AddPaymentMethod',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: false,
      paymentInfo: {
        firstName: '',
        lastName: '',
        country: '',
        streetAddress: '',
        streetAddress2: '',
        city: '',
        postalCode: '',
      },
      countryList: countryList,
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  watch: {
    showDialog(value) {
      if (!value || this.cardNumber) {
        return
      }

      setTimeout(() => {
        console.log('Render Stripe Element')
        const style = {
          base: {
            color: 'rgba(0, 0, 0, .87)',
            fontFamily: 'Avenir, "Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: 'antialiased',
            fontSize: '16px',
            '::placeholder': {
              color: '#9f9f9f',
            },
          },
        };

        this.cardNumber = this.$stripe.elements().create('cardNumber', { style });
        this.cardNumber.mount('#card-number');
        this.cardExpiry = this.$stripe.elements().create('cardExpiry', { style });
        this.cardExpiry.mount('#card-expiry');
        this.cardCvc = this.$stripe.elements().create('cardCvc', { style });
        this.cardCvc.mount('#card-cvc');
      }, 100)
    },
  },

  beforeDestroy () {
    this.cardNumber?.destroy();
    this.cardExpiry?.destroy();
    this.cardCvc?.destroy();
  },
}
</script>

<style lang="scss">
.payment-method {
  #card-number,
  #card-cvc,
  #card-expiry {
    background-color: rgba(0, 0, 0, .06);
    padding: 11px 10px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
  }
}
</style>