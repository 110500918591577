import { showConfirmationDialog, showErrorToast, showSuccessToast } from '@/utils/toast';

import i18n from '../../i18n';
import makeFoldersService from '@/services/api/folder';

const state = {
  folders: [],
};

const getters = {
  getAllFolders: (state) => state.folders,
};

const mutations = {
  SET_FOLDERS: (state, folders) => (state.folders = folders),
  ADD_FOLDER: (state, folder) => state.folders.unshift(folder),
  DELETE_FOLDER: (state, folderUid) => (state.folders = state.folders.filter((folder) => folder.uid !== folderUid)),
  UPDATE_FOLDER: (state, updatedFolder) => {
    const index = state.folders.findIndex((folder) => folder.uid === updatedFolder.uid);
    if (index !== -1) {
      state.folders.splice(index, 1, updatedFolder);
    }
  },
};

const makeActions = (api) => {
  const foldersService = makeFoldersService(api);
  return {
    get: async function ({ commit }) {
      try {
        const response = await foldersService.getFolders();
        commit('SET_FOLDERS', response.data);
      } catch (error) {
        console.error('Error fetching folders: ', error);
      }
    },
    getProjectFolders: async function ({ commit }, { projectUid, handle }) {
      try {
        const response = await foldersService.getProjectFolders(handle, projectUid);
        commit('SET_FOLDERS', response.data.folders);
      } catch (error) {
        console.error('Error fetching folders: ', error);
      }
    },
    add: async function ({ commit }, { swal, handle, payload }) {
      const response = await foldersService.createFolder(handle, payload);
      commit('ADD_FOLDER', response.data);
    },
    delete: async function ({ commit }, { swal, handle, folderUid, projectKey }) {
      try {
        await foldersService.deleteFolder(handle, projectKey, folderUid);
        commit('DELETE_FOLDER', folderUid);
      } catch (error) {
        console.error('Error deleting folder: ');
        showErrorToast(swal, i18n.t('error.folderDeleteFailed'));
      }
    },
    update: async function ({ commit }, { swal, handle, oldFolder, payload, projectKey }) {
      try {
        await foldersService.updateFolder(handle, projectKey, oldFolder.uid, payload);
        commit('UPDATE_FOLDER', { ...oldFolder, ...payload });
      } catch (error) {
        console.error('Error updating folder: ');
        showErrorToast(swal, i18n.t('error.updateFolder'));
      }
    },
  };
};

export default function makeFolder(api) {
  return {
    namespaced: true,
    getters,
    state,
    mutations,
    actions: makeActions(api),
  };
}
