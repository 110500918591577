<template>
  <v-dialog
    v-model="showDialog"
    max-width="500"
    persistent
  >
    <v-card class="pa-2">
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ $t('settingsPage.newApiKey') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <p class="text-left font-weight-light mt-3">
          {{ $t('settingsPage.copyApiKeyNotice') }}
        </p>

        <p class="text-left fs-14px text-theme-label font-weight-medium">
          {{ $t('settingsPage.apiKey') }}
        </p>

        <v-text-field
          :value="newApiKey"
          type="text"
          dense
          filled
          readonly
        >
          <template v-slot:append>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <DuplicateIcon
                  class="cursor-pointer"
                  v-on="on"
                  @click="copyKey"
                />
              </template>
              {{ $t('common.copy') }}
            </v-tooltip>
          </template>
        </v-text-field>

        <v-checkbox
          v-model="isSavedKey"
          class="field-theme"
          hide-details
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
        >
          <template v-slot:label>
            <span class="fs-14 text-theme-label">{{ $t('settingsPage.savedApiKeyQuestion') }}</span>
          </template>
        </v-checkbox>
      </v-card-text>

      <v-card-actions class="pb-3">
        <v-row>
          <v-col
            cols="6"
            offset="6"
          >
            <v-btn
              color="blue"
              width="100%"
              elevation="0"
              height="40"
              class="white--text text-capitalize rounded-lg"
              :disabled="!isSavedKey"
              @click="$emit('close-dialog')"
            >
              {{ $t('common.gotIt') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { showSuccessToast, showErrorToast } from '@/utils/toast';
import DuplicateIcon from '@/assets/svg/duplicate.svg'; 

export default {
  name: 'NewApiKeyCopyDialog',
  components: {
    DuplicateIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    newApiKey: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isSavedKey: false,
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  methods: {
    copyKey() {
      navigator.clipboard
        .writeText(this.newApiKey)
        .then(() => {
          showSuccessToast(this.$swal, this.$t("settingsPage.savedApiKey"));
        }, (err) => {
          showErrorToast(this.$swal, err);
        })
    }
  }
}
</script>