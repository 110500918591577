<template>
  <v-container fluid>
    <v-row
      align="start"
      class="my-20"
    >
      <v-col
        cols="6"
        align="start"
      >
        <h4>{{ $t("orgs") }}</h4>
      </v-col>
      <v-col
        v-if="orgs.length"
        cols="5"
        align="start"
        class="ml-50"
      >
        <v-btn
          to="/orgs/new"
          text
          small
          class="secondary"
        >
          <small class="font-weight-bold px-6">{{ $t('newOrganization') }}</small>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-if="orgs.length"
        cols="8"
        align="start"
      >
        <org-item
          v-for="org in orgs"
          :key="org.uid"
          :org="org"
          @leave="confirmLeave"
        />
      </v-col>
      <v-col
        v-else
        cols="8"
        align="center"
      >
        <v-col cols="12">
          <h3>{{ $t('noOrg') }}</h3>
        </v-col>
        <v-col cols="6">
          <p>{{ $t('toAccessDashboard') }}</p>
        </v-col>
        <v-col cols="12">
          <v-btn
            to="/orgs/new"
            color="primary"
          >
            {{ $t('startHere') }}
          </v-btn>
        </v-col>
      </v-col>
    </v-row>
    <v-row>
      <v-dialog
        id="confirm-delete"
        v-model="leaveDialog"
        title="Confirm"
        centered
        persistent
        :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '500px'"
      >
        <v-card>
          <v-card-title class="headline">
            Confirm
          </v-card-title>
          <v-card-text>
            <p
              v-if="selectedOrg"
              class="my-6 mx-12"
            >
              {{ $t('youWantToLeaveOrg', { orgname: selectedOrg.name }) }}
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />

            <v-btn
              color="primary"
              text
              @click="leaveDialog = false"
            >
              {{ $t('no') }}
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="leaveOrg(selectedOrg)"
            >
              {{ $t('yes') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar
      v-model="requestState.done"
      :timeout="3000"
      :color="requestState.hasError ? 'error' : 'success'"
      top
    >
      {{
        requestState.message }}
    </v-snackbar>
  </v-container>
</template>

<script>
import OrgItem from '@/components/Organization/ListItems/OrgItem';
import makeOrgService from '@/services/api/org';

import { createNamespacedHelpers } from 'vuex';
const { mapState, mapActions } = createNamespacedHelpers('user');

export default {
  components: { OrgItem },
  computed: {
    ...mapState({ orgs: 'orgs', user: 'user', org: 'currentAccount' }),
  },
  data() {
    return {
      leaveDialog: false,
      selectedOrg: undefined,
      requestState: {
        isLoading: false,
        hasError: false,
        data: undefined,
        message: '',
        done: false,
      },
    };
  },
  methods: {
    ...mapActions({
      setOrgs: 'setOrgs',
      setCurrentAccount: 'setCurrentAccount',
      initSettings: 'initSettings',
    }),
    confirmLeave(org) {
      this.leaveDialog = true;
      this.selectedOrg = org;
    },
    /**
     * remove user from organization
     * @param {Object} org
     */
    async leaveOrg(org) {
      try {
        const orgService = makeOrgService(this.$api);
        this.requestState.isLoading = true;
        // hit api to remove user
        await orgService.removeUser({
          handle: this.org.handle,
          memberId: this.user.uid,
        });
        // update orgs state
        let orgs = this.orgs.filter(currentOrg => org.uid !== currentOrg.uid);
        this.setOrgs(orgs);
        if (org.uid === this.currentAccount.uid) {
          this.setCurrentAccount(null);
          this.initSettings();
        }
        // update ui state
        this.requestState.message = this.$t('leftOrganization', {
          orgname: org.name,
        });
        this.requestState.hasError = false;
        this.requestState.done = true;
        this.leaveDialog = false;
      } catch (error) {
        this.requestState.message = this.$t('unableToRemoveYou', {
          orgname: org.name,
        });
        this.requestState.hasError = true;
        this.requestState.done = true;
      } finally {
        this.requestState.isLoading = false;
      }
    },
  },
};
</script>
