<template>
  <v-text-field 
    v-model="internalSearch" 
    class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
    height="40"
    background-color="#F9F9FB"
    :hide-details="true"
    :placeholder="placeholder"
    @input="$emit('update:search', internalSearch)"
  >
    <template v-slot:prepend-inner>
      <SearchIcon />
    </template>
  </v-text-field>
</template>

<script>
import SearchIcon from '@/assets/svg/search-icon.svg';
export default {
  name: 'SearchComponent',
  components: {
    SearchIcon
  },
  props: {
    search: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'Search'
    }
  },
  data() {
    return {
      internalSearch: this.search
    };
  },
  watch: {
    search(newValue) {
      this.internalSearch = newValue;
    }
  }
};
</script>
