<template>
  <div>
    <v-select
      v-model="selectedItem"
      :items="filteredItems"
      :placeholder="placeholder + '...'"
      :item-text="getItemText"
      :item-value="getItemValue"
      append-icon="mdi-chevron-down"
      :menu-props="{ offsetY: true, left: true, maxWidth: '215px !important' }"
      height="20px"
      hide-details
      flat
      class="rounded-lg pt-0 mt-0 select-item font-weight-regular select-placeholder-theme"
    >
      <!-- Search input -->
      <template
        v-if="searchActive"
        v-slot:prepend-item
      >
        <v-list-item>
          <v-list-item-content>
            <v-text-field
              v-model="searchQuery"
              class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
              :placeholder="searchPlaceholder"
              height="40"
              background-color="#F9F9FB"
              hide-details
              @input="filterItems"
            >
              <template v-slot:prepend-inner>
                <SearchIcon />
              </template>
            </v-text-field>
          </v-list-item-content>
        </v-list-item>
      </template>
  
      <!-- No data slot -->
      <template v-slot:no-data>
        <span class="font-weight-regular fs-14px text-theme-label">{{ noDataText }}
        </span>
      </template>
  
      <!-- Selected item -->
      <template v-slot:selection="{ item }">
        <div class="font-weight-regular fs-14px text-theme-label text-truncate">
          {{ getItemText(item) }}
        </div>
      </template>
  
      <!-- Item slot -->
      <template v-slot:item="{ item, attrs, on }">
        <v-list-item
          class="mh-36px cursor-pointer"
          v-bind="attrs"
          @click="on.click"
        >
          <v-list-item-content class="py-0">
            <v-list-item-title>
              <div
                v-bind="attrs"
                class="font-weight-regular fs-14px text-theme-label text-truncate"
              >
                {{ getItemText(item) }}
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-select>
  </div>
</template>
  
  <script>
  import SearchIcon from '@/assets/svg/search-icon.svg';
  export default {
    components: {
      SearchIcon,
    },
    props: {
      items: {
        type: Array,
        required: true,
      },
      placeholder: {
        type: String,
        default: 'Select',
      },
      searchPlaceholder: {
        type: String,
        default: 'Search',
      },
      noDataText: {
        type: String,
        default: 'No Matching Items',
      },
      searchActive: {
        type: Boolean,
        default: true,
      },
    },
    data() {
      return {
        selectedItem: null,
        searchQuery: '',
        filteredItems: this.items,
      };
    },
    methods: {
      getItemText(item) {
        return `${item.name}`;
      },
      getItemValue(item) {
        return item.id;
      },
      filterItems() {
      if (!this.searchQuery.trim()) {
        this.filteredItems = this.items;
      } else {
        this.filteredItems = this.items.filter((item) =>
          this.getItemText(item).toLowerCase().includes(this.searchQuery.toLowerCase())
        );
      }
    },
    },
  };
  </script>
  

  