<!-- ToastComponent.vue -->
<template>
  <v-row
    class="toast-main"
    width="356px"
    height="78px"
  >
    <v-col>
      <v-row>
        <v-col cols="3">
          <SuccessIcon />
        </v-col>
        <v-col class="message-content">
          <span>{{ message }}</span>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      class="btns"
      cols="4"
    >
      <span
        class="btn-undo pointer"
        @click="handleReverse"
      >Undo</span>
      <span
        class="btn-close pointer"
        @click="handleClose"
      >Close</span>
    </v-col>
  </v-row>
</template>

<script>
import SuccessIcon from '@/assets/svg/success-outline.svg';
export default {
  components: {
    SuccessIcon,
  },
  props: {
    message: {
      type: String,
      required: true
    },
  },
  methods: {
    handleReverse(){
      console.log("Clicked Reverse")
    },
    handleClose(){
      console.log("Clicked Close")
    }
  }
};
</script>

<style>
.toast-main {
  padding: 0;
}

.message-content{
  font-size: 14px;
  display: flex;
  align-items: center;
}

.btns{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.btn-undo{
  color: red;
  font-size: 14px;
  width: 45px !important;
  padding: 12px 3px;
}
.btn-close{
  color: blue;
  font-size: 14px;
  width: 47px !important;
  padding: 12px 3px;
}
</style>