<template>
  <v-expansion-panels
    :mandatory="mandatoryStatus"
    flat
  >
    <v-expansion-panel>
      <v-expansion-panel-header
        class="pa-0"
        @click="onHeaderClick"
      >
        <div class="d-flex justify-start align-center">
          <v-checkbox
            v-model="parentCheckbox"
            :indeterminate="isIndeterminate"
            :ripple="false"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            indeterminate-icon="icon-indeterminate"
            class="text-body-2 cw-custom"
            @change="toggleAll"
            @click="onParentCheckboxClick($event)"
          >
            <template v-slot:label>
              <span class="text-theme-label mb-2 fs-14px">{{ parentLabel }}</span>
            </template>
          </v-checkbox>
          <p class="text-body-2 mb-0 grey--text ml-custom">
            {{ description }}
          </p>
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-for="(item, index) in internalItems"
          :key="index"
          class="d-flex justify-start align-center"
        >
          <v-checkbox
            v-model="item.selected"
            class="text-body-2 cw-custom ml-5"
            :ripple="false"
            off-icon="icon-checkbox-off"
            on-icon="icon-checkbox-on"
            indeterminate-icon="icon-indeterminate"
            @change="updateParentCheckbox"
          >
            <template v-slot:label>
              <span class="text-theme-label mb-2 fs-14px">{{ item.name }}</span>
            </template>
          </v-checkbox>
          <p class="text-body-2 mb-0 grey--text ml-custom-child">
            {{ item.description }}
          </p>
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'CheckboxPanel',
  props: {
    items: {
      type: Array,
      required: true,
    },
    parentLabel: {
      type: String,
      default: 'Select All',
    },
    description: {
      type: String,
      default: '',
    },
    initial: {
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      internalItems: this.items.map(item => ({
        ...item,
        selected: this.initial.includes(item.value), // Set selected based on the initial array
      })),
      parentCheckbox: false,
      mandatoryStatus: true,
    };
  },
  computed: {
    isIndeterminate() {
      const selectedCount = this.internalItems.filter(item => item.selected).length;
      return selectedCount > 0 && selectedCount < this.internalItems.length;
    },
    getValueOnSelected() {
      return this.internalItems
        .filter(item => item.selected && item.value !== undefined && item.value !== null)
        .map(item => item.value);
    }
  },
  watch: {
    internalItems: {
      handler() {
        this.$emit('update-items', this.getValueOnSelected);
      },
      deep: true,
    },
    initial: {
      handler() {
        this.internalItems = this.items.map(item => ({
          ...item,
          selected: this.initial.includes(item.value), // Update when initial changes
        }));
      },
      deep: true,
    },
  },
  methods: {
    toggleAll() {
      const allSelected = this.parentCheckbox;
      this.internalItems.forEach(item => {
        item.selected = allSelected;
      });
    },
    updateParentCheckbox() {
      const selectedCount = this.internalItems.filter(item => item.selected).length;
      this.parentCheckbox = selectedCount === this.internalItems.length;
    },
    onHeaderClick() {
      this.mandatoryStatus = false;
    },
    onParentCheckboxClick(event) {
      event.stopPropagation();
    }
  },
};
</script>

<style scoped>
.ml-custom {
  margin-left: 20rem;
}
.ml-custom-child {
  margin-left: 300px;
}
.cw-custom {
  width: 100%;
  max-width: 160px;
}
</style>