<template>
  <v-dialog
    v-model="this.value"
    width="500"
    @click:outside="close"
  >
    <v-sheet class="ma-0 pa-0 mt-3">
      <v-row>
        <v-col>
          <h2 class="display-1 font-weight-bold text-center mb-1">
            {{ $t('deleteResource') }}
          </h2>
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col cols="12">
          <p class="mt-4">
            {{ $t('deleteResourceWarning') }}
          </p>
          <p class="text-left pl-12">
            {{ $t('resourceName') }}: <span class="font-weight-bold text-h6">{{ model }}</span>
          </p>
          <p class="text-left pl-12">
            {{ $t('id') }}: <span class="font-weight-bold text-h6">{{ resource.uid }}</span>
          </p>
        </v-col>
      </v-row>
      <v-row class="mt-3">
        <v-col>
          <v-btn
            class="right"
            color="error"
            outlined
            large
            @click="deleteResource"
          >
            {{ $t('delete') }}
            <v-progress-circular
              v-if="showLoader"
              class="ml-1"
              indeterminate
              size="16"
              width="3"
            />
          </v-btn>
          <v-btn
            class="right ml-3"
            color="success"
            text
            large
            @click="close"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-col>
      </v-row>
      <br><br>
    </v-sheet>
  </v-dialog>
</template>

<script>
import makeAdminService from '@/services/api/admin';
let service;
export default {
  props: {
    show: { type: Boolean, required: true },
    resource: { type: Object, required: true },
    model: { type: String, required: true },
  },
  emits: ['close', 'delete'],
  data: () => ({ showLoader: false }),
  computed: {
    value() {
      return this.$props.show;
    },
  },
  created() {
    service = makeAdminService(this.$api);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    deleteResource() {
      this.showLoader = true;
      service
        .archiveResourceEntry(this.model, this.resource.uid)
        .then(() => {
          this.$swal({
            title: this.$t('resourceDeleted'),
            icon: 'success',
            showConfirmButton: false,
            position: 'top-end',
            timer: 2000,
            toast: true,
          });
          this.$emit('delete');
          this.$emit('close');
        })
        .catch((err) => {
          this.$swal({
            title: err.response?.data?.message ?? this.$t('problemProcessingRequest'),
            icon: 'error',
            showConfirmButton: false,
            position: 'top-end',
            timer: 2000,
            toast: true,
          });
        });
    },
  },
};
</script>