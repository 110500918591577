<template>
  <v-container class="">
    <v-row>
      <v-col>
        <h3>{{ title }}</h3>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <div class="wrap">
          <h4 class="subtitle">
            {{ $t('description') }}
          </h4>
          <p>{{ description }}</p>
        </div>
      </v-col>
      <v-col>
        <div class="wrap">
          <h4 class="subtitle">
            {{ $t('expectedResult') }}
          </h4>
          <p>{{ expectedResult }}</p>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: {
    title: String,
    description: String,
    expectedResult: String,
  },
}
</script>
<style>
.subtitle{
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}
</style>