<template>
  <v-container
    fluid
    class="bg-white"
  >
    <v-row class="justify-space-between">
      <v-col cols="auto">
        <h2 class="text-h4 font-weight-medium font-inter py-16 pl-9 mb-0">
          {{ $t('accessTokens') }}
        </h2>
      </v-col>
      <v-col cols="auto">
        <v-row class="pr-lg-24 pr-md-12 p-xs-6">
          <v-btn
            color="purple darken-2"
            dark
            class="my-4 font-inter font-weight-medium fs-12 height-38"
            @click="$router.push('/settings/tokens/new')"
          >
            {{ $t('genatenewtoken') }}
          </v-btn>
          <v-btn
            dark
            class="my-4 font-inter font-weight-medium fs-12 height-38 ml-5"
            @click="revokeAll()"
          >
            {{ $t('revokeall') }}
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-divider />
    <p
      class="text-black font-weight-normal font-inter py-16 pl-9 mb-0"
    >
      {{ $t('Tokens you have generated that can be used to access the TestFiesta API.') }}
    </p>
    <v-container
      v-if="newAccessToken != ''"
      class="text-center py-16 px-9 mb-0"
    >
      <v-alert
        type="info"
        class="border border-blue-500 border-1 rounded"
        dismissible
        @input="newAccessToken = ''"
      >
        {{ $t('Make sure to copy your personal access token now. You won’t be able to see it again!') }}
      </v-alert>
    </v-container>
    <v-list>
      <new-pat-row
        v-if="newAccessToken != ''"
        :access-token="newAccessToken"
        @onDelete="onDeleteNewAccessToken"
      />
      <user-pat-row
        v-for="accessTokenObject in arrangedAccessTokens"
        :key="accessTokenObject.uid"
        :access-token="accessTokenObject"
        @onDelete="(accessTokenId) => { onDelete(accessTokenId); }"
      />
    </v-list>
    <v-dialog
      v-model="isDelete"
      max-width="500px"
    >
      <v-card>
        <v-card-title>
          <span
            class="font-weight-medium font-inter"
          >{{ $t('Are you sure you want to delete this token?') }}</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            type="warning"
            class="mb-4"
          >
            {{ $t('Any applications or scripts using this token will no longer be able to access the TestFiesta API. You cannot undo this action.') }}
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-2"
            text
            @click="isDelete = false"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            color="white"
            text
            @click="confirmDeleteAccessToken()"
          >
            {{ $t('I understand, delete this token') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteAll"
      max-width="30%"
    >
      <v-card class="pa-12">
        <v-card-title>
          <span
            class="font-weight-medium font-inter"
          >{{ $t('Are you sure you want to revoke access for all personal access tokens?') }}</span>
        </v-card-title>
        <v-card-text>
          <v-alert
            type="warning"
            class="mb-4"
          >
            {{ $t('This will revoke access for all personal access tokens. This action cannot be undone.') }}
          </v-alert>
          <div class="px-16">
            <p class="font-weight-bold font-inter mb-0">
              {{ $t('Type DELETE ALL to confirm') }}
            </p>
            <v-text-field
              id="deleteConfirm"
              v-model="deleteConfirm"
              name="deleteConfirm"
              class="mt-5 mb-10"
              autocomplete="off"
            />
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="red darken-2"
            text
            @click="deleteAll = false"
          >
            {{ $t('Cancel') }}
          </v-btn>
          <v-btn
            text
            :disabled="!isMatchDeleteAll"
            @click="confirmDeleteAllAccessTokens()"
          >
            {{ $t('I understand, revoke access for everything') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TokenRow from '@/components/User/AccessToken/Row';
import NewTokenRow from '@/components/User/AccessToken/NewRow';
import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapActions } = createNamespacedHelpers('accessToken');
const moduleUserHelper = createNamespacedHelpers('user');
export default {
  components: {
    'user-pat-row': TokenRow,
    'new-pat-row': NewTokenRow,
  },
  data() {
    return {
      deleteAccessTokenId: '',
      isLoading: false,
      newAccessToken: '',
      accessTokenId: '',
      isDeleteAll: false,
      deleteConfirm: '',
    };
  },
  created() {
    this.getAccessTokens(this.$store.state.user.currentAccount.handle);
    const accessToken = localStorage.getItem('newAccessToken');

    if (accessToken) {
      this.newAccessToken = accessToken;
    }
    const accessTokenId = localStorage.getItem('accessTokenId');
    if (accessTokenId) {
      this.accessTokenId = accessTokenId;
    }
  },
  computed: {
    ...mapGetters(['accessTokens']),
    ...moduleUserHelper.mapGetters(['userName']),
    isDelete: {
      get() {
        if (this.deleteAccessTokenId === '') {
          return false;
        } else {
          return true;
        }
      },
      set() {
        this.deleteAccessTokenId = '';
        return '';
      },
    },
    deleteAll: {
      get() {
        return this.isDeleteAll;
      },
      set() {
        this.isDeleteAll = false;
        return false;
      },
    },
    arrangedAccessTokens() {
      if (this.accessTokenId != '') {
        const arrangedAccessTokens = this.accessTokens.filter(e => {
          return e.uid != this.accessTokenId;
        });
        return arrangedAccessTokens;
      } else {
        return this.accessTokens;
      }
    },
    isMatchDeleteAll() {
      if (this.deleteConfirm == 'DELETE ALL') {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(['getAccessTokens', 'deleteAccessToken', 'deleteAllAccessTokens']),
    onDelete(accessTokenId) {
      this.deleteAccessTokenId = accessTokenId;
    },
    onDeleteNewAccessToken() {
      this.deleteAccessTokenId = this.accessTokenId;
    },
    confirmDeleteAccessToken() {
      this.deleteAccessToken(this.deleteAccessTokenId);
      this.deleteAccessTokenId = '';
      this.removeAccessToken();
    },
    confirmDeleteAllAccessTokens() {
      this.deleteAllAccessTokens();
      this.isDeleteAll = false;
    },
    removeAccessToken() {
      this.newAccessToken = '';
      this.accessTokenId = '';
    },
    revokeAll() {
      this.isDeleteAll = true;
    },
  },

  beforeUpdate() {
    const accessToken = localStorage.getItem('newAccessToken');
    if (accessToken) {
      localStorage.removeItem('newAccessToken');
      localStorage.removeItem('accessTokenId');
    }
  },
};
</script>

<style lang="scss">
.v-alert {
  border: 1px solid #1e40af;
}
</style>
