<template>
  <v-data-table
    :header-props="{ 'sort-icon': 'mdi-chevron-down' }"
    class="custom-table data-table-style table-fixed mt-6"
    :headers="filteredHeaders"
    :items="items"
    :item-key="itemKey"
  >
    <template v-slot:[`header.name`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.requestedBy`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.role`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.tag`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.email`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.lastActivity`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`header.project`]="{ header }">
      <span class="header_text">
        {{ header.text }}
      </span>
    </template>
    <template v-slot:[`item.role`]="{ item }">
      <td class="text-start">
        <v-menu
          offset-y
          :position-y="10"
        >
          <template #activator="{ on }">
            <div
              role="button"
              v-on="on"
            >
              {{ item?.role?.name ? item.role.name : item?.role }}
              <v-icon class="ml-3">
                mdi-chevron-down
              </v-icon>
            </div>
          </template>
          <v-list class="pt-4">
            <v-list-item
              v-for="(role,index) in roles"
              :key="index"
              class="m-0"
              @click="$emit('update-role', {uid: role.uid, name: role.name,currentRole: item.role.uid}, item.uid)"
            >
              <div class="d-flex align-start">
                <div class="d-flex align-center mt-2">
                  <span class="mr-2">{{ role.name }}</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </td>
    </template>
    <template v-slot:[`item.tag`]="{ item }">
      <td class="text-start">
        <v-menu
          offset-y
          :position-y="10"
        >
          <template #activator="{ on }">
            <div
              role="button"
              v-on="on"
            >
              {{ item?.tags?.length ? `${item.tags[0].name} ${item.tags.length > 1 ? `+${item.tags.length - 1}` : ''}` : '' }}
              <v-icon class="ml-3">
                mdi-chevron-down
              </v-icon>
            </div>
          </template>
          <v-list class="pt-4">
            <v-list-item
              v-for="tag in item?.tags"
              :key="tag.uid"
              class="m-0"
            >
              <div class="d-flex align-start">
                <div class="d-flex align-center mt-2">
                  <span class="mr-2">{{ tag.name }}</span>
                </div>
              </div>
            </v-list-item>
          </v-list>
        </v-menu>
      </td>
    </template>
    <template v-slot:[`item.email`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item?.email }}
      </span>
    </template>
    <template v-slot:[`item.lastActivity`]="{ item }">
      <span class="gray-ish--text text-subtitle-1">
        {{ item?.lastActivity }}
      </span>
    </template>
    <template v-slot:[`item.name`]="{ item }">
      <td class="d-flex align-center">
        <div class="custom-div mr-2" />
        <v-avatar
          class="mr-2"
          size="40"
        >
          <img
            :src="resolveAvatarUrl(item?.avatar_url)"
            width="100%"
            alt="logo"
          >
        </v-avatar>
        <div class="text-start">
          <div class="font-weight-bold">
            {{ item?.firstName }} {{ item?.lastName }}
          </div>
        </div>
      </td>
    </template>

    <template v-slot:[`item.deleteIcon`]="{ item }">
      <td class="d-flex align-center">
        <v-btn
          v-if="filter == 'pending'"
          icon
          @click="$emit('resend-invite', item)"
        >
          <ResendIcon style="color: #000" />
        </v-btn>
        <v-btn
          icon
          @click="$emit('edit-item', item)"
        >
          <EditIcon style="color: #000" />
        </v-btn>
        <v-btn
          icon
          @click="$emit('delete-item', item)"
        >
          <CancelIcon />
        </v-btn>
      </td>
    </template>
  </v-data-table>
</template>

<script>

import EditIcon from '@/assets/svg/edit.svg';
import ResendIcon from '@/assets/svg/resend.svg';
import CancelIcon from '@/assets/svg/cancel.svg';
import { formattedDate } from '@/utils/util';

export default {
  components: {
    EditIcon,
    ResendIcon,
    CancelIcon,
  },
  props: {
    filteredHeaders: Array,
    itemKey: String,
    items: Array,
    roles: Array,
    filter: String,
  },
  methods: {
    formattedDate,
    resolveAvatarUrl(avatarUrl) {
      return avatarUrl || require('@/assets/png/avatar.png');
    }
  },
};
</script>
