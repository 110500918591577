import { createNamespacedHelpers  } from 'vuex';
import makeHandleService from "@/services/api/handle";
import { showErrorToast } from '@/utils/toast';

const { mapActions: mapUserActions, mapGetters: mapUserGetters, mapState: mapUserState, mapMutations: mapUserMutations } = createNamespacedHelpers("user");
const { mapGetters: mapOrgsGetters, mapMutations: mapOrgsMutations } = createNamespacedHelpers("org");

let handleService;

export default {
  data(){
    return {
      priorityEntityType: null,
      statusEntityType: null
    }
  },
  created() {
    handleService = makeHandleService(this.$api);
  },
  computed: {
    ...mapUserGetters(['getUserStatusColorsByEntityType', 'getUserPriorityColorsByEntityType', 'getUserPreferences']),
    ...mapOrgsGetters(['getOrgStatusColorsByEntityType', 'getOrgPriorityColorsByEntityType', 'getOrgPreferences']),
    ...mapUserState(["currentAccount"]),
    isOrg() {
      return this.currentAccount?.type == "org" || this.currentAccount?.isOrg;
    },
    handle() {
      return this.$route.params.handle || this.currentAccount.handle;
    },
    getDefaultStatus(){
      return this.statuses.find(status => status.isDefault === true);
    },
    priorities(){
      return this.isOrg ? this.getOrgPriorityColorsByEntityType(this.handle, this.priorityEntityType) : this.getUserPriorityColorsByEntityType(this.priorityEntityType)
    },
    statuses(){
      return this.isOrg ? this.getOrgStatusColorsByEntityType(this.handle, this.statusEntityType) : this.getUserStatusColorsByEntityType(this.statusEntityType)
    }
  },
  methods: {
    ...mapOrgsMutations(["setOrgPreferences"]),
    ...mapUserActions(["setUserPreferences"]),
    getPriorities(entityType) {
      this.priorityEntityType = entityType;
    },
    getStatuses(entityType) {
      this.statusEntityType = entityType;
    },
    isStatusesHasDefault(entityType){
      const statuses = this.isOrg ? this.getOrgStatusColorsByEntityType(this.handle, entityType) : this.getUserStatusColorsByEntityType(entityType);
      return statuses.some(status => status.isDefault === true);
    },
    isPrioritiesHasDefault(entityType){
      const priorities = this.isOrg ? this.getOrgPriorityColorsByEntityType(this.handle, entityType) : this.getUserPriorityColorsByEntityType(entityType);
      return priorities.some(priority => priority.isDefault === true);
    },
    getPriorityColor(priorityId) {
      return this.priorities.find((priority) => priority.id == priorityId)?.color || this.generateRandomHexColor();
    },
    getStatusColor(statusId) {
      return this.statuses.find((status) => status.id == statusId)?.color || '#0C2FF3';
    },
    getStatusColorByName(statusName) {
      const name = statusName?.toLowerCase()?.replace(/\s+/g, '');
      return this.statuses.find((status) => status.name?.toLowerCase()?.replace(/\s+/g, '') == name)?.color || this.generateRandomHexColor();
    },
    getPriorityColorByName(priorityName) {
      const name = priorityName?.toLowerCase()?.replace(/\s+/g, '');
      return this.priorities.find((priority) => priority.name?.toLowerCase()?.replace(/\s+/g, '') == name)?.color || this.generateRandomHexColor();
    },
    getStatusName(statusId) {
      return this.statuses.find((status) => status.id == statusId)?.name || "";
    },
    getPriorityName(priorityId) {
      return this.priorities.find((priority) => priority.id == priorityId)?.name || "";
    },
    generateRandomHexColor() {
      return `#${Math.floor(Math.random() * 16777215).toString(16).padStart(6, "0")}`;
    },
    updatePreferences(handleObj){
      const handle = handleObj?.handle || this.handle;
      const currentTime = new Date().getTime();
      const fifteenMinutes = 15 * 60 * 1000;
      try {
      if(handleObj?.type == 'org'){
        let timestamp = this.getOrgPreferences(handle)?.timestamp;

        if(!timestamp || currentTime - timestamp > fifteenMinutes){
          handleService.getPreferences(handle).then((preferences) => {
            const preferencesData = preferences.data.preferences || preferences.data;
            const timestamp = new Date().getTime();
            this.setOrgPreferences({ 
              handle : handle, 
              preferences: {...preferencesData, timestamp} 
            });
          }).catch((err) => {
            showErrorToast(this.$swal, err.response?.data?.message);
          });

        } else {
          const preferences = this.getOrgPreferences(handle);
          this.setOrgPreferences({ 
            handle : handle, 
            preferences: {...preferences, timestamp} 
          });
        }
      } else {
        let timestamp = this.getUserPreferences?.timestamp;

        if(!timestamp || currentTime - timestamp > fifteenMinutes){
          handleService.getPreferences(handle).then((preferences) => {
            const preferencesData = preferences.data.preferences || preferences.data;
            const timestamp = new Date().getTime();
            this.setUserPreferences({...preferencesData, timestamp});
          }).catch((err) => {
            showErrorToast(this.$swal, err.response?.data?.message);
          });
        } else {
          const preferences = this.getUserPreferences;
          this.setUserPreferences({...preferences, timestamp});
        }

      }
      }
      catch (err) {
        showErrorToast(this.$swal, err.response?.data?.message);
      }
    }
  }
};