<template>
  <v-dialog
    v-model="showDialog"
    class="test-cases-filter-drawer dialog-theme"
    transition="slide-x-transition"
    attach
    fullscreen
    width="485px"
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ isEditMode ? $t('tagPage.editTag') : $t('tagPage.createNewTag') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
  
        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-10"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-start">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('tag') }}<strong class="red--text">*</strong>
                </v-label>
              </div>
              <v-text-field
                v-model="tag.name"
                type="text"
                dense
                background-color="#F9F9FB"
                class="field-theme"
                height="38"
                :placeholder="$t('tagPage.placeholderTag')"
                :rules="requiredRules"
              />
            </v-col>
  
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-start">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('description') }}
                </v-label>
              </div>
              <v-text-field
                v-model="tag.description"
                type="text"
                dense
                background-color="#F9F9FB"
                class="field-theme"
                height="38"
                hide-details
                :placeholder="$t('description')"
              />
            </v-col>
  
            <v-col cols="12">
              <div class="text-start">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('tagPage.type') }}
                </v-label>
              </div>
              <v-select
                :items="tagTypes"
                :value="tag.entityTypes"
                dense
                background-color="#F9F9FB"
                append-icon="mdi-chevron-down"
                :menu-props="{ offsetY: true }"
                class="rounded-lg field-theme custom-prepend mh-38px disabled-action"
                item-text="name"
                item-value="value"
                :placeholder="$t('tagPage.chooseType')"
                persistent-placeholder
                readonly
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div class="actions-container d-flex justify-space-between">
      <v-btn
        width="204.5px"
        color="#F2F4F7"
        height="40"
        :depressed="true"
        class="text-capitalize btn-theme"
        elevation="0"
        @click="onCancel()"
      >
        {{ $t('cancel') }}
      </v-btn>
  
      <v-btn
        width="204.5px"
        class="btn-theme"
        height="40"
        color="primary"
        :depressed="true"
        elevation="0"
        @click="onCreate()"
      >
        {{ isEditMode ? $t('save') : $t('create') }}
      </v-btn>
    </div>
  </v-dialog>
</template>
  
  <script>
  import { tagTypes } from '@/constants/tag.js';
  
  export default {
    name: 'CreateTagDialog',
  
    props: {
      value: {
        type: Boolean,
        default: false,
      },
  
      data: {
        type: Object,
        default: () => ({})
      },
    },
  
    data () {
      return {
        requiredRules: [
          value => !!value || this.$t('error.requiredField'),
        ],
        validForm: false,
        tagTypes: tagTypes,
        tag: {
          uid: '',
          name: '',
          description: '',
          entityTypes: '',
        },
      }
    },
  
    computed: {
      showDialog: {
        get() {
          return this.value
        },
        set(v) {
          this.$emit('input', v)
        }
      },
  
      isEditMode() {
        return !!this.tag.uid
      }
    },
  
    watch: {
      showDialog(value) {
        if (!value) {
          return
        }
      },
    },

    created() {
      this.tag.entityTypes = 'users'
    },
  
    methods: {
      onCreate() {
        const isValidForm = this.$refs.form.validate()
  
        if (!isValidForm) {
          return
        }
  
        this.$emit(this.isEditMode ? 'update-tag' : 'create-new-tag', this.tag)
      },
      onCancel() {
        this.$emit('close-dialog')
      }
    }
  }
  </script>