<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-3 py-2 d-flex flex-row">{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-expansion-panels
            v-model="priorityPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('testruns.create_testrun.priority') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(item,index) in priorities"
                  :key="index"
                  v-model="panel_priority"
                  :value="item"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <span class="fs-14px text-theme-label">{{ item }}</span>
                  </template>
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-expansion-panels
            v-model="statusPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('testruns.create_testrun.status') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(item,index) in statuses"
                  :key="index"
                  v-model="panel_status"
                  :value="item"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <span class="fs-14px text-theme-label">{{ item }}</span>
                  </template>
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          
          <div class="text-start pt-6">
            <div class="text-start mb-2">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('assignDate') }}
              </v-label>
            </div>
            <div class="d-flex align-center mb-8">
              <v-menu
                v-model="assignDateStartMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    :value="assignDateStart"
                    background-color="#F9F9FB"
                    readonly
                    height="38"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="assignDateStart"
                  @change="assignDateStartMenu = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                {{ $t('-') }}
              </div>
              <v-menu
                v-model="assignDateEndMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#F9F9FB"
                    class="text-field mt-0 field-theme pa-0 rounded-lg custom-prepend"
                    :value="assignDateEnd"
                    readonly
                    height="40"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="assignDateEnd"
                  @change="assignDateEndMenu = false"
                />
              </v-menu>
            </div>


            <div class="text-start mb-2">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('milestone.create_milestone.dueDate') }}
              </v-label>
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="dueDateStartMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    :value="dueDateStart"
                    background-color="#F9F9FB"
                    readonly
                    height="38"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dueDateStart"
                  @change="dueDateStartMenu = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                {{ $t('-') }}
              </div>
              <v-menu
                v-model="dueDateEndMenu"
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    background-color="#F9F9FB"
                    class="text-field mt-0 field-theme pa-0 rounded-lg custom-prepend"
                    :value="dueDateEnd"
                    readonly
                    height="40"
                    hide-details
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <calendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="dueDateEnd"
                  @change="dueDateEndMenu = false"
                />
              </v-menu>
            </div>


            <v-expansion-panels
              v-model="projectPanel"
              flat
              class="mb-8"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  <div class="text-start">
                    <v-label class="text-theme-label font-weight-medium">
                      {{ $t('projectsName') }}
                    </v-label>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="searchProject"
                    :placeholder="$t('search')"
                    background-color="#F9F9FB"
                    class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                    height="38"
                    dense
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <SearchIcon />
                    </template>
                  </v-text-field>
                  <v-checkbox
                    v-for="(item,index) in filterProjects"
                    :key="index"
                    v-model="panel_project"
                    :value="item.name"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ item.name }}</span>
                    </template>
                  </v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <v-expansion-panels
              v-model="tagPanel"
              flat
              class="mb-8"
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  <div class="text-start">
                    <v-label class="text-theme-label font-weight-medium">
                      {{ $t('testruns.create_testrun.tag') }}
                    </v-label>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    v-model="searchTag"
                    :placeholder="$t('search')"
                    background-color="#F9F9FB"
                    class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                    height="38"
                    dense
                    hide-details
                  >
                    <template v-slot:prepend-inner>
                      <SearchIcon />
                    </template>
                  </v-text-field>
                  <v-checkbox
                    v-for="(item,index) in filterTags"
                    :key="index"
                    v-model="panel_tag"
                    :value="item.name"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ item.name }}</span>
                    </template>
                  </v-checkbox>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{ $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          elevation="0"
          @click="apply"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import SearchIcon from '@/assets/svg/search-icon.svg';
import calendarBlueIcon from '@/assets/svg/calendar-blue.svg';

export default {
  components: {
    SearchIcon,
    calendarBlueIcon,
  },
  props: {
    projects: {
        type: Array,
        default: () => [] 
    },
    tags: {
        type: Array,
        default: () => [] 
    },
  },
  data() {
    return {
      priorities: ["High", "Medium", "Low"],
      statuses:["Active","In progress", "Passed", "Failed"],
      searchTag: null,
      searchProject: null,
      panel_priority: [],
      panel_status: [],
      selectedProjects: [],
      panel_tag: [],
      panel_project: [],
      testrun: [0, 100],

      assignDateStart: null,
      assignDateEnd: null,
      assignDateStartMenu: false,
      assignDateEndMenu: false,

      dueDateStart: null,
      dueDateEnd: null,
      dueDateStartMenu: false,
      dueDateEndMenu: false,

      showDialog: false,
      priorityPanel: 0,
      statusPanel: 0,
      tagPanel: 0,
      projectPanel: 0,
    };
  },
  computed:{
    filterTags(){
      if(!this.searchTag)
        return this.tags
      
      return this.tags.filter(tag => tag.name?.startsWith(this.searchTag))
    },
    filterProjects(){
      const activeProjects = this.projects.filter(project => project.archivedAt === null);
      if (!this.searchProject) {
        return activeProjects;
      }
      return activeProjects.filter(project =>
        project.name?.startsWith(this.searchProject)
      );
    }
  },
  methods: {
    apply() {
      this.$emit("applyFilters", {
        panel_priority: this.panel_priority,
        panel_status: this.panel_status,
        panel_tag: this.panel_tag,
        dateRange: {
          start: this.assignDateStart,
          end: this.assignDateEnd,
        },
      });
      this.showDialog = false;
    },
    clearAll() {
      this.panel_priority = [];
      this.panel_status = [];
      this.panel_tag = [];
      this.testrun = [0, 100];
      this.assignDateStart = null;
      this.assignDateEnd = null;

      this.$emit("applyFilters", {
        panel_priority: this.panel_priority,
        panel_status: this.panel_status,
        panel_tag: this.panel_tag,
        dateRange: {
          start: this.assignDateStart,
          end: this.assignDateEnd,
        },
      });
      
      this.showDialog = false;

    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>