<template>
  <v-container
    fluid
    class="bg-white"
  >
    <v-row class="text-left">
      <v-col
        cols="12"
        class="pa-5"
      >
        <span class="title text-left" />
        {{ $t('newPersonalAccessToken') }}
        <v-divider />
        <span class="subtitle-2">{{ $t('personalAccessTokensdesc') }}</span>
      </v-col>
      <v-col cols="12">
        <v-card class="elevation-0 mx-2">
          <v-card-text class="p-5">
            <ValidationObserver
              ref="observer"
              v-slot="{ handleSubmit }"
            >
              <v-form
                role="profileForm"
                @submit.prevent="handleSubmit(createNewAccessToken)"
              >
                <v-row class="form-group">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      id="newAccessTokenName"
                      v-model="newAccessTokenName"
                      name="New Token"
                      :label="$t('name')"
                      :rules="[
                        (v) => !!v || $t('required'),
                        (v) => v.length <= 255 || $t('max255'),
                      ]"
                    />
                  </v-col>
                </v-row>
                <v-row class="form-group">
                  <v-col cols="12">
                    <span class="subtitle-2">{{ $t('tokenFor') }}</span>
                  </v-col>
                  <v-col
                    v-if="orgs.length>0"
                    cols="12"
                    md="6"
                  >
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="orgs[selectedOrgIndex].name"
                          :readonly="true"
                          v-on="on"
                        />
                      </template>
                      <v-list
                        dense
                        class="text-left"
                      >
                        <v-list-item
                          v-for="(org, index) in orgs"
                          :key="org.id"
                          dense
                          @click="selectOrg(index)"
                        >
                          <v-list-item-title>{{ org.name }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
                <v-row class="form-group">
                  <v-col cols="12">
                    <span class="subtitle-2">{{ $t('expiration') }}</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-menu offset-y>
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dates[selectedIndex].title"
                          :readonly="true"
                          v-on="on"
                        />
                      </template>
                      <v-list
                        dense
                        class="text-left"
                      >
                        <v-list-item
                          v-for="date in dates"
                          :key="date.id"
                          @click="selectIndex(date.id)"
                        >
                          <v-list-item-title>{{ date.title }}</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <p v-if="selectedIndex != 4">
                      {{ expirationDate }}
                    </p>
                    <v-date-picker
                      v-if="selectedIndex == 4"
                      v-model="expTime"
                      class="pl-16"
                      :allowed-dates="allowedDates"
                    />
                  </v-col>
                  <v-col
                    v-if="selectedIndex == 5"
                    cols="12"
                  >
                    <p
                      class="text-black font-weight-medium fs-14 font-inter py-16 pl-9 text-left mb-0 border border-blue-500 border-1 rounded"
                    >
                      {{ $t('noExpiration') }}
                    </p>
                  </v-col>
                </v-row>
                <v-row class="form-group">
                  <v-col cols="12">
                    <v-btn
                      type="submit"
                      class="my-4 bg-purple-700 border-purple-700 text-white font-weight-medium fs-14 height-38"
                    >
                      {{ $t('genatenewtoken') }}
                    </v-btn>
                    <v-btn
                      id="cancel"
                      class="my-4 bg-white border-white text-blue-500 font-weight-medium fs-14 height-38 ml-10"
                      @click="$router.push('/settings/personal-access-token')"
                    >
                      {{ $t('cancel') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </ValidationObserver>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('user');
import makeAccessTokenService from '@/services/api/accessToken';
import { getUTCDateString, formatDate, getDayofWeek } from '@/utils/util';
export default {
  name: 'NewPersonalAccessToken',
  data() {
    return {
      expTime: null,
      newAccessTokenName: '',
      dates: [
        { id: 0, title: '7 days' },
        { id: 1, title: '30 days' },
        { id: 2, title: '60 days' },
        { id: 3, title: '90 days' },
        { id: 4, title: 'Custom...' },
        { id: 5, title: 'No expiration' },
      ],
      selectedIndex: 3,
      selectedOrgIndex: 0,
    };
  },
  computed: {
    ...mapState({ currentUser: 'user', orgs: 'orgs' }),
    expirationDate() {
      const today = new Date();
      let expiration = 'The token will expire on ';
      switch (this.selectedIndex) {
        case 0:
          today.setDate(today.getDate() + 6);
          expiration += getDayofWeek(today) + ', ' + formatDate(today, 'MMM dd yyyy');
          break;
        case 1:
          today.setDate(today.getDate() + 29);
          expiration += getDayofWeek(today) + ', ' + formatDate(today, 'MMM dd yyyy');
          break;
        case 2:
          today.setDate(today.getDate() + 59);
          expiration += getDayofWeek(today) + ', ' + formatDate(today, 'MMM dd yyyy');
          break;
        case 3:
          today.setDate(today.getDate() + 89);
          expiration += getDayofWeek(today) + ', ' + formatDate(today, 'MMM dd yyyy');
          break;
        case 4:
          expiration += formatDate(this.expTime);
          break;
        case 5:
          expiration = 'The token will never expire!';
          break;
        default:
          expiration = 'The token will never expire!';
          break;
      }

      return expiration;
    },
    allowedDates() {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      const allowedDates = {
        to: new Date(today.getFullYear() + 10, today.getMonth(), today.getDate()),
      };
      return allowedDates;
    },
  },
  methods: {
    selectIndex(index) {
      this.selectedIndex = index;
      if (index !== 4) {
        this.expTime = null;
      }
    },
    selectOrg(index) {
      this.selectedOrgIndex = index;
    },
    createNewAccessToken() {
      if (this.orgs.length === 0) {
        this.$swal({
          title: this.$t('createOrgNoti'),
          icon: 'info',
          showConfirmButton: false,
          position: 'top-end',
          timer: 2000,
          toast: true,
        });
      }
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      let utcDate = '';
      switch (this.selectedIndex) {
        case 0:
          today.setDate(today.getDate() + 7);
          utcDate = getUTCDateString(today);
          break;
        case 1:
          today.setDate(today.getDate() + 30);
          utcDate = getUTCDateString(today);
          break;
        case 2:
          today.setDate(today.getDate() + 60);
          utcDate = getUTCDateString(today);
          break;
        case 3:
          today.setDate(today.getDate() + 90);
          utcDate = getUTCDateString(today);
          break;
        case 4:
          {
            this.expTime.setHours(0);
            this.expTime.setMinutes(0);
            this.expTime.setSeconds(0);
            this.expTime.setDate(this.expTime.getDate() + 1);
            utcDate = getUTCDateString(this.expTime);
          }
          break;
        case 5:
          utcDate = 'no expiration';
          break;
        default:
          break;
      }

      const expirationDate = utcDate;
      const accessTokenService = makeAccessTokenService(this.$api);
      try {
        const orgUid = this.orgs[this.selectedOrgIndex].uid;
        accessTokenService
          .newUserAccessToken({ newAccessTokenName: this.newAccessTokenName, expirationDate, orgUid })
          .then(res => {
            localStorage.setItem('newAccessToken', res.data.accessToken);
            localStorage.setItem('accessTokenId', res.data.accessTokenId);
            this.$router.push('/settings/tokens');
          })
          .catch(error => {
            this.$swal({
              title: this.$t('failedToken'),
              icon: 'error',
              showConfirmButton: false,
              position: 'top-end',
              timer: 2000,
              toast: true,
            });
          });
      } catch (error) {
        console.log(error);
        this.$swal({
          title: this.$t('failedToken'),
          icon: 'error',
          showConfirmButton: false,
          position: 'top-end',
          timer: 2000,
          toast: true,
        });
      }
    },
  },
};
</script>

<style lang="scss">
.form-group {
  margin-bottom: 5px;
}

#expiration::after {
  color: red;
  content: '*';
}

.dropdown-container {
  #dropdown-1 {
    margin: 8px 0 !important;
    min-width: 150px;

    .dropdown-toggle {
      border: 1px solid #d1d5db;
      text-align: left;
      position: relative;
    }

    .dropdown-toggle::after {
      position: absolute;
      top: 15px;
      right: 10px;
    }
  }
}
</style>