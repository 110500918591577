<template>
  <v-dialog
    v-model="showDialog"
    max-width="400"
    height="224"
    persistent
  >
    <v-card class="px-2 pt-8 font-inter rounded-lg">
      <v-card-text class="black--text pb-0 pr-1">
        <div class="d-flex flex-row align-start justify-space-between">
          <h2 class="black--text text-left pt-2 font-24">
            {{ $t('organization.leaveOrganizationConfirm') }}
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <p class="text-body-2 text-left font-weight-light mt-3 font-inter">
          {{ $t('organization.willLostData') }}
        </p>
      </v-card-text>

      <v-card-actions class="pb-8">
        <v-row>
          <v-col cols="6">
            <v-btn
              color="gray-100"
              width="100%"
              class="text-capitalize"
              elevation="0"
              @click="showDialog = false"
            >
              {{ $t('cancel') }}
            </v-btn>
          </v-col>

          <v-col cols="6">
            <v-hover v-slot="{ hover }">
              <v-btn
                :color="hover ? 'primary' : 'error'"
                width="100%"
                elevation="0"
                class="custom-btn white--text text-capitalize"
                @click="onLeaveOrganization()"
              >
                {{ $t('discard_confirm') }}
              </v-btn>
            </v-hover>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LeaveOrganizationConfirmDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      validForm: false,
      password: '',
      visiblePassword: false,
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  },

  methods: {
    onLeaveOrganization() {
      this.$emit('leave')
    }
  }
}
</script>

<style scoped>
.font-24 {
  font-size: 24px;
}
.custom-btn {
  transition: background-color .4s ease-in-out;
}
</style>