<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-3 py-2 d-flex flex-row">{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="close()"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <v-expansion-panels
            v-model="typesPanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('customFieldPage.dataType') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(item, index) in dataTypes"
                  :key="index"
                >
                  <v-checkbox
                    v-model="selectedTypes"
                    :value="item.type"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ item.name }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-model="datePanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('creationDate') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row>
                  <v-col
                    cols="6"
                    class="pr-1"
                  >
                    <v-menu
                      v-model="showStartDateCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="startDate"
                          single-line
                          class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                          readonly
                          v-bind="attrs"
                          background-color="#F9F9FB"
                          clearable
                          height="38"
                          hide-details
                          :placeholder="$t('customFieldPage.startDate')"
                          v-on="on"
                        >
                          <template v-slot:prepend-inner>
                            <calendarBlueIcon />
                          </template>
                        </v-text-field> 
                      </template>
                      <v-date-picker
                        v-model="startDate"
                        :max="today"
                        @input="showStartDateCalendar = false"
                      />
                    </v-menu>
                  </v-col>

                  <v-col
                    cols="6"
                    class="pl-1"
                  >
                    <v-menu
                      v-model="showEndDateCalendar"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="endDate"
                          single-line
                          class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                          readonly
                          v-bind="attrs"
                          background-color="#F9F9FB"
                          clearable
                          height="38"
                          hide-details
                          :placeholder="$t('customFieldPage.endDate')"
                          v-on="on"
                        >
                          <template v-slot:prepend-inner>
                            <calendarBlueIcon />
                          </template>
                        </v-text-field>
                      </template>
                      <v-date-picker
                        v-model="endDate"
                        :max="today"
                        @input="showEndDateCalendar = false"
                      />
                    </v-menu>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-if="dataSources.length > 0"
            v-model="sourcesPanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                <div class="text-start">
                  <v-label class="text-theme-label font-weight-medium">
                    {{ $t('customFieldPage.dataSource') }}
                  </v-label>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-checkbox
                  v-for="(item, index) in dataSources"
                  :key="index"
                  v-model="selectedSources"
                  :value="item"
                  class="field-theme"
                  :ripple="false"
                  off-icon="icon-checkbox-off"
                  on-icon="icon-checkbox-on"
                  :hide-details="true"
                >
                  <template v-slot:label>
                    <span class="fs-14px text-theme-label">{{ item }}</span>
                  </template>
                </v-checkbox>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{ $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme white--text text-capitalize"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="apply"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { formatDate } from '@/utils/util';
import calendarBlueIcon from '@/assets/svg/calendar-blue.svg';
import { dataTypes } from '@/constants/customField.js';

export default {
  name: 'CustomFieldFilterDialog',
  components: {
    calendarBlueIcon,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        types: [],
        sources: [],
        startDate: null,
        endDate: null,
      })
    },
    dataSources: {
      type: Array,
      default: () => [],
    }
  },

  data() {
    return {
      showDialog: false,
      typesPanel: 0,
      dataTypes: dataTypes,
      selectedTypes: [],
      sourcesPanel: 0,
      selectedSources: [],
      datePanel: 0,
      showStartDateCalendar: false,
      startDate: null,
      showEndDateCalendar: false,
      endDate: null,
    };
  },

  computed: {
    today() {
      return formatDate(new Date(), 'yyyy-MM-dd')
    }
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      this.selectedTypes = this.data.types || []
      this.selectedSources = this.data.sources || []
      this.startDate = this.data.startDate
      this.endDate = this.data.endDate
    },
  },

  methods: {
    apply() {
      this.$emit('update-filter-condition', {
        types: this.selectedTypes,
        sources: this.selectedSources,
        startDate: this.startDate,
        endDate: this.endDate,
      });

      this.showDialog = false;
    },

    clearAll() {
      this.selectedTypes = []
      this.selectedSources = []
      this.startDate = null
      this.endDate = null
    },

    close() {
      this.showDialog = false;
    }
  }
}
</script>

<style lang="scss">
.custom-field-filter-dialog {
  .v-input.v-text-field {
    .v-input__slot {
      padding-right: 4px !important;
    }
  }
}
</style>