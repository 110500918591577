<template>
  <v-menu
    v-model="menuVisible"
    offset-y
    content-class="custom_ele rounded-lg shadow-theme"
    nudge-bottom="8"
    max-width="260"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        :color="buttonColor"
        full-width
        height="40"
        :depressed="true"
        class="text-capitalize rounded-lg fw-semibold btn-theme"
        elevation="0"
        :text="buttonTypeText"
        :class="{
          'disabled-action': buttonLoading,
        }"
        v-on="on"
      >
        <v-icon>mdi-plus</v-icon>
        <span>{{ buttonLabel }}</span>
      </v-btn>
    </template>
  
    <v-list
      dense
      class="text-left"
      flat
    >
      <v-list-item>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
        >
          <div class="d-flex flex-column">
            <div class="text-left">
              <v-label class="text-left fs-14px text-theme-label font-weight-medium">
                {{ label }}
              </v-label>
              <v-text-field
                v-model="newConfigurationName"
                :rules="nameRules"
                height="38"
                background-color="#F9F9FB"
                :placeholder="placeholder"
                class="field-theme rounded-lg mt-0 pt-1"
              />
            </div>
            <div class="d-flex justify-space-between">
              <v-btn
                width="110px"
                color="#F2F4F7"
                height="40"
                :depressed="true"
                class="text-capitalize fw-semibold btn-theme mr-3"
                elevation="0"
                @click="cancel"
              >
                {{ cancelButtonText }}
              </v-btn>
              <v-btn
                width="110px"
                class="btn-theme fw-semibold ml-3"
                height="40"
                color="primary"
                :depressed="true"
                elevation="0"
                @click="apply"
              >
                {{ applyButtonText }}
              </v-btn>
            </div>
          </div>
        </v-form>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
  
  <script>
  export default {
    name: "ButtonWithMenu",
    props: {
      buttonLabel: {
        type: String,
      },
      buttonColor: {
        type: String,
      },
      label: {
        type: String,
      },
      placeholder: {
        type: String,
      },
      cancelButtonText: {
        type: String,
      },
      applyButtonText: {
        type: String,
      },
      value: {
        type: Boolean,
        default: false,
      },
      buttonTypeText: {
        type: Boolean,
        default: false,
      },
      buttonLoading: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        newConfigurationName: "",
        valid: true,
        nameRules: [
          (v) => !!v || "Name is required",
        ],
      };
    },
    computed: {
      menuVisible: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit("input", val); 
        },
      },
      isFormValid() {
        return this.nameRules.every((rule) => rule(this.newConfigurationName) === true);
      },
    },
    methods: {
      apply() {
        if (this.$refs.form.validate()) {
          this.$emit("apply", this.newConfigurationName);
          this.menuVisible = false;
          this.newConfigurationName = "";
        }
      },
      cancel() {
        this.$emit("cancel");
        this.menuVisible = false;
        this.newConfigurationName = "";
      },
    },
  };
  </script>