<template>
  <v-expansion-panels
    v-model="panel"
    flat
  >
    <v-expansion-panel>
      <v-expansion-panel-header class="mx-0 px-0">
        <div class="d-flex justify-space-between align-center">
          <div>
            <v-checkbox
              class="field-theme mt-0"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
              hide-details
              @click.native.stop
            >
              <template v-slot:label>
                <span class="fs-14 text-theme-label">{{ title }}</span>
              </template>
            </v-checkbox>
          </div>
          <ButtonWithMenu
            v-model="addConfigurationMenuItems"
            button-color="primary"
            :button-label="addLabel"
            :label="$t('name')"
            :placeholder="$t('testruns.create_testrun.egOSorBrowsersDescription')"
            :cancel-button-text="$t('cancel')"
            :apply-button-text="$t('add')"
            button-type-text
            :button-loading="buttonLoading"
            @cancel="cancelAddConfigurationItems"
            @apply="applyAddConfigurationItems"
          />
        </div>
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <v-text-field
          v-model="search"
          class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
          :placeholder="searchPlaceholder"
          height="40"
          background-color="#F9F9FB"
          :hide-details="true"
        >
          <template v-slot:prepend-inner>
            <SearchIcon />
          </template>
        </v-text-field>

        <v-checkbox
          v-for="(item, index) in filteredItems"
          :key="item + index"
          v-model="selectedItems"
          :value="item"
          class="field-theme"
          :ripple="false"
          off-icon="icon-checkbox-off"
          on-icon="icon-checkbox-on"
          :hide-details="true"
          @change="emitSelectedItems"
        >
          <template v-slot:label>
            <span class="fs-14px text-theme-label">{{ item }}</span>
          </template>
        </v-checkbox>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import SearchIcon from '@/assets/svg/search-icon.svg';
import ButtonWithMenu from '@/components/base/Forms/CustomButton/ButtonWithMenu.vue';

export default {
    components: {
        SearchIcon,
        ButtonWithMenu
    },
    props: {
        uid: {
            type: String,
        },
        title: {
            type: String,
        },
        items: {
            type: Array,
            required: true,
        },
        addLabel: {
            type: String,
            default: 'Add',
        },
        searchPlaceholder: {
            type: String,
            default: 'Search',
        },
        value: {
            type: [Array, String],
            default: () => [],
        },
        buttonLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            panel: 0,
            search: '',
            selectedItems: this.value,
            addConfigurationMenuItems: false,
        };
    },
    computed: {
        filteredItems() {
            if (this.search) {
                return this.items.filter((item) =>
                    item.toLowerCase().includes(this.search.toLowerCase())
                );
            }
            return this.items;
        },
        filteredItemsEmpty() {
            return this.filteredItems.length === 0;
        },
        configurationItems() {
            return this.items;
        },
    },
    methods: {
        emitSelectedItems() {
            this.$emit('input', this.selectedItems);
        },
        async applyAddConfigurationItems(newConfigurationName) {
            const items = [
                ...this.configurationItems,
                newConfigurationName
            ];

            this.$emit('addItem', {
              uid: this.uid, 
              name: this.title, 
              items: items, 
              description: ''
            });
        },
        cancelAddConfigurationItems() {
            this.addConfigurationMenuItems = false;
        },
        
    },
};
</script>

<style scoped></style>