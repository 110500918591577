import { createNamespacedHelpers } from 'vuex';

const { mapState: mapProjectState } = createNamespacedHelpers('project');

export default {
  computed: {
    ...mapProjectState(['projects']),

    selectedProjectFromRoute() {
      return this.projects.find((project) => project.key === this.$route.params.key);
    },

    isProjectArchived() {
      if(!this.$route.params.key) return false; 
      return this.selectedProjectFromRoute?.archivedAt !== null;
    },
  },

};
