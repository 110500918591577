export const testResultFileTypes = [
  'image/png',
  'image/jpeg',
  'application/pdf',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv'
];

export const projectImageTypes = [
  'image/png',
  'image/jpeg'
]

export const orgImageTypes = [
  'image/png',
  'image/jpeg'
]

export const profileImageTypes = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/jpg'
]