<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-3 py-2 d-flex flex-row">{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <v-expansion-panels
            v-model="rolesPanel"
            flat
            class="mb-5"
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('priority') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <div
                  v-for="(priority, index) in priorities"
                  :key="index"
                >
                  <v-checkbox
                    v-model="priority.selected"
                    :value="priority.selected"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ priority.text }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <v-expansion-panels
            v-model="tagsPanel"
            flat
          >
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('tags') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  v-model="searchTerm"
                  class="text-field mr-3 mt-0 rounded-lg field-theme custom-prepend pa-0"
                  :placeholder="$t('search')"
                  height="40"
                  background-color="#F9F9FB"
                  :hide-details="true"
                >
                  <template v-slot:prepend-inner>
                    <SearchIcon />
                  </template>
                </v-text-field>
                <div
                  v-for="(tag, index) in searchedTags"
                  :key="index"
                >
                  <v-checkbox
                    :value="isSelected(tag)"
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :hide-details="true"
                    @change="toggleTagSelection(tag)"
                  >
                    <template v-slot:label>
                      <span class="fs-14px text-theme-label">{{ `#${tag.name}` }}</span>
                    </template>
                  </v-checkbox>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearFilters"
        >
          {{ $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="apply"
        >
          {{ $t('apply') }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { priorities } from '@/constants/grid.js';
import SearchIcon from '@/assets/svg/search-icon.svg';
import makeTagService from '@/services/api/tag';
import { showErrorToast } from '@/utils/toast';

export default {
  name: 'TestCasesFilter',
  components: {
    SearchIcon
  },
  data() {
    return {
      rolesPanel: 0,
      projectsPanel: 0,
      tagsPanel: 0,
      rolesMenu: false,
      projectsMenu: false,
      tagsMenu: false,
      showDialog: false,
      priorities: Object.values(priorities),
      searchTerm: '',
      tags: [],
      selectedTags: []
    };
  },
  computed: { 
    searchedTags(){
      return this.searchTerm.length ? this.tags.filter(tag => tag.name.toLowerCase().includes(this.searchTerm.toLowerCase())) : this.tags
    }
  },

  async mounted() {
    await this.fetchTags();

  },
  methods: {
    apply() {
      const selectedPriorities = this.priorities.filter(priority => priority.selected);
      const filters = {
        priorities: selectedPriorities.map(item => item.value),
        tags: this.selectedTags.map(item => item.name)
      };

      this.$emit('filters', filters);
      this.showDialog = false;
    },

    clearFilters() {
      this.$emit('filters', { priorities: [], tags: [] });

      this.priorities = this.priorities.map(priority => ({ ...priority, selected: false }));
      this.tags = this.tags.map(tag => ({ ...tag, selected: false }));

      this.searchTerm = '';

      this.showDialog = false;
    },

    isSelected(tag) {
      return this.selectedTags.some(selectedTag => selectedTag.uid === tag.uid);
    },

    toggleTagSelection(tag) {
      if (this.isSelected(tag)) {
        this.selectedTags = this.selectedTags.filter(
          selectedTag => selectedTag.uid !== tag.uid
        );
      } else {
        this.selectedTags.push(tag);
      }
    },

    async fetchTags() {
      try {
        const tagService = makeTagService(this.$api);
        const response = await tagService.getTags(this.$route.params.handle, 'cases');
        this.tags = response.data;

      } catch (error) {
        console.error('Error fetching tags:', error);
        showErrorToast(this.$swal, 'fetchError', { item: 'tags' });
      }
    },

  }
};
</script>