<template>
  <v-menu
    content-class="custom_ele rounded-lg shadow-theme menu-options"
    nudge-bottom="50"
    min-width="191"
    :close-on-content-click="false"
    left
  >
    <template v-slot:activator="{ on }">
      <v-sheet
        color="#F2F4F7"
        width="40px"
        class="d-flex align-center justify-center cursor-pointer v-sheet-theme"
        height="40px"
        rounded="lg"
        :outlined="true"
        v-on="on"
      >
        <GearIcon />
      </v-sheet>
    </template>
    <v-list :flat="true">
      <h4 class="font-weight-regular text-start ml-4 mb-4 mt-2">
        {{ $t('columns') }}
      </h4>
      <template v-for="(item, index) in filteredMenuHeaders">      
        <v-list-item 
          v-if="item.text" 
          :key="index" 
          dense
        >
          <v-list-item-action start>
            <v-checkbox 
              v-model="item.checked" 
              :value="item.checked" 
              class="field-theme"
              :ripple="false"
              off-icon="icon-checkbox-off"
              on-icon="icon-checkbox-on"
              @change="handleItemChange"
            >
              <template v-slot:label>
                <span class="fs-14 text-theme-label">{{ item.text }}</span>
              </template>
            </v-checkbox>
          </v-list-item-action>
        </v-list-item>
      </template>
      <v-list-item
        class="pl-5 mt-2 fw-semibold px-0 fs-14px"
        :ripple="false"
        :class="{ 'text-theme-primary': hasUnchecked, 'text-theme-disabled': !hasUnchecked }"
        @click="restore"
      >
        {{ $t('restoreDefault') }}
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import GearIcon from '@/assets/svg/gear.svg';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    GearIcon
  },
  props: {
    requiredItems: Array,
    tableType: {
      type: String, 
      default: ''
    }
  },
  data() {
    return {
      filterItems: [],
    }
  },
  computed: {
    ...mapGetters({
      dynamicHeaders: 'headers/dynamicHeaders'
    }),
    filteredMenuHeaders() {
      return this.filterItems.filter((header) => header.value != 'actions');
    },
    hasUnchecked() {
      return this.filterItems.some(item => !item.checked);
    },
  },
  created() {
    if (!this.dynamicHeaders[this.tableType]) {
      this.initializeHeaders({ type: this.tableType });
    }
    this.filterItems = this.dynamicHeaders[this.tableType];
    this.updateHeaders({ type: this.tableType, headers: this.filterItems });
  },
  methods: {
    ...mapActions("headers", ['updateHeaders', 'resetHeaders', 'initializeHeaders']),
    handleItemChange() {
      this.updateHeaders({ type: this.tableType, headers: this.filterItems });
    },
    restore() {
      this.filterItems.forEach((item) => {
        item.checked = this.requiredItems?.includes(item.text) ?? true;
      });
      this.resetHeaders({ type: this.tableType, defaultHeaders: this.filterItems });
    },
  }
};
</script>

<style>
.menu-options {
  z-index: 19 !important;
}
</style>
