<template>
  <v-dialog
    v-model="isOpenStatus"
    max-width="480"
    class="dialog-theme"
    content-class="shadow-theme"
    @click:outside="clickOutside"
  >
    <v-card
      class="bg-white rounded-lg"
    >
      <v-card-text class="py-8 px-sm-10">
        <v-flex class="d-flex align-start">
          <p class="ma-0 font-weight-bold text-h6 text-sm-h5 text-start black--text">
            {{ title }}
          </p>
          <v-icon
            class="mt-1 ml-4 pointer"
            @click="$emit('closeDialog')"
          >
            mdi-close
          </v-icon>
        </v-flex>
        <slot name="content">
          <v-flex class="mt-4">
            <p class="text-start text-theme-secondary">
              {{ $t('testruns.test_case.bulk_remove.content') }}
            </p>
          </v-flex>
        </slot>
        <slot name="footer">
          <v-row>
            <v-col cols="6">
              <v-btn
                width="194px"
                color="#F2F4F7"
                full-width
                height="40"
                depressed
                class="text-capitalize btn-theme"
                elevation="0"
                @click="$emit('closeDialog')"
              >
                {{ $t('projects.create_project.close_dialog.cancel_button') }}
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                width="194px"
                class="btn-theme text-capitalize white--text"
                height="40"
                color="#F2284E"
                :depressed="true"
                full-width
                elevation="0"
                @click="$emit('confirm')"
              >
                {{ $t('remove') }}
              </v-btn>
            </v-col>
          </v-row>
        </slot>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    isOpen: Boolean,
    title:String,
  },
  data() {
    return {
      isOpenStatus: this.isOpen
    }
  },
  watch: {
    isOpen(newVal) {
      this.isOpenStatus = newVal
    }
  },
  methods: {
    clickOutside() {
      this.$emit('closeDialog')
    }
  }
}
</script>
