<template>
  <v-row
    align-content="start"
    justify="start"
    dense
    class=""
  >
    <div
      :style="{ width: isTreeViewCollapsed ? '5%' : '15%' }"
      class="mt-4"
    >
      <div
        class="d-flex flex-column white card rounded-lg ml-1 mr-2 sticky-on-scroll"
        :class="{ 'is-collapsed-menu': isTreeViewCollapsed }"
      >
        <TreeView
          :items="folders"
          :selected-folder-uid="selectedFolderUid"
          :collapsed="isTreeViewCollapsed ? 0 : 1"
          :hide-create="showCreate"
          @folder-selected="selectFolder"
          @folder-delete="deleteFolder"
        />
        <div
          v-if="showCollapse"
          class="collapse-btn"
          @click="toggleMenu"
        >
          <v-icon
            color="#0C2FF3"
          >
            {{ isTreeViewCollapsed ? 'mdi-arrow-right-bottom' : 'mdi-arrow-left-bottom'
            }}
          </v-icon>
          <span
            v-if="!isTreeViewCollapsed"
            class="collapse-text"
          >{{ $t('collapse') }}
          </span>
        </div>
      </div>
    </div>
    <v-col
      class=""
      :style="{ width: isTreeViewCollapsed ? '95%' : '85%' }"
    >
      <v-row
        align-content="start"
        justify="start"
        dense
      >
        <div
          :class="[isDetailViewCollapsed ? 'col-7' : 'col-12']"
          class="col mt-3"
        >
          <cases-list
            id="cases-list"
            :case-items="filterCases"
            :initial-selected="runCases"
            :bread-crumbs="breadCrumbs"
            :allow-action="allowAction"
            :is-repository="!quickCreate"
            :from-run="fromRun"
            :selected-case.sync="selectedCase"
            @expandDetail="openDetailView"
            @createCase="onCreateCase"
            @updateSelectedCases="updateSelectedCases"
            @selectedCases="handleCases"
            @updateCasesHistoryData="updateCasesHistoryData"
          />
          <slot name="control-area" />
        </div>
        <div
          v-if="isDetailViewCollapsed"
          class="col-5 mt-3"
        >
          <detail-view
            :case-item="selectedCase"
            :is-selected-case-first-index="isCurrentSelectedCasesFirstIndex"
            :is-selected-case-last-index="isCurrentSelectedCasesLastIndex"
            :selected-folder="breadCrumbs"
            :from-run="fromRun"
            @closeDetail="closeDetailView"
            @viewPreviousCase="viewPreviousCase"
            @viewNextCase="viewNextCase"
          />
        </div>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import CasesList from '@/views/Tests/Case/List/Index.vue';
import TreeView from '@/views/Tests/Case/Tree/Index.vue';
import DetailView from '@/views/Tests/Case/Detail/Index.vue';
import { showErrorToast } from '@/utils/toast';
import makeCasesService from '@/services/api/case';
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('user');
export default {
  components: {
      CasesList,
      TreeView,
      DetailView
  },
  props:{
    /*
      showCreate: Enables the creation of new folders.
      showCollapse: Hides the collapse folder option.
      selectOption: Returns selected cases only when set to true.
      allowAction: Activates the action button for selected cases.
    */
    fromRun:{
      type: Boolean,
      default: false,
    },
    showCreate:{
      type: Boolean,
      default: true,
    },
    showCollapse:{
      type: Boolean,
      default: true
    },
    selectOption:{
      type: Boolean,
      default: false,
    },
    allowAction:{
      type: Boolean,
      default: true
    },
    quickCreate:{
      type: Boolean,
      default: false
    },
    cases:{
      type: Array,
      default: () => {
        return []
      },
      required: true
    },
    runCases:{
      type: Array,
      default: () => {
        return []
      }
    },
    folders:{
      type: Array,
      default: () =>{
        return []
      },
      required: true,
    }
  },
  data() {
    return {
      isTreeViewCollapsed: false,
      isDetailViewCollapsed: false,
      selectedItem : null,
      breadCrumbs: [],
      selectedCase: {},
      selectedCases: [],
      selectedFolder: {},
      folderUid: null,
    };
  },
  computed: {
  ...mapState(['currentAccount']),
  selectedFolderUid:{
    get(){
      return this.folderUid ?? this.$route.params.folderUid;
    },
    set(value){

      this.folderUid = value 
    }
  },
  filterCases(){
    if(this.selectOption) {
      return this.selectedCases
    }  
    return this.cases.filter(item => item.active === true);
  },
    currentSelectedCasesIndex(){
      return this.filterCases.indexOf(this.selectedCase)
    },
    isCurrentSelectedCasesFirstIndex(){
      return this.currentSelectedCasesIndex == 0
    },
    isCurrentSelectedCasesLastIndex(){
      return this.currentSelectedCasesIndex == this.filterCases.length - 1
    },
    nextCase(){
      return this.filterCases[this.currentSelectedCasesIndex + 1]
    },
    previousCase(){
      return this.filterCases[this.currentSelectedCasesIndex - 1]
    }
  },
  async mounted(){
    await new Promise(resolve => setTimeout(() => {
      this.buildBreadCrumbs(parseInt(this.$route.params.folderUid), this.folders, []);
      resolve();
    }, 1000))
  },
  methods: {
    // For Testrun
    toggleMenu() {
      this.isTreeViewCollapsed = !this.isTreeViewCollapsed
    },
    changeExpansion(item) {
      this.selectedItem = item
      this.isDetailViewCollapsed = true;
    },
    updateCasesHistoryData(data) {
      this.$emit('updateCasesHistoryData', data)
    },
    closeDetailView() {
      this.selectedCase = {};
      this.isDetailViewCollapsed = false;
    },
    handleCases(selectedCases){
      this.selectedCases = selectedCases
      this.$emit('selectedCases', selectedCases)
      this.$emit('updateCaseCount', selectedCases.length, this.cases.length)
    },
    viewPreviousCase() {
      this.selectedCase = this.previousCase
    },
    viewNextCase() {
      this.selectedCase = this.nextCase
    },
    buildBreadCrumbs(searchUID, currentFolders, chain) {
      for (let idx = 0; idx < currentFolders.length; idx++) {
        let folder = currentFolders[idx];
        if (folder.uid === searchUID) {
          chain.push({ text: folder.name });
          this.breadCrumbs = chain;
          break;
        } else if (folder.children && folder.children.length > 0) {
          let newChain = [...chain, { text: folder.name }];
          this.buildBreadCrumbs(searchUID, folder.children, newChain);
        }
      }
    },
    async selectFolder(folder) {
      this.selectedFolder = folder;
      let folderUID = folder
      if (folderUID) {
        this.selectedFolderUid = folderUID.uid ? folderUID.uid : folderUID
      } else {
        folderUID = this.selectedFolderUid
      }


      this.buildBreadCrumbs(folderUID, this.folders, []);
      this.isDetailViewCollapsed = false;
      this.$emit('folder-select', folderUID)
    },
    async onCreateCase(caseItem) {
      const caseService = makeCasesService(this.$api);
      try {
        const res = await caseService.createTestCase(
          this.$route.params.handle,
          this.$route.params.key,
          caseItem
        );

        if (res.status == 200) {
          let newCaseItem = res.data;
          // Emit an event to update cases instead of modifying the prop directly

          if (Array.isArray(newCaseItem)) {
            this.$emit('update-cases', [...newCaseItem, ...this.cases]);
          } else {
            this.$emit('update-cases', [newCaseItem, ...this.cases]);
          }
        } else {
          showErrorToast(this.$swal, 'createError', { item: 'Case' });
        }
      } catch (error) {

        showErrorToast(this.$swal, 'createError', { item: 'Case' });
      }
    },
    openDetailView(item) {
      this.selectedCase = item;
      this.isDetailViewCollapsed = true;
    },
    deleteFolder(){
      this.$emit('update-cases', [])
    },
    updateSelectedCases(payload) {
      this.$emit('updateSelectedCases', payload)
    },
  }
}
</script>
<style scoped>
.sticky-on-scroll {
  position: -webkit-sticky;
  position: sticky;
  top: 12px;
  height: calc(100vh - 24px);
}
</style>
