<template>
  <div class="d-flex justify-center align-center flex-column">
    <img
      :src="imageSrc"
      alt="Empty state image" 
      class="placeholder-img mb-6" 
      :style="'max-width: ' + imageMaxWidth"
    >
    <div>
      <div class="d-flex flex-column align-center">
        <p class="ma-0 font-weight-bold fs-24px">
          {{ title }}
        </p>
        <slot name="description" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: {
        imageSrc: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        imageMaxWidth: {
            type: String,
            default: '480px',
        },
    },
};
</script>
<style scoped>
.placeholder-img {
  width: 100%;
}
</style>