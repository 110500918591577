<template>
  <v-card
    class="white py-6 px-6"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex align-start justify-space-between">
      <div class="d-flex flex-column align-start">
        <button
          plain
          class="btn-nav-back font-inter mb-3"
          @click="$emit('back-to-roles')"
        >
          <v-icon color="blue">
            mdi-chevron-left
          </v-icon>
          <span class="d-flex-inline justify-center align-center ma-0 blue--text">
            {{ $t('backToRoles') }}
          </span>
        </button>

        <h2>{{ roleName }}</h2>
        <p class="blue-grey--text pa-0 mb-0">
          {{ roleDescription }}
        </p>
      </div>
      <v-btn
        icon
        @click="$emit('back-to-roles')"
      >
        <v-icon color="black">
          mdi-close
        </v-icon>
      </v-btn>
    </div>
  </v-card>
</template>
  
  <script>
  export default {
    name: 'RoleMembersHeader',
    
    props: {
        role: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        roleName() {
            return this.role?.name;
        },
        roleDescription() {
            return this.role?.description;
        },
    },
  }
  </script>
  