<template>
  <v-card
    class="white pt-4 px-6 pb-0  mb-2"
    rounded="lg"
    elevation="0"
    width="100%"
  >
    <div class="d-flex flex-row align-center justify-space-between">
      <h2 class="text-theme-base">
        {{ $t(title) }}
      </h2>
    </div>
    <div class="d-flex align-center justify-start ml-0 py-4">
      <v-chip
        :class="{ 'blue--text': filterType === 'statusColors' }"
        width="115px"
        :color="filterType === 'statusColors' ? '#e6ecff' : '#f9fafb'"
        label
        @click="updateTab('statusColors')"
      >
        <div :class="{'font-weight-bold': filterType === 'statusColors', 'px-2': true }">
          {{ $t('Statuses') }} <span class="ml-2">{{ totalStatusCount }}</span>
        </div>
      </v-chip>
      <div class="ml-2">
        <v-chip
          :class="{ 'blue--text': filterType === 'priorityColors' }"
          width="115px"
          :color="filterType === 'priorityColors' ? '#e6ecff' : '#f9fafb'"
          label
          @click="updateTab('priorityColors')"
        >
          <div :class="{'font-weight-bold': filterType === 'priorityColors', 'px-2': true }">
            {{ $t('Priorities') }} <span class="ml-2">{{ totalPriorityCount }}</span> 
          </div>
        </v-chip>
      </div>
    </div>
    <slot name="additional-actions" />
  </v-card>
</template>

<script>
export default {
  name: 'PersonalizationHeader',

  props: {
    title: String,
    actionText: String,
    filterType: String,
    updateTab: Function,
    totalStatusCount: Number,
    totalPriorityCount: Number,
  },
};
</script>

<style scoped>
.horizontal-margin {
  margin: 0px 10px;
}

.tray-btn-margin {
  min-width: 40px !important;
  width: 40px !important;
  padding: 10px 0px !important;
}

.tray-btn-outline {
  border-radius: 8px;
  box-shadow: none;
}
</style>
