<template>
  <!-- CTODO - move this to generic BaseRow with data passed into it -->
  <v-col class="pl-0 pr-0">
    <v-row class="ml-0 mr-0 justify-space-between">
      <v-col cols="auto">
        <p class="mb-0">
          {{ accessToken.name }}
        </p>
      </v-col>
      <v-col cols="auto">
        <v-btn
          class="text-red-100 border-gray-300 font-weight-medium fs-12"
          @click="deleteAccessToken()"
        >
          Delete
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ml-0 mr-0">
      <ExIcon
        v-if="getExpiration == 0 || getExpiration == 1"
        class="activeImg width-15 height-15 mt-2 mr-5"
      />

      <v-col cols="auto">
        <p
          v-if="getExpiration == 1 || getExpiration == 3"
          class="font-weight-medium fs-14 font-inter text-left mb-0 pr-5"
          :class="{ 'text-yellow-100': getExpiration == 1 }"
        >
          Expires
        </p>
        <p
          v-if="getExpiration == 2"
          class="font-weight-medium fs-14 font-inter text-left mb-0 pr-5"
        >
          Expired
        </p>
        <p
          class="font-weight-medium fs-14 font-inter font-italic text-left mb-0"
          :class="{
            'text-yellow-100': parsedExpiration == noExpire || getExpiration == 1,
            'font-italic': parsedExpiration != noExpire,
          }"
        >
          {{ parsedExpiration }}
        </p>
      </v-col>
    </v-row>
  </v-col>
</template>

<script>
import { utcToLocalDate, formatDate, getDayofWeek, compareDates } from '@/utils/util';
import ExIcon from '@/assets/svg/danger.svg';
export default {
  components: {
    ExIcon,
  },
  props: {
    accessToken: Object,
  },
  data() {
    return {
      noExpire: 'This token has no expiration date.',
    };
  },
  computed: {
    parsedExpiration() {
      if (this.accessToken.expires_at === 'no expiration') {
        return this.noExpire;
      } else {
        const localDate = utcToLocalDate(this.accessToken.expires_at);
        localDate.setDate(localDate.getDate() - 1);
        const strDate = 'on ' + getDayofWeek(localDate) + ', ' + formatDate(localDate, 'MMM dd yyyy') + '.';
        return strDate;
      }
    },
    getExpiration() {
      if (this.accessToken.expires_at === 'no expiration') {
        return 0; // no expiration
      } else {
        const localDate = utcToLocalDate(this.accessToken.expires_at);
        localDate.setDate(localDate.getDate() - 1);
        const today = new Date();
        const value = compareDates(today, localDate);
        if (value === 0 || value === 3) {
          // same dates
          return 1;
        } else if (value === 1) {
          // expired
          return 2;
        } else {
          // value = 2: not expired yet
          return 3;
        }
      }
    },
  },
  methods: {
    deleteAccessToken() {
      this.$emit('onDelete', this.accessToken.uid);
    },
  },
};
</script>

<style lang="scss" scoped>
.activeImg {
  path {
    fill: #ffad50;
  }
}
.activetext {
  color: #ffad50;
}
</style>