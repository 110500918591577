<template>
  <v-tiptap
    ref="tiptap"
    v-model="content"
    class="tiptap-theme"
    :toolbar="toolbarOptions"
  >
    <template #bold="{ editor }">
      <BoldIcon 
        class="d-flex align-center"
        @click="editor.commands.toggleBold"
      />
    </template>
    <template #italic="{ editor }">
      <ItalicIcon 
        class="d-flex align-center"
        @click="editor.commands.toggleItalic"
      />
    </template>
    <template #strike="{ editor }">
      <StrikeIcon 
        class="d-flex align-center"
        @click="editor.commands.toggleStrike"
      />
    </template>
    <template #bulletList="{ editor }">
      <BulletListIcon 
        class="d-flex align-center"
        @click="editor.commands.toggleBulletList"
      />
    </template>
    <template #orderedList="{ editor }">
      <OrderedListIcon
        class="d-flex align-center" 
        @click="editor.commands.toggleOrderedList"
      />
    </template>
    <template #bottom>
      <div class="tiptap-footer">
        <div class="d-flex align-center justify-space-between w-full">
          <div class="d-flex align-center">
            <AttachmentsIcon
              class="mr-1"
              @click="addAttachment"
            />
            <input
              ref="fileInput"
              type="file"
              style="display: none;"
              @change="handleFileUpload"
            >
            <LinkIcon
              class="mr-1"
              @click="toggleLink"
            />
            <AtIcon class="mr-1" />
            <HashtagIcon class="mr-1" />
          </div>
          <div class="d-flex align-center">
            <slot name="action" />
          </div>
        </div>
      </div>
    </template>
  </v-tiptap>
</template>

<script>
import BoldIcon from '@/assets/svg/tiptap/bold.svg';
import ItalicIcon from '@/assets/svg/tiptap/italic.svg';
import StrikeIcon from '@/assets/svg/tiptap/strike.svg';
import BulletListIcon from '@/assets/svg/tiptap/bullet-list.svg';
import OrderedListIcon from '@/assets/svg/tiptap/ordered-list.svg';
import AttachmentsIcon from '@/assets/svg/tiptap/attachments.svg';
import LinkIcon from '@/assets/svg/tiptap/link.svg';
import AtIcon from '@/assets/svg/tiptap/at.svg';
import HashtagIcon from '@/assets/svg/tiptap/hashtag.svg';

export default {
  name: 'TiptapEditor',
  components: {
    BoldIcon,
    ItalicIcon,
    StrikeIcon,
    BulletListIcon,
    OrderedListIcon,
    AttachmentsIcon,
    LinkIcon,
    AtIcon,
    HashtagIcon,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      files: [], 
      editor: null, 
      toolbarOptions: [ '#bold', '#italic', '#strike', '|', '#bulletList', '#orderedList' ],
    };
  },
  computed: {
    content: {
      get() {
        return this.value;
      },
      set(newValue) {
        this.$emit('input', newValue);
      },
    },
  },
  created() {
    this.$nextTick(() => {
      this.editor = this.$refs.tiptap.editor;
    });
  },
  methods: {
    addAttachment() {
      this.$refs.fileInput.click();
    },
    handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.files.push(file);
        this.$emit('files-added', this.files);
        const reader = new FileReader();
        reader.onload = (e) => {
          const content = e.target.result;
          if (file.type.startsWith('image/')) {
            this.$refs.tiptap.editor.commands.setImage({ src: content });
          } else {
            this.$refs.tiptap.editor.commands.insertContent(
              `<div class="file-type">Uploaded file: ${file.name}</div>`
            );
          }
        };
        reader.readAsDataURL(file);
      }
    },
    toggleLink() {
      const previousUrl = this.editor.getAttributes('link').href;
      const url = prompt('Enter the URL', previousUrl);
      if (url === null) {
        return;
      }
      if (url === '') {
        this.editor.commands.unsetLink();
        return;
      }
      this.editor.commands.setLink({ href: url });
    },
  },
};
</script>

