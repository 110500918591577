<template>
  <div>
    <!-- Attachments Field -->
    <v-file-input
      v-model="localAttachments"
      :label="$t('attachments')"
      :placeholder="$t('chooseFile')"
      :accept="acceptedFileTypes"
      :multiple="multiple"
      outlined
      flat
      hide-details
      @change="handleFileSelection"
    />

    <!-- Thumbnails and Progress Bars -->
    <v-row class="mx-8 my-6">
      <v-col
        v-for="(file, index) in localAttachments"
        :key="index"
        cols="12"
        md="3"
        class="elevation-1 ma-1 rounded-lg position-relative"
      >
        <!-- Image Thumbnails -->
        <v-img
          v-if="thumbnails[index]"
          :src="thumbnails[index].url"
          class="thumbnail-border"
          aspect-ratio="1"
        />

        <!-- Remove File Icon -->
        <v-icon
          small
          class="position-absolute top-0 right-0 mt-1 mr-1"
          @click="deleteAttachment(index)"
        >
          mdi-close-circle
        </v-icon>

        <!-- Circular Progress Bar -->
        <v-progress-circular
          v-if="uploadStatuses[index]?.uploading"
          :size="35"
          :width="3"
          :value="uploadProgress[index]"
          color="primary"
          class="center-progress"
        />

        <!-- Error Message -->
        <div
          v-if="uploadStatuses[index]?.error"
          class="upload-error"
          @click="retryUpload(index)"
        >
          <v-btn icon>
            <v-icon color="red">
              mdi-reload
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from 'axios';
import { uuid } from 'vue-uuid';
import makeAttachment from '@/services/api/attachment';

export default {
  props: {
    maxFiles: {
      type: Number,
      default: 5,
    },
    attachments: {
      type: Array,
      default: () => [],
    },
    acceptedFileTypes: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      thumbnails: [],
      localAttachments: this.attachments,
      uploadStatuses: {},
      uploadProgress: {},
    };
  },
  watch: {
    localAttachments: {
      handler(newAttachments) {
        this.$emit('update:attachments', newAttachments);
        this.generateThumbnails(newAttachments);
      },
      deep: true,
    },
  },
  mounted() {
    this.loadExistingAttachments();
  },
  methods: {
    async handleFileSelection() {
      try {
        this.resetUploadStatuses();
        for (const file of this.localAttachments) {
          const fileInfo = {
            fileName: file.name,
            size: file.size,
            type: file.type,
            mediaType: 'attachment',
          };
          const upload = await this.requestSignedUrl(fileInfo);
          await this.uploadFile(file, upload.upload_url, upload.client_headers);
        }
      } catch (error) {
        console.error('Error processing files:', error);
      }
    },
    removeFile(index) {
      this.localAttachments.splice(index, 1);
      // Remove corresponding thumbnail
      this.thumbnails.splice(index, 1);

      // Remove the upload status and progress for the file
      this.$delete(this.uploadStatuses, index);
      this.$delete(this.uploadProgress, index);

      // Since we've modified arrays, let's update the remaining statuses and progress
      this.localAttachments.forEach((_, i) => {
        if (this.uploadStatuses[i] !== undefined) {
          this.$set(this.uploadStatuses, i, this.uploadStatuses[i]);
          this.$set(this.uploadProgress, i, this.uploadProgress[i]);
        }
      });
    },

    async requestSignedUrl(file) {
      const attachmentService = makeAttachment(this.$api);
      const { data } = await attachmentService.getSignedAttachmentUrl(file);
      return data;
    },

    generateThumbnails(files) {
      this.thumbnails = [];
      for (let file of files) {
        if (file.type.startsWith('image/')) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.thumbnails.push({
              url: e.target.result,
            });
          };
          reader.readAsDataURL(file);
        } else {
          this.thumbnails.push({
            url: '/public/image/favicon.png',
          });
        }
      }
    },

    resetUploadStatuses() {
      this.uploadStatuses = this.localAttachments.reduce((statuses, file, index) => {
        statuses[index] = { uploading: false, error: null };
        return statuses;
      }, {});
    },

    async uploadFile(file, signedUrl, headers) {
      const index = this.localAttachments.indexOf(file);
      this.$set(this.uploadStatuses, index, { uploading: true, error: null });
      this.$set(this.uploadProgress, index, 0);

      try {
        await axios.put(signedUrl, file, {
          headers,
          onUploadProgress: (progressEvent) => {
            const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
            this.$set(this.uploadProgress, index, progress);
          },
        });
        this.$set(this.uploadStatuses, index, { uploading: false, error: null });
        this.$emit('update:attachment', file); // Notify parent of successful upload
      } catch (error) {
        console.error('Error uploading file:', error);
        this.$set(this.uploadStatuses, index, { uploading: false, error: 'Failed to upload. Click to retry.' });
      }
    },

    retryUpload(index) {
      const file = this.localAttachments[index];
      this.uploadFile(file, index);
    },

    loadExistingAttachments() {
      // Assuming `this.attachments` contains URLs of existing attachments
      this.thumbnails = this.attachments;
    },
    async deleteAttachment(index) {
      const file = this.thumbnails[index];
      // Remove from local state
      this.removeFile(index);

      const attachmentService = makeAttachment(this.$api);

      // Send delete request to the server
      try {
        await attachmentService.deleteAttachment(file);
      } catch (error) {
        console.error('Error deleting file:', error);
      }
    },
  },
};
</script>
<style scoped>
.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.mt-1 {
  margin-top: 0.25rem; /* Adjust as needed */
}

.mr-1 {
  margin-right: 0.25rem; /* Adjust as needed */
}

.center-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  /* Adjust these next two lines based on the size of the progress circle */
  transform: translate(-50%, -50%);
}

.upload-error {
  color: red;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 100%; /* Ensure it spans the width of the column */
}
</style>
