<template>
  <v-menu
    bottom
    left
    :close-on-content-click="false"
    :nudge-right="40"
    transition="scale-transition"
  >
    <template #activator="{ on }">
      <v-avatar>
        <v-icon
          v-if="!currentAccount.avatar_url"
          large
        >
          mdi-account-circle
        </v-icon>
        <img
          v-else
          :src="currentAccount.avatar_url"
        >
      </v-avatar>
      <!-- <v-btn v-if="currentAccount" variant="text" class="text-secondary" size="small" v-on="on">{{ $t('switchAccount')}}</v-btn> -->
      <v-btn
        class="text-secondary"
        size="small"
        text
        dense
        v-on="on"
      >
        {{ currentAccount.handle }}
      </v-btn>
    </template>
    <v-list
      v-if="selectableAccounts.length > 0"
      dense
      class="text-left"
    >
      <v-list-item
        v-for="account in selectableAccounts"
        :key="account.uid"
        @click="selectAccount(account)"
      >
        <v-list-item-icon>
          <v-icon v-if="!account.avatar_url">
            mdi-account-circle
          </v-icon>
          <img
            v-else
            :src="account.avatar_url"
          >
        </v-list-item-icon>
        <v-list-item-content v-if="account && account.handle">
          <v-list-item-title>{{ account.handle }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapGetters } = createNamespacedHelpers('user');

export default {
  props: {
    currentAccount: { type: Object, required: true }
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters(['accounts']),
    selectableAccounts() {
      return this.accounts.filter(a => a.handle != this.currentAccount.handle)
    }
  },
  methods: {
    ...mapActions(['setCurrentAccount']),
    selectAccount(account) {
      this.setCurrentAccount(account);
      this.filterAccounts(account);
    },
  },
};
</script>

<style lang="scss" scoped>
.activetext {
  color: #6d28d9;
}

.activeImg {
  path {
    stroke: #6d28d9;
  }
}

.accounts-menu>ul {
  width: 100% !important;
}

.accounts-box {
  box-sizing: border-box;
  max-width: 110%;
  display: flex;
  flex-wrap: wrap;
  margin-right: -1.875rem;
  background-color: rgba(55, 65, 81, 1);
  margin-left: -0.75rem;
}
</style>
<style>
.v-menu__content .v-list-item--active .v-list-item__title {
  color: #6d28d9;
}
</style>