<template>
  <div>
    <v-row
      align="start"
      justify="center"
      class="my-12"
    >
      <v-col
        cols="12"
        sm="12"
        md="9"
        class="mx-2"
      >
        <v-row>
          <v-col
            v-show="$vuetify.breakpoint.xsOnly"
            cols="12"
            sm="12"
            md="8"
          >
            <v-responsive>
              <AccountCard
                v-if="user"
                :accounts="accounts"
                :current-account="{
                  isOrg: false,
                  name: user.first_name + ' ' + user.last_name,
                  handle: user.username,
                  ...user,
                }"
              />
            </v-responsive>
          </v-col>
          <v-col
            v-show="$vuetify.breakpoint.xsOnly"
            cols="12"
            sm="12"
            md="8"
            class="mx-2"
          >
            <v-responsive>
              <ProfileDropDown :menus="sidebars" />
            </v-responsive>
          </v-col>
          <v-col
            v-show="!$vuetify.breakpoint.xsOnly"
            cols="12"
            sm="3"
            md="3"
          >
            <ProfileSideBar
              :sidebars="sidebars"
              :current-account="{
                isOrg: false,
                name: user.first_name + ' ' + user.last_name,
                handle: user.username,
                ...user,
              }"
              :accounts="accounts"
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="9"
          >
            <router-view />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProfileSideBar from '@/components/Layout/ProfileSidebar/ProfileSideBar.vue';
import ProfileDropDown from '@/components/Layout/ProfileSidebar/ProfileDropDown.vue';
import AccountCard from '@/components/User/AccountCard.vue';

import { createNamespacedHelpers } from 'vuex';
const { mapGetters, mapState } = createNamespacedHelpers('user');

export default {
  computed: {
    ...mapGetters(['orgs', "accounts"]),
    ...mapState(['user']),
  },
  components: { ProfileSideBar, ProfileDropDown, AccountCard },
  data() {
    return {
      sidebars: [
        {
          id: 0,
          title: 'Settings',
          children: [
            {
              id: 0,
              subTitle: 'Profile',
              imgName: 'home.svg',
              link: '/settings/profile',
              description: 'profile',
              icon: 'mdi-home-outline',
            },
            {
              id: 1,
              subTitle: 'Account',
              imgName: 'account.svg',
              link: '/settings/account',
              description: 'account',
              icon: 'mdi-account-group-outline',
            },
            {
              id: 2,
              subTitle: 'Notifications',
              imgName: 'calendar.svg',
              link: '/settings/notifications',
              description: 'notifications',
              icon: 'mdi-bell-outline',
            },
            {
              id: 3,
              subTitle: 'Billing',
              imgName: 'home.svg',
              link: '/settings/billing',
              description: 'billing',
              icon: 'mdi-credit-card-outline',
            },
            {
              id: 4,
              subTitle: 'Organizations',
              imgName: 'calendar.svg',
              link: '/settings/organizations',
              description: 'organizations',
              icon: 'mdi-domain',
            },
          ],
        },
        {
          id: 2,
          title: 'Security',
          children: [
            {
              id: 0,
              subTitle: 'Access tokens',
              imgName: 'calendar.svg',
              link: '/settings/tokens',
              description: 'Access Token',
              icon: 'mdi-key-outline',
            },
          ],
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.bm-burger-button {
  top: 65px;
  left: 15px;
  width: 25px;
  height: 25px;
}

.bm-menu {
  background-color: white;
}
</style>
