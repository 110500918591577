<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40px"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-3 py-2 d-flex flex-row">{{ $t('filters') }} <v-icon
        size="16px"
        class="ml-2"
      >mdi-filter-variant</v-icon></span>
    </v-sheet>
    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="showDialog = false"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>
          <div>
            <div class="text-start mt-4 mb-1">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('numberOfTestRuns') }}
              </v-label>
            </div>
            <v-range-slider
              v-model="testRunsCountRange"
              class="slider-theme"
              color="blue"
              track-color="#F2F4F7"
              thumb-color="#FFFFFF"
              hide-details
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="testRunsCountRange[0]"
                background-color="#F9F9FB"
                class="field-theme pt-0"
                height="38"
                hide-details
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                v-model="testRunsCountRange[1]"
                background-color="#F9F9FB"
                class="field-theme pt-0"
                height="38"
                hide-details
              />
            </div>
          </div>
          <div>
            <div class="text-start mt-4 mb-1">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('numberOfTestCases') }}
              </v-label>
            </div>
            <v-range-slider
              v-model="testCasesCountRange"
              class="slider-theme"
              color="blue"
              track-color="#F2F4F7"
              thumb-color="#FFFFFF"
              hide-details
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="testCasesCountRange[0]"
                background-color="#F9F9FB"
                class="field-theme pt-0"
                height="38"
                hide-details
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                v-model="testCasesCountRange[1]"
                background-color="#F9F9FB"
                class="field-theme pt-0"
                height="38"
                hide-details
              />
            </div>
          </div>
          <!-- <div>
            <div class="mb-1 text-start mt-4">{{ $t('numberOfDefects') }}</div>
            <v-range-slider style="height: 50px;" color="blue" v-model="defects"></v-range-slider>

            <div class="d-flex align-center">
              <v-text-field
                value="3"
                color="blue"
                class="mr-0 custom_input"
              />
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-text-field
                value="25"
                color="blue"
                class="mr-0 custom_input"
              />
            </div>
          </div> -->
          <!-- <v-expansion-panels flat v-model="panel">
            <v-expansion-panel>
              <v-expansion-panel-header class="mx-0 px-0">
                {{ $t('projectAdmin') }}
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-text-field
                  color="blue"
                  prepend-inner-icon="mdi-magnify"
                  :placeholder="$t('search')"
                  class="custom_input mx-0"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Nate"
                  color="blue"
                  dense
                  label="Nate"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Alex"
                  color="blue"
                  dense
                  label="Alex"
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Mandy N."
                  color="blue"
                  dense
                  label="Mandy N."
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Maria A."
                  color="blue"
                  dense
                  label="Maria A."
                />
                <v-checkbox
                  v-model="selectedTags"
                  value="Boris N."
                  color="blue"
                  dense
                  label="Boris N."
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels> -->
          <div class="text-start pt-6">
            <div class="text-start mt-4 mb-1">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('lastChanges') }}
              </v-label>
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="lastChangesStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.lastChanges.start"
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    readonly
                    background-color="#F9F9FB"
                    height="38"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <CalendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date.lastChanges.start"
                  @input="lastChangesStart = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-menu
                v-model="lastChangesEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.lastChanges.end"
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    readonly
                    background-color="#F9F9FB"
                    height="38"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <CalendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date.lastChanges.end"
                  :min="date.lastChanges.start"
                  @input="lastChangesEnd = false"
                />
              </v-menu>
            </div>
            <div class="text-start mt-4 mb-1">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('creationDate') }}
              </v-label>
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="creationDateStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.creationDate.start"
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    readonly
                    background-color="#F9F9FB"
                    height="38"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <CalendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date.creationDate.start"
                  @input="creationDateStart = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-menu
                v-model="creationDateEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.creationDate.end"
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    readonly
                    background-color="#F9F9FB"
                    height="38"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <CalendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date.creationDate.end"
                  :min="date.creationDate.start"
                  @input="creationDateEnd = false"
                />
              </v-menu>
            </div>

            <div v-if="$store.state.user.currentAccount.type === 'org'">
              <div class="text-start mt-4 mb-1">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('numberOfUsers') }}
                </v-label>
              </div>
              <v-range-slider
                v-model="users"
                class="slider-theme"
                color="blue"
                track-color="#F2F4F7"
                thumb-color="#FFFFFF"
                hide-details
              />
              <div class="d-flex align-center">
                <v-text-field
                  v-model="users[0]"
                  background-color="#F9F9FB"
                  class="field-theme pt-0"
                  height="38"
                  hide-details
                />
                <div class="mx-4 font-weight-bold text-h6">
                  -
                </div>
                <v-text-field
                  v-model="users[1]"
                  background-color="#F9F9FB"
                  class="field-theme pt-0"
                  height="38"
                  hide-details
                />
              </div>
            </div>

            <v-expansion-panels
              v-if="$store.state.user.currentAccount.type === 'org'"
              v-model="panel1"
              flat
            >
              <v-expansion-panel>
                <v-expansion-panel-header class="mx-0 px-0">
                  {{ $t('users') }}
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-text-field
                    color="blue"
                    class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                    height="40"
                    background-color="#F9F9FB"
                    :hide-details="true"
                    :placeholder="$t('search')"
                  >
                    <template v-slot:prepend-inner>
                      <SearchIcon />
                    </template>
                  </v-text-field>
                  <v-checkbox
                    v-for="member in filteredMembers"
                    :key="member.uid"
                    v-model="selectedTags"
                    :value="`${member.firstName} ${member.lastName}`"
                    dense
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :label="`${member.firstName} ${member.lastName}`"
                  />
                  <v-checkbox
                    v-model="selectedTags"
                    value="Project."
                    color="blue"
                    dense
                    class="field-theme"
                    :ripple="false"
                    off-icon="icon-checkbox-off"
                    on-icon="icon-checkbox-on"
                    :label="$t('show_only_project')"
                  />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>

            <div class="text-start mt-4 mb-1">
              <v-label class="text-theme-label font-weight-medium">
                {{ $t('toast.noActivity') }}
              </v-label>
            </div>
            <div class="d-flex align-center">
              <v-menu
                v-model="noActivityStart"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.noActivity.start"
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    background-color="#F9F9FB"
                    readonly
                    height="38"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <CalendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date.noActivity.start"
                  @input="noActivityStart = false"
                />
              </v-menu>
              <div class="mx-4 font-weight-bold text-h6">
                -
              </div>
              <v-menu
                v-model="noActivityEnd"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date.noActivity.end"
                    dense
                    single-line
                    class="text-field field-theme mt-0 pa-0 rounded-lg custom-prepend"
                    background-color="#F9F9FB"
                    readonly
                    height="38"
                    hide-details
                    v-bind="attrs"
                    v-on="on"
                  >
                    <template v-slot:prepend-inner>
                      <CalendarBlueIcon />
                    </template>
                  </v-text-field>
                </template>
                <v-date-picker
                  v-model="date.noActivity.end"
                  :min="date.noActivity.start"
                  @input="activeOnlyEnd = false"
                />
              </v-menu>
            </div>
          </div>
        </v-card-text>
      </v-card>
      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{
            $t('clearAll') }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme text-capitalize"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="apply"
        >
          {{
            $t('apply')
          }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import { dateMixin } from '@/mixins/date.js';
import dayjs from 'dayjs';
import CalendarBlueIcon from '@/assets/svg/calendar-blue.svg';
import SearchIcon from '@/assets/svg/search-icon.svg';

export default {
  name: "ProjectFilter",
  components: {
    CalendarBlueIcon,
    SearchIcon,
  },
  mixins: [dateMixin],
  props: {
    filteredMembers: Array,
  },
  data() {
    return {
      panel: 0,
      testRunsCountRange: [0, 100],
      testCasesCountRange: [0, 100],
      // defects: [0, 100],
      users: [0, 100],
      selectedTags: [],
      panel1: 0,
      date: {
        lastChanges: {
          start: '',
          end: '',
        },
        creationDate: {
          start: this.formatDate(dayjs()),
          end: this.formatDate(dayjs()),
        },
        noActivity: {
          start: '',
          end: '',
        },
      },
      lastChangesStart: false,
      lastChangesEnd: false,
      creationDateStart: false,
      creationDateEnd: false,
      noActivityStart: false,
      noActivityEnd: false,
      showDialog: false,
    };
  },
  methods: {
    apply() {
      const queryParams = {
        creationStartDate: this.date.creationDate.start,
        creationEndDate: this.date.creationDate.end,
        lastChangeStartDate: this.date.lastChanges.start,
        lastChangeEndDate: this.date.lastChanges.end,
        noActivityStartDate: this.date.noActivity.start,
        noActivityEndDate: this.date.noActivity.end,
        minTestRuns: this.testRunsCountRange[0],
        maxTestRuns: this.testRunsCountRange[1],
        minTestCases: this.testCasesCountRange[0],
        maxTestCases: this.testCasesCountRange[1],
      };
      this.$emit('applyFilters', queryParams,true);
      this.showDialog = false;
    },
    clearAll() {
      this.testRunsCountRange = [0, 100];
      this.testCasesCountRange = [0, 100];
      this.users = [0, 100];
      this.date = {
        lastChanges: {
          start: '',
          end: '',
        },
        creationDate: {
          start: new Date().toISOString().substr(0, 10),
          end: new Date().toISOString().substr(0, 10),
        },
        noActivity: {
          start: '',
          end: '',
        },
      };
      this.selectedTags = [];
      this.$emit('applyFilters', {},false);
    },
  },
};
</script>

<style>
.v-dialog--fullscreen {
  max-height: 100vh !important;
  width: 485px !important;
  right: 0 !important;
  left: auto !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 !important;
}
</style>
