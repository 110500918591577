import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export const dateMixin = {
    methods: {
        formatDate(date, formatOption = 'MM/DD/YY') {
            return dayjs(date).tz(dayjs.tz.guess()).format(formatOption);
        },
    },
};