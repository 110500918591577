<template>
  <v-container class="bg-white">
    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <h4 class="text-left ma-4">
          Public Profile
        </h4>
        <v-divider />
        <v-card
          class="elevation-0 pa-4"
          rounded
        >
          <ValidationObserver
            ref="observer"
            v-slot="{ handleSubmit }"
          >
            <v-form
              role="profileForm"
              @submit.prevent="handleSubmit(updateProfile)"
            >
              <v-text-field
                id="name"
                v-model="user.name"
                class="mb-3"
                name="Name"
                :label="$t('name')"
                :rules="[(v) => !!v || $t('required'), (v) => (v && v.length >= 2) || $t('minLength', { length: 2 })]"
                outlined
              />
              <v-text-field
                id="email"
                v-model="user.email"
                class="mb-3"
                name="email"
                type="email"
                :label="$t('enterEmail')"
                :rules="[(v) => !!v || $t('required'), (v) => (v && /.+@.+\..+/.test(v)) || $t('emailInvalid')]"
                outlined
              />

              <v-textarea
                id="bio"
                v-model="user.bio"
                class="mb-3"
                :label="$t('enterbio')"
                :rows="3"
                :max-rows="6"
                outlined
              />

              <v-text-field
                id="url"
                v-model="user.url"
                class="mb-3"
                name="url"
                :label="$t('enterurl')"
                :rules="[(v) => !!v || $t('required'), (v) => (v && v.length >= 3) || $t('minLength', { length: 3 })]"
                outlined
              />

              <v-text-field
                id="location"
                v-model="user.location"
                class="mb-3"
                name="location"
                :label="$t('enterlocation')"
                :rules="[(v) => !!v || $t('required'), (v) => (v && v.length >= 3) || $t('minLength', { length: 3 })]"
                outlined
              />
              <div class="text-left">
                <v-btn
                  id="login"
                  color="primary"
                  type="submit"
                  class="my-4 bg-purple-700 border-purple-700 text-white font-weight-medium fs-14 height-38"
                >
                  {{
                    $t('savechanges') }}
                </v-btn>
              </div>
            </v-form>
          </ValidationObserver>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        class="text-center"
        md="3"
        align="center"
        justify="center"
      >
        <v-badge
          overlap
          color="white"
        >
          <template v-slot:badge>
            <upload-avatar />
          </template>
          <v-avatar
            class="mb-4"
            size="9rem"
            color="secondary"
          >
            <img
              v-if="currentUser.avatar_url"
              :src="currentUser.avatar_url"
              alt="avatar"
            >
            <v-icon
              v-else
              size="6rem"
              color="white"
            >
              mdi-account
            </v-icon>
          </v-avatar>
        </v-badge>
        <p class="text-gray-900 font-weight-medium">
          {{ currentUser.first_name }}
          {{ currentUser.last_name }}
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
const { mapState } = createNamespacedHelpers('user');
import UploadAvatar from '../Profile/UploadAvatar.vue';

export default {
  components: { UploadAvatar },
  data() {
    return {
      profileForm: true,
      user: {
        name: '',
        email: '',
        bio: '',
        url: '',
        location: '',
      },
    };
  },
  computed: {
    ...mapState({ currentUser: 'user' }),
  },
  mounted() {
    this.user.name = `${this.currentUser.first_name} ${this.currentUser.last_name}`;
    this.user.email = this.currentUser.email
  },
  methods: {
    updateProfile() {
      console.log('Clicked Save Changes');
    },
  },
};
</script>
