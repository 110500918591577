<template>
  <div>
    <v-sheet
      color="#F2F4F7"
      class="d-flex align-center justify-center pointer"
      height="40"
      rounded="lg"
      @click="showDialog = true"
    >
      <span class="px-4">{{ $t('filters') }} <v-icon>mdi-filter-variant</v-icon></span>
    </v-sheet>

    <v-dialog
      v-model="showDialog"
      class="test-cases-filter-drawer dialog-theme"
      transition="slide-x-transition"
      attach
      fullscreen
      width="485px"
    >
      <v-card>
        <v-card-text class="black--text">
          <div class="d-flex align-center justify-space-between pt-6">
            <h2 class="black--text">
              {{ $t('filters') }}
            </h2>
            <v-btn
              icon
              @click="close()"
            >
              <v-icon color="black">
                mdi-close
              </v-icon>
            </v-btn>
          </div>

          <div class="mt-6 text-start">
            <div class="text-left">
              <v-label class="fs-14px text-theme-label font-weight-medium">
                {{ $t('sharedStepPage.numberOfSteps') }}
              </v-label>
            </div>
            <v-range-slider
              v-model="stepsNum"
              class="slider-theme mt-4"
              color="blue"
              track-color="#F2F4F7"
              thumb-color="#FFFFFF"
              hide-details
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="stepsNum[0]"
                background-color="#F9F9FB"
                class="field-theme mr-0"
                height="38"
                hide-details
                dense
              />
              <v-icon class="mx-4">
                mdi-minus
              </v-icon>
              <v-text-field
                v-model="stepsNum[1]"
                background-color="#F9F9FB"
                class="field-theme mr-0"
                height="38"
                hide-details
                dense
              />
            </div>
          </div>

          <div class="mt-6 text-start">
            <div class="text-left">
              <v-label class="fs-14px text-theme-label font-weight-medium">
                {{ $t('sharedStepPage.referencedBy') }}
              </v-label>
            </div>
            <v-range-slider
              v-model="referencesNum"
              class="slider-theme mt-4"
              color="blue"
              track-color="#F2F4F7"
              thumb-color="#FFFFFF"
              hide-details
            />
            <div class="d-flex align-center">
              <v-text-field
                v-model="referencesNum[0]"
                background-color="#F9F9FB"
                class="field-theme mr-0"
                height="38"
                hide-details
                dense
              />
              <v-icon class="mx-4">
                mdi-minus
              </v-icon>
              <v-text-field
                v-model="referencesNum[1]"
                background-color="#F9F9FB"
                class="field-theme mr-0"
                height="38"
                hide-details
                dense
              />
            </div>
          </div>
        </v-card-text>
      </v-card>

      <div class="actions-container d-flex justify-space-between">
        <v-btn
          width="204.5px"
          color="#F2F4F7"
          full-width
          height="40"
          :depressed="true"
          class="text-capitalize btn-theme"
          elevation="0"
          @click="clearAll"
        >
          {{
            $t('clearAll')
          }}
        </v-btn>
        <v-btn
          width="204.5px"
          class="btn-theme"
          height="40"
          color="primary"
          :depressed="true"
          full-width
          elevation="0"
          @click="apply"
        >
          {{
            $t('apply')
          }}
        </v-btn>
      </div>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'SharedStepFilterDialog',

  data() {
    return {
      tagsPanel: 0,
      showDialog: false,
      defaultStepRange: [2, 6],
      defaultReferenceRanage: [0, 553],
      stepsNum: [2, 6],
      referencesNum: [0, 553],
    };
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return;
      }
    },
  },

  mounted() {
    this.clearAll();
  },

  methods: {
    apply() {
      this.$emit('update-filter', false, this.stepsNum, this.referencesNum);
      this.showDialog = false;
    },

    clearAll() {
      this.$emit('update-filter', true, this.stepsNum, this.referencesNum);
      this.showDialog = false;
    },

    close() {
      this.showDialog = false;
    },
  },
};
</script>
