<template>
  <v-dialog
    v-model="showDialog"
    class="test-cases-filter-drawer dialog-theme"
    transition="slide-x-transition"
    attach
    fullscreen
    width="485px"
  >
    <v-card>
      <v-card-text class="black--text">
        <div class="d-flex align-center justify-space-between pt-6">
          <h2 class="black--text">
            {{ isEditMode ? $t('tagPage.editTag') : $t('tagPage.createNewTag') }} 
          </h2>
          <v-btn
            icon
            @click="showDialog = false"
          >
            <v-icon color="black">
              mdi-close
            </v-icon>
          </v-btn>
        </div>

        <v-form
          ref="form"
          v-model="validForm"
          lazy-validation
          class="mt-10"
        >
          <v-row>
            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-start">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('tag') }}<strong class="red--text">*</strong>
                </v-label>
              </div>
              <v-text-field
                v-model="tag.name"
                type="text"
                dense
                background-color="#F9F9FB"
                class="field-theme"
                height="38"
                :placeholder="$t('tagPage.placeholderTag')"
                :rules="requiredRules"
              />
            </v-col>

            <v-col
              cols="12"
              class="pb-0"
            >
              <div class="text-start">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('description') }}
                </v-label>
              </div>
              <v-text-field
                v-model="tag.description"
                type="text"
                dense
                background-color="#F9F9FB"
                class="field-theme"
                height="38"
                hide-details
                :placeholder="$t('description')"
              />
            </v-col>

            <v-col cols="12">
              <div class="text-start">
                <v-label class="text-theme-label font-weight-medium">
                  {{ $t('tagPage.type') }}
                </v-label>
              </div>
              <v-select
                v-model="tag.entityTypes"
                :items="tagTypes"
                dense
                background-color="#F9F9FB"
                append-icon="mdi-chevron-down"
                :menu-props="{ offsetY: true }"
                class="rounded-lg field-theme custom-prepend mh-38px"
                multiple
                item-text="name"
                item-value="value"
                :placeholder="$t('tagPage.chooseType')"
                persistent-placeholder
                :rules="requiredMultiSelectRules"
              >
                <template v-slot:selection="{ item }">
                  <div
                    class="d-flex align-center custom-chip-theme ma-1"
                  >
                    <div class="text-theme-label label text-truncate mr-1">
                      {{ item.name }}
                    </div>
                    <v-icon
                      size="16px"
                      @click="onRemoveSelectedTags(item.value)"
                    >
                      mdi-close
                    </v-icon>
                  </div>
                </template>

                <template v-slot:item="{ item, on, attrs }">
                  <v-list-item
                    :ripple="false"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-action>
                      <v-checkbox
                        hide-details
                        :input-value="tagsSelection(item.value)"
                        class="field-theme mt-0 pt-0"
                        :ripple="false"
                        off-icon="icon-checkbox-off"
                        on-icon="icon-checkbox-on"
                      >
                        <template v-slot:label>
                          <span class="fs-14px text-theme-label">{{ `${item.name}` }}</span>
                        </template>
                      </v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <div class="actions-container d-flex justify-space-between">
      <v-btn
        width="204.5px"
        color="#F2F4F7"
        height="40"
        :depressed="true"
        class="text-capitalize btn-theme"
        elevation="0"
        @click="onCancel()"
      >
        {{ $t('cancel') }}
      </v-btn>

      <v-btn
        width="204.5px"
        class="btn-theme"
        height="40"
        color="primary"
        :depressed="true"
        elevation="0"
        @click="onCreate()"
      >
        {{ isEditMode ? $t('save') : $t('create') }}
      </v-btn>
    </div>
  </v-dialog>
</template>

<script>
import { tagTypes } from '@/constants/tag.js';

export default {
  name: 'CreateUpdateTagDialog',

  props: {
    value: {
      type: Boolean,
      default: false,
    },

    data: {
      type: Object,
      default: () => ({})
    },
  },

  data () {
    return {
      requiredRules: [
        value => !!value || this.$t('error.requiredField'),
      ],
      requiredMultiSelectRules: [
        value => !!value || value.length > 0 || this.$t('error.requiredField'),
      ],
      validForm: false,
      tagTypes: tagTypes,
      tag: {
        uid: '',
        name: '',
        description: '',
        entityTypes: [],
      },
    }
  },

  computed: {
    showDialog: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      }
    },

    isEditMode() {
      return !!this.tag.uid
    }
  },

  watch: {
    showDialog(value) {
      if (!value) {
        return
      }

      if (this.data.uid) {
        // Edit mode - use existing data
        this.tag = {
          uid: this.data.uid,
          name: this.data.name || '',
          description: this.data.description || '',
          entityTypes: this.data.entityTypes || [],
        }
      } else {
        // Create mode - set all types except 'user' by default
        this.tag = {
          uid: '',
          name: '',
          description: '',
          entityTypes: this.tagTypes
            .filter(type => type.value !== 'users')
            .map(type => type.value),
        }
      }
    },
  },

  methods: {
    onCreate() {
      const isValidForm = this.$refs.form.validate()

      if (!isValidForm) {
        return
      }

      this.$emit(this.isEditMode ? 'update-tag' : 'create-new-tag', this.tag)
    },
    onRemoveSelectedTags(name) {
      const index = this.tag.entityTypes.findIndex((item) => item === name);
      if (index !== -1) {
        this.tag.entityTypes.splice(index, 1);
      }
    },
    tagsSelection(name) {
      return this.tag.entityTypes ? this.tag.entityTypes.some((item) => item === name) : false;
    },
    onCancel() {
      this.$emit('close-dialog')
    }
  }
}
</script>