<template>
  <div class="text-center">
    <v-dialog
      v-model="isOpenStatus"
      max-width="400"
      class="dialog-theme"
      @click:outside="clickOutside"
    >
      <v-card
        prepend-icon="mdi-map-marker"
        text="Let Google help apps determine location. This means sending anonymous location data to Google, even when no apps are running."
        title="Use Google's location service?"
      >
        <template v-slot:actions>
          <v-spacer />

          <v-btn
            width="194px"
            color="#F2F4F7"
            full-width
            height="40"
            depressed
            class="text-capitalize btn-theme"
            elevation="0"
            @click="$emit('closeDialog')"
          >
            {{ $t('cancel') }}
          </v-btn>

          <v-btn
            width="194px"
            class="btn-theme text-capitalize"
            height="40"
            color="#F2284E"
            :depressed="true"
            full-width
            elevation="0"
            @click="$emit('confirm')"
          >
            {{ $('remove') }}
          </v-btn>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    isOpen: Boolean
  },
  data() {
    return {
      isOpenStatus: this.isOpen
    }
  },
  watch: {
    isOpen(newVal) {
      this.isOpenStatus = newVal
    }
  },
  methods: {
    clickOutside() {
      this.$emit('closeDialog')
    }
  }
}
</script>
